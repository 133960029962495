import { html } from 'fxjs/es';

export const tipHtml = () => {
  return html`
    <div class="pb-product-tip">
      <h2 class="pb-product-tip__title">상품 등록 살펴보기</h2>
      <div class="pb-product-tip__body">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <ul class="pb-product-tip__ul">
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/ceb72577d48ff28f655f625df20d1103683297da1.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">상품 이미지 등록</h3>
                    <p class="pb-product-tip__p">
                      상품 이미지를 직접 사진을 찍거나 디자인해서 등록할 수 있어요. 대표 이미지 1장은 필수이며
                      최대 6장까지 등록이 가능해요.
                    </p>
                  </div>
                </li>
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/a8fb19a8cbc2cbe254bddbdf68eafea4e5ed92da1.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">상품 정보 등록</h3>
                    <p class="pb-product-tip__p">
                      내가 등록할 상품의 기본 정보인 상품명, 가격, 카테고리 등을 등록해요. 상품의 공개 여부도
                      선택할 수 있어요.
                    </p>
                  </div>
                </li>
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/e3f137dc1e918752b9a4124c488265bc3fb8f2f31.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">상품 타입 선택</h3>
                    <p class="pb-product-tip__p">
                      상품 타입에 따라서 등록하는 정보가 달라져요. 내 상품의 특별함을 핸드메이드, 커스텀주문,
                      한정판 등의 타입으로 강조할 수도 있어요.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="swiper-slide">
              <ul class="pb-product-tip__ul">
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/3983f7c61aaad8db2ce834ee0b1b1e51ed8c9c281.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">옵션 정보 등록</h3>
                    <p class="pb-product-tip__p">
                      상품 타입마다 다르게 노출되는 옵션 등을 활용해 내 상품을 특별함을 표현해 보세요.
                    </p>
                  </div>
                </li>
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/054d751dbc6d68f50b30dc94ef72767941e17abe1.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">상품 등록 완료!</h3>
                    <p class="pb-product-tip__p">
                      내 상품을 3분만에 쉽게 등록할 수 있어요. 🎉<br />
                      상품 등록 후에는
                      <strong
                        >마플샵 스튜디오 > 상품 관리 ><br />
                        크리에이터 정보</strong
                      >를 등록해 주세요.
                    </p>
                  </div>
                </li>
                <li class="pb-product-tip__li">
                  <div class="pb-product-tip__img">
                    <img
                      src="//s3.marpple.co/files/u_218933/2023/1/original/396f4f4f5b8308704370c064341f3861b3a61dbf1.png"
                      alt=""
                    />
                  </div>
                  <div class="pb-product-tip__text">
                    <h3 class="pb-product-tip__h3">마플샵에서 상품이 보이기를 원해요!</h3>
                    <p class="pb-product-tip__p">
                      처음 등록한 상품은 개인 크리에이터샵에서만 판매할 수 있어요. 마플샵 메인 등에서도
                      노출하고 싶다면<br />
                      <strong>마플샵에 상품 공개하기</strong>로 심사를 신청해 주세요.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="swiper-pagination swiper-pagination-black swiper-pagination-clickable swiper-pagination-bullets"
          ></div>
          <div class="swiper-button-prev swiper-button-black"></div>
          <div class="swiper-button-next swiper-button-black"></div>
        </div>
      </div>
    </div>
  `;
};
