import axios from 'axios';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { extend } from 'fxjs/es';

export const loginMessage = async (user, navigate) => {
  if (!user || !MShopUtilF.isApp()) return;
  const { id, count: cart_count, name, avatar = '', token, type } = user;
  MShopUtilF.postMessage({
    login: extend(
      {
        id,
        type,
        cart_count,
        name,
        avatar,
        store: user?._?.store,
        token: token || (await axios.get('/@api/session/token')).data,
      },
      navigate && { navigate },
    ),
  });
};
