import { go, map, pipe } from 'fxjs/es';
import DesignCollectionDetailF from '../../../../../modules/DesignCollection/Detail/F/index.js';
import { OMPDesignCollectionAdminDetailF } from '../../../../../modules/OMP/DesignCollection/Admin/Detail/F/Function/module/OMPDesignCollectionAdminDetailF.js';

$.frame.defn_frame({
  frame_name: 'mp.design_collection_maker',
  page_name: 'mp.design_collection_maker_page',
  header_height: G.is_pc_size() ? 0 : 60,
});
$.frame.defn_page({
  page_name: 'mp.design_collection_maker_page',
  tabs: [
    {
      tab_name: 'mp.design_collection_maker_tab',
      appended: pipe(
        DesignCollectionDetailF.fs.mp_design_collection_maker_tab_appended,
        OMPDesignCollectionAdminDetailF.delegateOmpTagEditorEvents,
      ),
    },
  ],
});
$.frame.defn_frame({
  frame_name: 'mp.design_collection',
  page_name: 'mp.design_collection_page',
});

$.frame.defn_frame({
  frame_name: 'mp.design_collection_list',
  page_name: 'mp.design_collection_list_page',
});
$.frame.defn_page({
  page_name: 'mp.design_collection_list_page',
  tabs: [
    {
      tab_name: 'mp.design_collection_list_tab',
    },
  ],
});

$.frame.defn_frame({
  frame_name: 'mp.design_collection_list_editor',
  page_name: 'mp.design_collection_list_editor_page',
});
$.frame.defn_page({
  page_name: 'mp.design_collection_list_editor_page',
  tabs: [
    {
      tab_name: 'mp.design_collection_list_editor_tab',
      appended: DesignCollectionDetailF.fs.mp_design_collection_list_editor_tab_appended,
    },
  ],
});

$.frame.defn_frame({
  frame_name: 'mp.design_collection_content_items_frame',
  page_name: 'mp.design_collection_content_items_page',
  el_class: 'design_collection_content_items_frame',
  always_remove: true,
  is_modal: G.is_pc_size(),
  closed: function (el, is_done) {
    if (is_done) return DesignCollectionDetailF.fs.mp_design_collection_content_items_frame_closed();
  },
  appended: __(
    $.on('click', '.don_page >.don_wrapper >.header .done', function (e) {
      go(
        e.delegateTarget,
        $.find('.card_list .item'),
        _p.reject((item) => $.attr(item, 'no_thumbnail') == null),
        function (selected_items) {
          if (selected_items.length !== 4) return $.alert('4개의 상품을 선택해야만 완료 할 수 있습니다.');
          $.frame.close(true);
        },
      );
    }),
  ),
});

$.frame.defn_page({
  page_name: 'mp.design_collection_page',
  tabs: [
    {
      tab_name: 'mp.design_collection_tab',
      appended: DesignCollectionDetailF.fs.mp_design_collection_tab_appended,
    },
  ],
});

$.frame.defn_page({
  page_name: 'mp.design_collection_content_items_page',
  custom_header: () => pug`
      .title 상품 목록
      button[type=button].done 완료
    `,
  tabs: [
    {
      tab_name: 'mp.design_collection_content_items_tab',
      data_func: __(
        DesignCollectionDetailF.fs.design_collection_content_items,
        map(_p.clone),
        function (dcis) {
          box.set('cloned_design_collection_content_items', dcis);
          return dcis;
        },
      ),
      template: (dcis) => pug`
        .card_list
          ${_p.sum(
            dcis,
            (dci) => pug`
          .item[no_thumbnail="${dci.no_thumbnail}" _sel="cloned_design_collection_content_items->(#${
              dci.id
            })"]
            img[src="${dci.url || ''}" style="width:${(dci.width || 50) + '%'}; top:${
              (dci.top || 50) + '%'
            };"]
          `,
          )}
      `,
      appended: DesignCollectionDetailF.fs.mp_design_collection_content_items_tab_appended,
    },
  ],
});
