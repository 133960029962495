import { find, go, reject, sel } from 'fxjs/es';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilS } from '../../../../Util/S/Function/module/MShopUtilS.js';

import { MShopStudioPbProductConstantS } from '../Constant/module/MShopStudioPbProductConstantS.js';
import { PB_PRODUCT_TYPES } from '../Constant/pb_product_types.js';
import { pb_product_editor_error } from './pbProductEditorError.js';

const pb_types = PB_PRODUCT_TYPES;
const pd_error = pb_product_editor_error;
const stock_types = SpoSpoConstantS.pSpoStockType;

export const checkThumbnail = (thumbnails) => {
  if (!thumbnails.length) return { result: false, error: pd_error.thumbnail_picture, type: 'thumbnails' };
  if (thumbnails.length > 6)
    return { result: false, error: pd_error.thumbnail_picture_max, type: 'thumbnails' };
  return { result: true };
};

export const checkPdName = (name) => {
  if (!name?.length) return { result: false, error: pd_error.required, type: 'pd_name' };
  if (name.length < 3) return { result: false, error: pd_error.min_text, type: 'pd_name' };
  if (name.length > 100) return { result: false, error: pd_error.text_length_100, type: 'pd_name' };
  if (MShopUtilS.isNotAllowSpecialCharProductName(name)) {
    return { result: false, error: pd_error.restrict_character2, type: 'pd_name' };
  }
  if (MShopUtilS.isProductNameBlackList(name)) {
    return { result: false, error: pd_error.black_list_name, type: 'pd_name' };
  }

  return { result: true };
};

export const checkPdProfit = (price) => {
  if (!UtilS.is_number(price)) return { result: false, error: pd_error.only_number, type: 'pd_profit' };
  if (parseInt(price) < 100) return { result: false, error: pd_error.min_price100, type: 'pd_profit' };
  if (parseInt(price) > 1000000) return { result: false, error: pd_error.max_price, type: 'pd_profit' };
  return { result: true };
};

export const checkClientPdSellerProfitShare = (num) => {
  if (!UtilS.is_number(num)) {
    return { result: false, error: pd_error.only_number, type: 'pd_seller_profit_share' };
  }
  if (UtilS.is_number(num) < 0 || UtilS.is_number(num) > 100) {
    return { result: false, error: pd_error.min0_max100, type: 'pd_seller_profit_share' };
  }

  return { result: true };
};

export const checkServerPdSellerProfitShare = (num) => {
  if (parseFloat(num) < 0 || parseFloat(num) > 1) {
    return { result: false, error: pd_error.min0_max100, type: 'pd_seller_profit_share' };
  }

  return { result: true };
};

export const checkOptionPrice = (price) => {
  if (!UtilS.is_number(price)) return { result: false, error: pd_error.only_number, type: 'option_price' };
  if (parseInt(price) < 0) return { result: false, error: pd_error.option_min_price, type: 'option_price' };
  if (parseInt(price) > 1000000)
    return { result: false, error: pd_error.option_max_price, type: 'option_price' };

  return { result: true };
};

export const checkOptionStock = (stock) => {
  if (!UtilS.is_number(stock)) return { result: false, error: pd_error.only_number, type: 'option_stock' };
  return { result: true };
};

export const checkOptionRealStock = (stock) => {
  if (!UtilS.is_number(stock))
    return { result: false, error: pd_error.only_number, type: 'option_real_stock' };
  return { result: true };
};

export const checkPdTotalStock = (count) => {
  if (!UtilS.is_number(count)) return { result: false, error: pd_error.only_number, type: 'total_stock' };
  return { result: true };
};

export const checkPdRealTotalStock = (count) => {
  if (!UtilS.is_number(count))
    return { result: false, error: pd_error.only_number, type: 'real_total_stock' };
  return { result: true };
};

export const checkPdCategory = (cate_list_id, cate_item_id) => {
  if (!cate_list_id || !cate_item_id)
    return { result: false, error: pd_error.select_category, type: 'category' };
  return { result: true };
};

export const checkProducingPeriod = (pd_type, avg_days, max_days) => {
  if (!pd_type) throw new Error('상품타입 정보가 없습니다.');

  if ([pb_types.print_on_demand, pb_types.hand_made, pb_types.custom].includes(pd_type)) {
    if (!avg_days) return { result: false, error: pd_error.producing_period_required, type: 'avg_days' };
    if (!max_days) return { result: false, error: pd_error.producing_period_required, type: 'max_days' };
    if (!UtilS.is_number(avg_days)) {
      return { result: false, error: pd_error.only_number, type: 'max_days' };
    }
    if (!UtilS.is_number(max_days)) {
      return { result: false, error: pd_error.only_number, type: 'max_days' };
    }
  }

  if (pd_type == pb_types.limited) {
    if (avg_days && !UtilS.is_number(avg_days)) {
      return { result: false, error: pd_error.limited_only_number, type: 'avg_days' };
    }
    if (max_days && !UtilS.is_number(max_days)) {
      return { result: false, error: pd_error.limited_only_number, type: 'max_days' };
    }
    if (!avg_days && max_days) {
      return { result: false, error: pd_error.limited_only_number, type: 'avg_days' };
    }
    if (avg_days && !max_days) {
      return { result: false, error: pd_error.limited_only_number, type: 'max_days' };
    }
  }

  if (parseInt(avg_days) >= parseInt(max_days)) {
    return { result: false, error: pd_error.producing_period_avg_big, type: 'avg_days' };
  }

  return { result: true };
};

export const checkSpoOptionName = (name) => {
  if (!name?.length) return { result: false, error: pd_error.required, type: 'option_name' };
  if (name.length > 300) return { result: false, error: pd_error.text_length_300, type: 'option_name' };
  if (MShopUtilS.isNotAllowSpecialCharProductName(name)) {
    return { result: false, error: pd_error.restrict_character2, type: 'option_name' };
  }
  if (MShopUtilS.isProductNameBlackList(name)) {
    return { result: false, error: pd_error.black_list_name, type: 'option_name' };
  }

  return { result: true };
};

export const checkSpoOptionDetailName = (name) => {
  if (!name?.length) return { result: false, error: pd_error.required, type: 'option_name' };
  if (name.split(',').length > 100) {
    return { result: false, error: pd_error.option_length_100, type: 'option_name' };
  }
  if (MShopUtilS.isNotAllowSpecialCharProductName(name)) {
    return { result: false, error: pd_error.restrict_character2, type: 'option_name' };
  }
  if (MShopUtilS.isProductNameBlackList(name)) {
    return { result: false, error: pd_error.black_list_name, type: 'option_name' };
  }

  return { result: true };
};

export const checkSpoDirectOptionTxt = (txt) => {
  if (!txt?.length) return { result: false, error: pd_error.required, type: 'direct_option' };
  if (txt.length > 100) return { result: false, error: pd_error.text_length_100, type: 'direct_option' };

  return { result: true };
};

export const checkCertificationInfoTxt = (txt) => {
  if (!txt?.length) return { result: false, error: pd_error.required, type: 'certification_info' };

  return { result: true };
};

export const checkHsCode = (hs_code) => {
  if (!hs_code?.length) return { result: false, error: pd_error.required, type: 'hs_code' };

  return { result: true };
};

export const checkQnaLink = (txt) => {
  if (!txt?.length) return { result: false, error: pd_error.required, type: 'qna_link' };
  if (!UtilS.isUrl(txt)) return { result: false, error: pd_error.qna_sns_link, type: 'qna_link' };

  return { result: true };
};

export const checkPdType = (stores_product) => {
  const arr = [];
  if (!stores_product?.product_type_id) arr.push({ result: false, error: pd_error.pb_type, type: 'pb_type' });

  const spo = sel('_.product._.spo', stores_product);
  const spo_producing_period = sel('_.product._.spo._.spo_producing_period', stores_product);

  if (!spo) arr.push({ result: false, error: pd_error.pb_type, type: 'pb_type' });

  const res = checkProducingPeriod(
    stores_product.product_type_id,
    spo_producing_period?.avg_days,
    spo_producing_period?.max_days,
  );

  if (!res.result) arr.push(res);

  if (stores_product.product_type_id == pb_types.limited) {
    if (
      !parseInt(stores_product.quantity) &&
      !stores_product.sell_start_at &&
      !parseInt(stores_product.max_purchase_per_user)
    ) {
      arr.push({
        result: false,
        error: pd_error.quantity_and_sell_period_and_max_purchase_per_user,
        type: 'limited',
      });
    }

    if (stores_product.quantity && !UtilS.is_number(stores_product.quantity)) {
      arr.push({ result: false, error: pd_error.check_limited_quantity, type: 'limited_quantity' });
    }

    if (stores_product.max_purchase_per_user && !UtilS.is_number(stores_product.max_purchase_per_user)) {
      arr.push({
        result: false,
        error: pd_error.check_max_purchase_per_user,
        type: 'limited_max_purchase_per_user',
      });
    }

    if (stores_product.sell_start_at || stores_product.sell_end_at) {
      if (stores_product.sell_start_at && !stores_product.sell_end_at) {
        arr.push({ result: false, error: pd_error.required, type: 'limited_period' });
      } else if (!stores_product.sell_start_at && stores_product.sell_end_at) {
        arr.push({ result: false, error: pd_error.required, type: 'limited_period' });
      } else if (stores_product.sell_start_at > stores_product.sell_end_at) {
        arr.push({ result: false, error: pd_error.limited_start_at_end_at_err, type: 'limited_period' });
      }
    }
  }

  return arr;
};

export const checkStockType = (stores_product) => {
  const arr = [];
  if (!stores_product) throw new Error(pd_error.pd_empty.text);

  const spo = sel('_.product._.spo', stores_product);
  if (!spo) throw new Error('spo 정보가 없습니다.');

  if (spo.stock_type == stock_types.TOTAL_STOCK) {
    const res = checkPdTotalStock(sel('_.spo_item_stock.quantity', spo));
    if (!res.result) arr.push(res);
  }

  if (spo.stock_type != stock_types.OPTION_STOCK && sel('_.spo_items.length', spo)) {
    const find_error = find((spo_item) => {
      const err_name = find((gnon) => {
        const res_group_name = checkSpoOptionName(gnon.group_name);
        if (!res_group_name.result) return true;
        const res_option_name = checkSpoOptionDetailName(gnon.option_name);
        if (!res_option_name.result) return true;
      }, spo_item.group_names_option_names);

      if (err_name) return true;

      const res_price = checkOptionPrice(spo_item.price);
      if (!res_price.result) return true;
    }, sel('_.spo_items', spo));

    if (find_error) arr.push({ result: false, error: pd_error.spo_option_error, type: 'spo_items' });
  }

  if (spo.stock_type == stock_types.OPTION_STOCK) {
    if (!sel('_.spo_items.length', spo)) {
      arr.push({ result: false, error: pd_error.spo_option_stock_items_length, type: 'spo_items' });
    }

    const find_error = find((spo_item) => {
      const err_name = find((gnon) => {
        const res_group_name = checkSpoOptionName(gnon.group_name);
        if (!res_group_name.result) return true;
        const res_option_name = checkSpoOptionDetailName(gnon.option_name);
        if (!res_option_name.result) return true;
      }, spo_item.group_names_option_names);

      if (err_name) return true;

      const res_price = checkOptionPrice(spo_item.price);
      if (!res_price.result) return true;

      const res_stock = checkOptionStock(spo_item._.spo_item_stock.quantity);
      if (!res_stock.result) return true;
    }, sel('_.spo_items', spo));

    if (find_error) arr.push({ result: false, error: pd_error.spo_option_error, type: 'spo_items' });
  }

  return arr;
};

export const checkDataErrors = (
  stores_product,
  pb_thumbnail_photos,
  is_global = false,
  create_sku = false,
) => {
  const errors = [];

  const resCheckThumbnail = checkThumbnail(pb_thumbnail_photos);
  if (!resCheckThumbnail.result) errors.push(resCheckThumbnail);

  const resCheckPdName = checkPdName(sel('_.product.name', stores_product));
  if (!resCheckPdName.result) errors.push(resCheckPdName);

  const resCheckPdProfit = checkPdProfit(sel('_.product.profit', stores_product));
  if (!resCheckPdProfit.result) errors.push(resCheckPdProfit);

  const resCheckPdSellerProfitShare = checkServerPdSellerProfitShare(
    sel('seller_profit_share', stores_product),
  );
  if (!resCheckPdSellerProfitShare.result) errors.push(resCheckPdSellerProfitShare);

  const resCheckCategory = checkPdCategory(stores_product.sp_cate_list_id, stores_product.sp_cate_item_id);
  if (!resCheckCategory.result) errors.push(resCheckCategory);

  const checkPdTypeErrors = checkPdType(stores_product);
  if (checkPdTypeErrors.length) errors.push(...checkPdTypeErrors);

  const checkStockTypeErrors = checkStockType(stores_product);
  if (checkStockTypeErrors.length) errors.push(...checkStockTypeErrors);

  if (stores_product.qna_type == MShopStudioPbProductConstantS.QNA_TYPES.SNS) {
    const res_qna_link = checkQnaLink(stores_product.qna_link);
    if (!res_qna_link.result) errors.push(res_qna_link);
  }

  if (is_global) {
    const resCheckPdNameEn = checkPdName(sel('_.product.name_en', stores_product));
    if (!resCheckPdNameEn.result) errors.push(resCheckPdNameEn);
    const resCheckPdNameJp = checkPdName(sel('_.product.name_jp', stores_product));
    if (!resCheckPdNameJp.result) errors.push(resCheckPdNameJp);
    const resHsCode = checkHsCode(sel('_.hs_code', stores_product));
    if (!resHsCode.result) errors.push(resHsCode);
    const resMaterial = checkMaterial(stores_product);
    if (!resMaterial.result) errors.push(resMaterial);

    if (create_sku) {
      const resCheckSkuSize = checkSkuSize(sel('_.product._.spo._.spo_items', stores_product));
      if (!resCheckSkuSize.result) errors.push(resCheckSkuSize);

      if (sel('_.product._.spo._.spo_options', stores_product).length === 0) {
        const resNoOptionSkuSize = checkNoOptionSkuSize(
          sel('_.product._.spo._.no_option_sku', stores_product),
        );
        if (!resNoOptionSkuSize.result) errors.push(resNoOptionSkuSize);
      }
    }
  }

  return errors;
};

export const checkMaterial = (stores_product) => {
  const is_cloth = sel('is_cloth', stores_product);
  const material = sel('material', stores_product);
  if (is_cloth && !material?.length) {
    return { result: false, error: pd_error.check_material, type: 'material' };
  }
  return { result: true };
};

export const checkSkuSize = (skus) => {
  const no_size = go(
    skus,
    reject(({ sku }) => sku.width && sku.height && sku.length && sku.weight),
  );
  if (no_size.length) {
    return { result: false, error: pd_error.check_sku_size, type: 'sku_size' };
  }
  return { result: true };
};

export const checkNoOptionSkuSize = (no_option_sku) => {
  const {
    size: { width, height, length, weight },
  } = no_option_sku;
  if (!(width && height && length && weight)) {
    return { result: false, error: pd_error.check_sku_size, type: 'sku_size' };
  }
  return { result: true };
};
