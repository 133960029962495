import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { makeProductStoryEditorHtml } from '../../../../../Creator/ProductStoryEditor/S/tmpl.js';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorTmplS } from './module/MShopStudioDigitalProductCreatorTmplS.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;
export const baseCreatorMo = ({ digital_product, store_ecommerce_info, crew_domain_name }) => {
  const qna_type = store_ecommerce_info?.qna_type || digital_product?.qna_type;
  const front_data =
    MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[digital_product?.sp_cate_item_id];
  return html`
    <div class="digital-product-creator" data-fx-json="${$dataStr(digital_product)}">
      <div class="digital-product-creator__wrapper-for-background">
        <div class="digital-product-creator__head">
          <p class="digital-product-creator__desc">
            <span class="digital-product-creator-form__required">*</span>
            ${T(messages.digital_products.required)}
          </p>
        </div>
        <div class="digital-product-creator__thumbnails-area">
          ${MShopStudioDigitalProductCreatorTmplS.makeThumbnailRegister(digital_product?.thumbnails)}
        </div>
      </div>
      <div class="digital-product-creator__form-area">
        <div class="digital-product-creator-form">
          <div class="digital-product-creator-form__control dpc-styl__mt-0px">
            ${MShopStudioDigitalProductCreatorTmplS.makeIsPublicInputHtml(digital_product?.is_public)}
          </div>
          <div class="digital-product-creator-form__control dpc-styl__mt-40px">
            ${MShopStudioDigitalProductCreatorTmplS.makeProductNameInputHtml({
              product_name_kr: digital_product?.product_name_kr,
            })}
          </div>
          <div class="digital-product-creator-form__control dpc-styl__mt-40px">
            ${MShopStudioDigitalProductCreatorTmplS.makeProductPriceInputHtml(digital_product?.price)}
          </div>
          <div class="digital-product-creator-form__control dpc-styl__mt-40px">
            ${MShopStudioDigitalProductCreatorTmplS.makeSetLicenseRangeInputHtml(digital_product?.license)}
          </div>

          <hr />

          ${
            /* TODO 카테고리 하드코딩 */ digital_product?.sp_cate_item_id ===
            MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE
              ? MShopStudioDigitalProductCreatorTmplS.makeIsHyperLinkInputHtml(digital_product?.is_hyper_link)
              : ''
          }

          <div class="digital-product-creator-form__control">
            <div class="digital-product-creator-form__input-title">
              ${front_data.upload_input_title}
              <span class="digital-product-creator-form__required">*</span>
            </div>
            ${MShopStudioDigitalProductCreatorTmplS.makeUploadFileHtml({
              guide_main: front_data.upload_guide_main_message,
              guide_sub: front_data.upload_guide_sub_message,
              accept: front_data.accept,
            })}
            ${digital_product.files_infos?.length
              ? html`
                  ${MShopStudioDigitalProductCreatorTmplS.makeUploadResultsDashboardHtml({
                    files_total_count: digital_product?.files_infos?.length,
                    init_count: digital_product?.files_infos?.length,
                  })}
                  <div
                    class="digital-product-creator-form__upload-results digital-product-creator-form__toggle"
                  >
                    ${strMap(
                      MShopStudioDigitalProductCreatorTmplS.makeImageUploadResultHtml,
                      digital_product?.files_infos,
                    )}
                  </div>
                `
              : ''}
          </div>

          ${
            /* TODO 카테고리 하드코딩 */ digital_product?.sp_cate_item_id ===
            MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.VOICE_PACK
              ? html` <div class="digital-product-creator-form__control">
                  <div class="digital-product-creator-form__input-title">미리듣기 파일 업로드</div>

                  ${MShopStudioDigitalProductCreatorTmplS.makePreviewUploadFileHtml({
                    guide_main: '미리듣기 파일을 업로드해 주세요.',
                    guide_sub: '업로드 가능 형식 : MP3, ACC / 파일당 1GB 이하 / 최대 60초 업로드 가능',
                    accept: '.mp3,.acc,.m4a',
                  })}
                </div>`
              : ''
          }

          <hr />

          <div class="digital-product-creator-form__control">
            ${MShopStudioDigitalProductCreatorTmplS.makeTokenGateInputHtml(
              digital_product?.stores_products_token_gates,
            )}
          </div>
          <div class="digital-product-creator-form__story dpc-styl__mt-40px">
            ${makeProductStoryEditorHtml(digital_product?.product_story, crew_domain_name)}
          </div>

          <hr />

          <div class="digital-product-creator-form__control dpc-styl__mt-24px">
            ${MShopStudioDigitalProductCreatorTmplS.makeSetQnaTypeInputHtml({
              qna_type,
              qna_link: digital_product?.qna_link || store_ecommerce_info?.qna_link,
            })}
          </div>

          <div class="digital-product-creator-form__information-list dpc-styl__mt-24px">
            ${MShopStudioDigitalProductCreatorTmplS.makeInformationHtml({
              title: '디지털 상품 판매 안내',
              contents: [
                '구매자는 결제 완료 후, 구매내역에서 7일 간 다운로드 받을 수 있습니다. (기한 이후 다운로드를 원할 경우 재구매가 필요합니다.)',
                '디지털 상품 특성 상 전자상거래 법 제 17조에 의거하여 구매자가 구매한 상품에 문제가 없다면 어떠한 사유로도 교환 및 환불이 불가합니다.',
                '구매되었거나 장바구니에 담긴 상품은 수정하거나 삭제할 수 없습니다. 파일을 수정하고 싶을 경우 새로운 상품을 등록해 주세요.',
              ],
            })}
            ${MShopStudioDigitalProductCreatorTmplS.makeInformationHtml({
              title: '판매 수수료 정보',
              contents: [
                '디지털 상품 판매 수수료는 1건당 400원이며 아래 비용을 포함하고 있습니다.',
                '&nbsp;(클라우드 스토리지 비용 업로드 / 다운로드 트래픽 비용 / 구매 문자, 알림톡 및 이메일 전송 비용 / 저작권 보호를 위한 구매 고객 휴대폰 인증 비용)',
              ],
            })}
            ${MShopStudioDigitalProductCreatorTmplS.makeInformationHtml({
              title: '디지털 저작권 정보',
              contents: [
                '개인 소장 및 다운로드용 라이센스 판매입니다.',
                '이미지/디자인은 크리에이터 본인이 직접 촬영/창작한 작업물이며 저작권/소유권이 명확히 있는 콘텐츠의 판매입니다.',
                '타인의 이미지 또는 타 사이트에서 가져온 이미지로 발생하는 모든 법적 분쟁에 대한 책임은 크리에이터 본인에게 있습니다.',
                '저작권, 재산권, 초상권 위반 발견 시 해당 샵은 계정 운영 정지될 수 있으며 관련 분쟁 책임 및 피해 보상 책임이 크리에이터 본인에게 있습니다.',
              ],
              required: true,
              custom_html: html`
                <label class="digital-product-creator-form__check-box-label dpc-styl__mt-18px">
                  <input
                    type="checkbox"
                    name="${digital_product_prop.agree_copyright}"
                    class="digital-product-creator--required-property"
                    ${digital_product?.agree_copyright && 'checked'}
                  />
                  디자인 저작권 정보에 동의합니다.
                  <span class="digital-product-creator-form__required-message">(필수)</span>
                </label>
              `,
            })}
          </div>
        </div>
      </div>
      <div class="digital-product-creator__form-buttons digital-product-creator__form-buttons">
        ${MShopStudioDigitalProductCreatorTmplS.creatorBottomButtons(digital_product?.product_id)}
      </div>
    </div>
  `;
};
