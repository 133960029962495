import axios from 'axios';
import {
  $addClass,
  $append,
  $appendTo,
  $attr,
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $hide,
  $parent,
  $qs,
  $qsa,
  $remove,
  $removeClass,
  $setAttr,
  $setData,
  $setScrollTop,
  $setText,
  $setVal,
  $show,
} from 'fxdom/es';
import { each, filter, find, go, ippL, isUndefined, map, pick, reject, split, tap } from 'fxjs/es';
import JSZip from 'jszip';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { makeAudioTagHtml } from '../../../../App/ProductDetail/S/Tmpl/partials/dtgInfo.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { isCreatorApp } from '../../../../Util/F/Function/util.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorS } from '../../S/Function/module/MShopStudioDigitalProductCreatorS.js';
import { byteConverter } from '../../S/Function/util.js';
import { makeImageUploadResultFailHtml } from '../../S/Tmpl/makeEachCategoryHtml.js';
import { appendFailUploadResultMo, changeDashboardFailState } from './elementControl.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;

export const getAllState = () => {
  return go($qs('.digital-product-creator'), $data);
};
export const setState = ({ key, value, state }) => {
  if (state) {
    go($qs('.digital-product-creator'), $setData(state));
  }

  if (!isUndefined(key) && !isUndefined(value)) {
    const all_state = getAllState();
    all_state[key] = value;
    go($qs('.digital-product-creator'), $setData(all_state));
  }
};

export const getStoreEcommerceInfo = async (store_id) => {
  const {
    data: { store_ecommerce_info = null },
  } = await axios.get(`/${T.lang}/@api/stores/${store_id}/pb_products/base_info`);

  return store_ecommerce_info;
};

export const checkAllInput = ($container_el) => {
  const all_state = getAllState();
  const errors = MShopStudioDigitalProductCreatorS.getDataErrors(
    all_state,
    box.sel('digital_product_thumbnail_photos'),
  );

  const fail_files = $findAll('.digital-product-creator-form__upload-result--fail', $container_el);

  const $btn_submit_els = $findAll('.digital-product-creator__btn-submit', $container_el);
  const is_uploading_file = isUploading();
  if (errors.length || fail_files.length || is_uploading_file) {
    each($addClass('digital-product-creator__btn-submit--disabled'), $btn_submit_els);
  } else {
    each($removeClass('digital-product-creator__btn-submit--disabled'), $btn_submit_els);
  }
};

export const getSignedUrlInformation = async ({
  Key,
  operation,
  file_name,
  file_size,
  store_id,
  file_ext,
}) => {
  const { data } = await axios.get(
    `/${T.lang}/@api/stores/${store_id || box.sel('store_id')}/digital_product/signed_url`,
    {
      params: {
        operation,
        Key,
        file_name: encodeURIComponent(file_name),
        file_size: encodeURIComponent(byteConverter(file_size)),
        file_ext,
      },
    },
  );
  return data;
};

export const hashFileName = (fileName) => {
  return fileName.split('').reduce((hash, char) => {
    const charCode = char.charCodeAt(0);
    hash = (hash << 5) - hash + charCode;
    return hash & hash; // Convert to 32-bit integer
  }, 0);
};

export const upLoadFileToS3 = async ({ signed_url, file, currentTarget }) => {
  await axios.put(signed_url, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-amz-meta-name': encodeURIComponent(file.name),
      'x-amz-meta-size': encodeURIComponent(MShopStudioDigitalProductCreatorS.byteConverter(file.size)),
      'x-amz-meta-ext': encodeURIComponent(
        MShopStudioDigitalProductCreatorS.getExtensionOfFilename(file?.name),
      ),
    },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      go(currentTarget, $find('.digital-product-creator-form__upload-result__progress'), $setVal(percentage));

      go(
        currentTarget,
        $find('.digital-product-creator-form__upload-result__progress-view'),
        $setText(`${percentage}%`),
      );
    },
  });
};

export const errorPass = (el) => {
  go(
    el,
    $closest('.digital-product-creator-form__control'),
    tap(
      $findAll('.digital-product-creator-form__input-wrapper'),
      each($removeClass('digital-product-creator-form__input-wrapper--error')),
    ),
    $findAll('.digital-product-creator__error-msg'),
    each($remove),
  );
};

export const showError = (error_check_result) => {
  const $current_el = go($qs('.digital-product-creator'), $find(`input[name=${error_check_result.key}]`));

  if (error_check_result.is_error) {
    const $err_msg_el = $el(
      `<p class="digital-product-creator__error-msg">${error_check_result.error.text}</p>`,
    );

    return go(
      $current_el,
      $closest('.digital-product-creator-form__control'),
      tap(
        $findAll('.digital-product-creator-form__input-wrapper'),
        each($addClass('digital-product-creator-form__input-wrapper--error')),
      ),
      tap($find('.digital-product-creator__error-msg'), ($err_msg_el) => $err_msg_el && $remove($err_msg_el)),
      $append($err_msg_el),
    );
  }

  errorPass($current_el);
};

const getNotAllowAccept = (file, accepts) => {
  const extension = (file.name.match(/\.[^/.]+$/) || [''])[0].toLowerCase();
  return accepts.indexOf(file.type) == -1 && accepts.indexOf(extension) == -1;
};

export const validateFile = ({ e, file, $upload_results }) => {
  const input_accepts = go(
    e.currentTarget,
    $attr('accept'),
    split(','),
    map((accept) => {
      return (accept || '').toLowerCase();
    }),
  );

  const not_allow_accept = getNotAllowAccept(file, input_accepts);

  if (not_allow_accept) {
    const $fail_result = $el(
      makeImageUploadResultFailHtml({
        file_name: file?.name,
        message: '업로드 불가능한 확장자입니다.',
      }),
    );

    MShopUtilF.isMobile()
      ? MShopStudioDigitalProductCreatorF.appendFailUploadResultMo({ $fail_result, $upload_results })
      : MShopStudioDigitalProductCreatorF.appendFailUploadResult({ $fail_result, $upload_results });

    changeDashboardFailState({ e, action: 'add' });
    return true;
  }

  if (file.size >= MShopStudioDigitalProductCreatorConstantS.FILE_SIZE_LIMIT) {
    const $fail_result = $el(
      makeImageUploadResultFailHtml({
        file_name: file?.name,
        message: '파일 용량이 초과되었습니다.',
      }),
    );

    MShopUtilF.isMobile()
      ? appendFailUploadResultMo({ $fail_result, $upload_results })
      : MShopStudioDigitalProductCreatorF.appendFailUploadResult({ $fail_result, $upload_results });

    changeDashboardFailState({ e, action: 'add' });
    return true;
  }

  return false;
};

export const getDownloadedFile = async ({ signed_url, file_name }) => {
  if (MShopUtilF.isCreatorApp()) {
    const a = document.createElement('a');
    a.href = signed_url;
    a.download = file_name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    await axios
      .get(signed_url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data]);
        return window.URL.createObjectURL(blob);
      })
      .then((url) => {
        const a_tag = document.createElement('a');
        a_tag.href = url;
        a_tag.download = file_name;
        a_tag.style.display = 'none';
        document.body.appendChild(a_tag);
        a_tag.click();
        document.body.removeChild(a_tag);
        window.URL.revokeObjectURL(url); // cleanup - 쓰임을 다한 url 객체 삭제
      })
      .catch((error) => {
        error.name = 'MShopAppDigitalProductCreatorF.getDownloadedFile';
        ConfigSentryF.error(error);
        console.error('Error:', error);
        $.alert(
          '네트워크 오류로 인해 다운로드에<br>실패하였습니다.<br>자세한 문의는 고객 서비스 센터로<br>연락해 주시기 바랍니다.',
        );
      });
  }
};

export const getDownloadedZipFile = async (file_infos) => {
  if (isCreatorApp()) {
    const digital_product = {
      downloadDigitalProduct: file_infos,
    };
    return window.ReactNativeWebView.postMessage(JSON.stringify(digital_product));
  }
  const zip = new JSZip();
  const downloadPromises = await file_infos.map(({ signed_url, file_name }) =>
    axios
      .get(signed_url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data]);
        // 다운로드된 파일을 zip 파일에 추가
        zip.file(decodeURIComponent(file_name), blob);
      })
      .catch((error) => {
        error.name = 'MShopAppDigitalProductCreatorF.getDownloadedZipFile';
        ConfigSentryF.error(error);
        console.error('Error:', error);
        $.alert(
          '네트워크 오류로 인해 다운로드에<br>실패하였습니다.<br>자세한 문의는 고객 서비스 센터로<br>연락해 주시기 바랍니다.',
        );
      }),
  );

  // 모든 파일 다운로드가 완료된 후에 zip 파일 생성 및 다운로드
  await Promise.all(downloadPromises)
    .then(() => zip.generateAsync({ type: 'blob' }))
    .then(function (content) {
      const blobUrl = URL.createObjectURL(content);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'MarppleDigitalGoods.zip'; // 다운로드될 파일의 이름

      downloadLink.click();

      // 사용이 완료된 Blob URL 해제
      URL.revokeObjectURL(blobUrl);
    });
};

export const $addSrcToPreviewPlayer = async ($preview, signed_url) => {
  return await fetch(signed_url)
    .then((response) => {
      return response.arrayBuffer();
    })
    .then(async function (array_buffer) {
      const url = window.URL.createObjectURL(new Blob([array_buffer]));
      go(url, makeAudioTagHtml, $el, $appendTo($preview));
      const audioContext = new AudioContext();
      return audioContext.decodeAudioData(array_buffer);
    })
    .then((decode_data) => {
      return decode_data.duration;
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const moveScrollToError = (error, $container_el) => {
  const is_mobile = MShopUtilF.isMobile();
  const { y } = is_mobile
    ? { y: 0 }
    : $find('.digital-product-creator', $container_el).getBoundingClientRect();
  const $scroll_el = is_mobile ? $qs('html, body') : $find('.digital-product-creator__right', $container_el);
  const scroll_top = $scroll_el.scrollTop;

  if (error.key === digital_product_prop.thumbnails) {
    return $setScrollTop(0, $scroll_el);
  }

  if (error.key === digital_product_prop.agree_copyright) {
    return $find(`input[name=${error.key}]`, $container_el).focus();
  }

  if (error.key === digital_product_prop.signed_url_keys) {
    const { top } = go(
      $container_el,
      $find('.digital-product-creator-form__upload-box'),
      $closest('.digital-product-creator-form__control'),
    ).getBoundingClientRect();
    return $setScrollTop(scroll_top + top - y - 30, $scroll_el);
  }

  const { top } = $find(`input[name=${error.key}]`, $container_el).getBoundingClientRect();
  $setScrollTop(scroll_top + top - y - 10, $scroll_el);
};

export const showErrors = (errors, $container_el) => {
  go(
    errors,
    filter(({ error }) => error.type === 'text'),
    each(showError),
  );

  moveScrollToError(errors[0], $container_el);

  const alert_error = find(({ error }) => error.type === 'alert', errors);
  if (alert_error) {
    return MShopShareFramePopUpF.alert(alert_error.error.info);
  }
};

function hideMoreButtons(e) {
  if (!$closest('.digital-product-creator__btn-more', e.target)) {
    const $open_els = $qsa('.digital-product-creator__form-buttons-container[is_open="true"] .more-buttons');
    each((el) => go(el, $hide, $parent, $setAttr({ is_open: false })), $open_els);
  }
}

export const defnMoreButtonsEvent = (wrap_el) => {
  document.addEventListener('click', hideMoreButtons);
  return $delegate('click', '.digital-product-creator__btn-more', (e) => {
    go(
      e.currentTarget,
      $closest('.digital-product-creator__form-buttons-container'),
      $setAttr({ is_open: 'true' }),
      $find('.more-buttons'),
      $show,
    );
  })(wrap_el);
};

export const getFileInfoByKeyPath = async (Key, store_id) => {
  return await go(
    axios.get(
      `/${T.lang}/@api/stores/${store_id || box.sel('store_id')}/digital_product/digital_product_info`,
      {
        params: {
          Key,
        },
      },
    ),
    ({ data: { name, size, ext } }) => {
      return { file_name: decodeURIComponent(name), file_size: decodeURIComponent(size), file_ext: ext };
    },
  );
};

export const validatePreviewFile = async ({ file }) => {
  const $audio = new Audio();
  const duration_promise = await new Promise((resolve) => {
    $audio.addEventListener('loadedmetadata', () => {
      resolve($audio.duration);
    });
    $audio.src = URL.createObjectURL(file);
  });
  if (duration_promise > MShopStudioDigitalProductCreatorConstantS.PREVIEW_DURATION_LIMIT) {
    return await MShopShareFramePopUpF.alert({
      title: '미리듣기 시간 초과',
      body: '미리듣기 파일은 60초까지 등록할 수 있습니다.<br>' + '재생시간 확인 후 다시 시도해주세요.',
    });
  }
  if (file.size > MShopStudioDigitalProductCreatorConstantS.FILE_SIZE_LIMIT) {
    return await MShopShareFramePopUpF.alert({
      title: '파일 용량 초과',
      body: '용량이 초과된 파일이 있습니다.\n' + '1GB 이하로 업로드해 주세요.',
    });
  }
  return false;
};

export const isUploading = () => {
  const uploading_files = go($qs('body'), $findAll('progress'), reject($hasClass('none')));
  return !!uploading_files.length;
};

export const disableButtons = (e) => {
  const $btn_submit_els = $findAll('.digital-product-creator__btn-submit', e.delegateTarget);
  each($addClass('digital-product-creator__btn-submit--disabled'), $btn_submit_els);
};

export const getDataForBackEnd = (all_state, $container_el) => {
  const store_id = box.sel('store_id');
  const stores_product = {
    id: all_state.store_product_id,
    store_id: box.sel('store_id'),
    sp_cate_list_id: all_state.sp_cate_list_id,
    sp_cate_item_id: all_state.sp_cate_item_id,
    qna_type: all_state.qna_type,
    qna_link:
      all_state.qna_type === MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS
        ? all_state.qna_link
        : null,
  };

  const stores_products_token_gates = all_state.stores_products_token_gates;

  const singed_url_keys = all_state.signed_url_keys.map((key) => {
    return {
      is_preview: false,
      resource_url: key,
    };
  });

  const digital_product_resources = all_state.preview_key
    ? [{ is_preview: true, resource_url: all_state.preview_key }, ...singed_url_keys]
    : singed_url_keys;

  const digital_product = {
    digital_product_id: all_state.digital_product_id,
    license_type: all_state.license_type,
    is_hyper_link:
      /* TODO 카테고리 하드코딩 */
      all_state.sp_cate_item_id ===
      MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE
        ? all_state.is_hyper_link
        : false,
    agreed_to_terms: all_state.agree_copyright,
  };

  const product = {
    id: all_state.product_id,
    name: all_state.product_name_kr,
    price: all_state.price,
    store_id,
    thumbnails: {
      value: go(
        $findAll('.digital-product-thumbnail-editor__item--on', $container_el),
        ippL,
        map(([idx, el]) => {
          const file_id = $attr('data-file-id', el);
          const thumb_data = $data(el);
          return {
            ...pick(['width', 'height', 'is_pb'], thumb_data),
            url: file_id ? '' : thumb_data.url,
            is_thumb: idx == 0,
          };
        }),
      ),
    },
    is_public: all_state.is_public,
  };

  return { stores_product, product, stores_products_token_gates, digital_product, digital_product_resources };
};
