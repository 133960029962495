export const CREATOR_RECEIPT_FRAME = '@FRAME/Creator/Receipt';
export const CREATOR_RECEIPT_PAGE = '@PAGE/Creator/Receipt';
export const CREATOR_RECEIPT_TAB = '@TAB/Creator/Receipt';

export const SPECIAL_PROMOTION_CODES = [
  'SUPPORT_SELLER_M_SGN',
  'NO_PROFIT_M_SGN',
  'NO_PRINT_NO_PROFIT_M_SGN',
  'FREE_FREE_M_SGN',
  'TESTGOGODEV',
];
