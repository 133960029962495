import { $closest, $data, $find, $findAll, $text } from 'fxdom/es';
import { go, mapC, reject } from 'fxjs/es';
import { MShopStudioDigitalProductCreatorS } from '../../S/Function/module/MShopStudioDigitalProductCreatorS.js';

export const getValidatedFiles = (e) => {
  const uploadedFiles = go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $findAll('.digital-product-creator-form__upload-result'),
    mapC(($result) => {
      const file_name = go($result, $find('.digital-product-creator-form__upload-result__file-name'), $text);
      const file_size = go($result, $find('.digital-product-creator-form__upload-result__file-size'), $text);
      return `${file_name}${file_size}`.replaceAll(/[\s\n]/g, '');
    }),
  );

  const { files_total_count: prev_count } = go(
    e.delegateTarget,
    $find('.digital-product-creator-form__upload-box'),
    $data,
  );

  const uploadFiles = go(
    e.currentTarget.files,
    reject((file) => {
      const { name: file_name, size: file_size } = file;
      return uploadedFiles.includes(
        `${file_name}${MShopStudioDigitalProductCreatorS.byteConverter(file_size)}`.replaceAll(/[\s\n]/g, ''),
      );
    }),
  );

  const current_upload_files_count = uploadFiles.length;
  const files_total_count = prev_count + current_upload_files_count;

  return {
    uploadFiles,
    files_total_count,
  };
};
