import { html, sel } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreCellTmplS } from '../../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPReviewDetailTmplS } from './module/OMPReviewDetailTmplS.js';
export const reveiwDetailPageTmplMo = (
  {
    score,
    is_best = false,
    files,
    created_at,
    comment,
    sns_url,
    _: { base_product_color, base_product, up_c, user },
  } = {},
  review_product,
) => {
  const size_info = up_c?._ ? OMPCoreCellTmplS.getSizeInfo(up_c._) : '';
  const sns = OMPCoreUtilS.getSnsUrl(UtilS.escape(sns_url));
  const { price_ranges, is_use_origin_price } = review_product;

  return html` <div class="omp-review-detail">
    <div class="omp-review-detail__body">
      ${OMPReviewDetailTmplS.reviewDetailBodyWrapperHtml({
        score,
        size_info,
        created_at,
        user,
        comment,
        sns,
        is_best,
      })}
      ${files && files.length ? OMPReviewDetailTmplS.reviewFileTmpl(files) : ''}
      ${OMPCoreCellTmplS.reviewProductInfoForReviewDetailPageMO({
        base_product_color,
        base_product,
        reviews: sel('_.reviews', review_product),
        product_price_html: OMPReviewDetailTmplS.productPriceHtml({ price_ranges, is_use_origin_price }),
      })}
    </div>
  </div>`;
};
