import { $delegate } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopStudioPbProductCreatorF } from '../../../PbProductCreator/F/Function/module/MShopStudioPbProductCreatorF.js';

export const editorEvents = (container_el) => {
  MShopStudioPbProductCreatorF.defnMoreButtonsEvent(container_el);

  return go(
    container_el,

    $delegate('click', '.pb-editor__tip', MShopStudioPbProductCreatorF.clickProductTipOpen),
    $delegate('keyup', '.pb-editor-form__body-input[name="name"]', MShopStudioPbProductCreatorF.keyUpName),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="name"]',
      MShopStudioPbProductCreatorF.focusoutName,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="profit"]',
      MShopStudioPbProductCreatorF.focusoutProfit,
    ),
    $delegate(
      'input',
      '.pb-editor-form__body-input[name="profit"]',
      MShopStudioPbProductCreatorF.convertPriceForm,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="seller_profit_share"]',
      MShopStudioPbProductCreatorF.focusoutPdSellerProfitShare,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="total_stock"]',
      MShopStudioPbProductCreatorF.focusoutTotalStock,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="real_total_stock"]',
      MShopStudioPbProductCreatorF.focusoutRealTotalStock,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="avg_days"]',
      MShopStudioPbProductCreatorF.focusoutAvgDays,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="max_days"]',
      MShopStudioPbProductCreatorF.focusoutMaxDays,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="qna_link"]',
      MShopStudioPbProductCreatorF.focusoutQnaLink,
    ),

    $delegate(
      'change',
      '.pb-editor-form__cate-lists select[name="cate_lists"]',
      MShopStudioPbProductCreatorF.changeCateLists,
    ),

    $delegate(
      'change',
      '.pb-editor-form__cate-items select[name="cate_items"]',
      MShopStudioPbProductCreatorF.changeCateItems,
    ),
    $delegate('change', 'select[name="product_type"]', MShopStudioPbProductCreatorF.changeProductType),
    $delegate('change', 'input[name="stock_type"]', MShopStudioPbProductCreatorF.changeStockType),
    $delegate(
      'change',
      'input[name="check_sales_period"]',
      MShopStudioPbProductCreatorF.changeCheckSalesPeriod,
    ),
    $delegate('change', 'input[name="check_quantity"]', MShopStudioPbProductCreatorF.changeCheckQuantity),
    $delegate('change', 'input[name="seller_profit_share"]', MShopStudioPbProductCreatorF.changeProfitShare),
    $delegate(
      'change',
      'input[name="check_max_purchase_per_user"]',
      MShopStudioPbProductCreatorF.changeCheckMaxPurchasePerUser,
    ),
    $delegate('change', 'input[name="qna_type"]', MShopStudioPbProductCreatorF.changeQnaType),
    $delegate(
      'input',
      'select[name="stores_delivery_company_id"]',
      MShopStudioPbProductCreatorF.changeStoresDeliveryCompanies,
    ),
    $delegate('change', 'input[name="refund_info_type"]', MShopStudioPbProductCreatorF.changeRefundInfoType),
    $delegate('click', '.pb-editor-form__icon-help', MShopStudioPbProductCreatorF.enterHelpIcon),
    $delegate(
      'click',
      '.pb-editor-form__btn-prohibit',
      MShopStudioPbProductCreatorF.patchStoreProductById('is_prohibited'),
    ),
    $delegate(
      'click',
      '.pb-editor-form__btn-is_solution',
      MShopStudioPbProductCreatorF.patchStoreProductById('is_solution'),
    ),
    $delegate('click', '.pb-editor-form__btn-to-tpl', MShopStudioPbProductCreatorF.toTplStoreProduct),
    $delegate('click', '.pb-editor-form__btn-delete', MShopStudioPbProductCreatorF.deleteStoreProduct),
    $delegate('click', '.pb-editor-form__btn-cancel', () => {
      MuiF.closeFrame();
    }),
    $delegate(
      'input',
      '.pb-editor-form__kc-radio input',
      MShopStudioPbProductCreatorF.changeCertificationInfo,
    ),
    $delegate('click', '.pb-editor-form__btn-temp-save', MShopStudioPbProductCreatorF.tempSave),
    $delegate('click', '.pb-editor-form__btn-submit', MShopStudioPbProductCreatorF.submit),
    MShopStudioPbProductCreatorF.delegateTplFeeCheckValue,
    $delegate('change', 'input[name="is_global"]', MShopStudioPbProductCreatorF.toggleGlobalInput),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="name_en"]',
      MShopStudioPbProductCreatorF.focusoutName,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input[name="name_jp"]',
      MShopStudioPbProductCreatorF.focusoutName,
    ),
    $delegate(
      'change',
      '.pb-editor-form__body-input-hs-code',
      MShopStudioPbProductCreatorF.validHscodeFormat,
    ),
    $delegate('change', '.pb-editor-form__body-input-material', MShopStudioPbProductCreatorF.changeMaterial),
    $delegate('change', '.pb-editor-form__toggle-sku', MShopStudioPbProductCreatorF.createSku),
  );
};

export const thumbnailEvents = (container_el) => {
  return go(
    container_el,
    $delegate(
      'click',
      '.pb-thumbnail-editor__item[data-id=""]',
      MShopStudioPbProductCreatorF.clickThumbListEmptyItem,
    ),
    $delegate('click', '.pb-thumbnail-editor__upload-btn', MShopStudioPbProductCreatorF.clickImageUpload),
    $.on3('change', '.pb-thumbnail-editor__input-file', MShopStudioPbProductCreatorF.changeThumbFile),
    $delegate('click', '.pb-thumbnail-editor__item-img', MShopStudioPbProductCreatorF.clickThumbListItem),
    $delegate(
      'click',
      '.pb-thumbnail-editor__photo-set-first input[name="pb_thumbnail_first"]',
      MShopStudioPbProductCreatorF.clickSetFirstThumb,
    ),
    $delegate(
      'click',
      '.pb-thumbnail-editor__item-thumb-del',
      MShopStudioPbProductCreatorF.clickDeleteThumbListItem,
    ),
    $delegate(
      'click',
      '.pb-thumbnail-editor__photo-delete',
      MShopStudioPbProductCreatorF.clickDeleteThumbnail,
    ),
    $delegate('click', '.pb-thumbnail-editor__arrow-prev', MShopStudioPbProductCreatorF.clickPrevThumbnail),
    $delegate('click', '.pb-thumbnail-editor__arrow-next', MShopStudioPbProductCreatorF.clickNextThumbnail),
  );
};

export const editorOptionEvents = (container_el) => {
  return go(
    container_el,
    $delegate(
      'keyup',
      '.pb-editor-form__body-input-op-direct',
      MShopStudioPbProductCreatorF.keyUpOptionDirectInput,
    ),
    $delegate(
      'keyup',
      '.pb-editor-form__body-input-op-direct_en',
      MShopStudioPbProductCreatorF.keyUpOptionDirectInputEn,
    ),
    $delegate(
      'keyup',
      '.pb-editor-form__body-input-op-direct_jp',
      MShopStudioPbProductCreatorF.keyUpOptionDirectInputJp,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input-op-direct',
      MShopStudioPbProductCreatorF.focusoutOptionDirect,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input-op-direct_en',
      MShopStudioPbProductCreatorF.focusoutOptionDirect,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-input-op-direct_jp',
      MShopStudioPbProductCreatorF.focusoutOptionDirect,
    ),
    $delegate(
      'click',
      '.pb-editor-form__btn-op-direct-add',
      MShopStudioPbProductCreatorF.clickAddOptionDirect,
    ),
    $delegate(
      'click',
      '.pb-editor-form__btn-op-direct-del',
      MShopStudioPbProductCreatorF.clickDelOptionDirect,
    ),
    $delegate('click', '.pb-editor-form__btn-option-add', MShopStudioPbProductCreatorF.clickAddOption),
    $delegate('click', '.pb-editor-form__btn-option-add-tpl', MShopStudioPbProductCreatorF.clickAddOptionTpl),
    $delegate('click', '.pb-editor-form__btn-op-del--action', MShopStudioPbProductCreatorF.clickDelOption),
    $delegate('click', '.pb-editor-form__btn-set-op-list', MShopStudioPbProductCreatorF.clickSetOpList),
    $delegate(
      'click',
      '.pb-editor-form__btn-set-op-list-tpl',
      MShopStudioPbProductCreatorF.clickSetOpListTpl,
    ),
    $delegate(
      'change',
      '.pb-editor-form__option-check-all',
      MShopStudioPbProductCreatorF.changeCheckAllSpoItem,
    ),
    $delegate('change', '.pb-editor-form__option-check', MShopStudioPbProductCreatorF.changeCheckSpoItem),
    $delegate(
      'click',
      '.pb-editor-form__btn-select-delete',
      MShopStudioPbProductCreatorF.clickDelSelectedSpoItems,
    ),
    $delegate('click', '.pb-editor-form__option-btn-del', MShopStudioPbProductCreatorF.clickDelSpoItem),
    $delegate(
      'click',
      '.pb-editor-form__option-btn-size',
      MShopStudioPbProductCreatorF.clickChangeOptionSize,
    ),
    $delegate('focusout', 'input[name="spo_item"]', MShopStudioPbProductCreatorF.focusoutOption),
    $delegate(
      'focusout',
      '.pb-editor-form__option-name-input-en',
      MShopStudioPbProductCreatorF.focusoutEnOption,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__option-name-input-jp',
      MShopStudioPbProductCreatorF.focusoutJpOption,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__option-price-input',
      MShopStudioPbProductCreatorF.focusoutOptionPrice,
    ),
    $delegate('input', '.pb-editor-form__option-price-input', MShopStudioPbProductCreatorF.convertPriceForm),
    $delegate(
      'focusout',
      '.pb-editor-form__option-quantity-input',
      MShopStudioPbProductCreatorF.focusoutOptionStock,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__option-real-quantity-input',
      MShopStudioPbProductCreatorF.focusoutRealOptionStock,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-option-detail-info_en',
      MShopStudioPbProductCreatorF.clickSetOpListTpl,
    ),
    $delegate(
      'focusout',
      '.pb-editor-form__body-option-detail-info_jp',
      MShopStudioPbProductCreatorF.clickSetOpListTpl,
    ),
  );
};
