import axios from 'axios';
import { $closest, $delegate, $find, $setAttr, $setHTML, $setText, $text } from 'fxdom/es';
import { go, noop, sel, tap, object } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { OMPReviewConstantS } from '../../S/Constant/module/OMPReviewConstantS.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPReviewTmplS } from '../../S/Tmpl/module/OMPReviewTmplS.js';

const reloadByCategory =
  (el) =>
  async ({ currentTarget }) => {
    try {
      const reviews$ = $find('.omp-review__contents-wrapper', el);

      const cate_list_id = currentTarget.dataset.cate_list_id;
      const queryParams = new URLSearchParams(window.location.search);

      if (cate_list_id === 'all') {
        queryParams.delete('cate_list_id');
      } else {
        queryParams.set('cate_list_id', cate_list_id);
      }
      MuiF.extendHistoryState(null, null, '?' + queryParams.toString(), true);

      $.don_loader_start();
      const res = await axios.get(`/${TT.lang}/@api/review/list`, {
        params: {
          ...object(queryParams.entries()),
          is_list: true,
          is_total: true,
        },
      });

      const filter$ = $closest('.omp-review__header-option__product-filter', currentTarget);

      if (queryParams.get('is_best') !== 'true') {
        go(
          currentTarget,
          $closest('.omp-review__header'),
          $find('.omp-review__header-title'),
          $setText(`${TT('review::review_01')} (${UtilS.commify(res.data.review_total?.count)})`),
        );
      }

      go(
        filter$,
        tap($find('.omp-review__header-option__product-filter-title'), $setText($text(currentTarget))),
        $find('.omp-review__header-option__product-filter-list'),
        tap(
          $find('.omp-review__header-option__product-filter-item[data-active="true"]'),
          $setAttr({ 'data-active': false }),
        ),
        $find(`.omp-review__header-option__product-filter-item[data-cate_list_id="${cate_list_id}"]`),
        $setAttr({ 'data-active': true }),
      );

      reviews$.dataset.next = (res.data.review_total > OMPReviewConstantS.REVIEW_LIST_LIMIT).toString();
      reviews$.dataset.total = res.data.review_total;

      go(res.data.reviews, (rs) =>
        (sel('change', $closest('.don_tab', reviews$).tab_opt.infi) || noop)(rs, 1),
      );
      $.don_loader_end();
    } catch (e) {
      ConfigSentryF.error(e);
    }
  };

export const reviewHeaderDelegate = (el) => {
  return go(
    el,
    $delegate('change', '#photo-review', async (e) => {
      try {
        const reviews$ = $find('.omp-review__contents-wrapper', el);
        reviews$.dataset.type = e.currentTarget.checked ? 'is_photo' : 'is_all';
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('is_all', (!e.currentTarget.checked).toString());
        if (!box.sel('review_list->query->bp_id')) {
          MuiF.extendHistoryState(null, null, '?' + queryParams.toString(), true);
        }

        const res = await axios.get(`/${TT.lang}/@api/review/list`, {
          params: { ...box.sel('review_list->query'), ...object(queryParams.entries()), is_list: true },
        });

        const reviews_contents$ = $find('.omp-review__contents', el);
        reviews_contents$.dataset.type = e.currentTarget.checked ? 'is_photo' : 'is_all';
        reviews$.dataset.next = (res.data.review_total > OMPReviewConstantS.REVIEW_LIST_LIMIT).toString();
        reviews$.dataset.total = res.data.review_total;

        if (res.data.reviews.length === 0) {
          if (e.currentTarget.checked) {
            go(reviews_contents$, $setHTML(OMPReviewTmplS.noPhotoReviewsTmpl()));
          }
          return;
        }
        go(res.data.reviews, (rs) =>
          (sel('change', $closest('.don_tab', reviews$).tab_opt.infi) || noop)(rs, 1),
        );
      } catch (e) {}
    }),
    OMPCoreUtilF.isMobile()
      ? $delegate('input', '.omp-review__header-option__product-filter-list', (e) =>
          reloadByCategory(el)({
            currentTarget: $find('.omp-review__header-option__product-filter-item:checked', e.currentTarget),
          }),
        )
      : $delegate('click', '.omp-review__header-option__product-filter-item', reloadByCategory(el)),
  );
};
