import { html } from 'fxjs/es';
import { CreatorSettingTermsTmplS } from './module/CreatorSettingTermsTmplS.js';

export const kr_studio_20230118 = ({ store_name }) => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h1>[마플샵 셀러스튜디오] 판매자 이용약관 변경 안내</h1>
      </div>
      <div class="body">
        <p>
          안녕하세요. (주)마플코퍼레이션입니다. 마플샵(이하 "몰")의 이용약관의 일부 내용이 변경되어 2023년 1월
          25일자로 적용될 예정입니다. 이에 판매자 이용약관 개정에 관한 내용을 사전에 알려드리오니 판매자회원
          여러분께서는 새로운 약관을 확인하시고 참고하여 주시기를 바랍니다
        </p>
        <p><b>변경고지일 : 2023년 1월 18일 변경 내용</b></p>
        <p>변경 내용</p>
        <table class="terms_preview">
          <tr>
            <th>변경 전</th>
            <th>변경 후</th>
          </tr>
          <tr>
            <td>내일 작업하겠습니다ㅠㅠ</td>
            <td>내일 작업하겠습니다ㅠㅠ</td>
          </tr>
        </table>

        <p>
          변경된 서비스 이용약관은 2023년 1월25일부터 적용됩니다. 변경된 이용약관에 동의하시지 않는 경우,
          마플샵 홈페이지에서 탈퇴 혹은 마플샵 고객센터를 통해 탈퇴를 요청하시거나 이의 제기를 하실 수
          있습니다.
        </p>
        <p>
          변경된 약관의 사전 변경 고지 개시 후 7일 이내에 별도의 이의 제기를 하지 않는 경우에는 본 약관 변경에
          동의한 것으로 간주하여 변경 된 이용약관이 적용됩니다.
        </p>
      </div>
      <hr style="margin-top: 50px;" />
    </div>

    ${CreatorSettingTermsTmplS.kr_studio_20230125({ store_name })}
  `;
};
