import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { go, html, strMap, pick, reduce, some, valuesL } from 'fxjs/es';
import { $find, $qs, $removeClass, $setHTML, $setText } from 'fxdom/es';
import { ShippingConstantS } from '../../S/Constant/module/ShippingConstantS.js';
import { checkInvalidShippingCharsForOversea } from '../../S/Function/shipping.js';

/**
 * @return {{selected_shipping_company_id: number}} selected_shipping_company_id 선택된 특송사 아이디 (이름)
 * */
export function replaceExpressHtml({ express_el, selected_express, express, selected_country_code }) {
  if (express_el == null) {
    throw new Error(`express_el is required`);
  }
  if (express == null) {
    throw new Error(`express is required`);
  }
  if (selected_country_code == null) {
    throw new Error(`selected_country_code is required`);
  }
  const express_available_in_country = express.filter(
    ({ country_code: _country_code }) => _country_code === selected_country_code,
  );

  let _selected_express = selected_express;

  if (_selected_express == null) {
    // 디폴트로 특송사 목록의 첫번째 선택

    if (selected_country_code === ShippingConstantS.COUNTY_CODE_JP) {
      _selected_express = express_available_in_country.find(
        (ep) => ep.shipping_company_id === ShippingConstantS.DEFAULT_SHIPPING_COMPANY_FOR_JP,
      );
    } else {
      _selected_express = express_available_in_country[0];
    }
  }

  const express_options_html = go(
    express_available_in_country,
    strMap(({ shipping_company_id: sc_id, shipping_company_name: sc_name }) => {
      return html`<option value="${sc_id}">${sc_name}</option>`;
    }),
  );

  $removeClass('disabled', express_el);
  $setHTML(`${express_options_html}`)(express_el);

  return { selected_shipping_company_id: _selected_express.shipping_company_id };
}

/**
 * @return {{selected_shipping_company_service_id: number | undefined}} selected_shipping_company_service_id 선택된 특송서서비스 아이디
 * */
export function replaceExpressServiceHtml({
  express_service_el,
  express_services,
  selected_shipping_company_id,
  selected_shipping_company_service,
}) {
  if (express_service_el == null) {
    throw new Error(`express_service_el is required`);
  }

  if (express_services == null) {
    throw new Error('express_services is required');
  }

  const express_service_available_in_express =
    express_services.filter(
      ({ shipping_company_id: sc_id, is_use }) => is_use && selected_shipping_company_id === sc_id,
    ) ?? [];

  $removeClass('disabled', express_service_el);

  let _selected_shipping_company_service = selected_shipping_company_service;

  if (_selected_shipping_company_service == null) {
    // 디폴트로 특송사 서비스 목록의 첫번째 선택
    _selected_shipping_company_service = express_service_available_in_express[0];
  }

  const express_service_options_html = UtilS.isEmpty(express_service_available_in_express)
    ? html`<option value="" class="selected" selected>
        ${ShippingConstantS.EXPRESS_SERVICE_DEFAULT_NAME}
      </option>`
    : go(
        express_service_available_in_express,
        strMap(({ id, display_name }) => {
          const is_selected = _selected_shipping_company_service.id === id ? 'selected' : '';
          return html`<option value="${id}" class="${is_selected}">${display_name}</option>`;
        }),
      );

  go(express_service_el, $setHTML(express_service_options_html));

  const selected_shipping_company_service_id = _selected_shipping_company_service?.id;

  express_service_el.value = selected_shipping_company_service_id ?? '';

  return { selected_shipping_company_service_id };
}

/**
 * @define 주문서 결제 (checkout) 페이지에서 배송비 오류일 때 alert
 * @param {{title: string, message: string }} reason
 * @return {undefined}
 * */
export function alertShippingCalculationError({ reason }) {
  const { title, message } = reason;

  $.alert(
    html`
      <h3 style="margin-top:0">${title}</h3>
      <p style="text-align:center;color:black;font-weight:400;font-size:15px">${message}</p>
    `,
  );
}

export function validationOverseaAddressItemsCharacters(shipping, is_jp_allowed) {
  const has_invalid_character_item = some(
    (input_str) => {
      const { has_invalid_characters } = checkInvalidShippingCharsForOversea(input_str, is_jp_allowed);

      return has_invalid_characters;
    },
    reduce(
      (a, b) => `${a} ${b}`,
      valuesL(pick(['city', 'detail', 'name', 'postcode', 'street', 'state'], shipping)),
    ),
  );

  return { has_invalid_character_item };
}

/**
 * @param {{title: string, description: string} | undefined } warning
 * @return {void}
 * */
export function changeShippingWarningTmpl(warning) {
  const is_show = UtilS.isNotEmpty(warning?.title) && UtilS.isNotEmpty(warning?.description);

  const warning_accordion_el = $qs('.accordion.shipping-warning');

  if (warning_accordion_el) {
    warning_accordion_el.setAttribute('is_show', is_show);
    const { title, description } = warning;
    go(warning_accordion_el, $find('.accordion-title span'), $setText(title));
    go(warning_accordion_el, $find('.accordion-content p.accordion-description'), $setText(description));
  }
}
