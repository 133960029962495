import { $addClass, $find, $on, $removeClass, $setAttr, $setCss } from 'fxdom/es';
import { sel } from 'fxjs/es';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { createFeed, getFeedValue, imageCheck, updateFeed } from './util.js';

export const handleLeaveAfterEdit = (checkAnyChange, $tab) => {
  $on('beforeunload', async (e) => {
    if (checkAnyChange($tab)) {
      e.preventDefault();
      e.returnValue = '';
    }
  })(window);
};

export const handleExpand = (tab$) => {
  const feed_body$ = $find('.feed_layout', tab$);
  const feed_info_el = $find('.feed_info', tab$);
  const feed_content_el = $find('.today-body__kv_content', tab$);
  let opened = false;
  let layout_anim;
  let info_anim;

  return {
    toggle: () => {
      if (layout_anim) layout_anim.pause();
      if (info_anim) info_anim.pause();

      (opened ? $setCss({ opacity: 0 }) : $setCss({ opacity: 1 }))(feed_content_el);

      layout_anim = anime({
        targets: feed_body$,
        width: opened ? ['100%', '73.125%'] : ['73.125%', '100%'],
        duration: 500,
        easing: 'easeOutQuad',
      });

      info_anim = anime({
        targets: feed_info_el,
        translateX: opened ? [feed_info_el.offsetWidth + 20, 0] : [0, feed_info_el.offsetWidth + 20],
        duration: 500,
        easing: 'easeOutQuad',
      });

      opened = !opened;
    },
  };
};

export const thumbnailChangeHandler = (tab_el) => async (e) => {
  const $img = $find('img.thumbnail', tab_el);
  const container_el = $find('.feed_info .item.image', tab_el);
  try {
    if (!sel('target.files.length', e)) return;
    const label$ = e.target.parentElement;

    const { ratio, src } = await imageCheck(
      e.target,
      1024 ** 2 * 5,
      { width: 500, height: 500 },
      false,
      false,
    );

    $img.src = src;

    $addClass('image_attached', label$);
    $setAttr({ 'data-show': true, 'data-ratio': ratio }, $img);

    container_el.style.paddingTop = `calc(${ratio * 100}% - 2px)`;

    // title 얹기
  } catch (err) {
    const label$ = e.target.parentElement;
    e.target.value = '';
    $img.src = '';
    $removeClass('image_attached', label$);
    $setAttr({ 'data-show': false, 'data-ratio': '' }, $img);
    container_el.style.paddingTop = `calc(100% - 2px)`;
    if (err?.title) {
      return MShopShareFramePopUpF.alert(err);
    }
    $.alert(err.message);
  }
};

export const previewHandler = (editor, editor_el) => (e) => {
  const is_active = editor.isActive;
  e.target.innerText = is_active ? T('plus_admin::pa30') : T('plus_admin::pa478');
  is_active ? $removeClass('editable', editor_el) : $addClass('editable', editor_el);
  is_active ? $addClass('preview', editor_el) : $removeClass('preview', editor_el);
  is_active ? editor.destroy() : editor.setup();
};

export const saveHandler = (tab_el, editor, is_update) => async (e) => {
  try {
    $.don_loader_start();
    const feed_data = await getFeedValue(tab_el, editor);

    const request = await (is_update ? updateFeed(feed_data) : createFeed(feed_data));
    $.don_loader_end();

    $.alert(T('plus_admin::pa421'));
    if (!is_update) location.href = location.href.replace('new', request.data.id);
  } catch (err) {
    $.don_loader_end();
    console.error(err);
    $.alert(err.message);
  }
};
