import { chunk, filter, find, go, groupBy, identity, indexBy, map, reject } from 'fxjs/es';
import { CateListConstantS } from '../../../../../CateList/S/Constant/module/CateListConstantS.js';

const setCategoryGroups = (category_groups, cate_lists, is_mobile, is_plus_admin) => {
  const group_by_cate_lists = indexBy((c) => c.id, cate_lists);
  return go(
    category_groups,
    map((cg) => {
      const category_group_items = go(
        cg._.category_group_items,
        map((cgi) => {
          const cate_list = group_by_cate_lists[cgi.cate_list_id];
          if (!is_plus_admin && !cate_list) return;
          const {
            _: { cate_items },
          } = cate_list;
          const { name: cate_item_name } = find((c) => c.id === cgi.cate_item_id, cate_items) || {};
          return {
            ...cgi,
            cate_item_name,
          };
        }),
        filter((r) => r?.cate_item_name),
      );
      if (is_plus_admin || category_group_items.length)
        return {
          ...cg,
          _: {
            ...cg._,
            category_group_items:
              is_mobile && cg.type === 'small' ? chunk(3, category_group_items) : category_group_items,
          },
        };
    }),
    filter((r) => r),
  );
};

export const makeData = ({
  cate_lists,
  original_cate_lists,
  category_groups,
  is_mobile,
  is_plus_admin,
  plus_crew,
  products,
}) => {
  const products_by_cate_list_id = groupBy((product) => product._.base_product.cate_list_id)(products);
  return {
    cate_lists: category_groups.length
      ? cate_lists
      : go(
          original_cate_lists,
          filter((cate_list) => products_by_cate_list_id[cate_list.id]),
          map((cate_list) => ({ ...cate_list, products: products_by_cate_list_id[cate_list.id] })),
        ),
    category_groups: setCategoryGroups(category_groups, original_cate_lists, is_mobile, is_plus_admin),
    is_mobile,
    plus_crew,
  };
};
/* TODO 카테고리 하드코딩 */
export const rejectDigitalGoods = (isApp) =>
  isApp ? reject(({ id }) => id === CateListConstantS.DIGITAL_LIST_ID) : identity;

export const slideCategory = (isApp) => (cate_list) =>
  go(
    cate_list,
    rejectDigitalGoods(isApp),
    map(({ name: content, emoticon: icon, id: value, selected }) => ({
      value,
      icon,
      content,
      selected,
    })),
  );
