import { each, go } from 'fxjs/es';

export const totalPriceMaterial = ({ n_so_pb_ups, target_shipping_company }) => {
  if (!n_so_pb_ups) throw new Error('totalPriceMaterial - no n_so_pb_ups');
  if (!n_so_pb_ups.length) throw new Error('totalPriceMaterial - no n_so_pb_ups length');
  go(
    n_so_pb_ups,
    each((pb_up) => {
      if (pb_up.price == null) throw new Error('totalPriceMaterial - no price');
      if (!pb_up.quantity) throw new Error('totalPriceMaterial - no quantity');
      if (pb_up.is_free_shipping_product == null)
        throw new Error('totalPriceMaterial - no is_free_shipping_product');
    }),
  );
  if (!target_shipping_company) throw new Error('totalPriceMaterial - no stores_delivery_company');
  if (target_shipping_company.shipping_price == null)
    throw new Error('totalPriceMaterial - no shipping_price');
  if (target_shipping_company.additional_shipping_price == null)
    throw new Error('totalPriceMaterial - no additional_shipping_price');
  if (target_shipping_company.free_shipping_min_price == null)
    throw new Error('totalPriceMaterial - no free_shipping_min_price');
};
