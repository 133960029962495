export const SELECTOR = {
  NOT_EDITABLE: '[contenteditable=false]',
};

export const KEY_CODE = {
  ENTER: 13,
  DELETE: 46,
  BACKSPACE: 8,
};

export const MARPPLE_EMBED_SELECTOR = '.marpple_embed';
export const EMBED_SHOW_SELECTOR = '.embed_show';
