/*

<p is_error="pb_product._.pb_error.name">pb_product.name <p.error>${pb_product._.pb_error.name}</p>
pb_product
  name
    < spo_options
    < spo_items
    < spo_text_options
    - spo_producing_period
    < spo_shipping_companies
    - product_story
* */

/*
 * <p >
 * 필수 입력사항 입니다.
 *
 * 어떤 에러들을 우리가 관리할것인가............
 * 필수 사항
 * pb_product.name 특수문자 (!@#$%^&*()_+~)를 제외하고 입력해주세요.
 * pb_product.price 숫자만 입력해 주세요.
 * pb_product.base_product_id 상품 카테고리를 설정해주세요.
 * pb_product.pb_type 필수 입력사항 입니다.
 * pb_product.thumbnails 필수 입력사항 입니다.
 *
 * spo_shipping_company.name 특수문자(~!@#$%^&*()_+=?)를 제외하고 입력해 주세요.
 * spo_shipping_company.price 숫자만 입력해 주세요.
 * spo_shipping_company.additional_price 숫자만 입력해 주세요.
 * spo_shipping_company.refund_price 숫자만 입력해 주세요.
 *
 * 선택 사항
 * spo_option.name 옵션 추가시 필수 사항입니다.
 * spo_option.name 옵션명은 중복 불가합니다.
 * spo_option.about_spo_option_values 옵션 추가시 필수 사항입니다.
 * spo_option.about_spo_option_values 옵션 상세 정보는 1개이상 필요합니다.
 * spo_option.about_spo_option_values 옵션 상세 정보는 중복 불가합니다.
 * spo_option.about_spo_option_values 옵션 상세 정보는 20개 이하 등록 가능합니다.
 *
 *
 *
 *
 * spo_item._.spo_item_stock.price 숫자만 입력해 주세요. 필수
 * spo_item._.spo_item_stock.stock 숫자만 입력해 주세요. 필수
 *
 * 옵션 목록에서 삭제할때 옵션 명, 옵션 상세정보도 같이 잘 없애준다.
 *
 *
 * pb_product.qna_type 1개를 선택해주세요.
 *
 * pb_product.pb_type === 'LIMIT'
 *  pb_product.quantity OR pb_product.sell_start_at, pb_product.sell_end_at
 * pb_product.quantity_and_sell_period 둘 중 한개 이상은 필수입니다.
 * pb_product.pb_type === 'CUSTOM', 'PRINT_ON_DEMAND', 'HAND_MADE'
 *  spo_producing_period 가 반드시 있어야 한다.
 *  spo_producing_period.spo_producing_period 제작 소요기간은 필수 입력 사항 입니다.
 *
 * 조합형인 경우 재고 부분이 없을때 throw
 * */

import { isBefore } from 'date-fns';
import { each, go, keys, sel, isEmpty } from 'fxjs/es';
import { setSpoOptionsError } from './setSpoOptionsError.js';
import { pb_product_editor_error } from './pbProductEditorError.js';

export const setPbProductError = (pb_product, is_server) => {
  // * pb_product.name 특수문자 (!@#$%^&*()_+~)를 제외하고 입력해주세요.
  //   * pb_product.thumbnails 필수 입력사항 입니다.
  if (!pb_product.name) {
    if (is_server) throw new Error(pb_product_editor_error.required.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.name = pb_product_editor_error.required.value;
  }
  const restrict_character = /[-/\\^$*+?.()|[\]{}!@#%&_~]/g;
  if (pb_product.name && restrict_character.test(pb_product.name)) {
    if (is_server) throw new Error(pb_product_editor_error.restrict_character.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.name = pb_product_editor_error.restrict_character.value;
  }
  if (!Number.isInteger(pb_product.price)) {
    if (is_server) throw new Error(pb_product_editor_error.only_number.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.price = pb_product_editor_error.only_number.value;
  }
  if (!pb_product.base_product_id) {
    if (is_server) throw new Error(pb_product_editor_error.select_category.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.base_product_id = pb_product_editor_error.select_category.value;
  }
  if (!pb_product.pb_type) {
    if (is_server) throw new Error(pb_product_editor_error.required.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.base_product_id = pb_product_editor_error.required.value;
  }
  if (!pb_product.thumbnails?.length) {
    if (is_server) throw new Error(pb_product_editor_error.thumbnail_picture.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.thumbnails = pb_product_editor_error.thumbnail_picture.value;
  }
  if (pb_product.thumbnails?.length > 6) {
    if (is_server) throw new Error(pb_product_editor_error.thumbnail_picture_max.value);
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.thumbnails = pb_product_editor_error.thumbnail_picture_max.value;
  }

  // * pb_product.qna_type 1개를 선택해주세요.
  if (isEmpty(pb_product.qna_type)) {
    pb_product._.error = pb_product._.error || {};
    pb_product._.error.qna_type = pb_product_editor_error.select_qna.value;
  }

  // * pb_product.pb_type === 'LIMIT'
  // *  pb_product.quantity OR pb_product.sell_start_at, pb_product.sell_end_at
  // * pb_product.quantity_and_sell_period 둘 중 한개 이상은 필수입니다.

  if (pb_product.pb_type === 'LIMIT') {
    // if (isEmpty(pb_product.quantity) && (isEmpty(pb_product.sell_start_at) || isEmpty(pb_product.sell_end_at)))
    if (pb_product.sell_start_at && pb_product.sell_end_at) {
      if (isBefore(pb_product.sell_end_at, pb_product.sell_start_at)) {
        pb_product._.error = pb_product._.error || {};
        pb_product._.error.sell_start_at = pb_product_editor_error.check_limited_period.value;
      }
    }
  }

  if (pb_product._.error && !keys(pb_product._.error).length) {
    delete pb_product._.error;
  }

  // * pb_product.pb_type === 'CUSTOM', 'PRINT_ON_DEMAND', 'HAND_MADE'
  // *  spo_producing_period.spo_producing_period 제작 소요기간은 필수 입력 사항 입니다.
  if (['CUSTOM', 'PRINT_ON_DEMAND', 'HAND_MADE'].includes(pb_product.pb_type)) {
    if (isEmpty(sel('_.spo_producing_period.avg_days', pb_product))) {
      pb_product._.spo_producing_period._.error = pb_product._.spo_producing_period._.error || {};
      pb_product._.spo_producing_period._.error.avg_days = pb_product_editor_error.required.value;
    }
    if (isEmpty(sel('_.spo_producing_period.max_days', pb_product))) {
      pb_product._.spo_producing_period._.error = pb_product._.spo_producing_period._.error || {};
      pb_product._.spo_producing_period._.error.max_days = pb_product_editor_error.required.value;
    }
    if (
      sel('_.spo_producing_period.max_days', pb_product) &&
      !Number.isInteger(sel('_.spo_producing_period.avg_days', pb_product))
    ) {
      pb_product._.spo_producing_period._.error = pb_product._.spo_producing_period._.error || {};
      pb_product._.spo_producing_period._.error.avg_days = pb_product_editor_error.only_number.value;
    }
    if (
      sel('_.spo_producing_period.max_days', pb_product) &&
      !Number.isInteger(sel('_.spo_producing_period.max_days', pb_product))
    ) {
      pb_product._.spo_producing_period._.error = pb_product._.spo_producing_period._.error || {};
      pb_product._.spo_producing_period._.error.max_days = pb_product_editor_error.only_number.value;
    }

    if (
      pb_product._.spo_producing_period._.error &&
      !keys(pb_product._.spo_producing_period._.error).length
    ) {
      delete pb_product._.spo_producing_period._.error;
    }
  }

  go(
    pb_product._.spo_shipping_companies,
    each((spo_shipping_company) => {
      if (!spo_shipping_company.name) {
        if (is_server) throw new Error(pb_product_editor_error.required.value);
        spo_shipping_company._.error = spo_shipping_company._.error || {};
        spo_shipping_company._.error.name = pb_product_editor_error.required.value;
      }
      if (spo_shipping_company.name && restrict_character.test(spo_shipping_company.name)) {
        if (is_server) throw new Error(pb_product_editor_error.restrict_character.value);
        spo_shipping_company._.error = spo_shipping_company._.error || {};
        spo_shipping_company._.error.name = pb_product_editor_error.restrict_character.value;
      }
      if (spo_shipping_company.price == null || !Number.isInteger(spo_shipping_company.price)) {
        if (is_server) throw new Error(pb_product_editor_error.only_number.value);
        spo_shipping_company._.error = spo_shipping_company._.error || {};
        spo_shipping_company._.error.name = pb_product_editor_error.only_number.value;
      }
      if (
        spo_shipping_company.additional_price == null ||
        !Number.isInteger(spo_shipping_company.additional_price)
      ) {
        if (is_server) throw new Error(pb_product_editor_error.only_number.value);
        spo_shipping_company._.error = spo_shipping_company._.error || {};
        spo_shipping_company._.error.additional_price = pb_product_editor_error.only_number.value;
      }
      if (spo_shipping_company.refund_price == null || !Number.isInteger(spo_shipping_company.refund_price)) {
        if (is_server) throw new Error(pb_product_editor_error.only_number.value);
        spo_shipping_company._.error = spo_shipping_company._.error || {};
        spo_shipping_company._.error.refund_price = pb_product_editor_error.only_number.value;
      }
      if (spo_shipping_company._.error && !keys(spo_shipping_company._.error).length) {
        delete spo_shipping_company._.error;
      }
    }),
  );
  setSpoOptionsError(pb_product);

  if (!pb_product.is_spo_item_type) {
    // 단독형
    if (sel('spo_items[0]._.spo_item_stock', pb_product)) {
      if (!Number.isInteger(sel('spo_items[0]._.spo_item_stock.stock', pb_product))) {
        pb_product.spo_items[0]._.spo_item_stock._.error =
          pb_product.spo_items[0]._.spo_item_stock._.error || {};
        pb_product.spo_items[0]._.spo_item_stock._.error.stock = pb_product_editor_error.only_number.value;
      }
      if (!Number.isInteger(sel('spo_items[0]._.spo_item_stock.price', pb_product))) {
        pb_product.spo_items[0]._.spo_item_stock._.error =
          pb_product.spo_items[0]._.spo_item_stock._.error || {};
        pb_product.spo_items[0]._.spo_item_stock._.error.price = pb_product_editor_error.only_number.value;
      }

      if (
        pb_product.spo_items[0]._.spo_item_stock._.error &&
        !keys(pb_product.spo_items[0]._.spo_item_stock._.error).length
      ) {
        delete pb_product.spo_items[0]._.spo_item_stock._.error;
      }
    }
  } else {
    // 조합형
    go(
      pb_product._.spo_items,
      each((spo_item) => {
        // 조합형인 경우 재고 부분이 없을때 throw
        if (!sel('_.spo_item_stock', spo_item))
          throw new Error('MShopStudioPbProduct setPbProductError - no pb stock');

        // spo_item._.spo_item_stock.stock 필수
        if (isEmpty(spo_item._.spo_item_stock.stock)) {
          spo_item._.spo_item_stock._.error = spo_item._.spo_item_stock._.error || {};
          spo_item._.spo_item_stock._.error.stock = pb_product_editor_error.required.value;
        }

        // spo_item._.spo_item_stock.price 필수
        if (isEmpty(spo_item._.spo_item_stock.price)) {
          spo_item._.spo_item_stock._.error = spo_item._.spo_item_stock._.error || {};
          spo_item._.spo_item_stock._.error.price = pb_product_editor_error.required.value;
        }

        // spo_item._.spo_item_stock.stock 숫자만 입력해 주세요.
        if (!isEmpty(spo_item._.spo_item_stock.stock) && !Number.isInteger(spo_item._.spo_item_stock.stock)) {
          spo_item._.spo_item_stock._.error = spo_item._.spo_item_stock._.error || {};
          spo_item._.spo_item_stock._.error.stock = pb_product_editor_error.only_number.value;
        }

        // spo_item._.spo_item_stock.price 숫자만 입력해 주세요.
        if (!isEmpty(spo_item._.spo_item_stock.price) && !Number.isInteger(spo_item._.spo_item_stock.price)) {
          spo_item._.spo_item_stock._.error = spo_item._.spo_item_stock._.error || {};
          spo_item._.spo_item_stock._.error.price = pb_product_editor_error.only_number.value;
        }

        if (spo_item._.spo_item_stock._.error && !keys(spo_item._.spo_item_stock._.error).length) {
          delete spo_item._.spo_item_stock._.error;
        }
      }),
    );
  }
};
