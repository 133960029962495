export const WITHDRAW_SETTINGS_FRAME = '@FRAME/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_PAGE = '@PAGE/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_TAB = '@TAB/Creator/Withdraw/withdraw_settings';

export const WITHDRAW_CREWS_FRAME = '@FRAME/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_PAGE = '@PAGE/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_TAB = '@TAB/Creator/Withdraw/withdraw_crews';

export const WITHDRAW_APPROVE_FRAME = '@FRAME/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_PAGE = '@PAGE/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_TAB = '@TAB/Creator/Withdraw/withdraw_approve';

export const CREW_WITHDRAW_FRAME = '@FRAME/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_PAGE = '@PAGE/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_TAB = '@TAB/Creator/Withdraw/crew_withdraws';

export const account_history_fields = [
  'type',
  'number',
  'owner',
  'bank',
  'user_name',
  'phone',
  'company_name',
  'business_license_number',
  'parent_name',
  'parent_birth',
  'nationality',
];

export const WITHDRAW_EXCEL_HEADER = [
  'No.',
  '상태',
  '검증여부',
  '유형',
  '상점이름',
  '디자인 상품 정산액',
  '디지털 상품 정산액',
  '정산액',
  '부가세',
  '소득세',
  '주민세',
  '총세금',
  '원천징수 반영',
  '셀러 상품 정산액',
  '입고 상품 정산액',
  '총 예상 입금액',
  '소득자',
  '주민번호',
  '은행명',
  '계좌번호',
  '예금주',
  '승인/취소',
  '취소사유',
];
export const WITHDRAW_DATE = '11';

export const MSG = {
  check_bank_account_filled_message: T('plus_admin::pa189'),
  check_tax_papaer_message: T('plus_admin::pa190'),
  check_bank_account_accuracy_message: T('plus_admin::pa191'),
  check_amount_over: T('plus_admin::pa192'),
};

export const WITHDRAW_TYPE = {
  integrated: 'integrated',
  separate: 'separate',
};

export const STORE_TYPE = {
  seller: 'seller',
  crew: 'crew',
};

export const MINIMUM_WITHDRAW_AMOUNT = 10000;
