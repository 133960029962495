import axios from 'axios';
import { $attr, $closest, $data, $delegate, $find, $qs, $setHTML, $setOuterHTML } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ComponentsInputF } from '../../../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { OMPCoreAtomF } from '../../../../../Core/Atom/F/Function/module/OMPCoreAtomF.js';
import { OMPCoreUtilF } from '../../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPDesignCollectionAdminDetailTmplS } from '../../S/Tmpl/module/OMPDesignCollectionAdminDetailTmplS.js';
import { handleDesignCollectionAdminDetailError } from '../Error/index.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateOmpTagEditorEvents = (el) => {
  return go(
    el,
    delegateCreateOmpTag,
    delegateRemoveOmpTag,
    OMPCoreAtomF.delegateInput,
    $delegate('debounce-input', `.omp-tag-editor__tag-input`, async (e) => {
      try {
        const $form = $closest('.omp-tag-editor__form', e.currentTarget);
        const $auto_complete_list = $find('.omp-tag-editor__auto-complete', $form);
        const lang = getEditorLang();

        const { inputValue } = e.detail;
        if (!inputValue) {
          $setHTML(OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems([]), $auto_complete_list);
          return;
        }

        const registered_omp_tags = $data($qs('.omp-tag-editor__tags'));
        const {
          data: { omp_tags: auto_completed_omp_tags },
        } = await axios.get(`/${T.lang}/@api/omp-tags/autocomplete`, {
          params: { tag_name: inputValue, lang },
        });
        $setHTML(
          OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems(
            auto_completed_omp_tags,
            registered_omp_tags,
          ),
          $auto_complete_list,
        );
      } catch (e) {
        await handleDesignCollectionAdminDetailError(e);
      }
    }),
    $delegate('click', '.omp-tag-editor__auto-complete-item', async (e) => {
      try {
        const $form = $closest('.omp-tag-editor__form', e.currentTarget);
        const $auto_complete_list = $find('.omp-tag-editor__auto-complete', $form);
        const $tag_input = $find('.omp-tag-editor__tag-input', $form);
        const $emoji_input = $find('.omp-tag-editor__emoji-input', $form);
        const $omp_tags = $qs('.omp-tag-editor__tags');

        const { design_collection_id } = $form.dataset;
        const { omp_tag_id, name } = e.currentTarget.dataset;
        const lang = getEditorLang();

        const { data: omp_tags } = await axios.post(`/${TT.lang}/@api/design-collection/tags`, {
          omp_tag_id,
          design_collection_id,
          lang,
        });
        $setOuterHTML(OMPDesignCollectionAdminDetailTmplS.ompTagsTmpl(omp_tags), $omp_tags);
        await OMPCoreUtilF.alert({ content: `"${name}" 태그가 생성되었습니다.` });
        $tag_input.value = '';
        $emoji_input.value = '';
        $setHTML(OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems([]), $auto_complete_list);
        ComponentsInputF.focusToInput($tag_input);
      } catch (e) {
        await handleDesignCollectionAdminDetailError(e);
      }
    }),
  );
};

/**
 * OMP 태그를 추가하는 F 함수
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
const delegateCreateOmpTag = (el) => {
  return $delegate('submit', '.omp-tag-editor__form', async (e) => {
    try {
      e.originalEvent.preventDefault();
      const lang = getEditorLang();

      const { name, design_collection_id, emoji } = OMPCoreUtilF.formDataToObject(e.currentTarget);
      const tag_name = OMPCoreUtilS.trim(name);
      if (!tag_name) {
        await OMPCoreUtilF.alert({ content: '태그가 비어있습니다' });
        return;
      }
      if (!design_collection_id) {
        await OMPCoreUtilF.alert({
          content: '오류가 발생했습니다. 관리자에게 문의해주세요. (design_collection_id가 유효하지 않습니다)',
        });
        return;
      }

      OMPCoreUtilF.loaderStart();
      const { data: omp_tag } = await axios.get(`/${TT.lang}/@api/omp-tags/${tag_name}`, {
        params: { lang },
      });

      const $omp_tags = $qs('.omp-tag-editor__tags');
      const $tag_input = $find('.omp-tag-editor__tag-input', e.currentTarget);
      const $form = $closest('.omp-tag-editor__form', $tag_input);
      const $emoji_input = $find('.omp-tag-editor__emoji-input', $form);
      const $auto_complete_list = $find('.omp-tag-editor__auto-complete', $form);

      // 이미 존재하는 omp_tag라면 design_collection에 연결만 해준다.
      if (omp_tag.omp_tag_id) {
        const { data: omp_tags } = await axios.post(`/${TT.lang}/@api/design-collection/tags`, {
          omp_tag_id: omp_tag.omp_tag_id,
          design_collection_id,
          lang,
        });
        OMPCoreUtilF.loaderEnd();
        $setOuterHTML(OMPDesignCollectionAdminDetailTmplS.ompTagsTmpl(omp_tags), $omp_tags);
        await OMPCoreUtilF.alert({ content: `"${omp_tag.name}" 태그가 생성되었습니다.` });

        $tag_input.value = '';
        $emoji_input.value = '';
        $setHTML(OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems([]), $auto_complete_list);
        ComponentsInputF.focusToInput($tag_input);

        // 존재하지 않는 태그라면 생성 후 연결
      } else {
        const { data: created_omp_tag } = await axios.post(`/${TT.lang}/@api/omp-tags`, {
          name: tag_name,
          lang,
          emoji,
        });
        const { data: omp_tags } = await axios.post(`/${TT.lang}/@api/design-collection/tags`, {
          omp_tag_id: created_omp_tag.omp_tag_id,
          design_collection_id,
          lang,
        });
        OMPCoreUtilF.loaderEnd();
        $setOuterHTML(OMPDesignCollectionAdminDetailTmplS.ompTagsTmpl(omp_tags), $omp_tags);
        await OMPCoreUtilF.alert({ content: `"${created_omp_tag.name}" 태그가 생성되었습니다.` });

        $tag_input.value = '';
        $emoji_input.value = '';
        $setHTML(OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems([]), $auto_complete_list);
        ComponentsInputF.focusToInput($tag_input);
      }
    } catch (e) {
      OMPCoreUtilF.loaderEnd();
      await handleDesignCollectionAdminDetailError(e);
    }
  })(el);
};

/**
 * OMP 태그를 삭제하는 F 함수
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
const delegateRemoveOmpTag = (el) => {
  return go(
    el,
    OMPCoreAtomF.delegateRemovableButton,
    $delegate('remove', '.omp-tag-editor__tags', async (e) => {
      try {
        OMPCoreUtilF.loaderStart();
        const { omp_tag_id, name, design_collection_id } = e.detail.data;
        const is_confirmed = await OMPCoreUtilF.confirm({
          content: `"${name}" 태그를 삭제하시겠습니까?`,
        });
        if (!is_confirmed) {
          OMPCoreUtilF.loaderEnd();
          return;
        }

        const req_body = { omp_tag_id, design_collection_id };
        const $omp_tags = $qs('.omp-tag-editor__tags');
        const $tag_input = $qs('.omp-tag-editor__tag-input');
        const $auto_complete_list = $qs('.omp-tag-editor__auto-complete');

        const { data: omp_tags } = await axios.delete(`/${TT.lang}/@api/design-collection/tags`, {
          data: req_body,
        });
        $setOuterHTML(OMPDesignCollectionAdminDetailTmplS.ompTagsTmpl(omp_tags), $omp_tags);

        if ($tag_input.value) {
          const {
            data: { omp_tags: auto_completed_omp_tags },
          } = await axios.get(`/${T.lang}/@api/omp-tags/autocomplete`, {
            params: { tag_name: $tag_input.value, lang: getEditorLang() },
          });
          $setHTML(
            OMPDesignCollectionAdminDetailTmplS.ompTagAutoCompleteItems(auto_completed_omp_tags, omp_tags),
            $auto_complete_list,
          );
        }

        OMPCoreUtilF.loaderEnd();
      } catch (e) {
        OMPCoreUtilF.loaderEnd();
        await handleDesignCollectionAdminDetailError(e);
      }
    }),
  );
};

const getEditorLang = () => {
  return $attr('lang', $qs('#main_option')) || 'kr';
};
