import { $delegate, $on } from 'fxdom/es';
import { createCustomToolbar } from './common/custom-toolbar.js';
import { closeToolbarHandler, openEmbedToolbarHandler, removeEmbedToolbarHandler } from './common/embed.js';

export const initEmbedToolbar = (editor) => {
  const $editor = editor.elements[0];
  const $custom_toolbar = createCustomToolbar({
    klass: 'custom_embed_toolbar',
    actions: [{ icon: 'fas fa-times', name: 'close-action' }],
  });

  // close
  $on('click', closeToolbarHandler($custom_toolbar))(document);

  // open
  $delegate('click', '.embed_show', openEmbedToolbarHandler(false, $custom_toolbar, editor))($editor);

  // action
  $delegate('click', '.close-action', removeEmbedToolbarHandler)($custom_toolbar);

  return $custom_toolbar;
};
