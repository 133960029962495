import { DeliveryTypeConstantS } from '../Constant/module/DeliveryTypeConstantS.js';

export const isMarpple = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.MARPPLE.id;
};
export const isSeller = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.SELLER.id;
};
export const isDigital = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.DIGITAL.id;
};

export const isNotMarpple = (delivery_type_id) => {
  return !isMarpple(delivery_type_id);
};
