import { each, go, keys, filter } from 'fxjs/es';
import { pb_product_editor_error } from './pbProductEditorError.js';

export const setSpoOptionsError = (pb_product, is_server) => {
  if (pb_product._?.spo_options?.length) {
    go(
      pb_product._?.spo_options,
      each((spo_option) => {
        spo_option._ = spo_option._ || {};

        // * spo_option.name 옵션 추가시 필수 사항입니다.
        if (!spo_option.name) {
          if (is_server) throw new Error(pb_product_editor_error.required.value);
          spo_option._.error = spo_option._.error || {};
          spo_option._.error.name = pb_product_editor_error.required.value;
        }

        //   * spo_option.name 옵션명은 중복 불가합니다.
        if (
          filter((pog) => {
            return pog.name == spo_option.name;
          }, pb_product._.spo_options)?.length > 1
        ) {
          if (is_server) throw new Error(pb_product_editor_error.same_spo_option_name.value);
          spo_option._.error = spo_option._.error || {};
          spo_option._.error.name = pb_product_editor_error.same_spo_option_name.value;
        }

        //   * spo_option.about_spo_option_values 옵션 상세 정보는 1개이상 필요합니다.
        if (!spo_option._?.spo_option_values?.length) {
          if (is_server) throw new Error(pb_product_editor_error.spo_option_value_length_min_required.value);
          spo_option._.error = spo_option._.error || {};
          spo_option._.error.about_spo_option_values =
            pb_product_editor_error.spo_option_value_length_min_required.value;
        }

        each((spo_option_value) => {
          //   * spo_option.about_spo_option_values 옵션 상세 정보는 중복 불가합니다.
          if (
            filter((po) => {
              return po.name == spo_option_value.name;
            }, spo_option._.spo_option_values).length > 1
          ) {
            if (is_server) throw new Error(pb_product_editor_error.same_spo_option_value_name.value);
            spo_option._.error = spo_option._.error || {};
            spo_option._.error.about_spo_option_values =
              pb_product_editor_error.same_spo_option_value_name.value;
          }
        }, spo_option._?.spo_option_values);

        //   * spo_option.about_spo_option_values 옵션 상세 정보는 20개 이하 등록 가능합니다.
        if (spo_option._?.spo_option_values?.length > 20) {
          if (is_server) throw new Error(pb_product_editor_error.spo_option_value_length_max_required.value);
          spo_option._.error = spo_option._.error || {};
          spo_option._.error.about_spo_option_values =
            pb_product_editor_error.spo_option_value_length_max_required.value;
        }

        if (spo_option._.error && !keys(spo_option._.error).length) {
          delete spo_option._.error;
        }
      }),
    );
  }
};
