import axios from 'axios';
import { $closest, $delegate, $find, $trigger } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPReviewDetailF } from '../../Detail/F/Function/module/OMPReviewDetailF.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';

export const reviewListDelegate =
  (sel = '.omp-review__content-wrapper') =>
  (tab_el) => {
    return go(
      tab_el,
      $delegate('click', '.omp-cell__review__detail_href', async (e) => {
        e.originalEvent.preventDefault();
        $trigger('click', $closest('article', e.currentTarget));
      }),
      $delegate('click', sel, async (e) => {
        if ($closest('a', e.target)) return;
        if ($closest('.omp-cell__review-delete', e.target)) return;

        try {
          let article = null;
          if (e.currentTarget.id) article = e.currentTarget;
          if (!article) article = $find('.omp-cell__review', e.currentTarget);
          if (!article || (article.dataset.can_open === 'false' && article.dataset.read_more === 'false')) {
            return;
          }
          $.don_loader_start();
          const get_url = makeApiUrl(`/${TT.lang}/@api/review/detail/:review_id`, {
            review_id: article.id,
          });
          const res = await axios.get(get_url);
          $.don_loader_end();

          return OMPReviewDetailF.openFrame(res.data);
        } catch (e) {
          console.error(e);
          $.don_loader_end();
        }
      }),
      $delegate('click', '.omp-cell__review-delete', async (e) => {
        if (!(await $.confirm(T('삭제 하시겠습니까?')))) return;
        try {
          const id = $closest('.omp-cell__review', e.currentTarget).id;
          $.don_loader_start();
          await axios.delete(`/@api/review`, { data: { id } });
          location.reload();
        } catch (e) {
          $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
        }
        $.don_loader_end();
      }),
    );
  };
