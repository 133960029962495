import format from 'date-fns/format/index.js';
import { html } from 'fxjs/es';
import { messages } from '../../../../Creator/Setting/S/messages_id.js';
import { makeSellerStudioTable } from '../../../../Creator/Setting/S/table.js';
import { sumPbAdjustments } from '../../../../Creator/SettingAdjustments/S/util.js';
import { GoodsTypeConstantS } from '../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { GoodsTypeTmplS } from '../../../../GoodsType/S/Tmpl/module/GoodsTypeTmplS.js';
import { DIGITAL_PRODUCT_FEE } from '../../../../MShop/Studio/DigitalProductCreator/S/Constant/digitalproductcreator.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

const makeSellerPbgAdjustmentTable = ({ adjustments }) => {
  const { quantity, profit, without_fee_amount, pg_amount, fee_amount } = sumPbAdjustments(adjustments);

  const pg_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T('studio::tooltip::PG사 수수료')}</h4>
      <p>${T('studio::tooltip::PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.')}</p>
    </div>
  `;

  const without_fee_amount_tooltip_content = html`<span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T('studio::tooltip::셀러 상품 정산')}</h4>
      <p>${T(messages.tooltip.t3)}</p>
    </div> `;

  return makeSellerStudioTable({
    col_rates: [
      { rate: 11, align: 'left' },
      { rate: 25, align: 'center', klass: 'seller-studio-table__td--text-overflow' },
      { rate: 10 },
      { rate: 10 },
      { rate: 10 },
      {
        rate: 12,
        tool_tip: pg_amount_tooltip_content,
      },
      {
        rate: 12,
        tool_tip: without_fee_amount_tooltip_content,
      },
      { rate: 10, align: 'center' },
    ],
    headers: [
      T(messages.table.th15),
      T(messages.table.th12),
      T(messages.table.th4),
      T(messages.table.th6),
      T(messages.table.th16_2),
      T(messages.table.th16_1),
      T(messages.table.th16),
      T(messages.table.th36),
    ],
    bodys: adjustments.map(
      ({ created_at, type, p_name, quantity, profit, without_fee_amount, pg_amount, fee_amount }) => {
        return [
          format(created_at, 'yyyy-MM-dd'),
          type == 'shipping' ? `<${T(messages.adjustments[type])}>` : p_name || 'Untitled',
          UtilS.commify(quantity),
          UtilS.commify(profit),
          UtilS.commify(fee_amount),
          UtilS.commify(pg_amount),
          UtilS.commify(without_fee_amount),
          T(messages.adjustments[type]),
        ];
      },
    ),
    totals: [
      T(messages.table.th18),
      '',
      UtilS.commify(quantity),
      UtilS.commify(profit),
      UtilS.commify(fee_amount),
      UtilS.commify(pg_amount),
      UtilS.commify(without_fee_amount),
      '-',
    ],
  });
};

const makeSellerTplAdjustmentTable = ({ adjustments }) => {
  const { quantity, profit, without_fee_amount, pg_amount, fee_amount } = sumPbAdjustments(adjustments);

  const pg_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T('studio::tooltip::PG사 수수료')}</h4>
      <p>${T('studio::tooltip::PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.')}</p>
    </div>
  `;

  const without_fee_amount_tooltip_content = html`<span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T(messages.menus.tpl_adjustments)}</h4>
      <p>${T(messages.tooltip.t3_1)}</p>
    </div> `;

  return makeSellerStudioTable({
    col_rates: [
      { rate: 11, align: 'left' },
      { rate: 25, align: 'center', klass: 'seller-studio-table__td--text-overflow' },
      { rate: 10 },
      { rate: 10 },
      { rate: 10 },
      {
        rate: 12,
        tool_tip: pg_amount_tooltip_content,
      },
      {
        rate: 12,
        tool_tip: without_fee_amount_tooltip_content,
      },
      { rate: 10, align: 'center' },
    ],
    headers: [
      T(messages.table.th15),
      T(messages.table.th12),
      T(messages.table.th4),
      T(messages.table.th6),
      T(messages.table.th16_4),
      T(messages.table.th16_1),
      T(messages.table.th16),
      T(messages.table.th36),
    ],
    bodys: adjustments.map(
      ({ created_at, type, p_name, quantity, profit, without_fee_amount, pg_amount, fee_amount }) => {
        return [
          format(created_at, 'yyyy-MM-dd'),
          type == 'shipping' ? `<${T(messages.adjustments[type])}>` : p_name || 'Untitled',
          UtilS.commify(quantity),
          UtilS.commify(profit),
          UtilS.commify(fee_amount),
          UtilS.commify(pg_amount),
          UtilS.commify(without_fee_amount),
          T(messages.adjustments[type]),
        ];
      },
    ),
    totals: [
      T(messages.table.th18),
      '',
      UtilS.commify(quantity),
      UtilS.commify(profit),
      UtilS.commify(fee_amount),
      UtilS.commify(pg_amount),
      UtilS.commify(without_fee_amount),
      '-',
    ],
  });
};

const makeSellerDtgAdjustmentTable = ({ adjustments }) => {
  const { quantity, profit, without_fee_amount, pg_amount, fee_amount } = sumPbAdjustments(adjustments);

  const pg_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T('studio::tooltip::PG사 수수료')}</h4>
      <p>${T('studio::tooltip::PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.')}</p>
    </div>
  `;

  const without_fee_amount_tooltip_content = html`<span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T(messages.menus.dtg_adjustments)}</h4>
      <p>
        ${T(messages.tooltip.t3_2, {
          digital_fee: DIGITAL_PRODUCT_FEE,
        })}
      </p>
    </div> `;

  return makeSellerStudioTable({
    col_rates: [
      { rate: 11, align: 'left' },
      { rate: 25, align: 'center', klass: 'seller-studio-table__td--text-overflow' },
      { rate: 10 },
      { rate: 10 },
      { rate: 10 },
      {
        rate: 12,
        tool_tip: pg_amount_tooltip_content,
      },
      {
        rate: 12,
        tool_tip: without_fee_amount_tooltip_content,
      },
    ],
    headers: [
      T(messages.table.th15),
      T(messages.table.th12),
      T(messages.table.th4),
      T(messages.table.th6),
      T(messages.table.th16_3),
      T(messages.table.th16_1),
      T(messages.table.th16),
    ],
    bodys: adjustments.map(
      ({ created_at, type, p_name, quantity, profit, without_fee_amount, pg_amount, fee_amount }) => {
        return [
          format(created_at, 'yyyy-MM-dd'),
          type == 'shipping' ? `<${T(messages.adjustments[type])}>` : p_name || 'Untitled',
          UtilS.commify(quantity),
          UtilS.commify(profit),
          UtilS.commify(fee_amount),
          UtilS.commify(pg_amount),
          UtilS.commify(without_fee_amount),
        ];
      },
    ),
    totals: [
      T(messages.table.th18),
      '',
      UtilS.commify(quantity),
      UtilS.commify(profit),
      UtilS.commify(fee_amount),
      UtilS.commify(pg_amount),
      UtilS.commify(without_fee_amount),
    ],
  });
};

const makeAdjustmentTable = ({ adjustments, goods_type_id }) => {
  switch (goods_type_id) {
    case GoodsTypeConstantS.DTG.id:
      return makeSellerDtgAdjustmentTable({ adjustments });
    case GoodsTypeConstantS.TPL.id:
      return makeSellerTplAdjustmentTable({ adjustments });
    default:
      return makeSellerPbgAdjustmentTable({ adjustments });
  }
};

export const fn = ({ adjustments, date, store_url, has_permission_tpl, goods_type_id, domain_name }) => {
  const preparing_adjustments = !!adjustments?.length;
  return html`
    <div class="header flex">
      <div class="left">
        <h2 class="tooltip-icon-wrapper">
          ${T(messages.menus.adjustments)} - ${format(date, T(messages.adjustments.ym_locale))}<span
            class="lnr tooltip-icon tooltip-question tooltip-question--large"
          ></span>
          <div class="tooltip-container">
            <p>
              ${T(messages.tooltip.h2, {
                tpl_adjustments: has_permission_tpl ? ` & ${T(messages.menus.tpl_adjustments)} ` : '',
              })}
            </p>
          </div>
        </h2>
        <p>${T(messages.adjustments.p2)}</p>
      </div>
      <div class="right">
        <button
          type="button"
          class="download-excel${preparing_adjustments ? '' : ' download-excel--disabled'}"
          ${preparing_adjustments ? '' : 'disabled'}
          data-goods_type_id=${goods_type_id}
        >
          <img
            src="${preparing_adjustments
              ? '//s3.marpple.co/files/u_29089/2023/2/original/8e3f4cc22c165c2cdfa3a9fc5af59b753975d1ac1.webp'
              : '//s3.marpple.co/files/u_29089/2023/2/original/9794c9df1bd62bfa1d2467dd05c1e924beb989851.webp'}"
            alt=""
            style="pointer-events:${preparing_adjustments ? 'auto' : 'none'}"
          />
          ${T('item_order_excel::정산 내역 내려받기')}
        </button>
        <button
          class="btn btn-blue"
          name="date"
          type="button"
          value="${format(date, T(messages.adjustments.ym_digit))}"
        >
          <i class="lnr lnr-calendar-full"></i>
          ${T(messages.adjustments.btn1)}
        </button>
      </div>
    </div>
    ${GoodsTypeTmplS.adjustmentGoodsTypeHref({
      has_permission_tpl,
      goods_type_id,
      domain_name,
    })}
    <div class="body">${makeAdjustmentTable({ adjustments, goods_type_id })}</div>
  `;
};
