import { $attr, $findAll, $qs, $text, $val } from 'fxdom/es';
import {
  cond,
  pick,
  constant,
  go,
  groupBy,
  head,
  identity,
  ifElse,
  last,
  map,
  mapObject,
  pipe,
  reject,
  selEq,
} from 'fxjs/es';

export const valueByType = cond(
  [selEq('type', 'checkbox'), (el) => pick(['value', 'checked'], el)],
  [selEq('type', 'file'), (el) => ''],
  [(el) => el.tagName === 'textarea', $text],
  [constant(true), $val],
);

export const getFormValues = ($selectForm) =>
  go(
    $selectForm,
    $findAll('textarea, input:not([type="radio"]), input[type="radio"]:checked, select'),
    map((el) => [$attr('name', el), valueByType(el)]),
    reject(selEq('0', null)),
    groupBy(([name]) => name),
    mapObject(
      pipe(
        map(last),
        ifElse((xs) => xs.length == 1, head, identity),
      ),
    ),
  );

export const getFormValuesFrom = (selector) => getFormValues($qs(selector));
