import { filter, flatMap, go, map, pipe } from 'fxjs/es';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { DeliveryTypeS } from '../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';

export const makeTotalPriceInfo = ({ store_orders, mp_plus_price_info }) => {
  const shipping_orders = go(
    store_orders,
    flatMap((store_order) => store_order._.shipping_orders),
  );
  const { coupon_price, point_price } = mp_plus_price_info || { coupon_price: 0, point_price: 0 };
  const flattenUpcs = pipe(
    flatMap((shipping_order) => shipping_order._.item_order_up_cs),
    flatMap((up_c) => up_c._.up_c_ss),
  );
  const marpple_shop_total_price_info = {
    quantity: go(
      shipping_orders,
      flattenUpcs,
      map(({ quantity }) => quantity),
      PriceS.add,
    ),
    original_products_price: go(
      shipping_orders,
      flattenUpcs,
      map((up) => PriceS.mult(up['price' + _en], up.quantity)),
      PriceS.add,
    ),
    products_price: go(
      shipping_orders,
      flattenUpcs,
      map((up) => PriceS.mult(up['discounted_price' + _en], up.quantity)),
      PriceS.add,
    ),
    shippings_price: go(
      shipping_orders,
      map(({ shipping_price, additional_shipping_price }) =>
        PriceS.add(shipping_price, additional_shipping_price),
      ),
      PriceS.add,
    ),
    mps_quantity: go(
      shipping_orders,
      flattenUpcs,
      filter((up) => GoodsTypeS.isMps(up.goods_type_id) || GoodsTypeS.isTpl(up.goods_type_id)),
      map(({ quantity }) => quantity),
      PriceS.add,
    ),
    mps_original_products_price: go(
      shipping_orders,
      flattenUpcs,
      filter((up) => GoodsTypeS.isMps(up.goods_type_id) || GoodsTypeS.isTpl(up.goods_type_id)),
      map((up) => PriceS.mult(up['price' + _en], up.quantity)),
      PriceS.add,
    ),
    mps_products_price: go(
      shipping_orders,
      flattenUpcs,
      filter((up) => GoodsTypeS.isMps(up.goods_type_id) || GoodsTypeS.isTpl(up.goods_type_id)),
      map((up) => PriceS.mult(up['discounted_price' + _en], up.quantity)),
      PriceS.add,
    ),
    pod_products_price: go(
      shipping_orders,
      flattenUpcs,
      filter((up) => GoodsTypeS.isMps(up.goods_type_id)),
      map((up) => PriceS.mult(up['discounted_price' + _en], up.quantity)),
      PriceS.add,
    ),
    mps_shippings_price: go(
      shipping_orders,
      filter((sh_o) => DeliveryTypeS.isMarpple(sh_o.delivery_type_id)),
      map(({ shipping_price }) => shipping_price),
      PriceS.add,
    ),
    mps_additional_shippings_price: go(
      shipping_orders,
      filter((sh_o) => DeliveryTypeS.isMarpple(sh_o.delivery_type_id)),
      map(({ additional_shipping_price }) => additional_shipping_price),
      PriceS.add,
    ),
    seller_shippings_price: go(
      shipping_orders,
      filter((sh_o) => DeliveryTypeS.isNotMarpple(sh_o.delivery_type_id)),
      map(({ shipping_price }) => shipping_price),
      PriceS.add,
    ),
    seller_additional_shippings_price: go(
      shipping_orders,
      filter((sh_o) => DeliveryTypeS.isNotMarpple(sh_o.delivery_type_id)),
      map(({ additional_shipping_price }) => additional_shipping_price),
      PriceS.add,
    ),
    coupon_price,
    point_price,
  };
  marpple_shop_total_price_info.total_price = PriceS.add(
    marpple_shop_total_price_info.products_price,
    marpple_shop_total_price_info.shippings_price,
    marpple_shop_total_price_info.coupon_price || 0,
    marpple_shop_total_price_info.point_price || 0,
  );
  marpple_shop_total_price_info.mps_total_price = PriceS.add(
    marpple_shop_total_price_info.mps_products_price,
    marpple_shop_total_price_info.mps_shippings_price,
    marpple_shop_total_price_info.mps_additional_shippings_price,
    marpple_shop_total_price_info.coupon_price || 0,
    marpple_shop_total_price_info.point_price || 0,
  );
  return marpple_shop_total_price_info;
};
