import {
  defaultTo,
  filter,
  flatMap,
  go,
  groupBy,
  html,
  join,
  map,
  partition,
  pipe,
  sel,
  strMap,
  sumBy,
  valuesL,
  zipWithIndexL,
} from 'fxjs/es';
import { GoodsTypeS } from '../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { isNeedPreview } from '../../../Maker/S/categorize.js';
import { messages } from '../../../MShop/App/ProductDetail/S/message_id.js';
import { NewMakerWeS } from '../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { OMPCoreUtilS } from '../../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import Ups from '../../../Ups/S/ups.js';
import { UserProductS } from '../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { BpOptionTmplS } from '../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import { OMPCoreUserProductS } from '../../../OMP/Core/UserProduct/S/Function/module/OMPCoreUserProductS.js';
import { ShippingConstantS } from '../../../Shipping/S/Constant/module/ShippingConstantS.js';

export const productUrlWithUpC = (up_c) => {
  const domain_name = sel('_.product_color._.stores_product._.store.domain_name', up_c);
  const sp = sel('_.product_color._.stores_product', up_c);
  const ev = sel('_.product_color.product_faces2.ev', up_c);
  if (ev?.url) {
    return `/${T.lang}/${ev.url}?pc_id=${up_c.product_color_id}`;
  }
  return domain_name
    ? `/${T.lang}/${domain_name}/products/${sp.original_product_id || sp.product_id}?bpc_id=${
        up_c.base_product_color_id
      }&bp_id=${up_c.base_product_id}&find_plus=1`
    : `/${T.lang}/product/detail?bp_id=${up_c.base_product_id}&pc_id=${up_c.product_color_id}`;
};

export function hyundaiNOptionString(up_c) {
  return sel('_.product_color.product_faces2.hyundai_n_products', up_c)
    ? up_c._.product_color.product_faces2.hyundai_n_products.option_to_string
    : '';
}

export const bpNameByUpC = (up_c) => {
  const can_not_change_color = Boolean(sel('_.product_color.product_faces2.can_not_change_color', up_c));

  const color_name = up_c._.product_color._.base_product.is_bpc_hidden
    ? ''
    : up_c._.product_color._.base_product_color['name' + _en];

  const option_title = sel(`_.product_color._.selected_option_group.title${_en}`, up_c);

  return `${up_c._.product_color._.base_product['name' + _collabo + _en]}
      ${
        isNeedPreview(sel('_.product_color._.base_product', up_c)) || can_not_change_color || !color_name
          ? ''
          : `[${color_name}]`
      }
      ${option_title ? ` - ${option_title}` : ''}`;
};

export const makeCartCreatorHtml = (ups, computeShippingCost, ordered_at) => legacyHtml`
  <div class='cart_body ${ups.length === 0 ? 'empty_page' : ''}' data-length='${
  flatMap((u) => u._.up_cs, ups).length
}'>
    <div class='empty_wrap'>
      <img
        src='//s3.marpple.co/files/u_14345/2021/5/original/97bafe228b83b24e7228a344e0d295a60020bde01.svg'
        alt=''
      />
      <span>${T('Your shopping cart is empty')}</span>
    </div>
    <div class='up_list' _sel='user_products'>
      ${makeUpListCreatorHtml(ups)}
    </div>
    <div class='cart_footer'>
      <div class='header'>${T('Total Price')}</div>
      <div class='summary'>
        ${makeCartSummaryContentHtml(ups, computeShippingCost, ordered_at)}
      </div>
      <div class='submits'>
        <div class='btn' id='checkout'>
          <span>${T('Proceed To Checkout')}</span>
        </div>
      </div>
    </div>
  </div>
`;

export const makeCartHtml = (ups, computeShippingCost, ordered_at) =>
  G.collabo_type === 'creator'
    ? makeCartCreatorHtml(ups, computeShippingCost, ordered_at)
    : legacyHtml`
      <div class='cart_body ${ups.length === 0 ? 'empty_page' : ''}'>
        <div class='header_title'>
          <span class='title ff_ccl'>${T('My Cart')}</span>
          <div class='status_bar pc_v'>
            <span class='active'>${T('Cart')}</span>
            <span class='arrow'>&nbsp;&nbsp;</span>
            <span>${T('Checkout')}</span>
            <span class='arrow'>&nbsp;&nbsp;</span>
            <span>${T('Order Complete')}</span>
          </div>
        </div>
        <div class='empty_wrap'>
          <img
            src='//s3.marpple.co/files/u_14345/2021/5/original/97bafe228b83b24e7228a344e0d295a60020bde01.svg'
            alt=''
          />
          <span>${T('Your shopping cart is empty')}</span>
        </div>
        <div class='up_list' _sel='user_products'>
          <div class='table_header pc_v'>
            <span>${T('Product Details')}</span>
            <span>${T('Size/Quantity')}</span>
            <span>${T('Price')}</span>
            <span>${T('Optional')}</span>
          </div>
          ${go(ups, strMap(makeCartUpItemHtml('cart')))}
        </div>
        <div class='summary'>${makeCartSummaryContentHtml(ups, computeShippingCost, ordered_at)}</div>
        <div class='submits'>
          <div class='btn' id='checkout'>
            <span>${T('Proceed To Checkout')}</span>
          </div>
        </div>
      </div>
    `;

const makeUpListCreatorHtml = (ups) =>
  go(
    ups,
    groupBy((u) => u.store_id),
    valuesL,
    strMap((ups) => {
      const { store } = ups[0]._.up_cs[0]._.product_color._.stores_product._;
      return legacyHtml`
        <div class='store_section'>
          <a
            href='/${T.lang}/${store.domain_name}?find_plus=1'
            class='store_name'
            data-logo_img='${store.logo_img}'
            data-title='${store.name}'
          >${store.name}</a>
          <div class='store_up_list'>
            ${strMap(makeCartUpItemHtml('cart'), ups)}
          </div>
        </div>
      `;
    }),
  );

const makeCartSummaryContentHtml = async function (
  user_products,
  computeShippingCost,
  ordered_at = moment().format('YYYY-MM-DD'),
) {
  try {
    const price_info = Ups.calc_projection_price_info(user_products, _en);
    const [dtg_ups, not_dtg_ups] = partition((up) => GoodsTypeS.isDtg(up.goods_type_id))(user_products);
    const all_is_digital_product = !!dtg_ups.length && !not_dtg_ups.length;
    const upcs_ids = OMPCoreUserProductS.getUpcsIdsFromUserProducts({ user_products });
    const is_oversea = T.lang !== 'kr';
    const shipping_price =
      is_oversea || all_is_digital_product
        ? 0
        : /* 카트라서 도서산간비 X */
          await computeShippingCost({
            collabo_type: G.collabo_type,
            shipping_type: 'parcel',
            country_id: T.lang == 'jp' ? ShippingConstantS.COUNTY_ID_JP : undefined,
            order_amount: price_info.product_price,
            ordered_at,
            lang: T.lang,
            upcs_ids,
          });
    return legacyHtml`
      <div class='content'>
        <span>${T('Total Qty')}</span>
        <span class='total_quantity'>${UtilS.commify(price_info.quantity)}</span>
      </div>
      <div class='content'>
        <span>${T('Subtotal')}</span>
        <span class='total_up_price mp_currency'>${PriceS.pricify(price_info.product_price)}</span>
      </div>
      ${
        is_oversea
          ? ``
          : html`<div class="content">
              <span>${T('Shipping')}</span>
              <span class="shipping_price mp_currency"> ${PriceS.pricify(shipping_price)} </span>
            </div>`
      }
      <div class='content final'>
        <span>${T('Total')}</span>
        <span class='total_all_price mp_currency'
          >${PriceS.pricify(PriceS.add(price_info.product_price, shipping_price))}</span
        >
      </div>
      <div class='border_bar pc_v'></div>
      <div class='calc_symbols pc_v'>
        <div class='plus'>
          <div class='stick'></div>
          <div class='stick rotate'></div>
        </div>
        <div class='equal'>
          <div class='stick'></div>
          <div class='stick'></div>
        </div>
      </div>
    `;
  } catch (e) {
    return '';
  }
};

export const makeCartModalSummaryContentHtml = (up_c_ss) =>
  go(
    Ups.calc_up_c_ss_price_info(up_c_ss, _en),
    ($) => legacyHtml`
      <div class='title'>${
        T.lang === 'kr'
          ? `${UtilS.commify($.quantity)}개 상품 금액`
          : `${T('Total Qty')}:${UtilS.commify($.quantity)}`
      }</div>
      <div class='total_price'>
        <span class='percent'
          >${
            $.discount_percent == 0 || isNaN($.discount_percent) ? '' : $.discount_percent.toFixed(1) + '%'
          }</span
        >
        <div class="prices">
          <span class='origin ${$.total_price == $.discounted_price ? '' : 'mp_currency'}'
            >${$.total_price == $.discounted_price ? '' : PriceS.pricify($.total_price)}</span
          >
          <span class='discounted mp_currency'>${PriceS.pricify($.discounted_price)}</span>
        </div>
      </div>
    `,
  );

const makeCartUpItemHtml = function (type) {
  const makeCardFooterHtml =
    type === 'cart'
      ? (up_cs) =>
          NewMakerWeS.isWeBaseProduct(up_cs[0].base_product_id) ||
          up_cs[0]._.product_color?._?.base_product_color?.name === ''
            ? ''
            : legacyHtml`
          <div class='card_footer'>
            <div class='add_color'>${T('+ Add Color')}</div>
          </div>
        `
      : () => '';

  return (up) => legacyHtml`
    <div class='card up_item' _up_id='${up.id}' _sel='./(#${up.id})'>
      <div class='up_c_list'>${go(up._.up_cs, strMap(makeUpCItemHtml(type)))}</div>
      ${makeCardFooterHtml(up._.up_cs)}
    </div>
  `;
};

export const makeUpCItemHtml = function (type) {
  const makeUpCControlsHtml = baseMakeUpCControlsHtml(type);
  return (up_c) => {
    return pug`
      .up_c_item[_up_c_id="${up_c.id}" _sel="./_->up_cs->(#${up_c.id})" class="${
      up_c.id === up_c.up_id ? 'parent' : ''
    }" data-is_digital_product="${GoodsTypeS.isDtg(up_c.goods_type_id)}"]
        .item_header
          span ${moment(up_c.created_at).format('YYYY-MM-DD HH:mm')}
          span.no ${T('No.')} ${up_c.id}
        .item_body
          .item_body_wrap1
            a[href="${productUrlWithUpC(up_c)}"].thumb
              .img.canvas_100
                canvas
                .don_loader_wrap2
                  .don_loader_img2
              
            .up_c_info
              .title_and_faces
                a[href="${productUrlWithUpC(up_c)}"].title
                  .pc_name ${
                    up_c._.product_color['name' + _en] || up_c._.product_color.name || bpNameByUpC(up_c)
                  }
                  .bp_name ${bpNameByUpC(up_c)}${hyundaiNOptionString(up_c)}
                .faces
                  .sub_title ${T('Print')} :
                  .face_list ${go(
                    up_c,
                    sel('_.product_color.product_faces2.value'),
                    defaultTo([]),
                    (pf2_value) =>
                      go(
                        pf2_value,
                        filter(sel('designs.length')),
                        map((pf) => pf['face_name' + G._en] || pf.face_name_en),
                        join(' / '),
                      ) || T('No Print'),
                  )}
              .sizes
                .sub_title.mobile_v ${T('Size')} :
                .up_c_s_list[_sel="./_->up_c_ss"] ${makeUpCSListHtml(up_c._.up_c_ss)}
              .product_price.pc_v
                ${makeUpCProductPriceContentHtml(up_c)}
              .controls.pc_v ${makeUpCControlsHtml()}
          .item_body_wrap2.mobile_v
            .product_price
              ${makeUpCProductPriceContentHtml(up_c)}
            .controls ${makeUpCControlsHtml()}
    `;
  };
};

const baseMakeUpCControlsHtml = function (type) {
  return type == 'wish'
    ? () => legacyHtml`
        <button type='button' class='btn save_cart'>${T('Add To Cart')}</button>
        <button type='button' class='btn delete'>${T('Remove')}</button>
      `
    : () => legacyHtml`
        <button type='button' class='btn edit'>${T('Edit')}</button>
        <button type='button' class='btn save_heart'>${T('Save for Later')}</button>
        <button type='button' class='btn delete'>${T('Remove')}</button>
      `;
};

const makeUpCSListHtml = strMap((up_c_s) =>
  sel('quantity', up_c_s)
    ? legacyHtml`
        <div class='up_c_s_item' is_not_stock='${up_c_s._is_not_stock}'>
          <div class='size'>${up_c_s['_name' + _en]}</div>
          <div class='quantity'>
            <span class='val'>${UtilS.commify(up_c_s.quantity)}</span>
          </div>
        </div>
      `
    : legacyHtml` <div class='up_c_s_item empty'>${T('Please choose the size of the product.')}</div> `,
);

export const makeUpCProductPriceContentHtml = pipe(
  function (up_c, _en) {
    _en = _en === undefined ? G._en : _en;
    return {
      discounted_price: PriceS.pricify_by(
        sumBy((up_c_s) => PriceS.mult(up_c_s['discounted_price' + _en], up_c_s.quantity), up_c._.up_c_ss),
        _en,
      ),
      price: PriceS.pricify_by(
        sumBy((up_c_s) => PriceS.mult(up_c_s['price' + _en], up_c_s.quantity), up_c._.up_c_ss),
        _en,
      ),
    };
  },
  ($) => legacyHtml`
    <div class='origin'>${$.price == $.discounted_price ? '' : $.price}</div>
    <div class='discounted mp_currency'>${$.discounted_price}</div>
  `,
);

export const makeCartModalProductPriceContentHtml = ($) => legacyHtml`
  <div
    class='product_price ${$.quantity == 0 ? 'hide' : ''}'
    is_discounted='${$['discounted_price' + _en] != $['price' + _en]}'
  >
    <div class='original mp_currency'>${PriceS.pricify(PriceS.mult($['price' + _en], $.quantity))}</div>
    <div class='discounted mp_currency'>
      ${PriceS.pricify(PriceS.mult($['discounted_price' + _en], $.quantity))}
    </div>
  </div>
`;

export const makeCartTooltipHtml = (up_c) => legacyHtml`
  <a class='item_body' href='${up_c.store_url || ''}/cart' _up_c_id='${up_c.id}'>
    <div class='thumb'>
      <div class='img canvas_75'><canvas></canvas></div>
    </div>
    <div class='up_c_info'>
      <div class='msg'>${T('Added to cart')}</div>
      <div class='title'>${bpNameByUpC(up_c)}</div>
      <div class='quantity'>
        <span class='qty'>${T('Qty')}:</span>
        <span>${UtilS.commify(sumBy(sel('quantity'), up_c._.up_c_ss))}</span>
      </div>
    </div>
  </a>
`;

export const makeWishHtml = (ups) => legacyHtml`
  <div class='cart_body ${ups.length === 0 ? 'empty_page' : ''}'>
    <div class='header_title'>
      <span class='title ff_ccl'>${T('Wish List')}</span>
    </div>
    <div class='empty_wrap'>
      <img
        src='//s3.marpple.co/files/u_18054/2018/10/original/f_6009_1539261673396_JduEPV0Lpt9E7ieXUH.svg'
        alt=''
      />
      <span>${T('Your wish list is empty')}</span>
    </div>
    <div class='up_list' _sel='user_products'>
      <div class='table_header pc_v'>
        <span>${T('Product Details')}</span>
        <span>${T('Size/Quantity')}</span>
        <span>${T('Price')}</span>
        <span>${T('Optional')}</span>
      </div>
      ${strMap(makeCartUpItemHtml('wish'), ups)}
    </div>
    <div class='submits for_wish'>
      <div class='submit save_cart_all'>${T('Add All To Cart')}</div>
    </div>
  </div>
`;

export const makeCartModalItemInfoWrapHtml = (
  { size, quantity },
  { min = 0, max = 5000 } = { min: 0, max: 5000 },
) => legacyHtml`
  <div class='info_wrap'>
    <div class='info size_info'>
      <span>${size || T('cart_modal::quantity')}</span>
    </div>
    <div class='info qty_info'>
      <div class='qty_wrap'>
        <div class='input_wrap'>
          <div class='minus'></div>
          <input
            type='number'
            class='quantity'
            value='${quantity}'
            min='${min}'
            max='${max}'
            inputmode='numeric'
            pattern='[0-9]*'
          />
          <div class='plus'></div>
        </div>
      </div>
    </div>
  </div>
`;

export const makeCartModalItemInfoWrapHtmlSelect = ({ size, quantity }, { quantities, max, min }) => html`
  <div class="info_wrap">
    <div class="info size_info">
      <span>${size || T('cart_modal::quantity')}</span>
    </div>
    <div class="info qty_info">
      <div class="qty_wrap">${BpOptionTmplS.makeSelectQuantityHtml({ quantities, quantity, min, max })}</div>
    </div>
  </div>
`;

export const makeCartModalHtml = (data) => {
  const thumbnail_url = data.up_c_ss[0].thumbnail_url;
  const price = data.up_c_ss[0]['discounted_price' + G._en];
  const goods_type_id = data.up_c_ss[0].goods_type_id;
  const wow_qty = data.wow_qty;

  const selected_option_group = data.product_color._.selected_option_group;
  const selected_option_names = selected_option_group?.option_names;
  const has_selected_option_names = !UtilS.isEmpty(selected_option_names) && G.collabo_type === '';
  const selected_option_names_el = has_selected_option_names
    ? `<div>${UserProductS.naming.makeOptionNames1({
        selected_option_group,
        base_product_size: {},
        base_product_color: data.product_color._.base_product_color,
        base_product: data.product_color._.base_product,
        product_faces2: data.product_color.product_faces2,
      })}</div>`
    : '';

  if (has_selected_option_names) {
    UserProductS.naming.makeEmptyUpSize(data.up_c_ss);
  }

  return html`
    <div class="modal" _sel="${data.sel}">
      ${GoodsTypeS.isNotPod(goods_type_id) && thumbnail_url
        ? html`
            <div class="product_preview pc_v">
              <div class="product_face_container"><img src="${thumbnail_url}" alt="" /></div>
            </div>
          `
        : makeCartModalPreviewHtml(data.product_color, 'modal->product_color')}
      <div class="for_desktop">
        <div class="list_body" _sel="modal->up_c_ss">
          <div class="product_title">
            <div class="pc_name">${data.product_color.store_id ? data.product_color['name' + _en] : ''}</div>
            <div class="bp_name">
              ${GoodsTypeS.isPod(goods_type_id)
                ? UserProductS.naming.makeBpNameWithColor(data.product_color._)
                : data.product_color._.base_product._.cate_item.name}
            </div>
            <div class="price">
              <div class="creator_price mp_currency">${price}</div>
            </div>
          </div>
          ${data.max_purchase_per_user
            ? html`
                <div class="possible-max-quantity">
                  ${T(messages.possible_quantity, {
                    possible_quantity: data.possible_quantity,
                  })}
                </div>
              `
            : ''}
          ${selected_option_names_el}
          ${strMap(
            ([i, up_c_s]) => legacyHtml`
              <div class='item' _sel='./${i}'>
                ${
                  wow_qty?.quantities?.length
                    ? makeCartModalItemInfoWrapHtmlSelect(up_c_s, wow_qty)
                    : makeCartModalItemInfoWrapHtml(up_c_s, wow_qty)
                }
                <div class='product_price_wrap'>${makeCartModalProductPriceContentHtml(up_c_s)}</div>
              </div>
            `,
            zipWithIndexL(data.up_c_ss),
          )}
        </div>
        <div class="modal_footer">
          <div class="summary">${makeCartModalSummaryContentHtml(data.up_c_ss)}</div>
          <div class="buttons"><button id="confirm" type="button">${T('Confirm')}</button></div>
        </div>
      </div>
    </div>
  `;
};

export const makeCartModalPreviewHtml = (pc, selector) => {
  const { expired_at } = pc?._?.pdf_printing_file || {};
  const is_pdf_file_expired = OMPCoreUtilS.isExpired({ expired_at });
  return legacyHtml`
  <div class='product_preview pc_v'>
    ${
      is_pdf_file_expired
        ? `<div class="product_face_container file_expired">${TT('pdf::expired::4')}</div>`
        : `<div class='product_face_container swiper-container'>
      <div class='product_face_list swiper-wrapper top' _sel='${selector}'>
        ${strMap(makeModalPreviewSlideHtml, zipWithIndexL(sel('product_faces2.value', pc)))}
      </div>
      <div class='product_face_list bottom' _sel='${selector}'>
        ${strMap(makeModalPreviewThumbHtml, zipWithIndexL(sel('product_faces2.value', pc)))}
      </div>
      <div class='swiper_control preview_next'></div>
      <div class='swiper_control preview_prev'></div>
    </div>`
    }
  </div>
`;
};

const makeModalPreviewSlideHtml = ([i, pf]) => legacyHtml`
  <div
    class='product_face swiper-slide'
    _idx='${i}'
    bpf_id='${pf.bpf_id}'
    face_name='${pf.face_name}'
    face_name_en='${pf.face_name_en}'
  >
    <div class='img canvas_600 canvas_fit'>
      <canvas width='800' height='800'></canvas>
      <div class='don_loader_wrap2'>
        <div class='don_loader_img2'></div>
      </div>
    </div>
  </div>
`;

const makeModalPreviewThumbHtml = ([i, pf]) => legacyHtml`
  <div class='product_face preview_thumb' _idx='${i}'>
    <div class='img canvas_60'>
      <canvas></canvas>
      <div class='don_loader_wrap2'>
        <div class='don_loader_img2'></div>
      </div>
    </div>
    <div class='face_name'>${pf['face_name' + _en]}</div>
  </div>
`;
