import { flatMap, go, map, sumBy } from 'fxjs/es';
import { OrderShippingOrderS } from '../../../ShippingOrder/S/Function/module/OrderShippingOrderS.js';

export const pCalcShippingsPrice = (customer_order, is_original) => {
  return go(
    customer_order._.store_orders,
    flatMap((store_order) => store_order._.shipping_orders),
    map((shipping_order) => OrderShippingOrderS.pGetShippingPrice(shipping_order, is_original)),
    sumBy((price) => price),
  );
};
