import { $append, $attr, $el, $findAll, $qs } from 'fxdom/es';
import { each, go, sel, tap } from 'fxjs/es';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { resetUserCounts } from '../../../../MPay/Cart/F/fs.js';
import { makeCartHtml, makeUpCItemHtml } from '../../../../MPay/Cart/S/tmpl.js';
import { MShopAppCartTmplS } from '../../../../MShop/App/Cart/S/Tmpl/module/MShopAppCartTmplS.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { computeShippingCostF } from '../../../../Shipping/Cost/F/fs.js';
import StickySidebar from 'sticky-sidebar';

export const afterEdit = async function () {
  const box = window.box;
  const user_products = box.sel('modal->_user_products_');
  // #8-1 모달 데이터 제거
  box.unset('modal');
  if (!user_products) return;

  box.set('user_products', user_products);
  if (G.collabo_type === 'creator') {
    return G.mp.cart.render(
      MShopUtilF.isMobile() ? MShopAppCartTmplS.pCreatorCartMobileHtml : MShopAppCartTmplS.pCreatorCartHtml,
      user_products,
    );
  } else {
    /* 마플 더이상 안쓰는듯 */
    return G.mp.cart.render(_p(makeCartHtml, _p, computeShippingCostF), user_products);
  }
};

export const cartTabAppended = (tab_el) =>
  go(
    tab_el,
    tap(function (el) {
      return go(
        el,
        $findAll('.up_c_item'),
        each(function (e) {
          G.mp.maker.draw_product_face2(e, sel('_.product_color', box.sel(e)));
        }),
      );
    }),
    G.mp.cart.refresh_ups('cart'),
  );

export const storeNameAndProductAppEvent = (el) =>
  go(
    el,
    $.on2('click', '.store_name', function (e) {
      if (!MShopUtilF.isCreatorApp()) return;
      e.preventDefault();
      const a_el = e.currentTarget;
      const {
        dataset: { logo_img: titleImage, title },
      } = a_el;
      MShopUtilF.postMessage({
        pushNavigate: {
          uri: $attr('href', a_el),
          ...(titleImage ? { titleImage } : { title }),
        },
      });
    }),
    $.on2('click', '.up_c_item a', function (e) {
      if (!MShopUtilF.isCreatorApp()) return;
      e.preventDefault();
      const a_el = e.currentTarget;
      MShopUtilF.postMessage({
        pushNavigate: {
          uri: $attr('href', a_el),
          title: T('mshop::상품상세'),
        },
      });
    }),
  );

export const cartPageAppended = (page_el) => {
  if (G.collabo_type === 'creator' && !MShopUtilF.isMobile() && T.lang !== 'kr') {
    new StickySidebar('.cart_footer', {
      containerSelector: '.cart_body',
      topSpacing: 12,
    });
  }
  return go(
    page_el,
    storeNameAndProductAppEvent,

    // #1 제품 수정
    $.on2(
      'click',
      '.up_item .controls .edit',
      __(
        // #1-1 이 함수는 'mp.cart.modal' 모달을 띄우는게 목표
        _p.v('currentTarget'),
        $.closest('.up_c_item'),
        _p.add_arg($.closest('.up_item')),
        async function ($up_c, $up_item) {
          const up_c = box.sel($up_c);
          const pc = up_c._.product_color;
          const bpss = pc._.base_product_color._.base_product_sizes;

          return _p.go(
            bpss,
            _p.map(function (bps) {
              let up_c_s = _p.find(up_c._.up_c_ss, function (up_c_s) {
                return up_c_s.base_product_size_id === bps.id;
              });
              up_c_s = up_c_s || _p.omit(up_c, ['id', '_', 'quantity', 'base_product_size_id']);
              return _p.defaults(_p.omit(up_c_s, '_'), {
                size: bps['name' + _en],
                base_product_size_id: bps.id,
                no: bps.no,
                quantity: 0,
                _is_not_stock: bps._is_not_stock,
              });
            }),
            _p.sort_by('no'),
            function (up_c_ss) {
              // #1-2 모달 내에서만 사용할 데이터를 셋팅 (#8-1에서 제거됨)
              box.set('modal', { product_color: pc, up_c_ss, up_c });

              const is_mobile = MShopUtilF.isMobile();
              if (G.collabo_type === 'creator' && !is_mobile) {
                const sticky_el = $qs('.cart_footer .inner-wrapper-sticky');
                if (sticky_el) {
                  const temp_sticky_el = $el(`
                    <div class="temp_sticky" style="${$attr('style', sticky_el)}"></div>
                  `);
                  $append(sticky_el, temp_sticky_el);
                  $append(temp_sticky_el, $qs('.cart_footer'));
                }
              }

              const height_390 = G.collabo_type === 'creator' && is_mobile && up_c_ss.length === 1;
              if (height_390) {
                MShopUtilF.bodyFixed$(true);
              }

              // #1-3 up_c._.up_c_ss 정보를 수정하는 모달을 띄운다
              return $.frame.open(
                {
                  frame_name: 'mp.cart.modal',
                  animation: is_mobile,
                  height: height_390 ? 390 : 0,
                  title: G.collabo_type === 'creator' ? T('How many do you need?') : '',
                  hiding: function () {
                    if (G.collabo_type === 'creator' && !is_mobile) {
                      // const sticky_el = $qs('.cart_footer .inner-wrapper-sticky');
                      // const temp_sticky_el = $qs('.cart_footer .temp_sticky');
                      // if (temp_sticky_el) {
                      //   $append(sticky_el, $qs('.cart_footer'));
                      //   $remove(temp_sticky_el);
                      // }
                    }
                    if (is_mobile) {
                      MShopUtilF.bodyFixed$(false);
                    }
                  },
                },
                {
                  title: G.collabo_type !== 'creator' ? T('How many do you need?') : '',
                  tabs: [
                    {
                      page_name: 'mp.cart.modal.page',
                      data_func: function () {
                        return {
                          product_color: pc,
                          up_c_ss,
                          sel: _reduce(
                            [$up_item, $up_c],
                            function (s, e) {
                              return s + '->' + $.attr(e, '_sel').substring(2);
                            },
                            'user_products',
                          ),
                        };
                      },
                    },
                  ],
                },
              );
            },
          );
        },
      ),
    ),

    // #4 위시리스트로 보내기
    $.on2(
      'click',
      '.up_item .controls .save_heart',
      __(
        _p.v('currentTarget'),
        _p
          .if2(
            $.closest('.up_c_item'),
            $.find1('.up_c_s_item.empty'),
          )(_p.$alert(T('Please choose the size of the product.')))
          .else(
            _p.add_arg(
              // #4-1 .up_c_item로 부터 box의 데이터를 가져온다
              G.mp.cart.box_data_from('.up_c_item'),
              _p(_p.im.set, _p, 'type', 'wish'),
              _p(_p.im.unset, _p, '_->product_color->_'),
              _p(_p.im.unset, _p, '_->product_color->product_faces2'),
              // #4-2 up_c 단위로 위시리스트로 넘겨주기 위해 api 호출
              _p($.post_load, '/@api/up_c/update'),
            ),
            _p
              .if2(function (el, res) {
                return res.is_failed;
              })(_p.$alert('Fail'), () => location.reload())
              .else(
                function (el, user_products) {
                  box.set('user_products', user_products);
                  /*카트라서 도서산간비 X */
                  return G.mp.cart.render(_p(makeCartHtml, _p, computeShippingCostF), user_products);
                },
                function () {
                  $.don_loader_end();
                  return resetUserCounts();
                },
              ),
          ),
      ),
    ),

    // #6 제품 삭제하기
    $.on2(
      'click',
      '.up_item .controls .delete',
      __(
        _p.v('currentTarget'),
        _p.tap(function (el) {
          const up_c = box.sel(el);
          const quantity = _sum(_p.v(up_c, '_.up_c_ss'), function (up_c_s) {
            return up_c_s.quantity;
          });
          try {
            if (quantity && typeof gtag == 'function') {
              gtag('event', 'remove_from_cart', {
                items: [
                  {
                    id: _p.v(up_c, '_.product_color._.base_product.id'),
                    name: _p.v(up_c, '_.product_color._.base_product.name' + _en),
                    list_name: '장바구니',
                    // "brand": "Google",
                    // "category": "Apparel/T-Shirts",
                    // "variant": "Black",
                    list_position: 1,
                    quantity,
                    price: _p.v(up_c, 'price' + _en),
                  },
                ],
              });
            }
          } catch (err) {}
        }),
        _p.add_arg(
          G.mp.cart.box_data_from('.up_c_item'),
          _p(_p.im.unset, _p, '_->product_color'),
          _p($.post_load, '/@api/up_c/delete'),
        ),
        function (el, user_products) {
          box.set('user_products', user_products);
          /*카트라서 도서산간비 X */
          return G.mp.cart.render(_p(makeCartHtml, _p, computeShippingCostF), user_products);
        },
        function () {
          $.don_loader_end();
          return resetUserCounts();
        },
      ),
    ),

    $.on(
      'click',
      '.up_c_item .up_c_s_item.empty',
      __(_p.v('currentTarget'), $.closest('.up_c_item'), $.find1('.edit'), $.trigger('click')),
    ),

    // #7 add_color 새로운 색상 추가하기
    $.on2('click', '.card_footer', function (e) {
      const $up_item = $.closest(e.currentTarget, '.up_item');
      const user_product = box.sel($up_item);
      const up_selector = 'user_products->' + $.attr($up_item, '_sel').slice(2);
      const base_product_colors = _p.reject(
        user_product._.product_color._.base_product._.base_product_colors,
        function (bpc) {
          return _p.some(user_product._.up_cs, function (up_c) {
            return up_c.base_product_color_id == bpc.id;
          });
        },
      );

      if (
        base_product_colors.length == 0 ||
        isNeedPreview(box.sel(up_selector + '->_->product_color->_->base_product'))
      )
        return $.alert(T('color::No colors are available to add'));

      return $.frame.open(
        {
          frame_name: 'maker.picker.bpcs',
        },
        {
          tabs: [
            {
              data_func: function () {
                return {
                  done_type: 'Add',
                  base_product_colors,
                  selector: up_selector + '->_->product_color->_->base_product->_->base_product_colors',
                  base_product: box.sel(up_selector + '->_->product_color->_->base_product'),
                  selected_bpc_id: base_product_colors[0].id,
                };
              },
            },
          ],
          // #7-1 add 버튼을 누르면 모달에서 실행시켜주는 함수
          adding: function (bpc) {
            return _p.go(
              { user_product, base_product_color: bpc },
              _p($.post_load, '/@api/up_c/add'),
              function (up) {
                if (up.is_failed) return $.alert('Adding failed. Please try again.');
                box.extend2(up_selector, up);
                return _p.go(
                  up._.up_cs,
                  _p.last,
                  makeUpCItemHtml('cart'),
                  $.append_to($.find1($up_item, '.up_c_list')),
                  function (e) {
                    G.mp.maker.draw_product_face2(e, box.sel(e)._.product_color);
                    resetUserCounts();
                    G.mp.cart.header_count_update(1);
                    $.don_loader_end();
                  },
                );
              },
            );
          },
        },
      );
    }),

    $.on2('click', '.btn#checkout', async (e) => {
      if (MShopUtilF.isCreatorApp()) {
        MShopUtilF.postMessage({
          navigate: {
            sel: 'PaymentStackNavigation.OrderScreen',
            params: {
              title: T('Proceed To Checkout'),
              uri: `/${T.lang}/@/checkout`,
            },
          },
        });

        return;
      }
      await go(
        e,
        __(
          _p.$1('.up_c_s_item.empty, .up_c_s_item[is_not_stock="true"]'),
          _p
            .if2(_p.idtt)(_p.tap(_p.$alert(T('There are some items in your cart'))), function ($empty) {
              const top = $.closest($empty, '.up_c_item').offsetTop - 235;
              $.scroll_top2(window, top, 500);
            })
            .else(_p.c(true), _p.redirect('checkout')),
        ),
      );
    }),
  );
};
