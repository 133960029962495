import { ConfigSentryF } from '../../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreUtilF } from '../../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

/**
 * @param {AxiosError|Error} error
 * @return {void}
 */
export const handleDesignCollectionAdminDetailError = async (error) => {
  const err_message = error?.response?.data || error.toString();
  ConfigSentryF.error(new OMPDesignCollectionAdminDetailError(err_message, error));
  await OMPCoreUtilF.alert({ content: err_message });
};

class OMPDesignCollectionAdminDetailError extends Error {
  constructor(message, originalError) {
    super(message);
    this.name = 'OMPDesignCollectionAdminDetailError';
    this.originalError = originalError;
  }
}
