import { curry, extend } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const validationResult = curry((message = '', is_valid) => ({
  is_valid,
  message: is_valid ? '' : message,
}));

// str => ValidationResult
export const validateNamingRules = (domain_name) => {
  const res = /(^[a-z]|[0-9])([a-z]|[0-9]|-){0,14}([a-z]|[0-9]$)/.exec(domain_name);
  const is_valid = res && res[0] === domain_name;
  return validationResult('2~16자, 소문자 + 숫자 + 대쉬, 대쉬는 시작과 끝에 올 수 없음', is_valid);
};

export const setSnsStoreData = (url) => {
  const sns = {
    type: '',
    ico_url: '',
    head_ico_url: '',
    link: UtilS.makeUrl(url) || '',
  };

  if (!url || !url.length) return sns;

  if (url.indexOf('youtube') != -1 || url.indexOf('youtu.be') != -1)
    return extend(sns, {
      type: 'youtube',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/165941892693bb2d08663901ef166c6e19ba7d9ef531a67.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/1869259b2d28718c7ffdfc039f46ac443a24c795e8c4af1.svg',
    });
  else if (url.indexOf('instagram') != -1)
    return extend(sns, {
      type: 'instagram',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/1659419dc54b6cc3a46fe5a9a4d77f2188015ff277edb0b.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692608a1c885fca72a3f0fc592de3fe7edaad58f9c005.svg',
    });
  else if (url.indexOf('grafolio') != -1)
    return extend(sns, {
      type: 'grafolio',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/16594163e1835b14c76974ba2c87080dc306ef64f237a2b.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/186925762dc538121cd0c87ed585bc8b7272fce2d4296db.svg',
    });
  else if (url.indexOf('facebook') != -1)
    return extend(sns, {
      type: 'facebook',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/16594212e7e40655f76070d6adceb5b677f846c6876ef51.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692623f85db52d7240c93be8bdd9a2ef55a9a563a5fbb.svg',
    });
  else if (url.indexOf('twitch') != -1)
    return extend(sns, {
      type: 'twitch',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/1659420c1c01532cf702d17e9a2333581c4c9e2e1bb7de6.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692618df9f0e7cab511c0d0c2fad8024c0c4dcd6e34a2.svg',
    });
  else if (url.indexOf('twitter') != -1)
    return extend(sns, {
      type: 'twitter',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186571306ce914c2918d331973f102a7025ff5a93f3e0f6.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1865718064d6b8459a2d6dd3e66b340333e4babeb734485.svg',
    });
  else if (url.indexOf('comic.naver') != -1)
    return extend(sns, {
      type: 'naver webtoon',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/3/original/1659417c5ea4c59311fa846269b6bea8ac39d06968c2793.png',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/186925830b1d4b72512b15bc22adbaef5db59027d214384.svg',
    });
  else if (url.indexOf('page.kakao') != -1)
    return extend(sns, {
      type: 'kakao page',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1865715f89c68715d1fdf2f55ee4d0d1cf5c43777ad7c5e.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657207f0b6722560c71472ccb36544dc9de28fcdf5da9.svg',
    });
  else if (url.indexOf('webtoon.daum') != -1)
    return extend(sns, {
      type: 'daum webtoon',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657148908e5e9d90f3335b0e9d361b8d6e274b4d0a68a.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186571982c427c152f6a1b41fa67a0e51ed72c26a670e93.svg',
    });
  else if (url.indexOf('blog.naver') != -1)
    return extend(sns, {
      type: 'naver blog',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/190826926d9822609ca3aec3f070f91c5cb820945623b6c.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/190884750759154c69d2474baf7d2f8920cc3e23529cf03.svg',
    });
  else if (url.indexOf('pinterest') != -1)
    return extend(sns, {
      type: 'pinterest',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657161791717152009cae08ac6d354e1b6cd5d47e671a.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186572131a073f75cb0b92e2a53e3f52079b2d17c29702f.svg',
    });
  else if (url.indexOf('tiktok') != -1)
    return extend(sns, {
      type: 'tiktok',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186571755920fe03237edb9e38c24b147e8551c95e1a749.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657225bca2126635ad62c76984a6c30f70512fdb0d51a.svg',
    });
  else if (url.indexOf('behance') != -1)
    return extend(sns, {
      type: 'behance',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908270c68e0a40022bf0dac88ab6b8e9f7b488ee879a12.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg',
    });
  else if (url.indexOf('brunch.co') != -1)
    return extend(sns, {
      type: 'brunch',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186606904dc62ce85eb80cb20d942e26000c5bbe12818ab.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg',
    });
  else if (url.indexOf('spooncast') != -1)
    return extend(sns, {
      type: 'spooncast',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18660671ccb8ed4e10b28eb5fa6b2c454c91e2e89699457.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1866068b7eee88c9b383ca70466400027ca379ae1464b83.svg',
    });
  else if (url.indexOf('modoo.at') != -1)
    return extend(sns, {
      type: 'modoo',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/5/original/18663261e052ff3ffc1af0686230edf4cf0372ca9d6aaa0.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/5/original/186632584b19bb35d3d534bf2c46ec0d1a27035b91b6e99.svg',
    });
  else
    return extend(sns, {
      type: 'site',
      ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908268c5b37f7595d3ce2dde2b72d1896b1ffeea4eba15.png',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908846be76562591509b3e23f9ee7f2d66902dcf8f2988.svg',
    });
};
