import { each, go, head, keys, map, pipe, reduce } from 'fxjs/es';
import { CurrencyConstantS } from '../../../Currency/S/Constant/module/CurrencyConstantS.js';
import { divDecimal } from '../../../Util/Number/S/Function/decimal.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { CreatorWithdrawConstantS } from '../../Withdraw/S/Constant/module/CreatorWithdrawConstantS.js';
import { getWithoutFeeAmount } from '../../Withdraw/S/util.js';

export const sumAdjustments = pipe(
  map(({ quantity, profit, bonus_profit, without_fee_amount, pg_amount }) => [
    quantity,
    profit,
    bonus_profit,
    profit + bonus_profit,
    pg_amount,
    without_fee_amount,
  ]),
  (nums) => (nums && nums.length ? UtilS.parallelSum(nums) : [0, 0, 0, 0, 0]),
  ([quantity, profit, bonus_profit, total_profit, pg_amount, without_fee_amount]) => ({
    quantity,
    profit,
    bonus_profit,
    total_profit,
    pg_amount,
    without_fee_amount,
  }),
);

export const sumPbAdjustments = pipe(
  map(({ quantity, profit, without_fee_amount, pg_amount, fee_amount }) => [
    quantity,
    profit,
    without_fee_amount,
    pg_amount,
    fee_amount,
  ]),
  (nums) => (nums && nums.length ? UtilS.parallelSum(nums) : [0, 0, 0, 0, 0]),
  ([quantity, profit, without_fee_amount, pg_amount, fee_amount]) => ({
    quantity,
    profit,
    fee_amount,
    pg_amount,
    without_fee_amount,
  }),
);

export const calcAdjustmentIndicators =
  (crew) =>
  ({
    quantity = 0,
    profit = 0,
    bonus_profit = 0,
    seller_profit = 0,
    seller_bonus_profit = 0,
    crew_margin = 0,
    price = 0,
    exchange_rate: _exchange_rate = 0,
    goods_type_id,
  }) => {
    const is_integrated_adjustment = crew.withdraw_type == CreatorWithdrawConstantS.WITHDRAW_TYPE.integrated;
    const seller_total = seller_profit + seller_bonus_profit;
    const total_profit = profit + bonus_profit;
    const crew_design_profit = total_profit - (is_integrated_adjustment ? seller_total : 0);
    const exchange_rate =
      !crew.currency || crew.currency == CurrencyConstantS.CURRENCY.KRW ? 1 : _exchange_rate;

    return {
      quantity,
      profit: UtilS.floorTo(-2, divDecimal(profit, exchange_rate)),
      bonus_profit: UtilS.floorTo(-2, divDecimal(bonus_profit, exchange_rate)),
      total_profit: UtilS.floorTo(-2, divDecimal(total_profit, exchange_rate)),
      seller_profit: UtilS.floorTo(-2, divDecimal(seller_profit, exchange_rate)),
      seller_bonus_profit: UtilS.floorTo(-2, divDecimal(seller_bonus_profit, exchange_rate)),
      seller_total_profit: UtilS.floorTo(-2, divDecimal(seller_total, exchange_rate)),
      crew_design_profit: UtilS.floorTo(-2, divDecimal(crew_design_profit, exchange_rate)),
      crew_margin: UtilS.floorTo(-2, divDecimal(crew_margin, exchange_rate)),
      crew_total_profit: UtilS.floorTo(-2, divDecimal(crew_design_profit + crew_margin, exchange_rate)),
      crew_total_adjustment: UtilS.floorTo(
        -2,
        divDecimal(
          (is_integrated_adjustment ? seller_total : 0) + crew_design_profit + crew_margin,
          exchange_rate,
        ),
      ),
      crew_without_fee_amount: getWithoutFeeAmount(crew.currency)({
        profit,
        bonus_profit,
        crew_margin,
        exchange_rate,
        goods_type_id,
      }),
      seller_without_fee_amount: getWithoutFeeAmount(crew.currency)({
        profit: seller_profit,
        bonus_profit: seller_bonus_profit,
        crew_margin: 0,
        exchange_rate,
        goods_type_id,
      }),
      // naver webtoon only
      ...(price
        ? {
            // profit 은 이미 quantity 곱해진 금액
            sales: price * quantity + (is_integrated_adjustment ? profit : profit + seller_profit),
          }
        : { sales: 0 }),
    };
  };

export const sumCrewAdjustments = (crew) => (adjustments) =>
  go(adjustments, map(calcAdjustmentIndicators(crew)), (adjustment_indicators) => {
    const indicator_names = go(adjustment_indicators, head, keys);

    return reduce(
      (acc, cur) => {
        each((name) => {
          acc[name] = (acc[name] || 0) + cur[name];
        }, indicator_names);

        return acc;
      },
      {},
      adjustment_indicators,
    );
  });

export const calcPbAdjustmentIndicators =
  (crew) =>
  ({
    quantity = 0,
    profit = 0,
    bonus_profit = 0,
    seller_profit = 0,
    seller_bonus_profit = 0,
    crew_margin = 0,
    price = 0,
    exchange_rate: _exchange_rate = 0,
    goods_type_id,
    fee_amount,
  }) => {
    const is_integrated_adjustment = crew.withdraw_type == CreatorWithdrawConstantS.WITHDRAW_TYPE.integrated;
    const seller_total = seller_profit + seller_bonus_profit;
    const total_profit = profit + bonus_profit;
    const crew_design_profit = total_profit - (is_integrated_adjustment ? seller_total : 0);
    const exchange_rate =
      !crew.currency || crew.currency == CurrencyConstantS.CURRENCY.KRW ? 1 : _exchange_rate;

    return {
      quantity,
      fee_amount,
      profit: UtilS.floorTo(-2, divDecimal(profit, exchange_rate)),
      bonus_profit: UtilS.floorTo(-2, divDecimal(bonus_profit, exchange_rate)),
      total_profit: UtilS.floorTo(-2, divDecimal(total_profit, exchange_rate)),
      seller_profit: UtilS.floorTo(-2, divDecimal(seller_profit, exchange_rate)),
      seller_total_profit: UtilS.floorTo(-2, divDecimal(seller_total, exchange_rate)),
      crew_design_profit: UtilS.floorTo(-2, divDecimal(crew_design_profit, exchange_rate)),
      crew_margin: UtilS.floorTo(-2, divDecimal(crew_margin, exchange_rate)),
      crew_total_profit: UtilS.floorTo(-2, divDecimal(crew_design_profit + crew_margin, exchange_rate)),
      crew_total_adjustment: UtilS.floorTo(
        -2,
        divDecimal(
          (is_integrated_adjustment ? seller_total : 0) + crew_design_profit + crew_margin,
          exchange_rate,
        ),
      ),
      crew_without_fee_amount: getWithoutFeeAmount(crew.currency)({
        profit,
        bonus_profit,
        crew_margin,
        exchange_rate,
        goods_type_id,
        fee_amount,
      }),
      seller_without_fee_amount: getWithoutFeeAmount(crew.currency)({
        profit: seller_profit,
        bonus_profit: seller_bonus_profit,
        crew_margin: 0,
        exchange_rate,
        goods_type_id,
        fee_amount,
      }),
      // naver webtoon only
      ...(price
        ? {
            // profit 은 이미 quantity 곱해진 금액
            sales: price * quantity + (is_integrated_adjustment ? profit : profit + seller_profit),
          }
        : { sales: 0 }),
    };
  };

export const sumPbAdjustmentsForCrew = pipe(
  map(
    ({
      quantity,
      crew_design_profit,
      profit,
      seller_total_profit,
      crew_margin,
      crew_total_profit,
      crew_without_fee_amount,
      sales,
    }) => [
      quantity,
      crew_design_profit,
      profit,
      seller_total_profit,
      crew_margin,
      crew_total_profit,
      crew_without_fee_amount,
      sales,
    ],
  ),
  (nums) => (nums && nums.length ? UtilS.parallelSum(nums) : [0, 0, 0, 0, 0, 0, 0, 0]),
  ([
    quantity,
    crew_design_profit,
    profit,
    seller_total_profit,
    crew_margin,
    crew_total_profit,
    crew_without_fee_amount,
    sales,
  ]) => ({
    quantity,
    crew_design_profit,
    profit,
    seller_total_profit,
    crew_margin,
    crew_total_profit,
    crew_without_fee_amount,
    sales,
  }),
);
