import { html } from 'fxjs/es';

export const popup = () => html`
  <div class="studio-pc-info">
    <div class="studio-pc-info__bg">
      <div class="studio-pc-info__content studio-pc-info__content--popup">
        <button type="button" class="studio-pc-info__close">
          <img
            src="//s3.marpple.co/files/u_29089/2021/8/original/94cbccebe3ec7f81fad86999d97d26d2c187eddc1.webp"
            alt=""
          />
        </button>
        <div class="studio-pc-info__title studio-pc-info__title--white">
          ${T('studio::pc_info::셀러 스튜디오를 PC에서 이용해 보세요')}
        </div>
        <div class="studio-pc-info__img">
          <img
            src="//s3.marpple.co/files/u_29089/2021/8/original/fda0f0db93513dbae83d113dd4cc012750e998ac1.webp"
            alt=""
          />
        </div>
        <div class="studio-pc-info__description studio-pc-info__description--white">
          ${T('studio::pc_info::스마트폰뿐 아니라 PC에서도 손쉽게 이용 가능해요 !')}
        </div>
      </div>
    </div>
  </div>
`;

export const unsupportedMobile = (title) => html`
  <div class="studio-pc-info">
    <div class="studio-pc-info__content">
      <div class="studio-pc-info__title">${title}</div>
      <div class="studio-pc-info__img">
        <img
          src="//s3.marpple.co/files/u_29089/2021/8/original/fda0f0db93513dbae83d113dd4cc012750e998ac1.webp"
          alt=""
        />
      </div>
      <div class="studio-pc-info__description">
        ${T('studio::pc_info::셀러 스튜디오를 PC에서 이용해 보세요')}
      </div>
    </div>
  </div>
`;
