import axios from 'axios';
import { ethers } from 'ethers';
import { $addClass, $closest, $delegate, $find, $removeClass, $setHTML } from 'fxdom/es';
import { go, pick } from 'fxjs/es';
import { MShopStudioTokenGateStoresProductTmplS } from '../../../TokenGate/StoresProduct/S/Tmpl/module/MShopStudioTokenGateStoresProductTmplS.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { setState } from './fn.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';

export const tokenGateEvent = (tab_el) => {
  const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;
  const readyContract = { data: {} };

  const resetTokenGateItems$ = () => {
    const all_state = MShopStudioDigitalProductCreatorF.getAllState();
    $setHTML(
      MShopStudioTokenGateStoresProductTmplS.edit(all_state.stores_products_token_gates),
      $find('.setting-token-gate', tab_el),
    );
  };

  const resetStoresProductsTokenGates = () => {
    const all_state = MShopStudioDigitalProductCreatorF.getAllState();
    const condition =
      $find('input[name="setting-token-gate-condition__radio"]:checked', tab_el)?.value || 'AND';
    all_state.stores_products_token_gates = all_state.stores_products_token_gates.map(
      (stores_products_token_gate) => ({
        ...stores_products_token_gate,
        condition,
      }),
    );
    setState({
      key: digital_product_prop.store_products_token_gate,
      value: all_state.stores_products_token_gates,
    });
  };

  const initContract = (data, disabled) => {
    readyContract.data = data;
    $find('.setting-token-gate-edit__add-btn', tab_el).disabled = disabled;
  };

  return go(
    tab_el,
    $delegate('input', '.setting-token-gate-edit__chain', (e) => {
      const addressInput$ = $find('.setting-token-gate-edit__address', tab_el);
      if (e.currentTarget.value == '') {
        $removeClass('setting-token-gate-edit__chain--black', e.currentTarget);
        addressInput$.disabled = true;
        // amountInput$.value ='';
      } else {
        $addClass('setting-token-gate-edit__chain--black', e.currentTarget);
        addressInput$.disabled = false;
      }
      initContract({}, true);
      addressInput$.value = '';
    }),
    $delegate('focusout', '.setting-token-gate-edit__address', async (e) => {
      const all_state = MShopStudioDigitalProductCreatorF.getAllState();
      e.currentTarget.disabled = true;
      if (ethers.utils.isAddress(e.currentTarget.value)) {
        const chain = $find('.setting-token-gate-edit__chain', tab_el)?.value;
        if (
          all_state.stores_products_token_gates.find(
            ({
              _: {
                ether_contract: { address, chain: _chain },
              },
            }) => e.currentTarget.value.toLowerCase() == address.toLowerCase() && chain == _chain,
          )
        ) {
          await $.alert(T('tokenGate::tg012'));
          e.currentTarget.value = '';
          initContract({}, true);
        } else {
          try {
            const { data } = await axios.post(
              `/${T.lang}/@api/stores/${box.sel('store_id')}/token_gate/contract`,
              {
                chain,
                address: e.currentTarget.value,
              },
            );
            initContract(data, false);
          } catch (err) {
            await $.alert(err.response.data.message);
            e.currentTarget.value = '';
          }
        }
      } else if (e.currentTarget.value) {
        await $.alert(T('tokenGate::tg013'));
        e.currentTarget.value = '';
        initContract({}, true);
      }
      e.currentTarget.disabled = false;
    }),
    $delegate('click', '.setting-token-gate-edit__add-btn', () => {
      const all_state = MShopStudioDigitalProductCreatorF.getAllState();
      const amount = $find('.setting-token-gate-edit__amount', tab_el).value;
      if (!amount || !readyContract.data.address) return;
      const etherContractsOnStoresProduct = {
        amount,
        _: {
          ether_contract: pick(['name', 'chain', 'address', 'symbol', 'erc_interface'], readyContract.data),
        },
      };
      all_state.stores_products_token_gates.push(etherContractsOnStoresProduct);
      $addClass('setting-token-gate-wrapper--list', $find('.setting-token-gate-wrapper', tab_el));
      if (all_state.stores_products_token_gates.length > 1) {
        $addClass('setting-token-gate-condition--show', $find('.setting-token-gate-condition', tab_el));
      }
      resetStoresProductsTokenGates();
      initContract({}, true);
      resetTokenGateItems$();
      console.log(all_state);
    }),
    $delegate('input', 'input[name="setting-token-gate-condition__radio"]', () => {
      resetStoresProductsTokenGates();
    }),
    $delegate('click', '.setting-token-gate-item__remove', (e) => {
      const all_state = MShopStudioDigitalProductCreatorF.getAllState();
      go(
        e.currentTarget,
        $closest('.setting-token-gate-item'),
        ({ dataset: { no } }) => all_state.stores_products_token_gates.splice(no, 1),
        () => {
          if (!all_state.stores_products_token_gates.length) {
            $removeClass('setting-token-gate-wrapper--list', $find('.setting-token-gate-wrapper', tab_el));
            $addClass('setting-token-gate-wrapper--empty', $find('.setting-token-gate-wrapper', tab_el));
          }
          if (all_state.stores_products_token_gates.length < 2) {
            $removeClass(
              'setting-token-gate-condition--show',
              $find('.setting-token-gate-condition', tab_el),
            );
          }
          setState({
            key: digital_product_prop.store_products_token_gate,
            value: all_state.stores_products_token_gates,
          });
        },
        resetTokenGateItems$,
      );
    }),
  );
};
