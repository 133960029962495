import { html } from 'fxjs/es';
import { CreatorSettingTermsTmplS } from './module/CreatorSettingTermsTmplS.js';

export const kr_studio_20230816 = ({ store_name }) => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h2>[마플샵 셀러스튜디오] 판매자 이용약관 변경 안내</h2>
      </div>
      <div class="body">
        <p>
          안녕하세요. (주)마플코퍼레이션입니다.<br />
          마플샵(이하 "몰")의 이용약관의 일부 내용이 변경되어 2023년 9월 11일자로 적용될 예정입니다.<br />
          이에 판매자 이용약관 개정에 관한 내용을 사전에 알려드리오니 판매자회원 여러분께서는 새로운 약관을
          확인하시고 참고하여 주시기를 바랍니다.
        </p>
        <p><b>변경고지일 : 2023년 8월 11일</b></p>
        <p><b>약관변경일 : 2023년 9월 11일</b></p>
        <p>변경 내용</p>
        <table class="terms_preview">
          <tr>
            <th>변경 전</th>
            <th>변경 후</th>
          </tr>
          <tr>
            <td>
              <h3>제13조 (정산)</h3>
              <ol>
                <li>
                  ⑧. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이
                  본 약관 7조 6항에 따라 회사에 제공한 결제 계좌로 출금신청이 마감된 10일 이후 7 영업일 이내에
                  그 금액을 송금합니다. 단, 판매자회원이 지정한 입금계좌의 예금주는 판매자회원과 동일인이어야
                  하며(단, 제5조 제3항에 따라 법정대리인이 정산을 받는 경우 예외로 합니다), 해당 일이 회사의
                  영업일이 아닌 경우 이후 최초 영업일에 송금합니다.
                </li>
              </ol>
            </td>
            <td>
              <h3>제13조 (정산)</h3>
              <ol>
                <li>
                  ⑧. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이
                  본 약관 7조 6항에 따라 회사에 제공한 결제 계좌로 출금신청이 마감된 10일 이후 9 영업일 이내에
                  그 금액을 송금합니다. 단, 판매자회원이 지정한 입금계좌의 예금주는 판매자회원과 동일인이어야
                  하며(단, 제5조 제3항에 따라 법정대리인이 정산을 받는 경우 예외로 합니다), 해당 일이 회사의
                  영업일이 아닌 경우 이후 최초 영업일에 송금합니다.
                </li>
              </ol>
            </td>
          </tr>
        </table>
      </div>
      <hr style="margin-top: 80px; margin-bottom: -20px;" />
    </div>

    ${CreatorSettingTermsTmplS.kr_studio_20230911({ store_name })}
  `;
};
