import { every, go, head, map, sel } from 'fxjs/es';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { OrderValidatorS } from '../../../Validator/S/Function/module/OrderValidatorS.js';

export const makeProjection = ({ marpple_delivery_ups, session_user_id, marpple_shop_total_price_info }) => {
  const {
    mps_original_products_price,
    mps_products_price,
    mps_shippings_price,
    mps_additional_shippings_price,
    mps_total_price,
    mps_quantity,
  } = marpple_shop_total_price_info;
  const up = go(marpple_delivery_ups, head);
  const user_product_id = go(up, sel('id'));
  const store_id = go(up, sel('store_id'));
  const merged_type = go(
    marpple_delivery_ups,
    map(sel('_.product_color._.stores_product._.store.user_id')),
    every((user_id) => user_id === session_user_id),
  )
    ? 'sample'
    : 'child';
  return {
    quantity: mps_quantity,
    ['product_original_price' + _en]: mps_original_products_price || 0,
    ['discount_price' + _en]: PriceS.sub(mps_original_products_price, mps_products_price) || 0,
    ['product_price' + _en]: mps_products_price || 0,
    ['shipping_price' + _en]: mps_shippings_price || 0,
    additional_shipping_price: mps_additional_shippings_price || 0,
    ['total_price' + _en]: mps_total_price || 0,
    ['amount' + _en]: mps_total_price || 0,
    status: 'before_order',
    user_id: session_user_id,
    user_product_id,
    store_id,
    merged_type,
    collabo_type: 'creator',
    type: 'parent',
    up_c_ss: JSON.stringify({ value: OrderValidatorS.flatAndPickUpCSs(marpple_delivery_ups) }),
    created_at: new Date(),
    lang: T.lang,
    // { local_ordered_at: ordered_at },
    // { up_c_ss: { value: marpple_delivery_up_c_ss }, lang: T.lang, created_at: now },
  };
};
