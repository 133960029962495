export const hasProblem = async (name) => {
  if (!name) return;
  try {
    const data = await $.get('/@api/module_info', { name });
    if (data?.module_info?.is_working === false && data.module_info['message' + G._en]) {
      await $.alert(data.module_info['message' + G._en]);
      return true;
    }
  } catch (e) {
    console.error(e);
  }
};
