import { go, head, html, strMap } from 'fxjs/es';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import {
  makeCateItemFilterHtml,
  makeEditableProductItemHtml,
  makeProductRevealingFilterHtml,
  makeProductRevealingFilterHtml2,
} from '../../../../../Creator/Setting/S/tmpl.js';
import { makePaginationHtml } from '../../../../../Pagination/S/tmpl.js';

export const root = ({
  is_store_deleted,
  store_products,
  pagination_state,
  total_product_count,
  available_categories,
  filter_state,
  need_confirm_action,
} = {}) => {
  const { cate_items } = go(available_categories, head)._;
  return html`
    <div class="header flex">
      <div class="left real-pb-setting-title">
        <h2>${T(messages.menus.make_digital_products)}</h2>
        <p>${T(messages.digital_products.p1)}</p>
      </div>
      <div class="right product-filters flex-column">
        <button class="product-filters__button" type="button">
          ${T('digitalProductModal::마플샵에 상품 공개하기')}
        </button>
        <div class="product-filters__filter">
          ${makeCateItemFilterHtml({ available_categories: cate_items, filter_state })}
          ${makeProductRevealingFilterHtml(filter_state)}
          ${need_confirm_action ? makeProductRevealingFilterHtml2(filter_state) : ''}
        </div>
      </div>
    </div>
    <div class="body">
      <ul
        class="products"
        data-total_product_count="${total_product_count}"
        data-is_store_deleted="${is_store_deleted}"
      >
        <li class="btn-create-product">
          <div class="wrap-content">
            <div class="plus-icon">
              <img
                src="//s3.marpple.co/files/u_1165777/2020/9/original/993035129b660d8b42e3a025bf5cae74a4d2e5421.png"
                alt="${T(messages.digital_products.p2)}"
              />
            </div>
            <div class="description">${T(messages.digital_products.p2)}</div>
          </div>
        </li>
        ${strMap(makeEditableProductItemHtml, store_products)}
      </ul>
      ${makePaginationHtml(pagination_state)}
    </div>
  `;
};
