import {
  $addClass,
  $append,
  $appendTo,
  $closest,
  $el,
  $find,
  $findAll,
  $hasClass,
  $remove,
  $removeClass,
  $setData,
  $setText,
  $text,
  $toggleClass,
} from 'fxdom/es';
import { delay, go, html, mapC, tap } from 'fxjs/es';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { UPLOAD_MESSAGE } from '../../S/Constant/digitalproductcreator.js';
import { MShopStudioDigitalProductCreatorTmplS } from '../../S/Tmpl/module/MShopStudioDigitalProductCreatorTmplS.js';
import { makeUploadResultsDashboardHtml } from '../../S/Tmpl/share.js';
import { clickDigitalProductUploadButton } from './handler.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';

const transitionend = (f) => (el) =>
  new Promise((resolve) =>
    setTimeout(() => {
      f(el);
      el.addEventListener('transitionend', () => resolve(el), { once: true });
    }, 1),
  );
export const updateResultsDashboard = (function () {
  const task_stack = [0];
  return async ({ e, message }) => {
    if (message === UPLOAD_MESSAGE.uploading) {
      task_stack.push(0);
      if (task_stack.length > 1) {
        return;
      }
    }

    if (message === UPLOAD_MESSAGE.upload_complete) {
      task_stack.pop();
      if (task_stack.length > 0) {
        return;
      }
    }

    if (message === UPLOAD_MESSAGE.init) {
      task_stack.pop();
      return;
    }

    const $form_control = go(e.currentTarget, $closest('.digital-product-creator-form__control'));
    await go(
      $form_control,
      $findAll(
        '.digital-product-creator-form__spinner-icon, .digital-product-creator-form__upload-results-dashboard__upload-message',
      ),
      mapC((el) => {
        if ($hasClass('digital-product-creator-form__spinner-icon', el)) {
          go(
            el,
            $toggleClass('hide'),
            delay(200),
            $toggleClass('none'),
            tap(async () => {
              await go(
                $form_control,
                $find('.digital-product-creator-form__complete-circle'),
                $toggleClass('none'),
                $toggleClass('hide'),
                delay(200),
                $toggleClass('hide'),
              );
            }),
            tap(async () => {
              await go(
                $form_control,
                $find('.digital-product-creator-form__complete-checkmark-screen'),
                $toggleClass('hide'),
              );
            }),
          );
        }
        if ($hasClass('digital-product-creator-form__upload-results-dashboard__upload-message', el)) {
          go(el, $toggleClass('text-fade'), $setText(message), $toggleClass('text-fade'));
        }
      }),
    );
  };
})();

export const initDashboard = (tab_el) => {
  go(
    tab_el,
    $findAll(
      '.digital-product-creator-form__spinner-icon, .digital-product-creator-form__upload-results-dashboard__upload-message',
    ),
    mapC((el) => {
      if ($hasClass('digital-product-creator-form__spinner-icon', el)) {
        go(
          el,
          $toggleClass('hide'),
          $toggleClass('none'),
          tap(async () => {
            await go(
              tab_el,
              $find('.digital-product-creator-form__complete-circle'),
              $toggleClass('none'),
              $toggleClass('hide'),
              $toggleClass('hide'),
            );
          }),
          tap(async () => {
            await go(
              tab_el,
              $find('.digital-product-creator-form__complete-checkmark-screen'),
              $toggleClass('hide'),
            );
          }),
        );
      }
      if ($hasClass('digital-product-creator-form__upload-results-dashboard__upload-message', el)) {
        go(
          el,
          $toggleClass('text-fade'),
          $setText(UPLOAD_MESSAGE.upload_complete),
          $toggleClass('text-fade'),
        );
      }
    }),
  );
  return tab_el;
};

export const setResultsDashboard = async ({ e, $results_dashboard, files_total_count }) => {
  if (!$results_dashboard) {
    go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $append($el(makeUploadResultsDashboardHtml({ files_total_count }))),
    );
  } else {
    go(
      $results_dashboard,
      $find('.digital-product-creator-form__upload-results-dashboard__files-total-count'),
      $setText(String(files_total_count) + '개'),
    );
    await updateResultsDashboard({ e, message: UPLOAD_MESSAGE.uploading });
  }
};

export const getUploadResults = (e) => {
  return (
    go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $find('.digital-product-creator-form__upload-results'),
    ) ||
    go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $append(
        $el(html`<div
          class="digital-product-creator-form__upload-results digital-product-creator-form__toggle"
        ></div>`),
      ),
    )
  );
};

export const startUploadProgress = ({ $upload_result, $upload_results }) => {
  go(
    $upload_result,
    tap($appendTo($upload_results)),
    $findAll(
      '.progress, ' +
        '.digital-product-creator-form__decorate-image-background, ' +
        '.digital-product-creator-form__decorate-image-icon, ' +
        '.digital-product-creator-form__upload-result__file-name, ' +
        '.digital-product-creator-form__upload-result__file-size',
    ),
    mapC($addClass('active')),
  );
};

export const completeEachUploadProgress = async ({ e, $upload_result }) => {
  await go(
    $upload_result,
    $findAll('.progress'),
    delay(500),
    mapC(transitionend($addClass('hide'))),
    mapC($addClass('none')),
  );

  go(
    $upload_result,
    $findAll(
      'img, ' +
        '.digital-product-creator-form__upload-result__file-name,' +
        '.digital-product-creator-form__upload-result__file-size',
    ),
    mapC($toggleClass('active')),
  );

  const current_upload_count = go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $find('.digital-product-creator-form__upload-count'),
    $text,
    Number,
  );

  go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $find('.digital-product-creator-form__upload-count'),
    $setText(String(current_upload_count + 1)),
  );
};

export const setProgressElForPreview = ({
  $upload_box,
  file_size,
  file_name,
  signed_url_key,
  digital_product_resource_id,
}) => {
  go(
    $upload_box,
    tap($find('.digital-product-creator-form__upload-box__guides'), $remove),
    $append(
      $el(
        html`<div class="digital-product-creator-form__preview-progress-wrapper">
          ${MShopStudioDigitalProductCreatorTmplS.makeProgressHtml({
            file_size,
            file_name,
            is_preview: true,
            signed_url_key,
            digital_product_resource_id,
          })}
        </div>`,
      ),
    ),
  );
};

export const revertElForPreview = ($upload_box) => {
  go(
    $upload_box,
    tap($find('.digital-product-creator-form__preview-progress-wrapper'), $remove),
    $append(
      $el(
        html`
          <div class="digital-product-creator-form__upload-box__guides">
            <div class="digital-product-creator-form__upload-box__guide-main">
              미리듣기 파일을 업로드해 주세요.
            </div>
            <div class="digital-product-creator-form__upload-box__guide-sub">
              업로드 가능 형식 : MP3, ACC / 파일당 1GB 이하 / 최대 60초 업로드 가능
            </div>
          </div>
        `,
      ),
    ),
  );
};

export const startPreviewUploadProgress = ($upload_box) => {
  go(
    $upload_box,
    $findAll(
      '.progress, ' +
        '.digital-product-creator-form__decorate-image-background, ' +
        '.digital-product-creator-form__decorate-image-icon, ' +
        '.digital-product-creator-form__upload-result__file-name, ' +
        '.digital-product-creator-form__upload-result__file-size',
    ),
    mapC($addClass('active')),
  );
};

export const completeProgress = async ($upload_box) => {
  await go(
    $upload_box,
    $findAll('.progress'),
    delay(500),
    mapC(transitionend($addClass('hide'))),
    mapC($addClass('none')),
  );

  go(
    $upload_box,
    $findAll(
      'img, ' +
        '.digital-product-creator-form__upload-result__file-name,' +
        '.digital-product-creator-form__upload-result__file-size',
    ),
    mapC($toggleClass('active')),
  );
};

export const initPreviewUploadBox = ({
  $upload_box,
  file_name,
  file_size,
  signed_url_key,
  digital_product_resource_id,
}) => {
  MShopStudioDigitalProductCreatorF.setProgressElForPreview({
    $upload_box,
    file_name,
    file_size,
    signed_url_key,
    digital_product_resource_id,
  });
  $upload_box.removeEventListener('click', clickDigitalProductUploadButton);
  disableUploadBox($upload_box);
  go($upload_box, $findAll('.progress'), mapC($addClass('hide')), mapC($addClass('none')));
  // mobile일 때는 class명에 따른 애니메이션이 다르다.
  MShopUtilF.isMobile()
    ? go($upload_box, $findAll('img'), mapC($toggleClass('active')))
    : go(
        $upload_box,
        $findAll(
          'img, ' +
            '.digital-product-creator-form__upload-result__file-name,' +
            '.digital-product-creator-form__upload-result__file-size',
        ),
        mapC($toggleClass('active')),
      );
};

export const initFileButtons = (tab_el) => {
  go(tab_el, $findAll('.progress'), mapC($addClass('none')));

  go(
    tab_el,
    $findAll(
      'img, ' +
        '.digital-product-creator-form__upload-result__file-name,' +
        '.digital-product-creator-form__upload-result__file-size',
    ),
    mapC($toggleClass('active')),
  );
  return tab_el;
};

export const initFileButtonsMo = (tab_el) => {
  go(tab_el, $findAll('.progress'), mapC($addClass('none')));
  go(tab_el, $findAll('.digital-product-creator-form__upload-result__icon'), mapC($addClass('active')));
  return tab_el;
};
export const afterDeleteEl = (e, file_len) => {
  const result_len =
    go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $findAll('.digital-product-creator-form__upload-result'),
    ).length - 1;

  $setData(
    { files_total_count: result_len },
    go(e.delegateTarget, $find('.digital-product-creator-form__upload-box')),
  );

  if (!file_len) {
    return go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $findAll(
        '.digital-product-creator-form__upload-results, .digital-product-creator-form__upload-results-dashboard',
      ),
      mapC($remove),
    );
  }

  const $results_dashboard = go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $find('.digital-product-creator-form__upload-results-dashboard'),
  );

  const current_file_count = go(
    $results_dashboard,
    $find('.digital-product-creator-form__upload-count'),
    $text,
  );

  go(
    $results_dashboard,
    $find('.digital-product-creator-form__upload-count'),
    $setText(String(Number(current_file_count) - 1)),
  );

  go(
    $results_dashboard,
    $find('.digital-product-creator-form__upload-results-dashboard__files-total-count'),
    $setText(String(result_len + '개')),
  );

  go(e.currentTarget, $closest('.digital-product-creator-form__upload-result'), $remove);
};
export const disableUploadBox = ($upload_box) => {
  go($upload_box, $addClass('disabled'));
};

export const enableUploadBox = ($upload_box) => {
  go($upload_box, $removeClass('disabled'));
};

export const appendFailUploadResult = ({ $fail_result, $upload_results }) => {
  go(
    $fail_result,
    $appendTo($upload_results),
    $findAll('img, .digital-product-creator-form__upload-result__file-name'),
    mapC($toggleClass('active')),
  );
};

export const appendFailUploadResultMo = ({ $fail_result, $upload_results }) => {
  go($fail_result, $appendTo($upload_results), $findAll('img'), mapC($toggleClass('active')));
};

export const changeDashboardFailState = ({ e, action }) => {
  const el = e.currentTarget || e;
  const $form_control = go(el, $closest('.digital-product-creator-form__control'));
  const current_fail_count = go(
    $form_control,
    $find('.digital-product-creator-form__upload-results-dashboard__fail-count'),
    $text,
    Number,
  );

  action === 'add' &&
    go(
      $form_control,
      $find('.digital-product-creator-form__upload-results-dashboard__fail'),
      $removeClass('none'),
    );

  if (current_fail_count === 1 && action === 'delete') {
    go(
      $form_control,
      $find('.digital-product-creator-form__upload-results-dashboard__fail'),
      $addClass('none'),
    );
  }

  const count_res = action === 'add' ? current_fail_count + 1 : current_fail_count - 1;
  go(
    $form_control,
    $find('.digital-product-creator-form__upload-results-dashboard__fail-count'),
    $setText(String(count_res)),
  );
};

export const afterFailDeleteEl = (e, file_len) => {
  const result_len =
    go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $findAll('.digital-product-creator-form__upload-result'),
    ).length - 1;

  $setData(
    { files_total_count: result_len },
    go(e.delegateTarget, $find('.digital-product-creator-form__upload-box')),
  );

  if (!file_len) {
    return go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $findAll(
        '.digital-product-creator-form__upload-results, .digital-product-creator-form__upload-results-dashboard',
      ),
      mapC($remove),
    );
  }

  const $results_dashboard = go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $find('.digital-product-creator-form__upload-results-dashboard'),
  );

  go(
    $results_dashboard,
    $find('.digital-product-creator-form__upload-results-dashboard__files-total-count'),
    $setText(String(result_len + '개')),
  );

  go(e.currentTarget, $closest('.digital-product-creator-form__upload-result'), $remove);
};
