import axios from 'axios';
import { $attr, $delegate } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { iamportPhoneCertification2 } from '../../../../../Iamport/Certification/F/fs.js';
import { MShopShareFramePopUpF } from '../../../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopStudioDigitalProductCreatorF } from '../../../../../MShop/Studio/DigitalProductCreator/F/Function/module/MShopStudioDigitalProductCreatorF.js';
import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { isCreatorApp } from '../../../../../MShop/Util/F/Function/util.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { NewMakerGuideDigitalGoodsDownMuiS } from '../../S/Mui/module/NewMakerGuideDigitalGoodsDownMuiS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...NewMakerGuideDigitalGoodsDownMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    return go(
      tab_el$,
      $delegate('click', '.btn_down_agree', async (e) => {
        try {
          await MuiF.closeFrame();
          $.don_loader_start();
          const { data: signed_urls } = await axios.get(
            UtilS.makeApiUrl('/@api/digital_product/signed_url/:id', {
              lang: T.lang,
              id: $attr('id', e.currentTarget),
            }),
          );

          const is_need_update_for_dtg = $attr('is_need_update_for_dtg', e.currentTarget) === 'true';

          // App이고, 다운로드 불가 버전이면 다운로드를 막는다.
          if (isCreatorApp() && is_need_update_for_dtg) {
            // return await MShopShareFramePopUpF.alert({
            //   title: T('일괄 다운로드 기능 준비 중'),
            //   body: T(
            //     'PC, 모바일 웹에서 일괄 다운로드가 가능합니다.<br>' +
            //       '다음 업데이트에서 앱의 일괄 다운로드 기능이<br>' +
            //       '제공될 예정으로 잠시만 기다려 주세요.',
            //   ),
            // });
            /*********앱 업데이트 이후 여기 부터********/
            const is_confirm = await MShopShareFramePopUpF.confirm({
              title: T('앱 업데이트 필요'),
              body: T(
                '현재 버전에서 일괄 다운로드를 제공하지 않습니다.<br>마플샵 앱을 최신 버전으로 업데이트하고<br>디지털 상품을 이용해 보세요.',
              ),
              ok: '업데이트 하기',
              cancel: '나중에 하기',
            });
            if (is_confirm) {
              const parser = new UAParser();
              const IS_IOS = /IOS/i.test(parser.getOS()?.name);
              const APP_STORE_URL = 'https://apps.apple.com/in/app/id1571108536';
              const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.marppleshop';

              const a = document.createElement('a');
              a.href = IS_IOS ? APP_STORE_URL : PLAY_STORE_URL;
              a.style.display = 'none';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              return;
            }
            return;
            /*********여기 까지********/
          }

          if (!box.sel('is_user->is_mobile_cert') && MShopUtilF.isApp()) {
            return $.alert('디지털 굿즈 다운로드는 크롬이나 사파리에서 본인 인증 후에 이용해주세요.');
          }

          if (!box.sel('is_user->is_mobile_cert')) {
            const res = await iamportPhoneCertification2({ popup: false }, (imp_uid) =>
              axios
                .post(`/${T.lang}/@api/cert/phone`, {
                  imp_uid,
                })
                .then(({ data }) => ({ imp_uid, ...data })),
            );

            if (!res.result) return $.alert(res.msg);

            box.set('is_user->is_mobile_cert', res.result);
          }

          const is_new_dtg = $attr('is_new_dtg', e.currentTarget) === 'true';
          if (is_new_dtg) {
            if (signed_urls.length > 1) {
              await MShopStudioDigitalProductCreatorF.getDownloadedZipFile(signed_urls);
            } else {
              await go(
                signed_urls,
                each(async ({ signed_url, file_name }) => {
                  await MShopStudioDigitalProductCreatorF.getDownloadedFile({
                    signed_url,
                    file_name: decodeURIComponent(file_name),
                  });
                }),
              );
            }
          } else {
            const {
              data: { domain_name },
            } = await axios.get(`/${T.lang}/@api/get_store_url/${$attr('store_id', e.currentTarget)}`);

            location.href = `/${T.lang}/${domain_name}/order/down_image/${$attr('id', e.currentTarget)}`;
            // location.href = `${box.sel('store_url')}/order/down_image/${$attr('id', e.currentTarget)}`;
          }
        } catch (_err) {
          _err.name = 'DigitalGoodsDownF tab';
          ConfigSentryF.error(_err);
          $.alert(
            '네트워크 오류로 인해 다운로드에<br>실패하였습니다.<br>자세한 문의는 고객 서비스 센터로<br>연락해 주시기 바랍니다.',
          );
        } finally {
          $.don_loader_end();
        }
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
