import { $attr, $delegate } from 'fxdom/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

let is_first = true;

const openEnterMarppleShop2 = () => {
  if (is_first) {
    is_first = false;
    $.frame.defn_frame({
      frame_name: 'enter_marpple_shop2',
      page_name: 'enter_marpple_shop2_page',
      is_modal: !UtilF.isLegacyMobile(),
      hide_frame_button_type: 'X',
      always_remove: true,
      prev_frame_show: UtilF.isLegacyMobile(),
      height: UtilF.isLegacyMobile() && $.height(window),
      showed: (el) => UtilF.isLegacyMobile() && $.on_prevent_default(el),
      hided: (el) => UtilF.isLegacyMobile() && $.off_prevent_default(el),
    });

    $.frame.defn_page({
      page_name: 'enter_marpple_shop2_page',
      tabs: [
        {
          tab_name: 'enter_marpple_shop2_tab',
          template: () => legacyHtml`
            <div id="main">
              <div class="header">
                <img
                  src="//s3.marpple.co/files/u_193535/2020/3/original/17676281d6bb7da7c5e787408313427d887238a4d0a5b25.jpg"
                  alt=""
                />
              </div>
              <div class="body">
                <div class="bold paragraph">너무 멋진 상품인걸요?<br />마플샵에서 판매 해보세요!</div>
                ${
                  UtilF.isLegacyMobile()
                    ? legacyHtml`
                      <div class="paragraph">
                        상품을 직접 만들지 않아도<br />내가 디자인한 굿즈를 판매할 수 있습니다.<br />
                        상품 제작, 배송, CS, 재고 관리까지<br />모두 다 마플샵이 해드립니다.
                      </div>
                    `
                    : legacyHtml`
                      <div class="paragraph">
                        상품을 직접 만들지 않아도 내가 디자인한 굿즈를 판매할 수 있습니다.<br />
                        상품 제작, 배송, CS, 재고 관리까지 모두 다 마플샵이 해드립니다.
                      </div>
                    `
                }
                <div class="bold paragraph">
                  <a href="https://marpple.shop">마플샵 셀러가 되어보세요!</a>
                </div>
              </div>
            </div>
          `,
          appended: $.on('click', 'a', function (e) {
            e.preventDefault();
            window.open($attr('href', e.currentTarget));
          }),
        },
      ],
    });
  }
  $.frame.open({ frame_name: 'enter_marpple_shop2' });
};
export const makeEnterMarppleShop2Event = $delegate('click', '.enter_marpple_shop2', function () {
  openEnterMarppleShop2();
});
