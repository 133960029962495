import { ShippingCostConstantS } from '../Constant/module/ShippingCostConstantS.js';
import { go, map, sel, unique } from 'fxjs/es';

/**
 * @define 서버에서 계산된 배송비를 조건에 따라 해석
 * @description 숫자가 1억원을 초과하는 경우 - 배송비 무게 초과로 인해 배송을 할 수 없는 상태
 *              숫자가 -1 인 경우 - 에러로 인한 배송비 계산 불가
 * @param {number} cost
 * @return {{
 *   is_ok: boolean,
 *   reason: {title: string, message: string},
 *   delivery_fee: number
 * }}
 * */
export function parseShippingCost(cost) {
  if (cost > ShippingCostConstantS.MAX_SHIP_FEE) {
    return {
      is_ok: false,
      reason: {
        title: T('checkout::delivery_fee_error1_title'),
        message: T('checkout::delivery_fee_error1'),
      },
      delivery_fee: 0,
    };
  }

  if (cost == -1) {
    return {
      is_ok: false,
      reason: {
        title: T('checkout::delivery_fee_error2_title'),
        message: T('checkout::delivery_fee_error2'),
      },
      delivery_fee: 0,
    };
  }

  return {
    is_ok: true,
    reason: { title: '', message: '' },
    delivery_fee: cost,
  };
}

export function isLegacyExpress({ express, current_shipping_company_id }) {
  if (current_shipping_company_id == null || current_shipping_company_id === '') return true;

  const available_shipping_company_ids = go(express, map(sel('shipping_company_id')), unique);
  return !available_shipping_company_ids.includes(current_shipping_company_id);
}

export function getAvailableExpressAndServiceForLegacyExpress({
  express,
  express_services,
  shipping_country_id,
}) {
  const { shipping_company_id: default_shipping_company_id } = express.find(
    (exp) => exp.country_id === shipping_country_id,
  );
  const { id: default_express_service_id } = express_services.find(
    (es) => es.shipping_company_id === default_shipping_company_id,
  );

  return {
    default_shipping_company_id,
    default_express_service_id,
  };
}
