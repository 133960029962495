import $dataStr from 'fxdom/es/dataStr.js';
import { find, go, html, identity, map, strMap } from 'fxjs/es';
import { DeliveryTypeS } from '../../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppUpItemTmplS } from '../../../UpItem/S/Tmpl/module/MShopAppUpItemTmplS.js';
import { MShopAppUpItemConstantS } from '../Constant/module/MShopAppUpItemConstantS.js';

const pOptionInfosHtml = (n_option_infos) => {
  return html`
    <div class="up-item-option-infos">
      ${n_option_infos.length
        ? strMap((n_option_info) => {
            const option_name = n_option_info.name || '수량';
            return html`
              <div
                class="up-item-option-infos__item"
                data-status="${n_option_info.status}"
                data-up_c_s_id="${n_option_info.up_c_s_id}"
              >
                <div class="up-item-option-infos__flex">
                  <span class="up-item-option-infos__option">
                    ${n_option_info.status !== MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL
                      ? html`<span class="up-item-option-infos__status-not-normal"
                          >${T('order::구매불가')}</span
                        >`
                      : html``}${UtilS.escape(option_name)}</span
                  ><span class="up-item-option-infos__divide">/</span
                  ><span class="up-item-option-infos__quantity">
                    <span>${n_option_info.quantity}개</span>
                  </span>
                </div>
                <div class="up-item-option-infos__flex">
                  <span class="up-item-option-infos__price">
                    <span class="mp_currency"
                      >${UtilS.commify(n_option_info.price * n_option_info.quantity)}</span
                    >
                  </span>
                </div>
              </div>
            `;
          }, n_option_infos)
        : html`<div class="up-item-option-infos__empty">${T('Please choose the size of the product.')}</div>`}
    </div>
  `;
};

const upItemHtml = ({
  item_order_up_c_id,
  n_other_info,
  n_thumb,
  n_text_option_infos,
  n_option_infos,
  n_info,
}) => {
  return html`
    <div
      class="up-item"
      data-item_order_up_c_id="${item_order_up_c_id}"
      data-is_need_option_btn="${n_other_info.is_need_option_btn}"
    >
      <div class="up-item__product-wrapper">
        <div class="up-item__thumbnail-wrapper">${MShopAppUpItemTmplS.pThumbnailHtml(n_thumb)}</div>
        <div class="up-item__product-info-wrapper">${MShopAppUpItemTmplS.pProductInfoHtml({ n_info })}</div>
        <div class="up-item__cart-option">
          <button type="button" class="up-item__cart-option__delete-btn">
            <img
              src="//s3.marpple.co/files/u_193535/2022/10/original/3c280f53fab4154989856ac707f50aa78d3d53c21.svg"
              alt=""
            />
          </button>
          <button type="button" class="up-item__cart-option__revise-btn">수량 변경</button>
        </div>
      </div>
      <div class="up-item__option-wrapper">
        <div class="up-item__option-infos">${pOptionInfosHtml(n_option_infos)}</div>
        ${n_text_option_infos?.length ? MShopAppUpItemTmplS.pTextOptionInfosHtml(n_text_option_infos) : ``}
      </div>
      ${go(
        n_option_infos,
        map((n_option_info) => MShopAppUpItemConstantS.WARNING_MESSAGE[n_option_info.status]),
        find(identity),
        (warning_message) => {
          return warning_message
            ? html`
                <div class="up-item__warning-message">
                  <img
                    src="//s3.marpple.co/files/u_193535/2022/10/original/fc5f1bd4455c8fee387645d7c7cddad7df7350401.svg"
                    alt="경고"
                  />
                  <span> ${warning_message} </span>
                </div>
              `
            : html``;
        },
      )}
    </div>
  `;
};

export const pUpItemListHtml = (store_orders) =>
  html`
    <div class="up-item-list">
      ${strMap(
        (store_order) => html`
          <div class="store-order">
            <div class="store-order__header">
              <div class="store-order__name">
                <a
                  data-post-message="${$dataStr({
                    navigate: {
                      sel: 'TabNavigation.HomeStack.UtilScreen',
                      params: {
                        type: 'sub',
                        title: UtilS.escape(store_order._.store.name),
                        titleImage: UtilS.escape(store_order._.store.logo_img) || '',
                        uri: UtilS.escape(store_order.n_redirect_url),
                      },
                    },
                  })}"
                  href="${UtilS.escape(store_order.n_redirect_url)}"
                  class="store-logo"
                  >${UtilS.escape(store_order._.store.name)}</a
                >
              </div>
            </div>
            <div class="store-order__shipping-orders-wrapper">
              ${strMap(
                (shipping_order) => html`<div
                  class="shipping-order"
                  data-is_mp="${DeliveryTypeS.isMarpple(
                    shipping_order._.stores_delivery_company.delivery_type_id,
                  )}"
                  data-delivery_type_id="${
                    /* TODO 정익님 여기 stores_delivery_company 있는 값으로 보는곳이 꽤 많던데
                     *   이러면 값이 바뀌면 다 바뀌니까 shipping_order에 있는 값으로 봐야하는거 아닌지... */
                    shipping_order._.stores_delivery_company.delivery_type_id
                  }"
                >
                  <div class="shipping-order__header">
                    ${DeliveryTypeS.isMarpple(shipping_order._.stores_delivery_company.delivery_type_id)
                      ? html`<div class="shipping-order__name marpple-delivery-type-logo">
                          <div>${T('order::마플샵배송')}</div>
                        </div>`
                      : DeliveryTypeS.isSeller(shipping_order._.stores_delivery_company.delivery_type_id)
                      ? html`<div class="shipping-order__name seller-delivery-type-logo">
                          <div>${T('order::셀러배송')}</div>
                          ${MShopAppUpItemTmplS.pSellerProductInfoToolTip()}
                        </div>`
                      : html`
                          <div class="shipping-order__name digital-delivery-type-logo">
                            <div>${T('order::디지털상품')}</div>
                          </div>
                        `}
                    ${DeliveryTypeS.isSeller(shipping_order._.stores_delivery_company.delivery_type_id) &&
                    shipping_order._.stores_delivery_company.free_shipping_min_price != null
                      ? html`
                          <span class="shipping-order__free-shipping-explain">
                            ${shipping_order._.stores_delivery_company.free_shipping_min_price === 0
                              ? '무료 배송'
                              : `${PriceS.pricify(
                                  shipping_order._.stores_delivery_company.free_shipping_min_price,
                                )}원 이상
                                  무료 배송`}
                          </span>
                        `
                      : ''}
                    <div class="shipping-order__sid">주문번호: ${UtilS.escape(shipping_order.sid)}</div>
                  </div>
                  <div class="shipping-order__up-items-wrapper">
                    ${strMap(
                      (item_order_up_c) =>
                        upItemHtml({
                          item_order_up_c_id: item_order_up_c.up_c_id,
                          n_other_info: item_order_up_c.n_other_info,
                          n_thumb: item_order_up_c.n_thumb,
                          n_info: item_order_up_c.n_info,
                          n_text_option_infos: item_order_up_c.n_text_option_infos,
                          n_option_infos: item_order_up_c.n_option_infos,
                        }),
                      shipping_order._.item_order_up_cs,
                    )}
                  </div>
                  <div class="shipping-order__price-info-wrapper">
                    ${MShopAppUpItemTmplS.pItemTotalPrice(shipping_order.n_price_info)}
                  </div>
                </div> `,
                store_order._.shipping_orders,
              )}
            </div>
          </div>
        `,
        store_orders,
      )}
    </div>
  `;
