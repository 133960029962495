import { CreatorSettingPrivacyTmplS } from '../../SettingPrivacy/S/Tmpl/module/CreatorSettingPrivacyTmplS.js';
import { CreatorSettingTermsTmplS } from '../../SettingTerms/S/Tmpl/module/CreatorSettingTermsTmplS.js';
import {
  makeStudioPrivacy20210727Html,
  makeStudioPrivacyHtml,
  makeStudioTerms20200707Html,
  makeStudioTerms20201106Html,
  makeStudioTermsHtml,
} from './tmpl2.js';

export const selectStudioTermsHtml = ({ date, store_name }) =>
  (TERMS_PRIVACY.terms[date] || TERMS_PRIVACY.terms.latest)({ store_name });
export const selectStudioPrivacyHtml = ({ date, store_name }) =>
  (TERMS_PRIVACY.privacy[date] || TERMS_PRIVACY.privacy.latest)({ store_name });

export const TERMS_PRIVACY = {
  terms: {
    latest: CreatorSettingTermsTmplS.kr_studio_20230911,
    20230911: CreatorSettingTermsTmplS.kr_studio_20230911,
    20230816: CreatorSettingTermsTmplS.kr_studio_20230816,
    20230125: CreatorSettingTermsTmplS.kr_studio_20230125,
    20201106: makeStudioTermsHtml,
    20200707: makeStudioTerms20201106Html,
    20200113: makeStudioTerms20200707Html,
  },
  privacy: {
    latest: CreatorSettingPrivacyTmplS.kr_studio_privacy_20230125,
    20230125: CreatorSettingPrivacyTmplS.kr_studio_privacy_20230125,
    20230118: CreatorSettingPrivacyTmplS.kr_studio_privacy_20230118,
    20210728: makeStudioPrivacy20210727Html,
    20200113: makeStudioPrivacyHtml,
  },
};
