import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { drawThumbnail } from './drawThumbnail.js';

export const pRenderCanvas = (el) => {
  go(
    el,
    $findAll('.up-item-thumbnail'),
    each((product_face_el) => {
      drawThumbnail(product_face_el);
    }),
  );
};
