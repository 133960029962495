import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function () {
  $.frame.defn_frame({
    frame_name: 'mp.shipping_country',
    page_name: 'mp.shipping_country',
    el_class: 'shipping_country_frame',
    is_modal: !UtilF.isLegacyMobile(),
    always_remove: true,
    hide_frame_button_type: 'X',
    appended: function () {},
  });

  $.frame.defn_page({
    page_name: 'mp.shipping_country',
    title: 'Shipping Country',
    tabs: [
      {
        tab_name: 'mp.shipping_country',
        template: _p.t$(
          '\
        .cont\
          p We ship internationally to all the countries!\
          p\
            |Please note that both product prices & delivery times are subject to change.\
            |Display prices for the products in different currencies. Rest assured\
            |that you will not be charged unless you proceed\
            |all the way through to the completion of your order confirmation page.\
          .country\
            img[src="//s3.marpple.co/files/u_218933/2020/5/original/186691155552233309b2e9ce0ad02174168bb49b79d9396.png"].is_pc\
            img[src="//s3.marpple.co/files/u_218933/2020/5/original/1866910042ab3ec117907e7e4cd0d7dd5a812eee8f6ca24.png"].is_mobile\
      ',
        ),
      },
    ],
  });
})();
