import { html } from 'fxjs/es';

export const pdTypeTooltipHTML = (type) => {
  if (type === 'sku') {
    return html`<div>
      Sku는 일반적으로 고유한 상품을 식별하고 추적하기 위해<br />
      사용되는 고유한 코드나 번호입니다. 바코드, QR 코드 등이<br />
      될 수 있습니다. 재고 관리, 주문 처리, 판매 추적 등 다양한<br />
      상품 관리에 도움이 됩니다.
    </div>`;
  }
  if (type === 'hs_code') {
    return html`<div>
      HS Code는 국제적으로 통용되는 표준 코드로<br />
      대한민국 관세청 웹사이트에서 상품별 HS Code를 검색할 수 있어요.
    </div>`;
  }
  if (type == 'pd_type') {
    return html`
      <div>
        <h3>상시판매</h3>
        <p>사전에 상품을 만들어놓고 상시 판매 가능한 상품</p>

        <h3>커스텀 주문</h3>
        <p>원하는 대로 커스터마이징하여 주문할 수 있는 상품</p>

        <h3>핸드메이드</h3>
        <p>크리에이터가 직접 손으로 만드는 상품</p>

        <h3>한정판</h3>
        <p>기간 혹은 수량이 한정된 상품</p>

        <h3>주문제작</h3>
        <p>주문 후 제작을 시작하는 상품</p>
      </div>
    `;
  }

  if (type == 'stock') {
    return html`
      <div>
        <h3>무제한 관리</h3>
        <p>수량을 설정하지 않고 무제한으로 판매할 수 있습니다.</p>
        <h3>총 수량 관리</h3>
        <p>판매할 상품의 전체 수량을 설정할 수 있습니다.</p>
        <h3>옵션 별 수량 관리</h3>
        <p>판매할 수량을 옵션별로 각각 설정할 수 있습니다.</p>
      </div>
    `;
  }

  if (type == 'total_stock') {
    return html`
      <div>
        <h3>판매 재고수량</h3>
        <p>상품 판매 시, 재고수량은 감소하고 남은 수량이 0이 되면 품절 처리가 됩니다.</p>
      </div>
    `;
  }

  if (type == 'real_total_stock') {
    return html`
      <div>
        <h3>총 재고수량</h3>
        <p>
          가지고 있는 총 재고수량입니다. 판매 재고수량보다 수량이 낮을 경우 판매 재고수량과 같게 설정됩니다.
        </p>
      </div>
    `;
  }

  if (type == 'limited') {
    return html`
      <div>
        <h3>한정 판매 기간</h3>
        <p>설정한 특정 기간 동안에만 판매할 수 있습니다.</p>
        <h3>한정 판매 수량</h3>
        <p>한정된 수량으로 설정하여 판매할 수 있습니다.</p>
      </div>
    `;
  }

  if (type == 'option') {
    return html`
      <div>
        <h3>옵션 설정</h3>
        <p>
          상품의 옵션명과 그에 따른 옵션 상세 정보를 입력 후 ’옵션 목록으로 적용하기’ 버튼을 선택하면 설정한
          옵션이 조합되어 아래 목록에 추가됩니다.
        </p>
      </div>
    `;
  }

  if (type == 'token_gating') {
    return html`
      <div>
        <h3>토큰 게이팅 설정</h3>
        <p>
          NFT 컨트랙트 주소를 입력하면 해당 NFT의 소유자(홀더)만 상품을 구매 할 수 있게 설정 할 수
          있습니다.<br />
          현재 이더리움과 폴리곤 체인을 지원하고 있습니다.
        </p>
      </div>
    `;
  }

  if (type == 'product_basic_info') {
    return html`
      <div>
        <p>'스토리'에 상품 정보 제공 고시 내용이 기재되어 있다면 '스토리 참고'로 기재 가능합니다.</p>
        <p class="editor-tooltip__p--mt">
          품목에 따른 상품 기재 정보를 모르시는 경우, 관련 정부 고시 내용을 참고해주세요.<br />
          <a
            href="https://www.law.go.kr/%ED%96%89%EC%A0%95%EA%B7%9C%EC%B9%99/%EC%A0%84%EC%9E%90%EC%83%81%EA%B1%B0%EB%9E%98%EB%93%B1%EC%97%90%EC%84%9C%EC%9D%98%EC%83%81%ED%92%88%EB%93%B1%EC%9D%98%EC%A0%95%EB%B3%B4%EC%A0%9C%EA%B3%B5%EC%97%90%EA%B4%80%ED%95%9C%EA%B3%A0%EC%8B%9C"
            target="_blank"
            >전자상거래 등에서의 상품 등의 정보 제공에 관한 고시</a
          >
        </p>
        <p class="editor-tooltip__p--mt">
          상세 내용은 아래 가이드에서 확인 부탁 드립니다.<br />
          <a href="https://marpple.notion.site/marpple/a350ff5b17be4cbbbce85cdfdaaaf5ac" target="_blank"
            >'상품 정보 제공 고시' 입력 가이드</a
          >
        </p>
      </div>
    `;
  }

  if (type == 'certification_info') {
    return html`
      <div>
        <p>
          상품의 KC 인증 유무와 종류에 따라서 입력해 주세요.<br />
          상품 등록 시 하기 링크 및 품목 별 정부 부처 페이지를 먼저 참고해주시기 바랍니다.
        </p>
        <p class="editor-tooltip__p--mt">
          <a href="https://marpple.notion.site/KC-781ae94b5bc344f39a9fec6cbb1c2f9f" target="_blank"
            >품목 별 'KC 및 기타 인증 안내 가이드'</a
          >
        </p>
      </div>
    `;
  }

  if (type == 'qna') {
    return html`
      <div>
        <h3>마플샵 일대일 문의</h3>
        <p>구매자와 판매자가 서로 문의와 답변을 주고 받을 수 있는 기능</p>
        <h3>개인 SNS를 통한 문의</h3>
        <p>
          구매자와 판매자가 서로 문의와 답변을 주고 받을 판매자의 개인 SNS링크 입력<br />
          (예 : 판매자의 인스타그램 개인 링크)
        </p>
      </div>
    `;
  }

  if (type == 'refund_info') {
    return html`
      <div>
        <p>
          상품 형태에 맞는 교환/안내 문구를 작성할 수 있습니다.<br />
          전자상거래법 제13조에 따라 해당 내용들은 필수로 기재 되어야 할 항목입니다.<br />
          <a
            href="https://www.law.go.kr/%EB%B2%95%EB%A0%B9/%EC%A0%84%EC%9E%90%EC%83%81%EA%B1%B0%EB%9E%98%20%EB%93%B1%EC%97%90%EC%84%9C%EC%9D%98%20%EC%86%8C%EB%B9%84%EC%9E%90%EB%B3%B4%ED%98%B8%EC%97%90%20%EA%B4%80%ED%95%9C%20%EB%B2%95%EB%A5%A0/%EC%A0%9C13%EC%A1%B0"
            target="_blank"
            >전자상거래법 제13조 보러가기</a
          >
        </p>
      </div>
    `;
  }

  if (type == 'stores_delivery_companies') {
    return html`<div>
      <p>
        상품별로 배송비, 출고지 등이 다를 경우 배송 정책을 다르게 설정할 수 있습니다. 배송 정책은 배송 정책
        관리에서 추가 및 수정이 가능합니다.
      </p>
    </div>`;
  }

  return '';
};
