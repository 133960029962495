import { html, initial, last, strMap } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { MShopAppProductItemTmplS } from '../../../MShop/App/Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { ACTION, ACTION_TARGET } from '../../Authority/S/constant.js';
import { checkActions } from '../../Authority/S/util.js';
import { messages } from '../../Setting/S/messages_id.js';

export const makeOrdersSettingsBodyHtml = ({ rows, user, without_bonus_profit }) => {
  const tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T(messages.table.th7)}</h4>
      <p>${T(messages.tooltip.t1)}</p>
    </div>
  `;
  const is_empty_rows = !rows?.length;
  const is_hide_crew_profit = checkActions(
    {
      actions: [ACTION.HIDE_CREW_PROFIT],
      target: ACTION_TARGET.STORE,
    },
    { user },
  );

  const width = 81 / (9 - (is_hide_crew_profit ? 2 : 0) - (without_bonus_profit ? 2 : 0));
  const preparing_orders = rows.length > 1;
  return html`
    <div class="header">
      <div class="header__title">
        <h2>${T(messages.menus.orders)}</h2>
        <p>${T(messages.orders.p1)}</p>
      </div>
      <button
        type="button"
        data-clickable="${!!preparing_orders}"
        class="download-excel${preparing_orders ? '' : ' download-excel--disabled'}"
        ${preparing_orders ? '' : 'disabled'}
      >
        <img
          src="${preparing_orders
            ? '//s3.marpple.co/files/u_29089/2023/2/original/8e3f4cc22c165c2cdfa3a9fc5af59b753975d1ac1.webp'
            : '//s3.marpple.co/files/u_29089/2023/2/original/9794c9df1bd62bfa1d2467dd05c1e924beb989851.webp'}"
          alt=""
          style="pointer-events:${preparing_orders ? 'auto' : 'none'}"
        />
        ${T('item_order_excel::주문 내역 내려받기')}
      </button>
    </div>
    <div class="body">
      <div id="orders" class="table-wrapper" _sel="rows">
        <div class="seller-studio-table">
          <div class="seller-studio-table__table">
            <div class="seller-studio-table__head">
              <div class="seller-studio-table__tr">
                <div class="seller-studio-table__th" style="text-align: left; width: 19%;">
                  ${T(messages.table.th1)}
                </div>
                <div class="seller-studio-table__th" style="width: ${width}%;">${T(messages.table.th2)}</div>
                <div class="seller-studio-table__th" style="width: ${width}%;">${T(messages.table.th3)}</div>
                <div class="seller-studio-table__th" style="width: ${width}%;">${T(messages.table.th4)}</div>
                <div class="seller-studio-table__th" style="width: ${width}%;">${T(messages.table.th5)}</div>
                <div class="seller-studio-table__th" style="width: ${width}%;">${T(messages.table.th6)}</div>
                ${without_bonus_profit
                  ? ''
                  : html` <div class="seller-studio-table__th" style="width: ${width}%;">
                        <div class="seller-studio-table__tool-tip-wrap">
                          ${T(messages.table.th7)}${tooltip_content}
                        </div>
                      </div>
                      <div class="seller-studio-table__th" style="width: ${width}%;">
                        ${T(messages.table.th8)}
                      </div>`}
                ${is_hide_crew_profit
                  ? ''
                  : html`
                      <div class="seller-studio-table__th" style="width: ${width}%;color:#fbc02d;">
                        ${T(messages.table.th9)}
                      </div>
                      <div class="seller-studio-table__th" style="width: ${width}%;color:#02bc77;">
                        ${T(messages.table.th10)}
                      </div>
                    `}
              </div>
            </div>
            <div class="seller-studio-table__body">
              ${is_empty_rows
                ? html` <div class="seller-studio-table__td" style="width: 100%;">
                    <p>
                      ${T(messages.orders.empty)}
                      <img
                        src="//s3.marpple.co/files/u_1504988/2021/4/original/9f11cadaa31c1d0916e9c07db39159b26717dceb1.png"
                      />
                    </p>
                  </div>`
                : strMap(
                    (row) =>
                      html`<div class="seller-studio-table__tr" _sel="./(r=>r.ssp_id=='${row.ssp_id}')">
                        <div
                          class="seller-studio-table__td seller-studio-table__date"
                          style="text-align: left; width: 19%; cursor: pointer;"
                        >
                          ${row.date}
                        </div>
                        <div class="seller-studio-table__td" style="width: ${width}%;">
                          ${UtilS.commify(row.count)}
                        </div>
                        <div class="seller-studio-table__td" style="width: ${width}%;">
                          ${UtilS.commify(row.cancel_complete_count)}
                        </div>
                        <div class="seller-studio-table__td" style="width: ${width}%;">
                          ${UtilS.commify(row.total)}
                        </div>
                        <div class="seller-studio-table__td" style="width: ${width}%;">
                          ${UtilS.commify(row.cancel_total)}
                        </div>
                        <div class="seller-studio-table__td" style="width: ${width}%;">
                          ${UtilS.commify(
                            without_bonus_profit ? row.seller_profit_without_bonus : row.profit,
                          )}
                        </div>
                        ${without_bonus_profit
                          ? ''
                          : html` <div
                                class="seller-studio-table__td bonus-profit color-info bonus-profit-service"
                                data-bonus_profit="${!!row.bonus_profit}"
                                style="width: ${width}%;"
                              >
                                ${UtilS.commify(row.bonus_profit)}
                              </div>
                              <div class="seller-studio-table__td" style="width: ${width}%;">
                                ${UtilS.commify(row.total_profit)}
                              </div>`}
                        ${is_hide_crew_profit
                          ? ''
                          : html`
                              <div class="seller-studio-table__td" style="width: ${width}%;">
                                ${UtilS.commify(row.seller_profit)}
                              </div>
                              <div class="seller-studio-table__td" style="width: ${width}%;">
                                ${UtilS.commify(row.company_profit)}
                              </div>
                            `}
                      </div>`,
                    initial(rows),
                  )}
            </div>
            <div class="seller-studio-table__totals">
              ${((row) => {
                return html`<div class="seller-studio-table__tr" _sel="./(r=>r.ssp_id=='${row.ssp_id}')">
                  <div class="seller-studio-table__tt" style="text-align: left; width: 19%;">${row.date}</div>
                  <div class="seller-studio-table__tt" style="width: ${width}%;">
                    ${UtilS.commify(row.count)}
                  </div>
                  <div class="seller-studio-table__tt" style="width: ${width}%;">
                    ${UtilS.commify(row.cancel_complete_count)}
                  </div>
                  <div class="seller-studio-table__tt" style="width: ${width}%;">
                    ${UtilS.commify(row.total)}
                  </div>
                  <div class="seller-studio-table__tt" style="width: ${width}%;">
                    ${UtilS.commify(row.cancel_total)}
                  </div>
                  <div class="seller-studio-table__tt" style="width: ${width}%;">
                    ${UtilS.commify(without_bonus_profit ? row.seller_profit_without_bonus : row.profit)}
                  </div>
                  ${without_bonus_profit
                    ? ''
                    : html` <div
                          class="seller-studio-table__tt bonus-profit color-info bonus-profit-service"
                          data-bonus_profit="${!!row.bonus_profit}"
                          style="width: ${width}%;"
                        >
                          ${UtilS.commify(row.bonus_profit)}
                        </div>
                        <div class="seller-studio-table__tt" style="width: ${width}%;">
                          ${UtilS.commify(row.total_profit)}
                        </div>`}
                  ${is_hide_crew_profit
                    ? ''
                    : html`
                        <div class="seller-studio-table__tt" style="width: ${width}%;">
                          ${UtilS.commify(row.seller_profit)}
                        </div>
                        <div class="seller-studio-table__tt" style="width: ${width}%;">
                          ${UtilS.commify(row.company_profit)}
                        </div>
                      `}
                </div>`;
              })(last(rows))}
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const makeOrderDetailTrHtml =
  (user, without_bonus_profit, no = 0) =>
  (p) =>
    html`
      <tr>
        <td class="center">${p.is_total ? p.title : ++no}</td>
        <td class="${p.is_total ? 'center' : 'left'}">
          ${!p.is_total
            ? html` <div class="product_info">
                <div class="img_wrap">${MpShopProductTmplS.thumbnail(p, { width: 34 })}</div>
                <span class="name"
                  >${UtilS.escape(
                    p['name' + G._en] || MShopAppProductItemTmplS.makeBpName(p.bp_name, p.bp_option_title),
                  )}</span
                >
              </div>`
            : '-'}
        </td>
        <td>${UtilS.commify(p.total_count)}</td>
        ${without_bonus_profit
          ? html`<td>${UtilS.commify(p.seller_profit_without_bonus)}</td>`
          : html`
              <td>${UtilS.commify(p.profit)}</td>
              <td class="bonus-profit color-info" data-bonus_profit="${!!p.bonus_profit}">
                ${UtilS.commify(p.bonus_profit)}
              </td>
              <td>${UtilS.commify(p.total_profit)}</td>
            `}
        <td class="is-public center">
          <span data-is_public="${p.is_public}">
            ${!p.is_total ? (p.is_public ? T(messages.common.public) : T(messages.common.private)) : '-'}
          </span>
        </td>
        ${checkActions(
          {
            actions: [ACTION.HIDE_CREW_PROFIT],
            target: ACTION_TARGET.STORE,
          },
          { user },
        )
          ? ''
          : html`
              <td>${UtilS.commify(p.seller_profit)}</td>
              <td>${UtilS.commify(p.company_profit)}</td>
            `}
      </tr>
    `;

export const makeOrderDetailModalHtml = ({ date, products }, user, without_bonus_profit) => {
  const preparing_products = !!products?.length;
  return html`
    <div class="header">
      <div class="title">
        <div class="icon">
          <img
            src="//s3.marpple.co/files/u_1504988/2021/3/original/db52ce3ed8f90fee699f6e10343755fad89e2c3c1.png"
          />
        </div>
        <h2>${T(messages.orders.t1)}</h2>
        <p>(${date})</p>
      </div>
      <button
        type="button"
        class="download-excel${preparing_products ? '' : ' download-excel--disabled'}"
        ${preparing_products ? '' : 'disabled'}
      >
        <img
          src="${preparing_products
            ? '//s3.marpple.co/files/u_29089/2023/2/original/8e3f4cc22c165c2cdfa3a9fc5af59b753975d1ac1.webp'
            : '//s3.marpple.co/files/u_29089/2023/2/original/9794c9df1bd62bfa1d2467dd05c1e924beb989851.webp'}"
          alt=""
          style="pointer-events:${preparing_products ? 'auto' : 'none'}"
        />
        ${T('products_excel::상품 판매 분석 내려받기')}
      </button>
    </div>
    <div class="body">
      <div id="analytics" class="table-wrapper">
        <table class="table has-total">
          <thead>
            <tr>
              <th class="center">${T(messages.table.th11)}</th>
              <th class="center">${T(messages.table.th12)}</th>
              <th>${T(messages.table.th4)}</th>
              <th>${T(messages.table.th6)}</th>
              ${without_bonus_profit
                ? ''
                : html`
                    <th>
                      ${T(messages.table.th7)}
                      <span class="lnr lnr-question-circle tooltip-icon"></span>
                      <div class="tooltip-container">
                        <h4>${T(messages.table.th7)}</h4>
                        <p>${T(messages.tooltip.t1)}</p>
                      </div>
                    </th>
                    <th>${T(messages.table.th8)}</th>
                  `}
              <th class="center">${T(messages.common.reveal_type)}</th>
              ${checkActions(
                {
                  actions: [ACTION.HIDE_CREW_PROFIT],
                  target: ACTION_TARGET.STORE,
                },
                { user },
              )
                ? ''
                : html`
                    <th style="color:#fbc02d;">${T(messages.table.th9)}</th>
                    <th style="color:#02bc77;">${T(messages.table.th10)}</th>
                  `}
            </tr>
          </thead>
          <tbody>
            ${strMap(makeOrderDetailTrHtml(user, without_bonus_profit), products)}
          </tbody>
        </table>
      </div>
    </div>
  `;
};
