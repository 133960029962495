import axios from 'axios';
import { $attr, $closest, $find, $findAll, $is, $next, $prev } from 'fxdom/es';
import {
  curry,
  filterL,
  find,
  go,
  head,
  identity,
  includes,
  last,
  mapL,
  rangeL,
  reject,
  reverseL,
  sel,
  takeUntil,
} from 'fxjs/es';
import { UtilFxS } from '../../../Util/Fx/S/Function/module/UtilFxS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { uploadRichContents } from '../../Editor/F/medium-editor/medium-editor.js';
import { FEED_TYPE } from '../S/constant.js';

export const getRadioValue = (name, parent) =>
  go($findAll(`input[name="${name}"]`, parent), find(sel('checked')), sel('value'));

export const imageCheck = async ($input, max_file_size, image_size, gif = false, strict = true) => {
  const image_size_error = {
    title: T('plus_admin::pa480-3'),
    body: T('plus_admin::pa480-4'),
  };

  const file_size_error = {
    title: T('plus_admin::pa480-5'),
    body: T('plus_admin::pa480-6'),
  };

  const extension_size_error = {
    title: T('plus_admin::pa480-7'),
    body: T('plus_admin::pa480-8'),
  };

  const extensions = gif ? ['jpg', 'jpeg', 'png', 'webp', 'gif'] : ['jpg', 'jpeg', 'png', 'webp'];

  return await go(
    $input.files,
    head,
    (file) =>
      new Promise((res, rej) => {
        if (max_file_size && file.size > max_file_size) {
          return rej(file_size_error);
        }

        if (!includes(file.type.split('/')[1], extensions)) {
          return rej(extension_size_error);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          res(reader.result || '');
        };
      }),
    (src) =>
      new Promise((res, rej) => {
        const img = new Image();
        img.src = src;

        img.onload = async function () {
          const { width, height } = image_size;
          if (strict && (img.width !== width || img.height !== height)) return rej(image_size_error);
          if (img.width > width || img.height > height) return rej(image_size_error);

          res({ src, ratio: width / height });
        };
      }),
  );
};

export const isRequiredFieldFill = (feed) => !(!feed.title || !feed.description || !feed.thumbnail);

export const uploadThumbnail = async ($tab) => {
  const $thumb_input = $find('input[name="thumbnail"]', $tab);
  const $thumb_img = $find('img.thumbnail', $tab);
  const thumb_ratio = $attr('data-ratio', $thumb_img);

  if ($thumb_img.src.includes('//s3.marpple')) return { thumbnail: $thumb_img.src, thumb_ratio };
  if (!$thumb_img.src.includes('data:')) throw new Error(T('plus_admin::pa605'));
  const { url = '' } = await $.upload($thumb_input);
  $thumb_img.src = url;
  return { thumbnail: url, thumb_ratio };
};

export const getFeedValue = async ($tab, editor) => {
  const basic_feed = {
    title: $find('[name="title"]', $tab).value,
    description: $find('input[name="description"]', $tab).value,
    ...(await uploadThumbnail($tab)),
  };

  // editor pre processing 을 하게 되면 feed 내용을 변형하므로 그 전에 필수 내용 체크
  if (!isRequiredFieldFill(basic_feed)) throw new Error(T('plus_admin::pa489'));

  await uploadRichContents($tab);
  const content = editor.save();

  const type_val = getRadioValue('type', $tab);
  return {
    id: $attr('data-feed_id', $find('.crew-feed_detail', $tab)),
    content: G._escape(content),
    type: type_val == 'seller_tip' ? FEED_TYPE.seller_tip : FEED_TYPE.mps_content,
    is_public: getRadioValue('is_public', $tab) === 'public',
    show_home: getRadioValue('show_home', $tab) === 'public',
    ...basic_feed,
  };
};

export const createFeed = (feed) => {
  const create_feed_url = UtilS.makeApiUrl('/:lang/@api/crews/:crew_id/feeds', {
    lang: T.lang,
    crew_id: box.sel('crew_id'),
  });
  return axios.post(create_feed_url, feed);
};

export const updateFeed = (feed) => {
  const update_feed_url = UtilS.makeApiUrl('/:lang/@api/crews/:crew_id/feeds/:feed_id', {
    lang: T.lang,
    crew_id: box.sel('crew_id'),
    feed_id: feed.id,
  });

  return axios.put(update_feed_url, feed);
};

export const findParentUntil = curry((sel, node) =>
  node.nodeType == 1 && $is(sel, node) ? node : findParentUntil(sel, node.parentNode),
);

export const setStartContainer = (range) => {
  const node = findParentUntil('.editable > *', range.startContainer);
  return range.startOffset == node.textContent.length ? $next(node) : node;
};

export const setEndContainer = (range) => {
  const node = findParentUntil('.editable > *', range.endContainer);
  return range.endOffset == 0 ? $prev(node) : node;
};

export const getAllSelectedRootEls = () => {
  const selection = window.getSelection();
  if (!selection.rangeCount) return;
  const range = selection.getRangeAt(0);

  const startContainer = setStartContainer(range);
  const endContainer = setEndContainer(range);

  if (startContainer == endContainer) return [startContainer];

  return go(
    rangeL(Infinity),
    UtilFxS.mapAccL((_, acc) => [_, [...acc, $next(last(acc))]], [startContainer]),
    mapL(sel('1')),
    takeUntil((els) => last(els) == endContainer),
    last,
    reject($is('[contenteditable=false]')),
  );
};

export const getRootEl = (el) => el && $closest('.editable > *', el);

export const saveSelectionRange = ($editable) => {
  const range = window.getSelection().getRangeAt(0);
  const preSelectionRange = range.cloneRange();
  preSelectionRange.selectNodeContents($editable);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);
  const start = preSelectionRange.toString().length;

  const savedSel = {
    start,
    end: start + range.toString().length,
  };

  return function restoreSelectionRange() {
    let charIndex = 0;

    const new_range = document.createRange();
    new_range.setStart($editable, 0);
    new_range.collapse(true);
    const nodeStack = [$editable];
    let node;
    let foundStart = false;
    let stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
        const nextCharIndex = charIndex + node.length;
        if (!foundStart && savedSel.start >= charIndex && savedSel.start < nextCharIndex) {
          new_range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (foundStart && savedSel.end > charIndex && savedSel.end <= nextCharIndex) {
          new_range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(new_range);
  };
};

export const lastTextNode = (node) => {
  if (node.nodeType == 3) return node;
  if (node.childNodes.length == 0) return false;
  return go(
    [...node.childNodes],
    reverseL,
    mapL((child_node) => lastTextNode(child_node)),
    filterL(identity),
    head,
  );
};

export const firstTextNode = (node) => {
  if (node.nodeType == 3) return node;
  if (node.childNodes.length == 0) return false;
  return go(
    [...node.childNodes],
    mapL((child_node) => firstTextNode(child_node)),
    filterL(identity),
    head,
  );
};

export const dataURLtoFile = (dataurl, fileName) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const getFeedType = (tab_el) => {
  const type_el = $find('input[name="type"]', tab_el);
  return type_el.value;
};
