import { html } from 'fxjs/es';
import { MShopAppSolutionHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppSolutionHomeTmplS.js';

export const make = (is_add) => html`
  <div class="request-of-certification">
    <h2>${T('report::본인인증')}</h2>
    <div class="article">
      <p>${T('report::신고 접수 서비스는 무분별한 상품 판매 정지 또는 샵의 운영 정지를 방지하고')}</p>
      <p>${T('report::본인 명의로 가입된 휴대전화를 통해 본인 인증을 할 수 있습니다.')}</p>
    </div>
    <div class="buttons">
      <a id="certification-button">
        ${is_add ? T('report::본인 인증 후 신고 접수하기') : T('report::본인 인증 후 신고 조회하기')}
      </a>
    </div>
  </div>
`;

export const fn = () => MShopAppSolutionHomeTmplS.wrapper('list', make());
