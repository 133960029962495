import { html } from 'fxjs/es';
import { tooltipIcon2El } from '../../../../../../Util/S/Tmpl/tootip.js';
export const cchDeliveryTypeLogo = (delivery_type_id) => {
  return html`
    <div class="cch-delivery-type-logo" data-delivery_type_id="${delivery_type_id}">
      <div class="cch-delivery-type-logo__marpple">
        <img
          src="//s3.marpple.co/files/u_1504988/2024/2/original/078520a757439566076ab09fcdfdc1c56274eace2.svg"
          alt=""
        />
        <span>${T('mshop_cart_and_checkout::마플샵배송')}</span>
      </div>
      <div class="cch-delivery-type-logo__seller">
        <img
          src="//s3.marpple.co/files/u_1504988/2024/2/original/d39564e55aec7d410bbfc24a99b009f30c14e39f1.svg"
          alt=""
        />
        <span>${T('mshop_cart_and_checkout::셀러배송')}</span>
        <span class="tooltip-icon2">${tooltipIcon2El}</span>
        <div class="tooltip-container">
          <h4 class="tooltip-title">크리에이터 배송</h4>
          <p style="margin-bottom:3px;">
            <span style="padding-right:6px;">・</span>크리에이터 배송 상품은 크리에이터가 직접 배송하는
            상품입니다.
          </p>
          <p style="margin-bottom:3px;">
            <span style="padding-right:6px;">・</span>상품준비중인 상품은 주문취소가 불가능합니다.
          </p>
          <p style="margin-bottom:3px;">
            <span style="padding-right:6px;">・</span>문의는 크리에이터에게 일대일 문의하기로 할 수 있습니다.
          </p>
        </div>
      </div>
      <div class="cch-delivery-type-logo__digital">
        <img
          src="//s3.marpple.co/files/u_1504988/2024/2/original/e1f4b33da3c21f8d17dcb17aa254c6e2ed9d78271.svg"
          alt=""
        />
        <span>${T('mshop_cart_and_checkout::디지털상품')}</span>
      </div>
    </div>
  `;
};
