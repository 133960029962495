import {
  MPAY_ORDER_LIST_FRAME,
  MPAY_ORDER_LIST_PAGE,
  MPAY_ORDER_LIST_TAB,
} from '../../../../../modules/MPay/Order/List/S/constant.js';
import {
  CREATOR_ORDER_LIST_FRAME,
  CREATOR_ORDER_LIST_PAGE,
  CREATOR_ORDER_LIST_TAB,
} from '../../../../../modules/Creator/Order/List/S/constant.js';
import { MShopAppOrdersMuiF } from '../../../../../modules/MShop/App/Orders/F/Mui/module/MShopAppOrdersMuiF.js';

const [ORDER_LIST_FRAME, ORDER_LIST_PAGE, ORDER_LIST_TAB] =
  collabo_type == 'creator'
    ? [CREATOR_ORDER_LIST_FRAME, CREATOR_ORDER_LIST_PAGE, CREATOR_ORDER_LIST_TAB]
    : [MPAY_ORDER_LIST_FRAME, MPAY_ORDER_LIST_PAGE, MPAY_ORDER_LIST_TAB];

$.frame.defn_frame({
  frame_name: ORDER_LIST_FRAME,
  page_name: ORDER_LIST_PAGE,
  appended: MShopAppOrdersMuiF.frame.appended,
});

$.frame.defn_page({
  page_name: ORDER_LIST_PAGE,
  tabs: [
    {
      tab_name: ORDER_LIST_TAB,
      infinite: MShopAppOrdersMuiF.tab.infinite,
    },
  ],
});
