import axios from 'axios';
import { IamportS } from '../../S/Function/module/IamportS.js';

export const getBasicImpUid = (imp_uid) =>
  axios
    .post(`/${T.lang}/@api/iamport/certification`, {
      imp_uid,
    })
    .then(({ data }) => ({ imp_uid, ...data }));

export const iamportPhoneCertification = (fn = getBasicImpUid) =>
  new Promise((rs, rj) =>
    IMP.certification({}, async (rsp) => {
      try {
        rsp.success ? rs(await fn(rsp.imp_uid)) : rj(Error(rsp.error_msg));
      } catch (e) {
        rj(e);
      }
    }),
  );

export const iamportPhoneCertification2 = (options = {}, fn = getBasicImpUid) => {
  return new Promise((rs, rj) =>
    IMP.certification(options, async (rsp) => {
      try {
        rsp.success ? rs(await fn(rsp.imp_uid)) : rj(Error(rsp.error_msg));
      } catch (e) {
        rj(e);
      }
    }),
  );
};

/**
 * @param {{name: string, birthday: string, mobile: string}} certification_info
 * @return {Promise<unknown>}
 */
export const iamportPhoneCertification3 = async (certification_info) => {
  return new Promise((resolve, reject) => {
    IMP.certification({}, async (res) => {
      try {
        if (!res.success) {
          // 사용자가 인증 취소
          if (res.error_code === 'F0000') {
            const error400 = new IamportS.CertificationError('본인인증 취소', {
              code: 400,
              message: '본인인증 취소',
              detail: '본인인증이 취소되었습니다.\n본인인증을 다시 시도해 주세요.',
            });
            return reject(error400);
          }

          const error = new IamportS.CertificationError('본인인증 오류', {
            code: 400,
            message: '본인인증 오류',
            detail:
              '오류가 발생하였습니다.\n다시 시도하거나 동일한 오류가 발생할 경우\n마플샵에 문의해 주세요.',
          });
          return reject(error);
        }

        $.don_loader_start();

        const { data } = await axios.post(`/${T.lang}/@api/iamport/certification2`, {
          imp_uid: res.imp_uid,
          certification_info,
          store_id: box.sel('store_id'),
        });

        resolve(data);
      } catch (axios_err) {
        $.don_loader_end();

        const { data: err_res } = axios_err.response;
        if (err_res.name === 'CertificationError') {
          return reject(new IamportS.CertificationError('본인인증 오류', err_res.error));
        }
        reject(err_res);
      }
    });
  });
};
