import {
  makeProductDetailDeliveryAreaKrHtml,
  makeProductDetailGuidAreaKrHtml,
} from '../../../../../modules/Product/Detail/S/tmpl.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function () {
  $.frame.defn_frame([
    {
      frame_name: 'mp.pd_delivery_frame',
      page_name: 'mp.pd_delivery_page',
      is_modal: !UtilF.isLegacyMobile(),
      header_height: !UtilF.isLegacyMobile() ? 80 : 60,
      el_class: 'pd_delivery_frame',
      title: '배송안내',
      hide_frame_button_type: 'X',
      appended: $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    },
    {
      frame_name: 'mp.pd_guide_frame',
      page_name: 'mp.pd_guide_page',
      is_modal: !UtilF.isLegacyMobile(),
      el_class: 'pd_guide_frame',
      title: '품질보증/청약철회 안내',
      header_height: !UtilF.isLegacyMobile() ? 80 : 60,
      hide_frame_button_type: 'X',
      appended: $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'mp.pd_delivery_page',
      tabs: [
        {
          tab_name: 'mp.pd_delivery_tab',
          template: makeProductDetailDeliveryAreaKrHtml,
          appended: __(
            $.on('click', '.price_table', function (e) {
              $.frame.open({ frame_name: 'mp.quick_shipping_price' });
            }),
          ),
        },
      ],
    },
    {
      page_name: 'mp.pd_guide_page',
      tabs: [
        {
          tab_name: 'mp.pd_guide_tab',
          template: makeProductDetailGuidAreaKrHtml,
        },
      ],
    },
  ]);
})();
