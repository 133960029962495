import { sel, strMap, html } from 'fxjs/es';
import { makePaginationHtml } from '../../../../../../Pagination/S/tmpl.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppSolutionHomeConstantS } from '../../../Home/S/Constant/module/MShopAppSolutionHomeConstantS.js';
import { MShopAppSolutionHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppSolutionHomeTmplS.js';
import { MShopAppSolutionListS } from '../Function/module/MShopAppSolutionListS.js';

export const item = (pathname) => (solution) => {
  const comment = (sel('comment', solution._.solution_thread) || '').replace(/\s+/g, ' ');
  return html`
    <div class="solution-item">
      <a href="${pathname}/${solution.unique_private_key}">
        <div class="solution-item-wrapper">
          <div class="top">
            <div class="head">
              <div class="status" data-status="${solution.status}">
                ${solution.type === 'internal-regulation'
                  ? MShopAppSolutionHomeConstantS.SOLUTION_STATUS_TITLE[solution.status]
                  : MShopAppSolutionHomeConstantS.STATUS_TITLE[solution.status]}
              </div>
              <div class="dday" data-is-close="${solution.is_close}">
                ${solution.is_close
                  ? T(`report::스레드 종료`)
                  : html`
                      ${solution.is_answer
                        ? T('report::재요청 기간이 몇일 남았습니다.', {
                            days: MShopAppSolutionListS.make14Dday(
                              solution.is_answer ? solution.last_answer_at : solution.last_request_at,
                            ),
                          })
                        : T('report::답변 기간이 몇일 남았습니다.', {
                            days: MShopAppSolutionListS.make14Dday(
                              solution.is_answer ? solution.last_answer_at : solution.last_request_at,
                            ),
                          })}
                    `}
              </div>
            </div>
            <div class="type">${MShopAppSolutionHomeConstantS.TYPE_TO_TITLE[solution.type]}</div>
            <div class="created_at">${moment(solution.created_at).format('YYYY.MM.DD HH:mm')}</div>
          </div>
          <div class="bottom">
            <div class="bottom-item">
              <div class="title">${T('report::신고 대상 셀러')}</div>
              <div class="store-name">${UtilS.escape(solution._.store.name)}</div>
            </div>
            <div class="bottom-item">
              <div class="title">${T('report::신고 상세 내용')}</div>
              <div class="comment">
                ${UtilS.escape(comment > 70 ? comment.slice(0, 70) + '...' : comment)}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  `;
};

export const fn = ({ solutions, pagination_state, pathname }) =>
  MShopAppSolutionHomeTmplS.wrapper(
    'list',
    html`
      <div class="request-of-solution-list">
        <h2>${T('report::신고 접수 조회')}</h2>
        <div class="article">
          ${solutions.length
            ? html`
                <div class="solution-list">${strMap(item(pathname), solutions)}</div>
                ${makePaginationHtml(pagination_state)}
              `
            : html`
                <p>${T('report::고객님의 신고 접수 요청 내역이 확인되지 않습니다.')}</p>
                <div class="buttons">
                  <a href="/${T.lang}/@/solution/add"><span>${T('report::신고 접수하기')}</span></a>
                  <a href="/${T.lang}/@/solution"><span>${T('report::서비스 안내')}</span></a>
                </div>
              `}
        </div>
      </div>
    `,
  );
