import { each, filterL, find, findWhere, go, map, mapL, takeAllC, tap } from 'fxjs/es';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { imageCheck } from '../../Feed/F/util.js';
import { makeProductStoryImageHtml } from '../../ProductStory/S/tmpl.js';
import { makeProductStoryImageRemoveHtml } from '../S/tmpl.js';
import { PRODUCT_STORY_MAX_HEIGHT, PRODUCT_STORY_MAX_WIDTH } from '../S/width.js';
import {
  snsInitFacebookEditor,
  snsInitInstagramEditor,
  snsInitTwitterEditor,
  snsInitYoutubeEditor,
} from './fs.js';
import {
  $addClass,
  $appendTo,
  $attr,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $remove,
  $removeClass,
  $setCss,
  $next,
  $val,
  $prev,
  $after,
  $before,
  $setHTML,
} from 'fxdom/es';

const initFile = (e) => () =>
  setTimeout(function () {
    e.currentTarget.type = '';
    e.currentTarget.type = 'file';
    e.currentTarget.disabled = false;
  }, 0);

let file_id = 1;
const makeUniqId = function () {
  return 'file_' + file_id++;
};

export const makePSImageToArrayInBox = ($tab) => {
  let no = 0;
  box.set(
    'product_story_image_no',
    map(
      ($el) => ({
        id: parseInt($attr('data-id', $el)),
        no: no++,
      }),
      $findAll('.product-story-image', $tab),
    ),
  );
  box.set(
    'product_story_image_files',
    map(
      ($el) => findWhere({ file_id: $attr('data-file-id', $el) }, box.sel('product_story_image_files')),
      $findAll('.product-story-image[data-id="0"]', $tab),
    ),
  );
  return $tab;
};

const imageUpDownEvent = ($tab, $prev_or_$next, $before_or_$after) =>
  function (e) {
    const $product_story_image = $closest('.product-story-image', e.currentTarget);
    const $prev_or_next_el = $prev_or_$next('.product-story-image', $product_story_image);
    if (!$prev_or_next_el) return;
    $before_or_$after($product_story_image, $prev_or_next_el);
    makePSImageToArrayInBox($tab);
    $.scroll_top2(
      $find('> .don_wrapper > .body', $closest('.don_frame', $tab)),
      $.position($product_story_image).top,
      200,
    );
  };

const setTextAreaHeight = ($txa) => {
  $setCss({ height: 0 }, $txa);
  $setCss({ height: $txa.scrollHeight }, $txa);
};

export const setProductStoryTextArea = ($tab) =>
  go($findAll('.story-section textarea', $tab), each(setTextAreaHeight), () => $tab);

export const setProductStoryImage = async (e, container_el, files) => {
  const is_input = !!e;

  const $story_file_upload = $find('#story-file-upload', container_el);
  const $product_story_images = $next('.product-story-images', $story_file_upload);

  if (!is_input) {
    $setHTML('', $product_story_images);
  }

  const total_len = $findAll('.product-story-image', $product_story_images).length + files.length;
  if (is_input && total_len > 10)
    return go($.alert(T('review::Maximum of MAX photos', { max: 10 })), initFile(e));
  const max_file_size = 6000000;
  if (is_input && find((file) => file.size > max_file_size, files))
    return go($.alert(T('File size is too large', { mb: 5 })), initFile(e));

  if (is_input) e.currentTarget.disabled = true;
  try {
    is_input &&
      (await imageCheck(
        e.currentTarget,
        max_file_size,
        { width: PRODUCT_STORY_MAX_WIDTH, height: PRODUCT_STORY_MAX_HEIGHT },
        false,
        false,
      ));
  } catch (err) {
    if (is_input) initFile({ currentTarget: e.currentTarget })();
    if (err?.title) {
      return MShopShareFramePopUpF.alert(err);
    }
    return $.alert(err.message);
  }

  return go(
    files,
    filterL((file) => file.type.match('image')),
    mapL((file) =>
      go(
        new Promise((rs) => {
          const reader = new FileReader();
          reader.onload = () => {
            const image = new Image();
            image.onload = () => {
              const max_size = PRODUCT_STORY_MAX_WIDTH;
              if (image.width > max_size) image.width = max_size;
              rs(image);
            };
            image.src = reader.result || '';
          };
          reader.readAsDataURL(file);
        }),
        async (image) => {
          const url = await $.get_data_url(image.height)(file);
          const file_id = makeUniqId();
          file.file_id = file_id;
          return $appendTo(
            $product_story_images,
            $el(
              makeProductStoryImageHtml(makeProductStoryImageRemoveHtml)({
                file_id,
                url,
              }),
            ),
          );
        },
        () => box.push('product_story_image_files', file),
      ),
    ),
    takeAllC,
    () => {
      if (is_input) initFile(e)();
    },
    () => makePSImageToArrayInBox(container_el),
  );
};

const uploadStoryImage = async (e, $tab) => {
  if ($.valid_files(e.currentTarget)) return $.alert(T('review::Accepted File Types: JPEG, PNG, GIF'));
  const files = e.currentTarget.files;
  return await setProductStoryImage(e, $tab, files);
};

export const defnProductStoryEvent = tap(($tab) => {
  box.set('product_story_image_files', []);
  box.set('remove_product_story_image_files', []);
  return go(
    $tab,
    makePSImageToArrayInBox,
    $delegate('change', '#youtube-section input', snsInitYoutubeEditor),
    $delegate('change', '#instagram-section input', snsInitInstagramEditor),
    $delegate('change', '#twitter-section input', snsInitTwitterEditor),
    $delegate('change', '#facebook-section input', snsInitFacebookEditor),
    $delegate('input', '.story-section textarea', function (e) {
      setTextAreaHeight(e.currentTarget);
    }),
    $delegate('change', '.product-story-radio-buttons input', function (e) {
      go(
        e.currentTarget,
        $closest('.product-story-wrap'),
        tap($find('.product-story-lang.selected'), $removeClass('selected')),
        $find(`.product-story-lang[data-lang="${$val(e.currentTarget)}"]`),
        $addClass('selected'),
        $findAll('textarea'),
        each(setTextAreaHeight),
      );
    }),
    $delegate('click', 'button.remove', function (e) {
      $remove($closest('.product-story-image', e.currentTarget));
      makePSImageToArrayInBox($tab);
      const id = $attr('data-id', e.currentTarget);
      if (id !== '0') box.push('remove_product_story_image_files', id);
    }),
    $delegate('click', 'button.up-down[data-check="next"]', imageUpDownEvent($tab, $next, $after)),
    $delegate('click', 'button.up-down[data-check="prev"]', imageUpDownEvent($tab, $prev, $before)),
    $.on3('change', '#story-file-upload input[type="file"]', async (e) => {
      await uploadStoryImage(e, $tab);
    }),
  );
});
