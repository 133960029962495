import { html } from 'fxjs/es';
import { MShopAppSolutionCertTmplS } from '../../../Cert/S/Tmpl/module/MShopAppSolutionCertTmplS.js';
import { MShopAppSolutionHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppSolutionHomeTmplS.js';

export const fn = () =>
  MShopAppSolutionHomeTmplS.wrapper(
    'add',
    html`
      <div class="request-of-solution-add">
        <div class="add-type article" data-key="type">
          <h2>${T('report::1. 신고 접수 유형 선택')}</h2>
          <p>
            ${T(
              'report::유형 사유/유형 별 요청 서식이 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 진행되지 않습니다.',
            )}
          </p>
          <ul class="select">
            <li data-value="copyright">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::저작권침해 (고객님의 작품, 콘텐츠 등을 침해한 상품 관련)')}</div>
            </li>
            <li data-value="fake-product">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::위조상품 판매 (상표권/디자인권 침해 상품 관련)')}</div>
            </li>
            <li data-value="internal-regulation">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::마플샵 내부 규정 위반 (연령제한, 타인 명의로 운영 중인 샵 관련)')}</div>
            </li>
          </ul>
        </div>
        <div class="add-user-type article" data-key="user_type">
          <h2>${T('report::2. 권리 침해를 받은 당사자 선택')}</h2>
          <p>
            ${T(
              'report::단체/개인에 따라 필요 서류가 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 되지 않습니다.',
            )}
          </p>
          <ul class="select">
            <li data-value="group">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::단체 (기업, 사업체, 종교단체, 학교 등)')}</div>
            </li>
            <li data-value="person">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::개인 (본인, 가족, 친척, 지인 등)')}</div>
            </li>
          </ul>
        </div>
        <div class="add-agree-type article" data-key="agree_type">
          <h2>${T('report::3. 필요 서류 안내 및 구비 여부 선택')}</h2>
          <p>
            ${T(
              'report::신고 접수 시 접수 유형과 당사자 선택에 따라 필요 서류가 달라지며 서류 구비가 되지 않은 경우, 신고 접수가 진행되지 않습니다.',
            )}
          </p>
          <div class="agree-box" id="agree_box">
            <div class="info copyright">
              <h5>${T('report::① 저작권 침해 (고객님의 작품, 컨텐츠 등을 침해한 상품 관련)')}</h5>
              <div class="note">
                <ul>
                  <li>${T('report::해당 셀러의 저작권 침해 사실을 증빙할 수 있는 자료 첨부')}</li>
                  <li class="group-li">
                    ${T('report::기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부')}
                  </li>
                  <li class="group-li">
                    ${T(
                      'report::접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부',
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div class="info fake-product">
              <h5>${T('report::② 위조 상품 판매 (상표권/디자인권 침해 상품 관련)')}</h5>
              <div class="note">
                <ul>
                  <li>${T('report::상표권/디자인권 및 해당 셀러의 침해 사실을 증빙할 수 있는 자료 첨부')}</li>
                  <li class="group-li">
                    ${T('report::기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부')}
                  </li>
                  <li class="group-li">
                    ${T(
                      'report::접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부',
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div class="info internal-regulation">
              <h5>${T('report::③ 마플샵 내부 규정 위반 (연령 제한, 타인 명의로 운영 중인 샵 관련)')}</h5>
              <div class="note">
                <ul>
                  <li>${T('report::해당 셀러가 마플샵 내부 규정을 위반했음을 증빙할 수 있는 자료 첨부')}</li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="select">
            <li data-value="ok">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::서류 구비 완료')}</div>
            </li>
            <li data-value="cancel">
              <div class="type-selector"><div class="is_check"></div></div>
              <div>${T('report::서류 구비가 되지 않음')}</div>
            </li>
          </ul>
        </div>
        <div class="article">
          <h2 style="margin-bottom:20px">${T('report::4. 본인 인증')}</h2>
          ${MShopAppSolutionCertTmplS.make(true)}
        </div>
      </div>
    `,
  );
