import { go, sumBy } from 'fxjs/es';
import { DeliveryTypeS } from '../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';

export const makePayableShippingPrice = ({
  user_products,
  stores_delivery_company,
  is_free_shipping_product,
  is_far,
  mps_i,
  _en,
}) => {
  if (DeliveryTypeS.isMarpple(stores_delivery_company.delivery_type_id) && mps_i > 0)
    return {
      shipping_price: 0,
      additional_shipping_price: 0,
    };
  return go(
    user_products,
    sumBy((up_c_s) => up_c_s['discounted_price' + _en] * up_c_s.quantity),
    (products_price) => {
      const is_free_shipping =
        is_free_shipping_product ||
        (stores_delivery_company?.free_shipping_min_price &&
          stores_delivery_company?.free_shipping_min_price <= products_price);
      const shipping_price = is_free_shipping ? 0 : stores_delivery_company.shipping_price;
      const additional_shipping_price = is_far ? stores_delivery_company.additional_shipping_price || 0 : 0;
      return {
        shipping_price,
        additional_shipping_price,
      };
    },
  );
};
