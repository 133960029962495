import { html } from 'fxjs/es';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';

export const pItemTotalPrice = (n_price_info) => {
  const { products_price, shipping_price, additional_shipping_price } = n_price_info;

  return html`
    <div class="shipping-order__price-info">
      <div class="shipping-order__price-info__left">
        <span>상품금액</span>
        <span class="mp_currency">${PriceS.pricify(products_price)}</span>
        <span>+</span>
        <span>배송비</span>
        <span class="mp_currency">${PriceS.pricify(shipping_price)}</span>
        ${additional_shipping_price
          ? html`
              <span>+</span>
              <span>도서산간비</span>
              <span class="mp_currency">${PriceS.pricify(additional_shipping_price)}</span>
            `
          : ''}
      </div>
      <div class="shipping-order__price-info__right">
        <span>총</span>
        <span class="mp_currency"
          >${PriceS.pricify(products_price + shipping_price + additional_shipping_price)}</span
        >
      </div>
    </div>
  `;
};
