import { html } from 'fxjs/es';
import { MShopStudioDigitalProductCreatorConstantS } from '../../../../../Studio/DigitalProductCreator/S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { DTG_LICENSE_DESCRIPTION, DTG_LICENSE_MESSAGE } from '../../constant.js';

export const makeAudioTagHtml = (src) => {
  return html`
    <audio class="preview__player" controls>
      <source src="${src}" type="audio/ogg" />
      <source src="${src}" type="audio/mpeg" />
      Your browser does not support the audio tag.
    </audio>
  `;
};
export const customAudioHtml = () => {
  return html`
    <div class="preview">
      <div class="preview__title">${T('미리 듣기')}</div>
      <div class="preview__custom-player">
        <div class="preview__play-buttons">
          <img
            src="//s3.marpple.co/files/u_2308668/2023/6/original/ea59c9f85c2bba2a76993e4be56c96414c79384a1.png"
            alt="play button icon"
            class="preview__play-buttons__play-icon"
          />
          <img
            src="//s3.marpple.co/files/u_2308668/2023/6/original/51ce2c621346782c9826678478bd53866afc4b2b1.png"
            alt="pause button icon"
            class="preview__play-buttons__pause-icon none"
          />
        </div>
        <div class="preview__progress-container">
          <div class="preview__progress-background">
            <span class="preview__progress"></span>
          </div>
        </div>
        <div class="preview__time-container">
          <span class="preview__time current-time">0:00</span>
          <span class="preview__time"> / </span>
          <span class="preview__time duration">0:00</span>
        </div>
      </div>
    </div>
  `;
};
export const makeDtgInfoHtmlPc = (product_detail) => {
  const {
    _: { stores_product },
    dtg_resources_info,
  } = product_detail;
  const {
    _: { digital_product },
  } = stores_product;
  const { sp_cate_item_id } = stores_product;
  const { license_type, is_hyper_link } = digital_product;
  const { preview_signed_url, file_type_msg } = dtg_resources_info;
  /* TODO 카테고리 하드코딩 */
  const is_good_note =
    sp_cate_item_id === MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE;

  return html`
    <div class="file-type">
      <div class="title">${T('파일 유형')}</div>
      <div class="content">${file_type_msg}</div>
    </div>
    ${is_good_note
      ? html`
          <div class="is-hyper-link">
            <div class="title">${T('하이퍼링크')}</div>
            <div class="content">${is_hyper_link ? T('있음') : T('없음')}</div>
          </div>
        `
      : ''}
    <div class="license">
      <div class="title">${T('라이센스 범위')}</div>
      <div class="contents">
        ${DTG_LICENSE_MESSAGE[license_type]}
        <div class="content__description">${DTG_LICENSE_DESCRIPTION[license_type]}</div>
      </div>
    </div>
    ${preview_signed_url ? customAudioHtml() : ''}
  `;
};

export const makeDtgInfoHtmlMo = (product_detail) => {
  const {
    _: { stores_product },
    dtg_resources_info,
  } = product_detail;
  const {
    _: { digital_product },
  } = stores_product;
  const { sp_cate_item_id, is_purchased_dtg = false } = stores_product;
  const { license_type, is_hyper_link } = digital_product;
  const { file_type_msg } = dtg_resources_info;
  /* TODO 카테고리 하드코딩 */
  const is_good_note =
    sp_cate_item_id === MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE;

  return html`
    <div class="file-type">
      <div class="title">${T('파일 유형')}</div>
      <div class="content">${file_type_msg}</div>
    </div>
    ${is_good_note
      ? html`
          <div class="is-hyper-link">
            <div class="title">${T('하이퍼링크')}</div>
            <div class="content">${is_hyper_link ? T('있음') : T('없음')}</div>
          </div>
        `
      : ''}
    <div class="license">
      <div class="title">${T('라이센스 범위')}</div>
      <div class="contents">
        ${DTG_LICENSE_MESSAGE[license_type]}
        <div class="content__description">${DTG_LICENSE_DESCRIPTION[license_type]}</div>
      </div>
    </div>
    ${is_purchased_dtg
      ? html`
          <div class="dtg-disabled">
            <img
              src="//s3.marpple.co/files/u_2308668/2023/7/original/0a51f2fdd47d99617b2888003f1bcdf7e87385cc1.webp"
              alt="caution icon image"
            />
            <div class="pd-submit__disabled-text">
              이미 구매한 상품으로 다운로드 가능 기간이 지나면 재구매가 가능해요.
            </div>
          </div>
        `
      : ''}
  `;
};
