import { takeAll, object, mapL, go, curry, rangeL } from 'fxjs/es';
import { SELECTOR } from '../S/constant.js';
import { $is, $el, $setAttr } from 'fxdom/es';

export const changeTagName = curry((tag, node) => {
  const new_el$ = $el(`<${tag}>${node.innerHTML}</${tag}>`);

  go(
    node.attributes,
    objectToIterL,
    mapL((attr) => [attr.name, attr.value]),
    takeAll,
    object,
    (attrs) => $setAttr(attrs, new_el$),
  );

  node.outerHTML = new_el$.outerHTML;

  return node;
});

export const isNotContenteditable = (el$) => $is(SELECTOR.NOT_EDITABLE, el$);

export const objectToIterL = function* (obj) {
  yield* go(
    rangeL(obj.length),
    mapL((i) => obj[i]),
  );
};

export const setCursor = (target_el) => {
  const new_range = document.createRange();
  new_range.setStart(target_el, 0);
  new_range.collapse(true);

  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(new_range);
};
