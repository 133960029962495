import { curry2 } from 'fxjs/es';
import { produce } from 'immer';

export const rename = curry2((prev_key, key, object) =>
  produce(object, (obj) => {
    if (!obj.hasOwnProperty(prev_key)) return obj;
    obj[key] = obj[prev_key];
    delete obj[prev_key];
  }),
);
