import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, ippL, range, strMap } from 'fxjs/es';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';

const _makeDigitalProductImageHtml = ({ image, idx }) => {
  return html`
    <div
      class="digital-product-thumbnail-editor__photo${idx == 0
        ? ` digital-product-thumbnail-editor__photo--show`
        : ''}"
      data-id="${image.id || 0}"
      data-file-id="${image.file_id}"
      data-idx="${idx}"
    >
      <img
        src="${(image.url.includes('data:image')
          ? image.url
          : UtilImageS.getResizedUrl({ url: image.url, width: 740, format: 'jpeg' })) || ''}"
        alt=""
      />
      <div class="digital-product-thumbnail-editor__photo-delete">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/6/original/e5d42fddc38f7417d0b7d3b59234678d714c7a271.png"
          alt=""
        />
      </div>
      <div class="digital-product-thumbnail-editor__photo-set-first">
        <label class="digital-product-thumbnail-editor__radio-label">
          <input type="radio" name="digital-product_thumbnail_first" ${idx == 0 ? 'checked' : ''} />
          ${T(messages.digital_products.set_first)}
        </label>
      </div>
    </div>
  `;
};
const _makeThumbnailItem = ({ image, idx }) => {
  return html`
    <li
      class="digital-product-thumbnail-editor__item digital-product-thumbnail-editor__item--on${idx == 0
        ? ' digital-product-thumbnail-editor__item--selected'
        : ''}"
      data-idx="${idx}"
      data-is_file="${!!image.file_id}"
      data-file-id="${image.file_id}"
      data-fx-json="${$dataStr(image)}"
    >
      <img
        src="${image.file_id ? image.url : UtilImageS.getResizedUrl({ url: image.url, width: 75 }) || ''}"
        class="digital-product-thumbnail-editor__item-img"
        alt=""
      />
      <span class="digital-product-thumbnail-editor__item-thumb-del">
        <img
          src="//s3.marpple.co/files/u_218933/2022/9/original/419cff064bd568d0de7a354d3460fefb793ac85e1.png"
          alt="X"
        />
      </span>
    </li>
  `;
};
export const makeThumbnailRegister = (images = []) => {
  return html`
    <div class="digital-product-thumbnail-editor">
      <ul class="digital-product-thumbnail-editor__list">
        ${strMap((idx) => {
          if (!images[idx]) return '<li class="digital-product-thumbnail-editor__item" data-id=""></li>';
          return _makeThumbnailItem({ image: images[idx], idx });
        }, range(6))}
      </ul>
      <div
        class="digital-product-thumbnail-editor__view-area"
        is-show-upload="${images.length ? 'false' : 'true'}"
        data-length="${images.length || '0'}"
      >
        <div class="digital-product-thumbnail-editor__upload">
          <div class="digital-product-thumbnail-editor__upload-icon">
            <img
              src="//s3.marpple.co/files/u_218933/2022/8/original/c21f831e7955dfcbcb88b3c08fb56cd7b96c0c4b1.png"
              alt=""
            />
          </div>
          <p class="digital-product-thumbnail-editor__update-text1">
            ${T(messages.digital_products.no_image_info_title)}
          </p>
          <p class="digital-product-thumbnail-editor__update-text2">
            ${T(messages.digital_products.no_image_info_contents)}
          </p>
          <div class="digital-product-thumbnail-editor__upload-btn-area">
            <label>
              <input
                type="file"
                class="digital-product-thumbnail-editor__input-file"
                accept="image/jpeg,image/png,image/pjpeg"
                multiple
              />
              <button type="button" class="digital-product-thumbnail-editor__upload-btn">
                <span>${T(messages.digital_products.image_upload_btn)}</span>
              </button>
            </label>
          </div>
        </div>
        <div class="digital-product-thumbnail-editor__photos">
          ${go(
            images,
            ippL,
            strMap(([idx, image]) => _makeDigitalProductImageHtml({ image, idx })),
          )}
          <div class="digital-product-thumbnail-editor__arrow">
            <div class="digital-product-thumbnail-editor__arrow-prev">
              <img
                src="//s3.marpple.co/files/u_193535/2018/10/original/f_11767_1540780825772_yl3V9ZHEgJKtbVURT.png"
                alt="prev"
              />
            </div>
            <div class="digital-product-thumbnail-editor__arrow-next">
              <img
                src="//s3.marpple.co/files/u_193535/2018/10/original/f_11765_1540780825772_Ec5FSJl4q4V9M8mlLHn0R.png"
                alt="next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="digital-product-thumbnail-editor__text">${T(messages.digital_products.recommend_size)}</p>
  `;
};
