import { AdScriptSmartLogF } from '../../../../../../modules/AdScript/SmartLog/F/Function/module/AdScriptSmartLogF.js';
import { AdScriptTwitterF } from '../../../../../../modules/AdScript/Twitter/F/Function/module/AdScriptTwitterF.js';

if (ENV.is_prod) {
  /* google tag manager */

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };

  gtag('js', new Date());

  /* End naver log */
  if (
    ENV.lang == 'kr' &&
    window.naver_wcslog &&
    !$.has_class(document.querySelector('html'), 'mp_order_complete')
  ) {
    window.naver_wcslog();
  }

  /* End naver log */

  if (ENV.lang == 'kr') {
    gtag('config', 'G-Y184NEXE0W');
    gtag('config', 'AW-881715956', { allow_enhanced_conversions: true });
    if (ENV.is_mobile) {
      gtag('event', 'conversion', {
        send_to: 'AW-828951906/_mRVCJTc3WYQ9NW3pAM',
        aw_remarketing_only: true,
      });
    }
  } else if (ENV.lang == 'jp') {
    gtag('config', 'UA-128570260-3');
    gtag('config', 'AW-828951906');
  } else if (ENV.lang == 'en') {
    gtag('config', 'UA-128570260-1');
    gtag('config', 'AW-828951906');
    if (ENV.is_mobile) {
      gtag('event', 'conversion', {
        send_to: 'AW-828951906/Uc2XCLuJ4I8BEOKao4sD',
        value: 1.0,
        currency: 'USD',
        aw_remarketing_only: true,
      });
    }
  }
  /* End google tag manager */

  /* facebook pixel code */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  if (ENV.lang == 'kr') fbq('init', '184539351899921', { em: box.sel('is_user->email') });
  else if (ENV.lang == 'en') fbq('init', '1908235679272864', { em: box.sel('is_user->email') });
  fbq('track', 'PageView');
  /* End facebook pixel code */

  /* twitter-ads */
  AdScriptTwitterF.init();
  AdScriptTwitterF.pageView();

  // 틱톡
  (function () {
    const ta = document.createElement('script');
    ta.type = 'text/javascript';
    ta.async = true;
    ta.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=BTDID5R1811BQC93UJ3G';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ta, s);
  })();

  if (ENV.lang == 'kr' && !$.has_class(document.querySelector('html'), 'mp_order_complete')) {
    AdScriptSmartLogF.initMp();
  }
}
