import format from 'date-fns/format/index.js';
import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';

export const makeFeedCardHtml = (feed, { link, label, public_at, close_btn, not_lazy }) => legacyHtml`
  <div class="feed_card swiper-slide" data-id="${feed.id}">
    ${
      close_btn
        ? legacyHtml`
          <div class="close-btn">
            <img
              class="img"
              src="//s3.marpple.co/files/u_1165777/2020/11/original/b2c940b485bfb27e87ebb2707db3a9ec235f74cd1.png"
              alt=""
            />
          </div>
        `
        : ''
    }
    ${
      label
        ? legacyHtml`<div class="label-container">
          <label data-show="${feed.show_home}" class="label main"> ${T('plus_admin::pa607')} </label>
          <label class="label ${feed.is_public ? 'public' : 'private'}">
            ${
              feed.is_public
                ? legacyHtml`
                  <img
                    src="//s3.marpple.co/files/u_1396787/2020/11/original/0cdf2841d33902b37040f20e3bcc57cadb2968441.png"
                    alt=""
                  />
                  ${T('plus_admin::pa25')}
                `
                : legacyHtml`<img
                    src="//s3.marpple.co/files/u_1396787/2020/11/original/c4278bcd4c48c7b216f7af22a13e3a7416f71bf31.png"
                    alt=""
                  />
                  ${T('plus_admin::pa26')}`
            }
          </label>
        </div> `
        : ''
    }
    <div class="thumbnail">
    ${
      not_lazy
        ? html`<a
            href="${UtilS.escape(link)}"
            target="_self"
            style="background-image: url('${UtilImageS.getResizedUrl({
              url: feed.thumbnail,
              width: 500,
              format: 'jpeg',
              quality: 85,
            })}');"
          >
          </a>`
        : html`<a
            class="don_lazy"
            href="${UtilS.escape(link)}"
            target="_self"
            data-src="${UtilImageS.getResizedUrl({
              url: feed.thumbnail,
              width: 500,
              format: 'jpeg',
              quality: 85,
            })}"
          >
          </a>`
    }
    
    </div>
    <div class="text">
      <p class="title">${UtilS.escape(feed.title)}</p>
      <p class="description">${UtilS.escape(feed.description) || ''}</p>
    </div>
    ${
      public_at
        ? legacyHtml`<div class="public_date">
          ${feed.public_at ? `${format(feed.public_at, 'yyyy.MM.dd HH:mm')}부터 게시` : '비공개'}
        </div> `
        : ''
    }
  </div>
`;
