import { go, some } from 'fxjs/es';

/*
 * error 객체 있는지 확인해서 있으면 true
 * */
export const hasPbProductError = (pb_product) => {
  if (pb_product._.error) return true;
  if (
    go(
      pb_product._.spo_shipping_companies,
      some((spo_shipping_company) => spo_shipping_company._?.error),
    )
  )
    return true;
  if (pb_product._?.spo_options?.length) {
    if (
      go(
        pb_product._.spo_options,
        some((spo_option) => spo_option._?.error),
      )
    )
      return true;
  }
};
