import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const reviewFileTmpl = (files) =>
  OMPCoreAtomTmplS.swiper({
    customClass: 'omp-review-detail__image-slider',
    navigationClass: 'omp-review-detail__image-slider-nav',
    arrow: true,
    children: OMPCoreAtomTmplS.swiperItems({
      items: files,
      customClass: 'omp-review-detail__image-wrapper',
      renderer: (file) =>
        html`<img
          src="${UtilImageS.getResizedUrl({
            url: file.url,
            quality: 70,
            width: 800,
            format: 'webp',
          })}"
          alt="review-thumbnail"
        />`,
    }),
  });
