import { entries, every } from 'fxjs/es';

const isObject = (obj) => obj != null && typeof obj === 'object';

const makeDateObjToTime = (date) => {
  if (date instanceof Date) {
    return date.getTime();
  } else {
    return date;
  }
};

const getObjLen = (obj) => Object.keys(obj).length;

/*
   객체의 모든 키-밸류 쌍을 비교하기 때문에 Object 가 클래스의 인스턴스 객체인 경우에는 주의를 요합니다.
   유저에 의해 컨트롤 되지 못하는 클래스의 멤버로 인해서 의도한 바와 다른 결과가 나올 수 있기 때문입니다.
*/
// @memo(ryan): fast-deep-equal 라이브러리를 쓰는 것이 더 안전하고 빨라보입니다!
export const isObjectDeepEqual = (obj_a, obj_b) => {
  const obj_a_len = getObjLen(obj_a);
  const obj_b_len = getObjLen(obj_b);

  if (obj_a_len !== obj_b_len) {
    return false;
  } else if (obj_a_len === 0 && obj_b_len === 0) {
    return true;
  } else {
    return every(([k_b, v_b]) => {
      let v_a = obj_a[k_b];
      v_a = makeDateObjToTime(v_a);
      v_b = makeDateObjToTime(v_b);
      const isObjects = isObject(v_a) && isObject(v_b);
      return !((isObjects && !isObjectDeepEqual(v_a, v_b)) || (!isObjects && v_a !== v_b));
    }, entries(obj_b));
  }
};
