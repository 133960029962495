import MediumEditor from '@marpple/dinosaurs/front/medium-editor.js';
import { $closest, $el, $find, $is, $on, $qs } from 'fxdom/es';

import { pipe, tap } from 'fxjs/es';
import { handleLeaveAfterEdit } from '../../../Feed/F/event.js';
import {
  focusClickPlaceholder,
  insertEmpty,
  preventDeleteNoteditable,
  preventOuterDivCreation,
  preventSpanCreation,
  replaceWhitespaceChar,
} from './contenteditable_bugfix.js';
import { uploadEditorImgs } from './custom-insert-plugin/image.js';
import { initInsertPlugin, insertPluginPreprocessing } from './custom-insert-plugin/index.js';
import { newlineAroundNotEditable } from './features/index.js';
import { getMediumHeader } from './toolbar/custom-header-btn.js';
import { getMediumAlignCenter, getMediumAlignLeft } from './toolbar/medium-editor-text-align.js';
import { getCustomPasteHandler } from './toolbar/paste_handler.js';

const preventBugs = ($editable) => {
  $on('keydown', pipe(tap(insertEmpty), tap(preventDeleteNoteditable)))($editable);
  $on('DOMNodeInserted', pipe(tap(preventSpanCreation), tap(preventOuterDivCreation)))($editable);
  $on('click', focusClickPlaceholder)($editable);
};

const addFeatures = pipe(tap($on('click', newlineAroundNotEditable)));

export default async (selector = '.editable', options, plugin_options = {}) => {
  const $editable = $qs(selector);

  addFeatures($editable);
  preventBugs($editable);

  const editor = new MediumEditor(selector, {
    ...options,
    toolbar: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'anchor',
        'custom-h2',
        'custom-h3',
        'align-center',
        'align-left',
      ],
    },
    anchorPreview: false,
    extensions: {
      'align-center': new (getMediumAlignCenter())(),
      'align-left': new (getMediumAlignLeft())(),
      paste: new (getCustomPasteHandler())(),
      'custom-h2': new (getMediumHeader(2))(),
      'custom-h3': new (getMediumHeader(3))(),
      imageDragging: {},
    },
  });

  await initInsertPlugin(editor, plugin_options);

  let inital_value = getEditorContentHtml(editor);
  handleLeaveAfterEdit(() => {
    return inital_value !== getEditorContentHtml(editor);
  });

  editor.save = () => {
    if (!editor.isActive) throw new Error('미리보기 상태에서는 저장할 수 없습니다.');
    inital_value = getEditorContentHtml(editor);
    return inital_value;
  };

  return editor;
};

export const createEmptyP = () => $el(`<p><br /></p>`);

export const getCursorEl = (editor, event) => {
  // contenteditable 이 false 인 곳에서는 클릭일 때 selection 이 제대로 동작 안함
  if (event && event.type == 'click') {
    // root 찾기
    const is_parent_editable = !$closest('[contenteditable=false]', event.target);
    if (!is_parent_editable) return event.target;
  }

  const selection = window.getSelection();
  const $current = selection && selection.rangeCount ? editor.getSelectedParentElement() : '';
  return !$current || $is('.editable', $current) ? $find('p', editor.elements[0]) : $current;
};

export const contentPreprocessing = pipe(replaceWhitespaceChar);

export const uploadRichContents = uploadEditorImgs;

export const getEditorContentHtml = (editor) => {
  const $clone_editor = editor.elements[0].cloneNode(true);
  insertPluginPreprocessing($clone_editor);
  return contentPreprocessing($clone_editor.innerHTML.trim());
};
