import MediumEditor from '@marpple/dinosaurs/front/medium-editor.js';

const proxys = [];

export const addProxy = (p) => proxys.push(p);
export const removeProxy = (p) => {
  proxys.splice(proxys.indexOf(p), 1);
};

export const getClipboardData = (e) =>
  window.clipboardData && window.clipboardData.getData // IE
    ? window.clipboardData.getData('Text')
    : e.clipboardData && e.clipboardData.getData
    ? e.clipboardData.getData('text/plain')
    : '';

export const getCustomPasteHandler = () => {
  const CustomPasteHandler = MediumEditor.extensions.paste.extend({
    handleKeydown: function (event) {},
  });

  return CustomPasteHandler;
};
