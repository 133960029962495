export const info = {
  CJ: {
    name: 'CJ대한통운',
    id: 'cj',
    tel: '+8215881255',
    link: `https://trace.cjlogistics.com/next/tracking.html?wblNo=`,
    lang: 'kr',
  },
  CU: {
    name: 'CU 편의점택배',
    id: 'cu',
    tel: '+8215771287',
    link: `https://www.cupost.co.kr/postbox/delivery/localResult.cupost?invoice_no=`,
    lang: 'kr',
  },
  GS: {
    name: 'GS25편의점택배',
    id: 'gs',
    tel: '+8215771287',
    link: `https://www.cvsnet.co.kr/invoice/tracking.do?invoice_no=`,
    lang: 'kr',
  },
  LOGEN: {
    name: '로젠택배',
    id: 'logen',
    tel: '+8215889988',
    link: `https://www.ilogen.com/web/personal/trace/`,
    lang: 'kr',
  },
  LOTTE: {
    name: '롯데택배',
    id: 'lotte',
    tel: '+8215882121',
    link: `https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=`,
    lang: 'kr',
  },
  EPOST: {
    name: '우체국택배',
    id: 'epost',
    tel: '+8215881300',
    link: `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?ems_gubun=E&sid1=`,
    lang: 'kr',
  },
  HANJIN: {
    name: '한진택배',
    id: 'hanjin',
    tel: '+8215880011',
    link: `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=`,
    lang: 'kr',
  },
  KUNYOUNG: {
    name: '건영택배',
    id: 'kunyoung',
    tel: '+82533543001',
    link: `https://www.kunyoung.com/goods/goods_01.php?mulno=`,
    lang: 'kr',
  },
  KDEXP: {
    name: '경동택배',
    id: 'kdexp',
    tel: '+8218995368',
    link: `https://kdexp.com/basicNewDelivery.kd?barcode=`,
    lang: 'kr',
  },
  DS: {
    name: '대신택배',
    id: 'ds',
    tel: '+82314620100',
    link: `https://www.ds3211.co.kr/freight/internalFreightSearch.ht?billno=`,
    lang: 'kr',
  },
  WOORI: {
    name: '우리택배(구호남택배)',
    id: 'woori',
    tel: '+82269592015',
    link: `http://www.honamlogis.co.kr/page/index.php?pid=tracking_number&SLIP_BARCD=`,
    lang: 'kr',
  },
  ILYANG: {
    name: '일양로지스',
    id: 'ilyang',
    tel: '+8215880002',
    link: `https://www.ilyanglogis.co.kr/board/board_delivery/tracking_result.asp?scrID=0000000220&pageNum=5&subNum=3&req_type=TRACKING&tracking_type=0&blNum=`,
    lang: 'kr',
  },
  CHUNIL: {
    name: '천일택배',
    id: 'chunil',
    tel: '+8218776606',
    link: `https://www.chunil.co.kr/HTrace/HTrace.jsp?transNo=`,
    lang: 'kr',
  },
  HDEXP: {
    name: '합동택배',
    id: 'hdexp',
    tel: '+8218993392',
    link: `https://hdexp.co.kr/basic_delivery.hd?barcode=`,
    lang: 'kr',
  },
  NH: {
    name: '농협택배',
    id: 'nh',
    tel: '+8216449702',
    link: `https://ex.nhlogis.co.kr/dlvy/dlvy/view.do?invNo=`,
    lang: 'kr',
  },
  VALLEY: {
    name: '로지스밸리',
    id: 'valley',
    tel: '+827040663663',
    link: `http://logisvalley.com/support/tracking.php?post_number=`,
    lang: 'kr',
  },
  DHL: {
    name: 'DHL',
    id: 'dhl-direct',
    tel: '+4922892959595',
    link: `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=`,
  },
  EMS: {
    name: 'EMS',
    id: 'ems',
    tel: '+8215881300',
    link: `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=`,
  },
};
