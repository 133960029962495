import axios from 'axios';
import { $addClass, $closest, $data, $find, $qsa, $removeClass, $setData, $val } from 'fxdom/es';
import { go, map, some } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPbProductSizeMuiF } from '../Mui/module/MShopStudioPbProductSizeMuiF.js';
import { getVolumetricWeightBySku } from '../../../../../Shipping/S/Function/shipping.js';

export const openFrame = ({ option_names, row_index, sku, spo_id, spo_item_id }) => {
  MuiF.openFrame(MShopStudioPbProductSizeMuiF.frame, async (frame, page, [tab]) => {
    frame.hide_frame_button_position = 'RIGHT';
    frame.hide_frame_button_type = 'X';
    frame.animation = false;
    frame.is_modal = !MShopUtilF.isMobile();
    tab.attrs = {
      row_index,
      spo_id,
      spo_item_id,
    };
    tab.makeData = () => ({ option_names, sku });
  });
};

export const getCurrentSizeInfo = ({ wrapper_el }) => {
  const width = go(wrapper_el, $find('#sku_size_width'), $val);
  const height = go(wrapper_el, $find('#sku_size_height'), $val);
  const length = go(wrapper_el, $find('#sku_size_length'), $val);
  const weight = go(wrapper_el, $find('#sku_size_weight'), $val);

  const volumetric_weight =
    width && height && length ? getVolumetricWeightBySku({ sku: { width, height, length, weight } }) : 0;

  return { width, height, length, weight, volumetric_weight };
};

export const handleChangeSizeInput = ({ currentTarget: ct }) => {
  const wrapper_el = go(ct, $closest('.pb-product-size__wrapper'));
  const volume_el = go(wrapper_el, $find('.pb-product-size__volume-text'));
  const save_button_el = go(wrapper_el, $find('button.save'));

  const { width, height, length, weight, volumetric_weight } = getCurrentSizeInfo({ wrapper_el });

  save_button_el.disabled = !(width && height && length && weight);

  volume_el.innerText = `${volumetric_weight}g`;
};

export const handleClickSaveButton = async ({ currentTarget: ct }) => {
  try {
    $.don_loader_start();
    const tab_el = go(ct, $closest('.don_tab'));
    const { row_index } = tab_el.tab_opt.attrs || {};

    const rows = $qsa('.pb-tpl-global-wrap--option-table tbody > tr');
    const row = rows[row_index];
    const { sku, spo_id, spo_item_id } = go(row, $data) || {};
    const option_button_size_el = go(row, $find('.pb-editor-form__option-btn-size'));
    if (option_button_size_el) {
      go(option_button_size_el, $removeClass('invalid'));
    }

    const wrapper_el = go(ct, $closest('.pb-product-size__wrapper'));
    const { width, height, length, weight } = getCurrentSizeInfo({ wrapper_el });

    const invalid_some_value = go(
      [width, height, length, weight],
      some((val) => val == 0 || val === ''),
    );
    if (invalid_some_value) {
      return MShopShareFramePopUpF.alert({
        title: '',
        body: '상품 체적정보의 모든 값을 입력해 주세요.',
      });
    }
    go(
      row,
      $setData({ sku: { ...sku, width, height, length, weight }, spo_item_id, spo_id }),
      $addClass('has-sku'),
    );

    if (sku?.id) {
      await axios.post(
        UtilS.makeApiUrl('/@api/stores/:store_id/spo/:spo_id/sku/tpl', {
          store_id: box.sel('store_id'),
          spo_id,
        }),
        {
          product_id: box.sel('pb_stores_product->product_id'),
          spo_item_id,
          sku_nickname: null,
          thumbnail_urls: go(
            box.sel('pb_thumbnail_photos'),
            map(({ url }) => `https:${url}`),
          ),
          sku_size: {
            width,
            height,
            length,
            weight,
          },
        },
      );
    }

    $.alert('저장되었습니다.');
  } catch (err) {
    $.alert(err);
  } finally {
    $.don_loader_end();
  }
};
