import { go } from 'fxjs/es';
import { reviewHeaderDelegate } from './reviewHeaderDelegate.js';
import { reviewListDelegate } from './reviewListDelegate.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement} el
 */
export const event = (el) => {
  go(el, reviewHeaderDelegate, reviewListDelegate());

  return el;
};
