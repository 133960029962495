import { go } from 'fxjs/es';
import { resetUserCounts } from '../../../../../modules/MPay/Cart/F/fs.js';
import { NewMakerGtagF } from '../../../../../modules/NewMaker/Gtag/F/Function/module/NewMakerGtagF.js';
import { NewMakerGtagConstantS } from '../../../../../modules/NewMaker/Gtag/S/Constant/module/NewMakerGtagConstantS.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { SVGEditorUtilF } from '../../../../../modules/SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { NewMakerCvObjectCvImageF } from '../../../../../modules/NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NewMakerUtilF } from '../../../../../modules/NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { errorHandlingEventWrapper } from '../../../../../modules/NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { MakerDebugConstantS } from '../../../../../modules/MakerDebug/S/Constant/module/MakerDebugConstantS.js';

!(function (lo) {
  $.frame.defn_frame({
    frame_name: 'maker.image_upload_frame',
    el_class: 'image_upload_frame after_bar',
    prev_frame_show: true,
    title: T('Upload an Image'),
    always_remove: true,
    animation: true,
    appended: (frame_el) => {
      SVGEditorUtilF.halfFrameClose(frame_el);
    },
    page_name: 'maker.image_upload_page',
    height: T.lang == 'kr' ? 320 + 48 + G.mp.frame.v_height : 230 + 48 + G.mp.frame.v_height,
    header_height: 48 + G.mp.frame.v_height,
    hide_frame_button_type: 'V',
    showed: $.on_prevent_default,
    hiding: $.off_prevent_default,
  });

  $.frame.defn_page({
    page_name: 'maker.image_upload_page',
    el_class: 'image_upload_page',
    tabs: [
      {
        tab_name: 'maker.image_upload_tab',
        template: () => {
          const is_not_image_task = $1('.mp_maker')?.dataset?.is_not_image_task === 'true';
          const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
          const vector = 'image/jpeg,image/png,image/svg+xml';
          const not_image_task = 'image/jpeg,image/png';
          const accept = VectorEditorConstantS.EDITOR_TYPES.includes(maker_type)
            ? vector
            : is_not_image_task === true
            ? not_image_task
            : 'image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript';
          if (T.lang == 'kr') {
            return go(
              void 0,
              async function () {
                await resetUserCounts();
                return $.attr($1('html'), 'my_image_count');
              },
              (count) => legacyHtml`
                <div class="image_upload_desc">
                  <p class="txt1">
                    업로드 가능한 이미지 파일은 JPEG 또는 PNG<span class="creator_del_ai">${
                      is_not_image_task ? '' : ', AI&nbsp;'
                    }</span>
                    파일이며 최대 25MB 이하입니다.
                  </p>
                  <p class="txt2">
                    제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드(CMYK/RGB)에 따라 차이가 발생할
                    수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를
                    업로드하면 저작권에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는
                    개인정보 보호 권한을 침해하지 않음을 확인합니다.
                  </p>
                  <div class="btn_upload">
                    <button type="button" class="btn_add_img">이미지 올리기</button>
                    <input
                      type="file"
                      accept="${accept}"
                    />
                    <button type="button" class="btn_my_lib">내 이미지 보기</button>
                  </div>
                </div>
              `,
            );
          } else if (T.lang == 'jp') {
            return go(
              void 0,
              async function () {
                await resetUserCounts();
                return $.attr($1('html'), 'my_image_count');
              },
              (count) => legacyHtml`
                <div class="image_upload_desc">
                  <p class="txt1">
                    アップロードできるファイル:
                    <b>JPEG, PNG<span class="creator_del_ai">${
                      is_not_image_task ? '' : ', AI&nbsp;'
                    }</span> (最大: 25MB)</b>
                  </p>
                  <p class="txt2">
                    画像をアップロードすることにより、当人にその画像を使用する法的権利があるということに同意したこととなります。
                    詳しくはプライバシーポリシーおよびガイドラインをご覧ください。
                  </p>
                  <div class="btn_upload">
                    <button type="button" class="btn_add_img">アップロード</button>
                    <input
                      type="file"
                      accept="${accept}"
                    />
                    <button type="button" class="btn_my_lib">ライブラリー</button>
                  </div>
                </div>
              `,
            );
          } else {
            return go(
              void 0,
              async function () {
                await resetUserCounts();
                return $.attr($1('html'), 'my_image_count');
              },
              async function () {
                await resetUserCounts();
                return $.attr($1('html'), 'my_image_count');
              },
              (count) => legacyHtml`
                <div class="image_upload_desc">
                  <p class="txt1">
                    Accepted File Types:
                    <b>JPEG, PNG<span class="creator_del_ai">${
                      is_not_image_task ? '' : ', AI&nbsp;'
                    }</span> (Max size: 25MB)</b>
                  </p>
                  <p class="txt2">
                    By uploading an image you agree that you have the legal right to use it. See our
                    <a href="/privacy">Privacy Policy.</a><br />
                    For best results, See our
                    <a href="/guideline/images">Image Guidelines.</a>
                  </p>
                  <div class="btn_upload">
                    <button type="button" class="btn_add_img">Upload</button>
                    <input
                      type="file"
                      accept="${accept}"
                    />
                    <button type="button" class="btn_my_lib">Library</button>
                  </div>
                </div>
              `,
            );
          }
        },
        appended: _p.pipe(
          $.on('click', '.btn_upload button.btn_my_lib', function (e) {
            _p.go(void 0, $.frame.close, function () {
              $.frame.open(
                {
                  frame_name: 'maker.my_lib',
                  page_name: 'maker_my_lib_page',
                  animation: true,
                  loader: true,
                  appending: __($.attr('is_fixed_head', 'true')),
                  closed: function (don_frame, image) {
                    if (!image) return;
                    return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
                  },
                },
                {
                  page_name: 'maker_my_lib_page',
                },
              );
            });
          }),
          $.on('click', '.btn_upload button.btn_add_img', function (e) {
            $.trigger($.find1(e.$delegateTarget, 'input[type="file"]'), 'click');
          }),
          $.on2(
            'change',
            'input[type="file"]',
            errorHandlingEventWrapper(
              async function (e) {
                $.don_loader_start();
                const image = await NewMakerUtilF.uploadFile(e.currentTarget);
                if (!image) return $.don_loader_end();
                await $.frame.close(image);
                NewMakerGtagF.handleFirstImageButtonClick(NewMakerGtagConstantS.gtag.UploadImage);
                $.don_loader_end();
              },
              MakerDebugConstantS.Category.ImageUpload,
              'image_upload_page',
            ),
          ),
        ),
      },
    ],
  });
})({});
