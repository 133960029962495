import { entries, go, map, reject } from 'fxjs/es';

export const isPaypal = (s = '') => ['paypal', '페이팔'].includes(s.toLowerCase());

export const makeColorText = (a) =>
  `${a[`name${_en}`]}${a[`is_public${_collabo}`] ? '' : '&nbsp<i>(품절)</i>'}`;

export const rejectDocIndex = (indexs, arr) =>
  go(
    arr,
    map(entries),
    map((row) => {
      return reject(([k]) => {
        return indexs.includes(Number(k));
      }, row);
    }),
    map(map(([, v]) => v)),
  );
