import { html, strMap, zipWithIndexL } from 'fxjs/es';

export const makeSellerStudioTable = ({ col_rates = [], headers = [], bodys = [], totals = [] }) => {
  col_rates = col_rates.map((col_rate) => {
    if (col_rate) {
      return {
        ...col_rate,
        style: `style="width: ${col_rate.rate}%; text-align: ${col_rate.align || 'right'};"`,
      };
    }
    return null;
  });
  return html`
    <div class="seller-studio-table">
      <div class="seller-studio-table__table">
        <div class="seller-studio-table__head">
          <div class="seller-studio-table__tr">
            ${strMap(([i, title]) => {
              const col_rate = col_rates[i];
              if (!col_rate) {
                return '';
              }
              return html` <div class="seller-studio-table__th ${col_rate.klass || ''}" ${col_rate.style}>
                ${col_rate.tool_tip
                  ? html`<div class="seller-studio-table__tool-tip-wrap">${title}${col_rate.tool_tip}</div>`
                  : title}
              </div>`;
            }, zipWithIndexL(headers))}
          </div>
        </div>
        <div class="seller-studio-table__body">
          ${strMap(
            (tds) =>
              html`<div class="seller-studio-table__tr">
                ${strMap(([i, val]) => {
                  const col_rate = col_rates[i];
                  if (!col_rate) {
                    return '';
                  }

                  return html`
                    <div class="seller-studio-table__td ${col_rate.klass || ''}" ${col_rate.style}>
                      ${val}
                    </div>
                  `;
                }, zipWithIndexL(tds))}
              </div>`,
            bodys,
          )}
        </div>
        ${totals?.length
          ? html`<div class="seller-studio-table__totals">
              <div class="seller-studio-table__tr">
                ${strMap(([i, val]) => {
                  const col_rate = col_rates[i];
                  if (!col_rate) {
                    return '';
                  }
                  return html`
                    <div class="seller-studio-table__tt ${col_rate.klass || ''}" ${col_rate.style}>
                      ${val}
                    </div>
                  `;
                }, zipWithIndexL(totals))}
              </div>
            </div>`
          : ''}
      </div>
    </div>
  `;
};
