import { flatMap, go, map, sumBy } from 'fxjs/es';

export const pCalcProductsPrice = (customer_order, is_original) => {
  return go(
    customer_order._.store_orders,
    flatMap((store_order) =>
      go(
        store_order._.shipping_orders,
        flatMap((shipping_order) =>
          go(
            shipping_order._.item_order_up_cs,
            flatMap((item_order_up_c) => item_order_up_c._.up_c_ss),
          ),
        ),
      ),
    ),
    map((up_c_s) => up_c_s.price * up_c_s[is_original ? 'original_quantity' : 'quantity']),
    sumBy((price) => price),
  );
};
