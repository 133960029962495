import { MShopUtilConstantS } from '../../../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { head, go, entries, find } from 'fxjs/es';

export const colorValueToName = (color) =>
  go(
    MShopUtilConstantS.color_pallete,
    entries,
    find(([k, v]) => v == color),
    head,
  );
