import { go, head, html } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreCellTmplS } from '../../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPProductListTmplS } from '../../../../ProductList/S/Tmpl/module/OMPProductListTmplS.js';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPReviewDetailTmplS } from './module/OMPReviewDetailTmplS.js';

export const reviewNavHtml = () => html`
  <div class="review_detail__nav">
    <a href="/${TT.lang}/review/list?is_best=true&is_all=false" class="review_all"
      >${TT('cell::review::latest_review')}</a
    >
    ${OMPCoreAtomTmplS.navLinkIcon()}
    <span class="review_currnet">${TT('cell::review::detail_review')}</span>
  </div>
`;

export const reviewDetailBodyWrapperHtml = ({
  score,
  size_info,
  created_at,
  user,
  comment,
  sns,
  is_best,
}) => html` <div class="omp-review-detail__body-info-wrapper">
  <div class="omp-review-detail__body-info">
    ${is_best ? OMPCoreAtomTmplS.badgeSmall({ color: 'og', text: 'Hot' }) : ''}
    ${OMPCoreCellTmplS.reviewStarRatingWithSizeTmpl({ score, size_info })}
    ${OMPCoreCellTmplS.reviewerTmpl({ user, created_at })}
  </div>
  <div class="omp-review-detail__body-content">
    <span class="omp-review-detail__body-description">${UtilS.escape(comment)}</span>
  </div>
  ${sns ? OMPCoreAtomTmplS.snsBadge(sns) : ''}
</div>`;

export const productPriceHtml = ({ price_ranges, is_use_origin_price }) =>
  go(price_ranges, head, ({ price_text, start }) => {
    const range_count = is_use_origin_price
      ? TT('cell::product::prod_1_9', { n: UtilS.commify(start) })
      : start > 1
      ? TT('cell::product::prod_1_6', { n: UtilS.commify(start) })
      : TT('cell::product::prod_1_5', { n: 1 });

    return html`
      <div class="omp-cell__product-text__price-wrapper">
        <div class="omp-cell__product-text__price">${range_count}</div>
        <div class="omp-cell__product-text__minimum-price">
          ${!is_use_origin_price ? `${TT('product::list::list_13_1', { price: price_text })}~` : ''}
        </div>
      </div>
    `;
  });

// seo 리뷰 검색 결과로 들어오는 페이지
export const reviewDetailSeoPage = ({
  is_mobile,
  review_detail,
  review_product,
  bps,
  review_count,
  cate_list_id,
  cate_list_name,
}) => {
  return html`
    <div class="review_detail_page_wrap">
      <div class="review_detail">
        ${is_mobile ? '' : reviewNavHtml()}
        <div class="review_detail__content">
          ${is_mobile
            ? OMPReviewDetailTmplS.reveiwDetailPageTmplMo(review_detail, review_product)
            : OMPReviewDetailTmplS.reviewDetailPageTmplPc(review_detail, review_product)}
          <a
            href="/${TT.lang}/review/list?is_best=true&is_all=false&cate_list_id=${cate_list_id}"
            class="review_more"
          >
            ${TT.lang === 'en'
              ? html`View more than&nbsp;&nbsp;
                  <span class="cate_list_review_count">${UtilS.commify(review_count)}</span>&nbsp;
                  ${cate_list_name} reviews`
              : html`<span class="cate_list_review_count"
                    >${TT('cell::review::review_count', {
                      count: UtilS.commify(review_count),
                    })}</span
                  >${TT('cell::review::view_more_reviews', { cate_list_name })} `}
          </a>
        </div>
      </div>
      <p class="review_product_list">
        ${is_mobile ? TT('cell::review::more_products_mo') : TT('cell::review::more_products')}
      </p>
      ${is_mobile ? OMPProductListTmplS.moListBody(bps) : OMPProductListTmplS.pcListBody(bps)}
      <a href="/product/list/${cate_list_id}" class="review_more"
        >${TT('cell::review::view_more_products', { cate_list_name })}
      </a>
    </div>
  `;
};
