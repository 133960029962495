import axios from 'axios';
import { sel, go, map } from 'fxjs/es';
import { setProductStoryImage } from '../../../../../Creator/ProductStoryEditor/F/event.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPbProductCreatorMuiF } from '../Mui/module/MShopStudioPbProductCreatorMuiF.js';
import { convertURLtoFile, setEditorAction, setThumbFile } from './fn.js';

export const openTempStorage = ({ store_id }) => {
  MuiF.openFrame(MShopStudioPbProductCreatorMuiF.frame, async (f, p, [t]) => {
    const [
      {
        data: { product_types, store_ecommerce_info, stores_delivery_companies },
      },
      {
        data: { id: stores_product_storage_id, data: stores_product },
      },
    ] = await Promise.all([
      axios.get(`/${T.lang}/@api/stores/${store_id}/pb_products/base_info`),
      axios.get(`/${T.lang}/@api/stores/${store_id}/pb_products/temp_storage`),
    ]);

    box.set('stores_product_storage_id', stores_product_storage_id);
    box.set('product_types', product_types);
    box.set('pb_stores_product', stores_product);
    box.set('im_pb_stores_product', stores_product);
    box.set('pb_product_spo_options', sel('_.product._.spo._.spo_options', stores_product));
    box.set('pb_thumbnail_photos', []);

    t.makeData = () => ({
      cate_lists: box.sel('cate_lists'),
      cate_items: box.sel(`cate_lists->(#${stores_product.sp_cate_list_id})->_->cate_items`),
      product_types,
      stores_product,
      store_ecommerce_info,
      stores_delivery_companies,
      crew_domain_name: box.sel('crew_domain_name'),
      action: setEditorAction(),
      is_studio_worker_admin: !!box.sel('is_studio_worker_admin'),
    });

    f.showed = async (el) => {
      const files = await go(
        sel('_.product.thumbnails.value', stores_product),
        map(async (image) => {
          return await convertURLtoFile(image.url);
        }),
      );

      const story_files = await go(
        sel('_.product._.product_story._.product_story_images', stores_product),
        map(async (image) => {
          return await convertURLtoFile(image.url);
        }),
      );

      await setThumbFile(null, el, files);
      await setProductStoryImage(null, el, story_files);
    };

    f.hiding = async (el, val) => {
      box.set('stores_product_storage_id', null);
      box.set('im_pb_stores_product', null);
      box.set('pb_stores_product', null);
      box.set('pb_product_spo_options', null);
      box.set('pb_thumbnail_files', []);
      box.set('pb_thumbnail_photos', []);
      if (!MShopUtilF.isMobile()) MShopUtilF.bodyFixed$(false);
      if (val) {
        window.location.reload();
      }
    };
  });
};
