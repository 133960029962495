import { go, html, map } from 'fxjs/es';
import { $before, $appendTo, $el, $on, $replaceWith, $attr, $findAll, $setAttr } from 'fxdom/es';
import { hideInsert } from './index.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { CreatorEditorButtonMuiF } from '../../../Button/F/Mui/module/CreatorEditorButtonMuiF.js';
import { getCursorEl } from '../medium-editor.js';
import { setCursor } from '../../util.js';
import { createButtonToolbar } from '../toolbar/button-toolbar.js';
import { CreatorEditorButtonF } from '../../../Button/F/Function/module/CreatorEditorButtonF.js';
import { createActionStack } from '../features/bug_tracker.js';

const makeFeatureBtnEl = () =>
  $el(`<div class="feature_btn embed_button">
          <i class="lnr lnr-bold"></i>
          <span class="feature-name">버튼</span>
        </div>`);

const parseBtnMetadata = (btn_el) => ({
  content: btn_el.innerText,
  link: $attr('href', btn_el),
  color: CreatorEditorButtonF.parseButtonColor(btn_el),
});

export const initButtonPlugin = (registerBtnToInsertBox, editor) => {
  // register btn
  const btn_el = makeFeatureBtnEl();
  registerBtnToInsertBox(btn_el);

  createButtonToolbar(editor);

  const $editable = editor.elements[0];
  go($findAll('.editor-button-container', $editable), map($setAttr({ contenteditable: false })));

  // handler
  $on('click', (e) => {
    openButtonEditor(editor);
    hideInsert();
  })(btn_el);
};

export const openButtonEditor = (editor, { prev_container_el, prev_btn_el } = {}) => {
  const current_el = getCursorEl(editor);
  const mode = !prev_container_el ? 'create' : prev_btn_el ? 'update' : 'append';
  MuiF.openFrame(CreatorEditorButtonMuiF.frame, (f, p, [t]) => {
    t.makeData = () => (prev_btn_el ? parseBtnMetadata(prev_btn_el) : {});

    f.removed = (_, new_btn_el) => {
      if (!new_btn_el) return;

      const btn_container_el = $el(html`<div class="editor-button-container" contenteditable="false"></div>`);
      $appendTo(btn_container_el, new_btn_el);

      if (mode == 'create') {
        $before(btn_container_el, current_el);
        setCursor(current_el);
        createActionStack('create button', { btn_container_el });
      } else if (mode == 'update') {
        $replaceWith(new_btn_el, prev_btn_el);
        createActionStack('update button', { new_btn_el });
      } else {
        $appendTo(prev_container_el, new_btn_el);
        createActionStack('add button', { new_btn_el });
      }
    };
  });
};
