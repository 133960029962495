import { html } from 'fxjs/es';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const pProductInfoHtml = ({ n_info }, _en = G._en) => {
  const { up_c_id, name, second_name, price } = n_info;
  return html`
    <div class="up-item-product-info">
      <div class="up-item-product-info__up_c_id">No. ${UtilS.escape(up_c_id)}</div>
      <div class="up-item-product-info__name">
        <span class="up-item-product-info__name-value">${UtilS.escape(name)}</span>
      </div>
      <div class="up-item-product-info__second-name">${UtilS.escape(second_name)}</div>
      <div class="up-item-product-info__price mp_currency">${PriceS.pricify_by(price, _en)}</div>
    </div>
  `;
};
