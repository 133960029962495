const pd = (path) => `mshop::ProductDetail::${path}`;
const ps = (path) => `mshop::ProductStatus::${path}`;

export const messages = {
  producing_period: pd`producing_period`,
  shipping_period: pd`shipping_period`,
  format: {
    short_date: 'format::date::short',
  },
  tabs: {
    story: pd`tabs::story`,
    review: pd`tabs::review`,
    bp: pd`tabs::bp`,
    purchaseInfo: pd`tabs::purchaseInfo`,
    faq: pd`tabs::faq`,
  },
  purchaseInfo: {
    delivery_title: pd`purchaseInfo::title`,
    seller_info_title: pd`purchaseInfo::seller_info_title`,
    seller_name: pd`purchaseInfo::seller_name`,
    seller_email: pd`purchaseInfo::seller_email`,
    seller_company_name: pd`purchaseInfo::seller_company_name`,
    seller_business_license_number: pd`purchaseInfo::seller_business_license_number`,
    seller_company_tel: pd`purchaseInfo::seller_company_tel`,
    delivery_desc: pd`purchaseInfo::desc`,
    delivery_expect: pd`purchaseInfo::expect`,
    tpl_delivery_expect: pd`purchaseInfo::tpl_expect`,
    producing_period: pd`purchaseInfo::producing_period`,
    refund_title: pd`purchaseInfo::refund_title`,
    take_back_mobile: pd`purchaseInfo::take_back_mobile`,
    take_back_title: pd`purchaseInfo::take_back_title`,
    refund_desc1: pd`purchaseInfo::refund_desc1`,
    refund_desc2: pd`purchaseInfo::refund_desc2`,
    take_back_price: pd`purchaseInfo::take_back_price`,
    take_back_address: pd`purchaseInfo::take_back_address`,
    cs_title: pd`purchaseInfo::cs_title`,
    cs_num: pd`purchaseInfo::cs_num`,
    email_title: pd`purchaseInfo::email_title`,
    email: pd`purchaseInfo::email`,
    btn_guide1: pd`purchaseInfo::btn_guide1`,
    btn_guide2: pd`purchaseInfo::btn_guide2`,
  },
  review_title: pd`review_title`,
  choose: pd`choose`,
  faq: {
    group1: pd`faq::group1`,
    group2: pd`faq::group2`,
    group3: pd`faq::group3`,
    q1: pd`faq::q1`,
    a1: pd`faq::a1`,
    q2: pd`faq::q2`,
    a2: pd`faq::a2`,
    q3: pd`faq::q3`,
    a3: pd`faq::a3`,
    q4: pd`faq::q4`,
    a4: pd`faq::a4`,
    q5: pd`faq::q5`,
    a5: pd`faq::a5`,
    q6: pd`faq::q6`,
    a6: pd`faq::a6`,
    q7: pd`faq::q7`,
    a7: pd`faq::a7`,
    q8: pd`faq::q8`,
    a8: pd`faq::a8`,
    q9: pd`faq::q9`,
    a9: pd`faq::a9`,
    q10: pd`faq::q10`,
    a10: pd`faq::a10`,
    q11: pd`faq::q11`,
    a11: pd`faq::a11`,
    q12: pd`faq::q12`,
    a12: pd`faq::a12`,
  },
  tpl_faq: {
    a1: pd`tpl_faq::a1`,
    a2: pd`tpl_faq::a2`,
    a4: pd`tpl_faq::a4`,
    a5: pd`tpl_faq::a5`,
  },
  preview_by_device: pd`preview_by_device`,
  color: pd`color`,
  size: pd`size`,
  quantity: pd`quantity`,
  sold_out: pd`sold_out`,
  device: pd`device`,
  max_purchase_per_user: pd`max_purchase_per_user`,
  possible_quantity: pd`possible_quantity`,
  already_possible_quantity_title: pd`already_possible_quantity_title`,
  already_possible_quantity: pd`already_possible_quantity`,
  check_size_guide: pd`check_size_guide`,
  expect: pd`expect`,
  total_count: pd`total_count`,
  left_count: pd`left_count`,
  coming_soon: pd`coming_soon`,
  not_approve: pd`not_approve`,
  end_sale: pd`end_sale`,
  sold_out_products: pd`sold_out_products`,
  only_pc: pd`only_pc`,
  purchased_dtg: pd`purchased_dtg`,
  size_guide: pd`size_guide`,
  product_base_info: pd`product_base_info`,
  check_cart_in: pd`check_cart_in`,
  opt_prod: pd`opt_prod`,
  preparing: pd`preparing`,
  sold_out_size: pd`sold_out_size`,
  quality_modal: {
    title: pd`quality_modal::title`,
    p1: pd`quality_modal::p1`,
    p2: pd`quality_modal::p2`,
    p3: pd`quality_modal::p3`,
    p4: pd`quality_modal::p4`,
  },
  retraction_modal: {
    title: pd`retraction_modal::title`,
    p1: pd`retraction_modal::p1`,
    p2: pd`retraction_modal::p2`,
    li1: pd`retraction_modal::li1`,
    li2: pd`retraction_modal::li2`,
    li3: pd`retraction_modal::li3`,
  },
  status: {
    prohibited: ps`prohibited`,
    checking: ps`checking`,
    public: ps`public`,
    private: ps`private`,
    ready_confirm: ps`ready_confirm`,
    not_confirmed: ps`not_confirmed`,
    not_use_product: ps`not_use_product`,
  },
  end_of_sale: ps`end_of_sale`,
  until_close: ps`until_close`,
  until_open: ps`until_open`,
};
