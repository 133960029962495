import $dataStr from 'fxdom/es/dataStr.js';
import { html, indexBy, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * @param {object} data
 * @param {OmpTagOfDesignCollection[]} data.omp_tags
 * @param data.design_collection_id
 */
export const ompTagEditor = ({ omp_tags, design_collection_id }) => {
  return html`<div class="omp-tag-editor">
    <section>
      <span class="omp-tag-editor__label">태그</span>
      ${ompTagsTmpl(omp_tags)}
    </section>

    <section>
      <form class="omp-tag-editor__form" data-design_collection_id="${design_collection_id}">
        <span class="omp-tag-editor__form-input">
          <label for="omp-tag__input" class="omp-tag-editor__label">태그 추가</label>
          ${OMPCoreAtomTmplS.input({
            name: 'name',
            input_klass: 'omp-tag-editor__tag-input',
            required: true,
            debounce_time: 200,
            placeholder: '태그명',
          })}
          ${OMPCoreAtomTmplS.input({
            name: 'emoji',
            input_klass: 'omp-tag-editor__emoji-input',
            placeholder: '이모지',
          })}
        </span>
        ${OMPCoreAtomTmplS.actionButton({
          klass: 'omp-tag-editor__submit-btn',
          type: 'submit',
          text: '추가',
        })}
        <ul class="omp-tag-editor__auto-complete"></ul>
        <input type="hidden" name="design_collection_id" value="${design_collection_id}" />
      </form>
    </section>
  </div>`;
};

/**
 * @param {OmpTagOfDesignCollection[]} omp_tags
 */
export const ompTagsTmpl = (omp_tags) => {
  return html`
    <div class="omp-tag-editor__tags" data-fx-json="${$dataStr(omp_tags)}">
      ${!omp_tags?.length ? html`<span>등록된 태그가 없습니다.</span>` : strMap(ompTagTmpl)(omp_tags)}
    </div>
  `;
};

/**
 * @param {OmpTagOfDesignCollection} omp_tag
 */
export const ompTagTmpl = ({ omp_tag_id, name, design_collection_id, emoji, is_main }) => {
  return html`
    <span class="omp-tag-editor__tag" data-is_main="${is_main}">
      ${OMPCoreAtomTmplS.removableButton({
        id: omp_tag_id,
        text: (emoji || '') + name,
        data: { omp_tag_id, design_collection_id, name },
      })}
    </span>
  `;
};

/**
 * @param auto_completed_omp_tags
 * @param {object[]} [registered_omp_tags=[]]
 * @return {*}
 */
export const ompTagAutoCompleteItems = (auto_completed_omp_tags, registered_omp_tags = []) => {
  if (!auto_completed_omp_tags?.length) {
    return html`<span>검색 결과가 없습니다.</span>`;
  }

  const registered_omp_tags_index_by = indexBy((omp_tag) => omp_tag.omp_tag_id)(registered_omp_tags);

  return strMap(({ omp_tag_id, name, emoji, is_main }) => {
    const is_already_registered_tag = !!registered_omp_tags_index_by[omp_tag_id];

    return html`<li
      class="omp-tag-editor__auto-complete-item"
      data-omp_tag_id="${omp_tag_id}"
      data-name="${name}"
      data-is_main="${is_main}"
      data-is_already_registered="${is_already_registered_tag}"
      ${emoji ? html`data-emoji="${emoji}"` : ''}
    >
      ${emoji || ''}${name} ${is_already_registered_tag ? html`<span>(이미 등록됨)</span>` : ''}
    </li>`;
  })(auto_completed_omp_tags);
};
