import Sortable from 'sortablejs';
import { $attr, $findAll, $removeAttr, $setAttr } from 'fxdom/es';
import { go, filterL, mapL, takeAll, each, zipWithIndexL, map } from 'fxjs/es';

// container, options
export const initSortableArrange = (container$, options) => {
  const SORTABLE_IDX_ATTR = 'data-sortable-idx';

  const base_sortable_opt = {
    ghostClass: 'sortable_drag_ghost',
    dragClass: 'sortable_drag_on',
    easing: 'cubic-bezier(0.0, 0.0, 0.58, 1.0)',
  };

  const els$ = $findAll(options.draggable, container$);

  // add inital idx
  go(
    els$,
    zipWithIndexL,
    each(([idx, el$]) => {
      $setAttr({ [SORTABLE_IDX_ATTR]: idx }, el$);
    }),
  );

  const sortable = Sortable.create(container$, {
    ...base_sortable_opt,
    ...options,
  });

  return {
    getSortedEls() {
      return go(
        $findAll(options.draggable, container$),
        zipWithIndexL,
        map(([idx, el$]) => ((el$.idx = idx), el$)),
      );
    },

    getUpdatedEls() {
      return go(
        $findAll(options.draggable, container$),
        zipWithIndexL,
        filterL(([cur_idx, el$]) => {
          const prev_idx = $attr(SORTABLE_IDX_ATTR, el$);
          return cur_idx != prev_idx;
        }),
        mapL(([cur_idx, el$]) => ((el$.idx = cur_idx), el$)),
        takeAll,
      );
    },
    destroy() {
      sortable.destroy();
      each((el$) => $removeAttr(SORTABLE_IDX_ATTR, el$), els$);
    },
  };
};
