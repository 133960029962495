import { html } from 'fxjs/es';

export const makeFileItemHtml =
  (is_button = true) =>
  (f) =>
    html`
      <div class="file-item">
        <input type="hidden" name="id" value="${f.id}" />
        <div class="original_name"><a href="${f.url}" target="_blank">${f.original_name}</a></div>
        ${is_button ? '<button type="button" class="remove-file">삭제</button>' : ''}
      </div>
    `;

export const checkMarppleShopProductTmpl = ({ domain_name, name, id, p_name, url }) =>
  domain_name
    ? html`
        <div>
          <span>스토어:</span>
          <a href="/${T.lang}/${domain_name}" target="_blank">${name}</a>
        </div>
        ${id
          ? `<div>
          <span>상품:</span>
          <a href="/${T.lang}/${domain_name}/products/${id}" target="_blank">${p_name || url}</a>
        </div>`
          : ``}
      `
    : '';

export const addSolutionAlertHtml = ({ type, unique_private_key }) =>
  type !== 'internal-regulation'
    ? html`
        <h2>신고 접수가 완료되었습니다.</h2>
        <p>
          스레드 생성이 완료 되었습니다. 신고 접수 관련 크리에이터의 답변 및 회신은 스레드에서만 가능합니다.
        </p>
        <a href="/${T.lang}/@/solution/list/${unique_private_key}" target="_blank">비공개 스레드 바로가기</a>
      `
    : html`
        <h2>신고 접수가 완료되었습니다.</h2>
        <p>내부 규정 위반 여부 확인 후 처리 결과를 등록하신 email로 안내드릴 예정입니다. 감사합니다.</p>
      `;

export const replySolutionAlertHtml = ({ type }) =>
  html`
    <h2>신고 접수에 대한 답변이 완료되었습니다.</h2>
    ${type !== 'internal-regulation'
      ? '<p>해당 상품 판매가 재개되었습니다.</p>'
      : '<p>이의신청 내용을 검토하여 알려드리겠습니다.</p>'}
  `;
