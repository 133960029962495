import { CreatorEditorMarppleEmbedTmplS } from './../Tmpl/module/CreatorEditorMarppleEmbedTmplS.js';

const mui_ref_path = '/modules/Creator/Editor/MarppleEmbed/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: 'e', // tab element id 추가
  el_class: 'marpple_embed', // tab element class 추가
  tag: '', // 커스텀 태
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  template: CreatorEditorMarppleEmbedTmplS.makeManageHtml, // 탭에 들어갈 html 을 그리는 함수

  // makeData(tab) {}, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함. template 함수 인자에 들어갈 값이며 이값은 프론트로 전달됨
};
