import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPReviewDetailTmplS } from './module/OMPReviewDetailTmplS.js';

export const reviewDetailTmplPc = ({
  score,
  is_best = false,
  files,
  acc_purchase,
  is_marpple_picked = false,
  created_at,
  comment,
  sns_url,
  _: { base_product_color, base_product, up_c, user },
} = {}) => {
  const size_info = up_c?._ ? OMPCoreCellTmplS.getSizeInfo(up_c._) : '';

  const category_more = TT('cell::review::category_more', { name: base_product._.cate_item.name });
  const sns = OMPCoreUtilS.getSnsUrl(UtilS.escape(sns_url));

  return html`
    <div class="omp-review-detail">
      <div class="omp-review-detail__body">
        <div class="omp-review-detail__body-info-wrapper">
          <div class="omp-review-detail__body-info">
            ${OMPCoreCellTmplS.reviewStarRatingTmpl({ score })}
            ${OMPCoreCellTmplS.reviewMetaTmpl({
              acc_purchase,
              files,
              is_hot: is_best,
              is_marpple_picked,
              user,
              created_at,
              is_horizontal: true,
            })}
          </div>
          <div class="omp-review-detail__body-content">
            <span class="omp-review-detail__body-description">${UtilS.escape(comment)}</span>
          </div>
          ${sns ? OMPCoreAtomTmplS.snsBadge(sns) : ''}
        </div>
        ${OMPCoreCellTmplS.reviewProductInfoTmpl({
          base_product_color,
          base_product,
          size_info,
          category_more,
        })}
      </div>
      ${files && files.length ? OMPReviewDetailTmplS.reviewFileTmpl(files) : ''}
    </div>
  `;
};
