import { go, html, strMap } from 'fxjs/es';
import { MShopAppCartS } from '../Function/module/MShopAppCartS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { MShopAppComponentsItemTmplS } from '../../../Components/Item/S/Tmpl/module/MShopAppComponentsItemTmplS.js';
import { MShopAppCartTmplS } from './module/MShopAppCartTmplS.js';
import { OrderCustomerOrderS } from '../../../../../Order/CustomerOrder/S/Function/module/OrderCustomerOrderS.js';
import { MShopAppUpItemTmplS } from '../../../UpItem/S/Tmpl/module/MShopAppUpItemTmplS.js';

function cartStoreItem(cart_store_item) {
  const {
    total_products_price,
    total_shipping_price,
    store_name,
    redirect_url,
    post_message,
    delivery_type_grouped_items,
  } = cart_store_item;
  const is_oversea = T.lang !== 'kr';
  return html`
    <div class="cart-store-item">
      <div class="cart-store-item__1">
        ${MShopAppComponentsItemTmplS.cchStoreLogo({
          store_name,
          redirect_url,
          post_message,
        })}
      </div>
      <div class="cart-store-item__2">
        ${go(
          delivery_type_grouped_items,
          strMap(
            (delivery_type_grouped_item) => html`
              <div class="cart-store-item__2__item">
                ${MShopAppCartTmplS.deliveryTypeGroupedItem(delivery_type_grouped_item)}
              </div>
            `,
          ),
        )}
      </div>
      <div class="cart-store-item__3">
        <div class="cart-store-item__price-set">
          ${
            is_oversea
              ? ''
              : html`<span class="cart-store-item__price-set__item">
                    <span class="cart-store-item__price-set__item__text"
                      >${T('mshop_cart_and_checkout::상품금액')}</span
                    >
                    <span class="cart-store-item__price-set__item__price mp_currency"
                      >${PriceS.pricify(total_products_price)}</span
                    >
                  </span>
                  <span class="cart-store-item__price-set__operator">+</span>
                  <span class="cart-store-item__price-set__item">
                    <span class="cart-store-item__price-set__item__text"
                      >${T('mshop_cart_and_checkout::배송비')}</span
                    >
                    <span class="cart-store-item__price-set__item__price mp_currency"
                      >${PriceS.pricify(total_shipping_price)}</span
                    >
                  </span>
                  <span class="cart-store-item__price-set__operator">=</span>`
          }

          <span class="cart-store-item__price-set__item"
            ><span class="cart-store-item__price-set__item__text"
              >${T('mshop_cart_and_checkout::주문금액')}</span
            >
            <span class="cart-store-item__price-set__item__total-price mp_currency"
              >${PriceS.pricify(
                is_oversea ? total_products_price : PriceS.add(total_products_price, total_shipping_price),
              )}
            </span
          >
        </div>
      </div>
    </div>
  `;
}

export const pCreatorCartHtml = (ups) => {
  const { has_issue, has_pbg, cart_store_items } = MShopAppCartS.cartHook(ups);

  const is_oversea = T.lang !== 'kr';
  const marpple_shop_total_price_info = OrderCustomerOrderS.makeTotalPriceInfoWithUps(
    { ups, _en: G._en },
    { selected_country_shipping_cost: { cost: is_oversea ? 0 : undefined } },
  );
  return html`
    <div
      class="mshop-cart ${ups.length === 0 ? 'empty_page' : ''}"
      data-has_pbg="${has_pbg}"
      data-has_sth="${!!cart_store_items.length}"
      data-has_issue="${has_issue}"
    >
      <div class="mshop-cart__empty-wrap">
        <img
          src="//s3.marpple.co/files/u_14345/2021/5/original/97bafe228b83b24e7228a344e0d295a60020bde01.svg"
          alt=""
        />
        <div>${T('Your shopping cart is empty')}</div>
      </div>
      <div class="mshop-cart__body">
        <div class="mshop-cart__body__1">
          ${go(
            cart_store_items,
            strMap(
              (cart_store_item) => html`
                <div class="mshop-cart__body__1__item">${cartStoreItem(cart_store_item)}</div>
              `,
            ),
          )}
        </div>
        <div class="mshop-cart__body__2">
          <div class="cart-up-item-list-price">
            <div
              class="cart-up-item-list-price__up-item-list-price-info-wrapper cart-up-item-list-price__sticky-sidebar"
            >
              ${MShopAppUpItemTmplS.pUpItemListPriceHtml(marpple_shop_total_price_info)}
            </div>
            <button type="button" class="cart-up-item-list-price__to-checkout-btn">
              ${T('mshop_cart_and_checkout::주문서 작성')}
            </button>
            <div class="cart-up-item-list-price__shipping-info" style="display:none;">
              크리에이터 배송 상품의 경우 각 각의 크리에이터가 개별 배송하는 상품이며,<br />배송비는
              크리에이터가 책정한 가격입니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};
