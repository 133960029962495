import $dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const pThumbnailHtml = (n_thumb_info, n_to_detail_url) => {
  const { thumbnail_url, product_face, redirect_to_product_detail } = n_thumb_info;
  return html`
    <div
      class="up-item-thumbnail"
      data-fx-json="${!thumbnail_url && product_face ? $dataStr(product_face) : ''}"
    >
      <a
        href="${UtilS.escape(n_to_detail_url) || UtilS.escape(redirect_to_product_detail)}"
        data-post-message="${$dataStr({
          navigate: {
            sel: 'TabNavigation.HomeStack.UtilScreen',
            params: {
              type: 'sub',
              title: n_to_detail_url ? T('order::Order details') : T('mshop::상품상세'),
              uri: UtilS.escape(n_to_detail_url) || UtilS.escape(redirect_to_product_detail),
            },
          },
        })}"
      >
        ${thumbnail_url
          ? html`
              <img
                src="${UtilImageS.getResizedUrl({ url: thumbnail_url, width: 220, format: 'webp' })}"
                alt=""
              />
            `
          : html` <div class="up-item-thumbnail__canvas-wrapper">
              <canvas></canvas>
              <div class="don_loader_wrap2"><div class="don_loader_img2"></div></div>
            </div>`}
      </a>
    </div>
  `;
};
