import $dataStr from 'fxdom/es/dataStr.js';
import { go, sel, strMap, sumBy } from 'fxjs/es';
import { isCancellableOrder } from '../../../../Creator/Order/S/util.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { rowTo_en } from '../../../../Util/S/LangAndCollaboType.js';

export const makeOrdersHtml = (prjs, user, is_shop) =>
  pug`
    .order_body
      .header_title
        span.title.ff_ccl ${T('order_list::Order Status')}
        ${
          T.lang == 'kr'
            ? pug`
              span.point ${T('My Point')}: 
                span.point2 ${UtilS.commify(user.point)}P`
            : ''
        }                
      .prj_list[_sel="projections"]
        ${
          prjs.length == 0
            ? pug`
              .empty_message
                .img
                .message ${T('order_list::No orders yet')}
              `
            : pug`
              .table_header.pc_v
                span ${T('order_list::Products')}
                span ${T('order_list::Total')}
                span ${T('order_list::Status')}
              ${strMap((prj) => makeOrderItemHtml(prj, is_shop), prjs)}
            `
        }
    `;

export const makeOrderItemHtml = (prj, is_shop) => {
  if (is_shop) {
    prj._.store.domain_name = '@';
  }
  return go(
    prj,
    function (prj) {
      prj._.up = sel('_.ups.0._.up_cs.0', prj);
      const counts = (sumBy((up) => up._.up_cs.length, prj._.ups) || 0) - 1;
      if (counts > 0)
        prj._.up.sub_title = T.lang == 'kr' ? ' 외 ' + counts + '개' : ' & ' + counts + ' other';
      return [
        prj,
        rowTo_en(prj),
        projectionReviewCondition(prj, function () {
          return _p.some(prj._.ups, function (up) {
            return !up.review_id;
          });
        }),
      ];
    },
    ([prj, _en, is_review]) =>
      !prj._.up
        ? ''
        : legacyHtml`
          <div class="prj_item infi_item" _sel="projections->(#${prj.id})" lang="${prj.lang || 'en'}">
            <div class="item_header">
              <span>${moment(prj.ordered_at).format('YYYY-MM-DD HH:mm')}</span>
              <span class="no">${T('Order Number')} ${prj.id}</span>
            </div>
            <div class="item_body">
              <a
                href="/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${prj.id}"
                class="thumb view_detail"
                data-post-message="${$dataStr({
                  screen: {
                    title: T('order::Order details'),
                    type: 'sub',
                    uri: `/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${
                      prj.id
                    }`,
                  },
                })}"
              >
                <span class="img canvas_100">
                  <canvas></canvas>
                </span>
              </a>
              <div class="up_c_info">
                <a
                  href="/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${prj.id}"
                  class="title view_detail"
                  data-post-message="${$dataStr({
                    screen: {
                      title: T('order::Order details'),
                      type: 'sub',
                      uri: `/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${
                        prj.id
                      }`,
                    },
                  })}"
                >
                  <span class="main_title"
                    >${(
                      (prj.store_id ? `${prj._.up._.product_color['name' + G._en]} ` : '') +
                      prj._.up._.product_color._.base_product['name' + _collabo + G._en]
                    ).trim()}</span><span class="sub_title" ${prj._.up.sub_title ? '' : 'hidden'}
                    >&nbsp;${prj._.up.sub_title}</span>
                </a>
                <div class="price">
                  <span class="origin_price mp_currency"
                    >${PriceS.pricify_by(prj['total_price' + _en], _en)}</span
                  >
                </div>
                <div class="status">
                  ${projectionStatusTitle(prj)}
                  ${
                    is_review
                      ? legacyHtml`
                        <div>
                          <a
                            href="/${T.lang}/${
                          prj.store_id ? prj._.store.domain_name + '/' : ''
                        }order/detail/${prj.id}?review=true"
                            class="write_a_review"
                            data-post-message="${$dataStr({
                              screen: {
                                title: T('order::Order details'),
                                type: 'sub',
                                uri: `/${T.lang}/${
                                  prj.store_id ? prj._.store.domain_name + '/' : ''
                                }order/detail/${prj.id}?review=true`,
                              },
                            })}"
                            >${
                              G.collabo_type == 'creator'
                                ? T('review::Support your Sellers')
                                : T('review::Write a Review')
                            }</a
                          >
                        </div>
                      `
                      : ''
                  }
                </div>
                <a
                  href="/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${prj.id}"
                  class="view_btn view_detail pc_v"
                  data-post-message="${$dataStr({
                    screen: {
                      title: T('order::Order details'),
                      type: 'sub',
                      uri: `/${T.lang}/${prj.store_id ? prj._.store.domain_name + '/' : ''}order/detail/${
                        prj.id
                      }`,
                    },
                  })}"
                  >${T('order_list::VIEW')}</a
                >
              </div>
            </div>
          </div>
        `,
  );
};
export function projectionStatus(prj) {
  if (prj.is_cancel_req) return 'canceled';
  if (
    prj.status == 'before_print' &&
    ((collabo_type == 'creator' && !isCancellableOrder(prj.ordered_at)) ||
      sel('_.task.id', prj) ||
      prj.can_not_cancel)
  ) {
    return 'printing';
  }
  return prj.status;
}

export function projectionStatusTitle(prj) {
  const status_match = {
    ordering: T('order::Checking deposit'),
    before_print: T('order::Order Placed'),
    printing: T('order::Processing'),
    before_deliver: T('order::Preparing to Ship'),
    delivering: T('order::Shipped'),
    before_confirm: T('order::Delivered'),
    confirmed: T('order::Delivered2') /*,
    canceled: 'Cancelled'*/,
  };
  if (prj.is_canceled) return T('order::Cancelled');
  if (prj.is_cancel_req) return T('order::Confirming Cancellation');
  return status_match[projectionStatus(prj)];
}

export function projectionReviewCondition(prj, predi) {
  // if (collabo_type == 'creator') return false;

  return (
    _p.contains(['confirmed', 'before_confirm', 'delivering'], prj.status) &&
    !prj.is_cancel_req &&
    !prj.is_canceled &&
    (!predi || predi(prj))
  );
}
