import { pipe, reject, head, map, each, flatMap, go, keys, uniq, curry } from 'fxjs/es';

// object array 를 편하게 aggregate 하는 함수
// { key: aggregate function (a, b) => any }
//
// key 가 없으면 default 함수는 head

// export const aggregate = curry((dict, objs) => {
//   const obj_keys = go(objs, flatMap(keys), uniq);
//
//   return reduce(
//     (obj1, obj2) => {
//       each((key) => {
//         const obj1_has_key = obj1.hasOwnProperty(key);
//         const obj2_has_key = obj2.hasOwnProperty(key);
//         const aggr = typeof dict[key] == 'string' ? helper[dict[key]] : dict[key];
//         if (!obj1_has_key && obj2_has_key) return;
//
//         obj1[key] = aggr ? aggr(obj1[key], obj2[key]) : obj1_has_key ? obj1[key] : obj2[key];
//       }, obj_keys);
//
//       return obj1;
//     },
//     { ...objs[0] }, // 첫 번째거를 복사해서 계속 누적
//     objs.slice(1),
//   );
// });

export const aggregate = curry((dict, objs) => {
  const obj_keys = go(objs, flatMap(keys), uniq);

  const result = {};

  each((key) => {
    result[key] = go(
      objs,
      map((obj) => (obj.hasOwnProperty(key) ? obj[key] : null)),
      dict[key] ||
        pipe(
          reject((a) => a === null),
          head,
        ),
    );
  }, obj_keys);

  return result;
});
