import { html } from 'fxjs/es';
import { cchCartStyleUpItemMain } from './private/cchCartStyleUpItemMain.js';
import { cchStyleOptionList } from './private/cchStyleOptionList.js';
export const cartStyleUpItem = (up_item_cart_style_up) => {
  const { n_option_infos, warning_message, up_c_id, n_text_option_infos, up_item_main_html_info } =
    up_item_cart_style_up;
  return html`
    <div class="cart-style-up-item" data-has_warning="${!!warning_message}" data-up_c_id="${up_c_id}">
      <div class="cart-style-up-item__content-1">
        <div class="cart-style-up-item__content-1__1">${cchCartStyleUpItemMain(up_item_main_html_info)}</div>
        <div class="cart-style-up-item__content-1__2">
          <div class="cart-style-up-item__option">
            <button type="button" class="cart-style-up-item__option__delete-btn up-delete-btn">
              <img
                src="//s3.marpple.co/files/u_193535/2022/10/original/3c280f53fab4154989856ac707f50aa78d3d53c21.svg"
                alt=""
              />
            </button>
            <button type="button" class="cart-style-up-item__option__modify-btn up-modify-btn">
              ${T('mshop_cart_and_checkout::수량 변경')}
            </button>
          </div>
        </div>
      </div>
      <div class="cart-style-up-item__content-2">
        ${cchStyleOptionList({ n_option_infos, n_text_option_infos })}
      </div>
      <div class="cart-style-up-item__content-warning">
        <img
          src="//s3.marpple.co/files/u_193535/2022/10/original/fc5f1bd4455c8fee387645d7c7cddad7df7350401.svg"
          alt="경고"
        />
        <span> ${warning_message} </span>
      </div>
    </div>
  `;
};
