import { each, entries, find, flatMap, go, groupBy, map, pipe, sel, some, sortBy, sumBy } from 'fxjs/es';
import { makeMainName } from '../../../UpItem/S/Function/makeMainName.js';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { makeMpUpSecondName } from '../../../UpItem/S/Function/makeMpSecondName.js';
import { makePbUpSecondName } from '../../../UpItem/S/Function/makePbUpSecondName.js';
import { pMakeOptionInfo } from '../../../UpItem/S/Function/pMakeOptionInfo.js';
import { makeOptionInfoStatus } from '../../../UpItem/S/Function/makeOptionInfoStatus.js';
import { MShopAppUpItemConstantS } from '../../../UpItem/S/Constant/module/MShopAppUpItemConstantS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { DeliveryTypeS } from '../../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';
import { OrderCustomerOrderS } from '../../../../../Order/CustomerOrder/S/Function/module/OrderCustomerOrderS.js';
import { DeliveryTypeConstantS } from '../../../../../DeliveryType/S/Constant/module/DeliveryTypeConstantS.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';

function makeName({
  selected_option_group,
  product_color,
  goods_type_id,
  base_product,
  base_product_color,
  base_product_colors,
  cate_item,
}) {
  const main_name = makeMainName(product_color);
  const second_name = GoodsTypeS.isMps(goods_type_id)
    ? makeMpUpSecondName({
        product_color,

        base_product,

        base_product_color,
        is_color_show: base_product_colors?.length > 1,
        selected_option_group,
      })
    : makePbUpSecondName({ cate_item });
  const hyundai_n_product_second_name = GoodsTypeS.isMps(goods_type_id)
    ? product_color.product_faces2?.hyundai_n_products?.option_to_string || ''
    : '';
  return {
    name: main_name,
    second_name: second_name + hyundai_n_product_second_name,
  };
}

export const cartHook = (
  user_products,
  { is_far = false, selected_country_shipping_cost } = { is_far: false },
) => {
  go(
    user_products,
    each((up) => {
      const base_product_colors = up._.product_color._.base_product._.base_product_colors;
      go(
        up._.up_cs,
        each((up_c) => {
          up_c._.product_color._.base_product._.base_product_colors = base_product_colors;
        }),
      );
    }),
  );
  const store_orders = OrderCustomerOrderS.convertUpsToStoreOrders(user_products, {
    is_far,
    selected_country_shipping_cost,
  });
  let has_issue = false;
  const is_oversea = T.lang !== 'kr';
  const cart_store_items = go(
    store_orders,
    map((store_order) => {
      const delivery_type_grouped_items = go(
        store_order._.shipping_orders,
        groupBy((shipping_order) => shipping_order.delivery_type_id),
        entries,
        map(([delivery_type_id, shipping_orders]) => {
          return {
            delivery_type_id,
            delivery_type_grouped_pre_shipping_orders: go(
              shipping_orders,
              map((shipping_order) => {
                return {
                  shipping_price: shipping_order.shipping_price,
                  additional_shipping_price: shipping_order.additional_shipping_price,
                  is_separate_shipping: shipping_order._.stores_delivery_company.is_separate_shipping,
                  free_shipping_min_price: shipping_order._.stores_delivery_company.free_shipping_min_price,
                  delivery_type_id: shipping_order._.stores_delivery_company.delivery_type_id,
                  is_free_shipping: is_oversea
                    ? false
                    : (shipping_order._.stores_delivery_company.free_shipping_min_price &&
                        shipping_order._.stores_delivery_company.free_shipping_min_price <=
                          shipping_order.shipping_price) ||
                      shipping_order.shipping_price === 0,
                  up_item_cart_style_ups: go(
                    shipping_order._.item_order_up_cs,
                    map((item_order_up_c) => {
                      const { name, second_name } = makeName({
                        selected_option_group: item_order_up_c._.product_color._.selected_option_group,
                        product_color: item_order_up_c._.product_color,
                        goods_type_id: item_order_up_c.goods_type_id,
                        base_product: item_order_up_c._.product_color._.base_product,
                        base_product_color: item_order_up_c._.product_color._.base_product_color,
                        base_product_colors:
                          item_order_up_c._.product_color._.base_product._.base_product_colors,
                        cate_item: item_order_up_c._.product_color._.base_product._.cate_item,
                      });
                      const osp = item_order_up_c._.product_color._.stores_product._.original_stores_product;
                      const goods_type_id = item_order_up_c.goods_type_id;
                      const n_text_option_infos = go(
                        item_order_up_c._.spo_text_option_values,
                        map((spo_text_option_value) => ({
                          option: spo_text_option_value._.spo_text_option['name' + G._en],
                          is_deleted: !!spo_text_option_value._.spo_text_option.deleted_at,
                          value: spo_text_option_value.value,
                        })),
                      );
                      const n_option_infos = go(
                        item_order_up_c._.up_c_ss,
                        map((up_c_s) => {
                          const status = makeOptionInfoStatus({
                            goods_type_id,
                            _is_not_stock: up_c_s._is_not_stock,
                            no_spo_item: !!up_c_s._?.spo_item?.deleted_at,
                            spo_item_stock_quantity: up_c_s._?.spo_item?._?.spo_item_stock?.quantity,
                            quantity: up_c_s.quantity,
                            remaining_limited_quantity: osp.quantity > 0 && osp.quantity - osp.order_count,
                            text_option_changed: go(n_text_option_infos, some(sel('is_deleted'))),
                            discontinued: up_c_s._is_discontinued,
                          });

                          if (status !== MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL)
                            has_issue = true;
                          return {
                            up_c_s_id: up_c_s.id,
                            name: GoodsTypeS.isMps(goods_type_id)
                              ? up_c_s['_name' + G._en]
                              : pMakeOptionInfo(up_c_s._.spo_item.group_names_option_names),
                            quantity: up_c_s.quantity,
                            discounted_price: up_c_s['discounted_price' + G._en],
                            price: up_c_s['price' + G._en],
                            status,
                            warning_message: MShopAppUpItemConstantS.WARNING_MESSAGE[status],
                          };
                        }),
                      );
                      const to_url = `/${T.lang}/${store_order._.store.domain_name}/products/${item_order_up_c._.product_color._.stores_product.original_product_id}?bpc_id=${item_order_up_c.base_product_color_id}&bp_id=${item_order_up_c.base_product_id}&find_plus=1`;
                      return {
                        up_c_id: item_order_up_c.up_c_id,
                        goods_type_id: item_order_up_c.goods_type_id,
                        n_option_infos,
                        n_text_option_infos,
                        warning_message: go(
                          n_option_infos,
                          find((n_option_info) => n_option_info.warning_message),
                          sel('warning_message'),
                        ),
                        up_item_main_html_info: {
                          to_url,
                          post_message: $dataStr({
                            navigate: {
                              sel: 'TabNavigation.HomeStack.UtilScreen',
                              params: {
                                type: 'sub',
                                title: T('mshop::상품상세'),
                                uri: to_url,
                              },
                            },
                          }),
                          thumbnail_url: go(
                            sel('_.product_color.thumbnails.value', item_order_up_c),
                            find(sel('is_thumb')),
                            sel('url'),
                            (url) =>
                              UtilImageS.getResizedUrl({
                                url,
                                format: 'jpg',
                                quality: 90,
                                bg: 'f6f6f6',
                                width: 192,
                              }),
                          ),
                          product_face: sel('_.product_color.product_faces2.value.0', item_order_up_c),
                          name,
                          second_name,
                          discounted_price: go(
                            n_option_infos,
                            sumBy(({ quantity, discounted_price }) => quantity * discounted_price),
                          ),
                          price: go(
                            n_option_infos,
                            sumBy(({ quantity, price }) => quantity * price),
                          ),
                        },
                      };
                    }),
                  ),
                };
              }),
            ),
          };
        }),
        sortBy(({ delivery_type_id }) => {
          return [
            DeliveryTypeConstantS.MARPPLE.id,
            DeliveryTypeConstantS.SELLER.id,
            DeliveryTypeConstantS.DIGITAL.id,
          ].indexOf(delivery_type_id);
        }),
      );
      const shipping_orders = go(
        delivery_type_grouped_items,
        flatMap(
          (delivery_type_grouped_item) =>
            delivery_type_grouped_item.delivery_type_grouped_pre_shipping_orders,
        ),
      );
      return {
        total_products_price: go(
          shipping_orders,
          flatMap((sh_o) => sh_o.up_item_cart_style_ups),
          sumBy((up) => up.up_item_main_html_info.discounted_price),
        ),
        total_shipping_price: go(
          shipping_orders,
          sumBy((o) => o.shipping_price),
        ),
        delivery_type_grouped_items,
        store_name: store_order._.store.name,
        redirect_url: `/${T.lang}/${store_order._.store.domain_name}`,
        post_message: $dataStr({
          navigate: {
            sel: 'TabNavigation.HomeStack.UtilScreen',
            params: {
              type: 'sub',
              title: store_order._.store.name,
              titleImage: store_order._.store.logo_img || '',
              uri: `/${T.lang}/${store_order._.store.domain_name}`,
            },
          },
        }),
      };
    }),
  );

  const flattenUp = pipe(
    flatMap((store_order) => store_order._.shipping_orders),
    flatMap((shipping_order) => shipping_order._.item_order_up_cs),
  );
  return {
    has_issue,
    has_pbg: go(
      store_orders,
      flattenUp,
      some((up) => GoodsTypeS.isPbg(up.goods_type_id)),
    ),
    has_dtg: go(
      store_orders,
      flattenUp,
      some((up) => GoodsTypeS.isDtg(up.goods_type_id)),
    ),
    has_mps: go(
      store_orders,
      flattenUp,
      some((up) => GoodsTypeS.isMps(up.goods_type_id)),
    ),
    has_tpl: go(
      store_orders,
      flattenUp,
      some((up) => GoodsTypeS.isTpl(up.goods_type_id)),
    ),
    has_marpple_shipping: go(
      store_orders,
      flatMap((store_order) => store_order._.shipping_orders),
      some((shipping_order) => DeliveryTypeS.isMarpple(shipping_order.delivery_type_id)),
    ),
    cart_store_items,
  };
};
