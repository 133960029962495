import { pipe, tap } from 'fxjs/es';
import {
  CREATOR_WISH_FRAME,
  CREATOR_WISH_PAGE,
  CREATOR_WISH_TAB,
} from '../../../../../modules/Creator/Cart/S/constant.js';
import { resetUserCounts } from '../../../../../modules/MPay/Cart/F/fs.js';
import { makeWishHtml } from '../../../../../modules/MPay/Cart/S/tmpl.js';

!(function (lo) {
  $.frame.defn_frame({
    frame_name: collabo_type == 'creator' ? CREATOR_WISH_FRAME : 'mp.wish',
    page_name: collabo_type == 'creator' ? CREATOR_WISH_PAGE : 'mp.wish',
  });

  $.frame.defn_page({
    page_name: collabo_type == 'creator' ? CREATOR_WISH_PAGE : 'mp.wish',
    tabs: [
      {
        tab_name: collabo_type == 'creator' ? CREATOR_WISH_TAB : 'mp.wish',
        appended: pipe(
          tap(function (el) {
            // $.off(window, 'resize');
            return _p.go(
              el,
              $.find('.up_c_item'),
              _p.each(function (e) {
                G.mp.maker.draw_product_face2(e, _p.v(box.sel(e), '_.product_color'));
              }),
            );
          }),
          G.mp.cart.refresh_ups('wish'),
        ),
      },
    ],
    appended: __(
      $.on2(
        'click',
        '.up_item .controls .save_cart',
        __(
          _p.v('currentTarget'),
          _p.add_arg(
            G.mp.cart.box_data_from('.up_c_item'),
            _p(_p.im.set, _p, 'type', 'cart'),
            _p(_p.im.unset, _p, '_->product_color->_'),
            _p(_p.im.unset, _p, '_->product_color->product_faces2'),
            _p($.post_load, '/@api/up_c/update'),
          ),
          _p
            .if2(function (el, res) {
              return res.is_failed;
            })(_p.$alert('Fail'), _p.redirect('/wish'))
            .else(
              function (el, user_products) {
                box.set('user_products', user_products);
                return G.mp.cart.render(makeWishHtml, user_products);
              },
              function () {
                $.don_loader_end();
                return resetUserCounts();
              },
            ),
        ),
      ),

      $.on2(
        'click',
        '.up_item .controls .delete',
        __(
          _p.v('currentTarget'),
          _p.tap(function (el) {
            const up_c = box.sel(el);
            const quantity = _sum(_p.v(up_c, '_.up_c_ss'), function (up_c_s) {
              return up_c_s.quantity;
            });

            try {
              if (quantity && typeof gtag == 'function') {
                gtag('event', 'remove_from_cart', {
                  items: [
                    {
                      id: _p.v(up_c, '_.product_color._.base_product.id'),
                      name: _p.v(up_c, '_.product_color._.base_product.name' + _en),
                      list_name: '장바구니',
                      // "brand": "Google",
                      // "category": "Apparel/T-Shirts",
                      // "variant": "Black",
                      list_position: 1,
                      quantity: quantity,
                      price: _p.v(up_c, 'price' + _en),
                    },
                  ],
                });
              }
            } catch (err) {}
          }),
          _p.add_arg(
            G.mp.cart.box_data_from('.up_c_item'),
            _p(_p.im.unset, _p, '_->product_color'),
            _p($.post_load, '/@api/up_c/delete'),
          ),
          function (el, user_products) {
            box.set('user_products', user_products);
            return G.mp.cart.render(makeWishHtml, user_products);
          },
          function () {
            $.don_loader_end();
            return resetUserCounts();
          },
        ),
      ),

      $.on2('click', '.submit.save_cart_all', function () {
        return _p.go(
          box.sel('user_products'),
          _p.reduce(function (up_cs, user_product) {
            return up_cs.concat(user_product._.up_cs);
          }, []),
          function (up_cs) {
            return {
              data: { type: 'cart' },
              up_cs: up_cs,
            };
          },
          _p($.post_load2, '/@api/up_cs/update'),
          _p.redirect('cart'),
        );
      }),
    ),
  });
})({});
