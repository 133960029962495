import { $appendTo, $attr, $closest, $delegate, $el, $find, $on, $remove, $setAttr } from 'fxdom/es';
import { createActionStack } from '../features/bug_tracker.js';

export const showAltPrompt = ($target) => {
  const alt = $attr('alt', $target);

  $.body_fixed(true);
  const $message_box = $el(`
      <div class="don_dialog_msg medium-editor-plugin__alt-prompt" contenteditable="false">
        <div class="content">
          <div class="body">
            <div class="msg">
              <input class="alt input_common" type="text" value="${alt}" placeholder="Description of image">
            </div>
          </div>
          <div class="footer">
            <div class="buttons">
              <button class="btn btn-cancel"> 취소 </button>
              <button class="btn btn-ok"> 확인 </button>
            </div>
          </div>
        </div>
      </div>
    `);

  const save = () => {
    $remove($message_box);
    const alt = $find('input.alt', $message_box).value;
    $.body_fixed(false);
    $setAttr({ alt }, $target);
    createActionStack('set image alt');
  };

  $delegate('click', '.btn-cancel', (e) => {
    $remove($message_box);
    $.body_fixed(false);
  })($message_box);

  $delegate('click', '.btn-ok', (e) => save())($message_box);

  $on('keydown', (e) => (e.code === 'Enter' ? save() : ''))($find('input.alt', $message_box));

  $appendTo($closest('.editor', $target), $message_box);
  $find('.body input', $message_box).focus();
};
