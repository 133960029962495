export function loadIamport() {
  const key = G.collabo_type == 'line' ? 'imp41212001' : 'imp03882726';
  if (window.IMP) {
    IMP.init(key);
  } else {
    _p.retry(
      () => window.IMP,
      () => IMP.init(key),
      _p.noop,
      300,
    );
  }
}
