import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerGuideDigitalGoodsDownMuiF } from '../Mui/module/NewMakerGuideDigitalGoodsDownMuiF.js';
import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';

export const open = (up_id, store_id, dtg_info = { is_new_dtg: false, is_need_update_for_dtg: 'true' }) => {
  if (!up_id) return $.alert('잘못된 요청입니다.');
  return MuiF.openFrame(NewMakerGuideDigitalGoodsDownMuiF.frame, (frame, page, [tab]) => {
    const is_mobile = MShopUtilF.isMobile();
    frame.is_modal = !is_mobile;
    tab.makeData = () => {
      return {
        src: is_mobile
          ? '//s3.marpple.co/files/u_2308668/2023/7/original/b9b58aab428ec4810d6134b6a7fec6b32b2389291.png'
          : '//s3.marpple.co/files/u_2308668/2023/7/original/a876f006511decf5e8e855d18552fbdc7d6989251.png',
        up_id,
        store_id,
        dtg_info,
      };
    };
  });
};
