const UID_PREFIX =
  typeof global != 'undefined'
    ? process.env.NODE_ENV == 'production'
      ? 'mpg_'
      : 'mpg_test_'
    : window.ENV.is_prod
    ? 'mpg_'
    : 'mpg_test_';

const EMCO_PREFIX =
  typeof global != 'undefined'
    ? process.env.NODE_ENV == 'production'
      ? 'EMCO'
      : 'TEST_EMCO'
    : window.ENV.is_prod
    ? 'EMCO'
    : 'TEST_EMCO';

export const genMerchantUid = (prj_id) => `${UID_PREFIX}${prj_id}`;
/*EMCO->EcommerceMarppleCusotmerOrder*/
export const genCustomerOrderMerchantUid = (customer_order_id) => {
  return `${EMCO_PREFIX}${customer_order_id}`;
};

export const merchantUidToCustomerOrderId = (merchant_uid) => {
  return merchant_uid.replace(EMCO_PREFIX, '').split('_')[0];
};

export const merchantUidToProjectionId = (merchant_uid) => merchant_uid.replace(UID_PREFIX, '').split('_')[0];

export const merchantUidToCustomerOrderIdORProjectionId = (merchant_uid) => {
  return merchant_uid.replace(UID_PREFIX, '').replace(EMCO_PREFIX, '').split('_')[0];
};
