!(function () {
  $.frame.defn_frame({
    frame_name: 'mp.contact_us',
    page_name: 'mp.contact_us',
    header_height: 60,
    appended: function () {},
  });

  $.frame.defn_page({
    page_name: 'mp.contact_us',
    tabs: [
      {
        tab_name: 'mp.contact_us',
      },
    ],
  });
})();
