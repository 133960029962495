import { go } from 'fxjs/es';
import { legacyHtml } from '../../../../../Util/S/Function/util.js';
import { wrapEmbedShow, wrapMarppleEmbed } from '../../../S/util.js';

export const makeManageHtml = (embed_html) =>
  legacyHtml`
    <div class="layout">
      <div class="content ${embed_html ? '' : 'empty'}">
        <label class="empty_label">
          <button class="plus_btn" name="upload_thumbnail">
            <img
              src="//s3.marpple.co/files/u_1165777/2020/9/original/81c91fe418fc9ceefcc1c3d951a63e57ca0378f71.png"
              alt=""
            />
          </button>
          <p>셀러들의 마플샵 상품을 추가하여 투데이를 만들어 보세요</p>
        </label>
        <div class="marpple-embed-container">
          <div class="header">
            <span class="title">
              <img
                class="icon-drag"
                src="//s3.marpple.co/files/u_1165777/2020/11/original/92b8f8dc173ae90620b72e431b5ed328b6e4086c1.png"
                alt=""
              />
              드래그하여 원하는 대로 정렬을 변경하실 수 있습니다.</span
            >
            <button class="btn btn-large btn-submit">
              <img
                class="icon-add"
                src="//s3.marpple.co/files/u_1165777/2020/11/original/812a55326289291bf080691808d993b043d9bcfb1.png"
                alt=""
              />
              상품 추가하기
            </button>
          </div>
          <div class="body">${go(embed_html, wrapMarppleEmbed, (s) => wrapEmbedShow(s, true))}</div>
        </div>
      </div>
      <div class="footer">
        <button class="btn btn-cancel-frame btn-large">취소하기</button>
        <button class="btn btn-large btn-submit">저장하기</button>
      </div>
    </div>
  `;
