import { CateListConstantS } from '../../../../../CateList/S/Constant/module/CateListConstantS.js';
import { messages as message } from '../../../../../Creator/Setting/S/messages_id.js';
export const PROPERTY = {
  digital_product_id: 'digital_product_id',
  store_product_id: 'store_product_id',
  product_id: 'product_id',
  sp_cate_list_id: 'sp_cate_list_id',
  sp_cate_item_id: 'sp_cate_item_id',
  thumbnails: 'thumbnails',
  is_public: 'is_public',
  product_name_kr: 'product_name_kr',
  product_name_en: 'product_name_en',
  product_name_jp: 'product_name_jp',
  price: 'price',
  license_type: 'license_type',
  signed_url_keys: 'signed_url_keys',
  stores_products_token_gates: 'stores_products_token_gates',
  product_story: 'product_story',
  is_hyper_link: 'is_hyper_link',
  preview_key: 'preview_key',
  qna_type: 'qna_type',
  qna_link: 'qna_link',
  agree_copyright: 'agree_copyright',
  store_id: 'store_id',
  og_image_url: 'og_image_url',
  is_prohibited: 'is_prohibited',
  is_solution: 'is_solution',
};

export const REQUIRED_PROPERTIES = [
  PROPERTY.thumbnails,
  PROPERTY.product_name_kr,
  PROPERTY.price,
  PROPERTY.signed_url_keys,
  PROPERTY.agree_copyright,
  PROPERTY.qna_link,
];

// TODO 영준 : 하드코딩 되어있는 부분, Prod 배포 전 필수적으로 체크해주어야 한다!!!!
/* 하드코딩 START */
export const DIGITAL_PRODUCT_FEE = 400;
/* TODO 카테고리 하드코딩 */
export const DIGITAL_PRODUCT_CATE_ITEM_ID = {
  WALL_PAPER: 464,
  VOICE_PACK: 465,
  GOOD_NOTE: 466,
  DIGITAL_ASSET: 467,
};
export const DIGITAL_PRODUCT_BP_ID = {
  WALL_PAPER: 6645,
  VOICE_PACK: 6647,
  GOOD_NOTE: 6649,
  DIGITAL_ASSET: 6651,
};
export const FILE_SIZE_LIMIT = 1024 * 1024 * 1024;
export const PREVIEW_DURATION_LIMIT = 60;
export const UPLOAD_FILE_NUMBER_LIMIT = 10;
export const UPLOAD_MESSAGE = {
  upload_complete: '업로드 완료',
  uploading: '업로드 진행 중',
  init: '상품 수정 시 task_stack 초기화',
};
export const LICENSE_MAP = {
  PRIVATE: 'PRIVATE',
  COMMERCIAL: 'COMMERCIAL',
};
export const LICENSE_MESSAGE = {
  [LICENSE_MAP.PRIVATE]: T(
    '구매자만 소장 가능한 파일로 저작권 보호를 받습니다. 무단으로 복제, 배포, 전송할 수 없습니다.',
  ),
  [LICENSE_MAP.COMMERCIAL]: T(
    '구매자는 해당 파일을 저작권 침해가 되지 않는 범위 내에서 상업적인 목적으로 활용할 수 있습니다.',
  ),
};
export const QNA_TYPES = {
  BOARD: 'BOARD',
  SNS: 'SNS',
};
export const PRODUCT_TYPE_MOCK = {
  id: 'ALWAYS',
  name: '상시판매',
  need_spo_producing_period: false,
  show_spo_producing_period: false,
  is_limited_possible: false,
  is_limited: false,
  is_pb_list_show: true,
};
export const SPO_MOCK = {
  stock_type: 'NONE',
};
export const MAX_PURCHASE_PER_USER_MOCK = 1;
/* 하드코딩 END */

/**
 * @typedef {Object} digital_product_info - 디지털 상품 정보 key를 통해 /digital_product/digital_product_info api호출
 * @property {Object} Key
 * @property {string} file_name
 * @property {string} file_size
 * */

/**
 * @typedef {Object} only_front_end_data - front-end 에서만 쓰이는 데이터 객체
 * @property {string} cate_view_name - 선택된 카테고리의 표출 이름
 * @property {string} category_image - 카테고리 선택창 이미지
 * @property {string} decorate_image - 업로드 시 파일 옆에 표출되는 이미지
 * @property {string} accept - 허용되는 파일 확장자
 * @property {string} upload_input_title
 * @property {string} upload_guide_main_message - 업로드 안내 문구
 * @property {string} upload_guide_sub_message - 업로드 안내 sub 문구
 * */

/**
 * @typedef {Object} digital_product
 * @property {(number|null)} store_product_id
 * @property {(number|null)} product_id
 * @property {(number|null)} sp_cate_list_id - 상품이 속한 cate_list_id
 * @property {(number|null)} sp_cate_item_id - 상품이 속한 cate_item_id
 * @property {string[]} thumbnails - 상품 썸네일 목록
 * @property {boolean} is_public - 상품 공개 여부
 * @property {string} product_name_kr - 상품 이름(국문)
 * @property {string} [product_name_en] - 상품 이름(영문)
 * @property {string} [product_name_jp] - 상품 이름(일문)
 * @property {(number|null)} price - 상품 가격
 * @property {string} license_type - PRIVATE:개인소장 | COMMERCIAL:상업적 활용 가능
 * @property {string[]} signed_url_keys - 디지털 상품 접근 키
 * @property {any[]} stores_products_token_gates - 토큰게이트 리스트
 * @property {object} product_story - 상품 스토리
 * @property {boolean} [is_hyper_link] - 하이퍼링크 포함 여부 (현재 굿노트만 적용)
 * @property {string} [preview_key] - 미리보기 접근 키 (현재 보이스팩만 적용)
 * @property {string} qna_type - 1:1 문의 방법
 * @property {string} qna_link - 1:1 문의 주소
 * @property {boolean} agree_copyright - 디지털 저작권 정보 동의
 * @property {any} [files_infos] - 디지털 파일 정보
 * @property {any} [preview_file_info] - 미리보기 파일 정보
 * @property {boolean} [is_prohibited] - 상품 판매 보류 여부
 * @property {boolean} [is_solution] - 신고 상품 여부
 * @property {number} [digital_product_id] - 디지털 상품 id
 * */

export const ONLY_FRONT_END_DATA = {
  /**
   * @type {only_front_end_data}
   * */
  [DIGITAL_PRODUCT_CATE_ITEM_ID.WALL_PAPER]: {
    cate_view_name: message.digital_products.cate_wall_paper,
    category_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/81738237d7ac9e36775ca9a4f374ab44e05c06ca1.png',
    decorate_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/aa8527454de6759f5c7c0bcdf2eb417c569ca27a1.png',
    accept: '.zip,image/jpeg,image/png,image/pjpeg,image/tiff,.gif',
    upload_input_title: '배경화면 파일 업로드',
    upload_guide_main_message: '배경화면 파일을 업로드해 주세요',
    upload_guide_sub_message: `업로드 가능 형식 : ZIP, JPG, PNG, GIF / 파일당 1GB 이하 / 최대 ${UPLOAD_FILE_NUMBER_LIMIT}개 업로드 가능`,
  },
  /**
   * @type {only_front_end_data}
   * */
  [DIGITAL_PRODUCT_CATE_ITEM_ID.VOICE_PACK]: {
    cate_view_name: message.digital_products.cate_voice_pack,
    category_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/4753fa958ecc3ab831a98d10eeb1b67398e86b2d1.png',
    decorate_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/ccf2c3a79f8a4050b06d23259f3a79c67e80f1691.png',
    accept: '.zip,.mp3,.acc,.pdf,.txt,.jpg,.png,.m4a',
    upload_input_title: '보이스팩 파일 업로드',
    upload_guide_main_message: '보이스팩과 대본 파일팩 등을 업로드해 주세요.',
    upload_guide_sub_message: `업로드 가능 형식 : ZIP, MP3, ACC, PDF, TXT, JPG, PNG / 파일당 1GB 이하 / 최대 ${UPLOAD_FILE_NUMBER_LIMIT}개 업로드 가능`,
  },
  /**
   * @type {only_front_end_data}
   * */
  [DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE]: {
    cate_view_name: message.digital_products.cate_good_note,
    category_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/a665fa77e09feada9b447940ad309fd531e232cf1.png',
    decorate_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/aa8527454de6759f5c7c0bcdf2eb417c569ca27a1.png',
    accept: '.zip,.pdf,.png',
    upload_input_title: '굿노트 파일 업로드',
    upload_guide_main_message: '굿노트 템플릿 또는 스티커 파일을 업로드해 주세요',
    upload_guide_sub_message: `업로드 가능 형식 : ZIP, PDF, PNG / 파일당 1GB 이하 / 최대 ${UPLOAD_FILE_NUMBER_LIMIT}개 업로드 가능`,
  },
  /**
   * @type {only_front_end_data}
   * */
  [DIGITAL_PRODUCT_CATE_ITEM_ID.DIGITAL_ASSET]: {
    cate_view_name: message.digital_products.cate_digital_asset,
    category_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/8317c5e7a9ad4550078b9dd493b51518baa6330c1.png',
    decorate_image:
      '//s3.marpple.co/files/u_2308668/2023/4/original/1c9a7f7e54eb83c72cb32b5fda130f1362659b611.png',
    accept:
      '.jpg,.jpeg,.png,.ai,.eps,.svg,.pdf,.epub,.clip,.sut,.abr,.aiff,.aac,.mp3,.vbr,.alac,.wav,.h264,.m4v,.mp4,.mov,.obj,.gltf,.glb,.usdz,.stl,.max,.3ds,.fbx,.stp,.x3d,.skp,.ttf,.otf,.aep,.aepx,.aet,.zip,.rar,.7z',
    upload_input_title: '에셋 파일 업로드',
    upload_guide_main_message: '에셋 파일을 업로드해 주세요.',
    upload_guide_sub_message: `다양한 파일 형식 지원 (ZIP, JPG, PNG, PSD, CLIP, ABR, SUT, OBJ 등) / 파일당 1GB 이하 / 최대 ${UPLOAD_FILE_NUMBER_LIMIT}개 업로드 가능`,
  },
};

/**
 * @param {digital_product} digital_product
 * @returns {digital_product}
 * */
export const DIGITAL_PRODUCT_INTERFACE = ({
  store_product_id,
  product_id,
  sp_cate_list_id,
  sp_cate_item_id,
  thumbnails,
  is_public,
  product_name_kr,
  product_name_en,
  product_name_jp,
  price,
  license_type,
  signed_url_keys,
  stores_products_token_gates,
  product_story,
  is_hyper_link,
  preview_key,
  qna_type,
  qna_link,
  agree_copyright,
  files_infos,
  preview_file_info,
  is_prohibited,
  is_solution,
  digital_product_id,
}) => {
  return {
    store_product_id,
    product_id,
    sp_cate_list_id,
    sp_cate_item_id,
    thumbnails,
    is_public,
    product_name_kr,
    product_name_en,
    product_name_jp,
    price,
    license_type,
    signed_url_keys,
    stores_products_token_gates,
    product_story,
    is_hyper_link,
    preview_key,
    qna_type,
    qna_link,
    agree_copyright,
    files_infos,
    preview_file_info,
    is_prohibited,
    is_solution,
    digital_product_id,
  };
};
/* TODO 카테고리 하드코딩 */
export const WALL_PAPER_STATE = DIGITAL_PRODUCT_INTERFACE({
  [PROPERTY.store_product_id]: null,
  [PROPERTY.product_id]: null,
  [PROPERTY.sp_cate_list_id]: CateListConstantS.DIGITAL_LIST_ID,
  [PROPERTY.sp_cate_item_id]: DIGITAL_PRODUCT_CATE_ITEM_ID.WALL_PAPER,
  [PROPERTY.thumbnails]: [],
  [PROPERTY.is_public]: false,
  [PROPERTY.product_name_kr]: '',
  [PROPERTY.price]: null,
  [PROPERTY.license_type]: 'PRIVATE',
  [PROPERTY.signed_url_keys]: [],
  [PROPERTY.stores_products_token_gates]: [],
  [PROPERTY.product_story]: {},
  [PROPERTY.is_hyper_link]: undefined,
  [PROPERTY.preview_key]: undefined,
  [PROPERTY.qna_type]: QNA_TYPES.BOARD,
  [PROPERTY.qna_link]: '',
  [PROPERTY.agree_copyright]: false,
});

export const VOICE_PACK_STATE = DIGITAL_PRODUCT_INTERFACE({
  [PROPERTY.store_product_id]: null,
  [PROPERTY.product_id]: null,
  [PROPERTY.sp_cate_list_id]: CateListConstantS.DIGITAL_LIST_ID,
  [PROPERTY.sp_cate_item_id]: DIGITAL_PRODUCT_CATE_ITEM_ID.VOICE_PACK,
  [PROPERTY.thumbnails]: [],
  [PROPERTY.is_public]: false,
  [PROPERTY.product_name_kr]: '',
  [PROPERTY.price]: null,
  [PROPERTY.license_type]: 'PRIVATE',
  [PROPERTY.signed_url_keys]: [],
  [PROPERTY.stores_products_token_gates]: [],
  [PROPERTY.product_story]: {},
  [PROPERTY.is_hyper_link]: undefined,
  [PROPERTY.preview_key]: '',
  [PROPERTY.qna_type]: QNA_TYPES.BOARD,
  [PROPERTY.qna_link]: '',
  [PROPERTY.agree_copyright]: false,
});

export const GOOD_NOTE_STATE = DIGITAL_PRODUCT_INTERFACE({
  [PROPERTY.store_product_id]: null,
  [PROPERTY.product_id]: null,
  [PROPERTY.sp_cate_list_id]: CateListConstantS.DIGITAL_LIST_ID,
  [PROPERTY.sp_cate_item_id]: DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE,
  [PROPERTY.thumbnails]: [],
  [PROPERTY.is_public]: false,
  [PROPERTY.product_name_kr]: '',
  [PROPERTY.price]: null,
  [PROPERTY.license_type]: 'PRIVATE',
  [PROPERTY.signed_url_keys]: [],
  [PROPERTY.stores_products_token_gates]: [],
  [PROPERTY.product_story]: {},
  [PROPERTY.is_hyper_link]: false,
  [PROPERTY.preview_key]: undefined,
  [PROPERTY.qna_type]: QNA_TYPES.BOARD,
  [PROPERTY.qna_link]: '',
  [PROPERTY.agree_copyright]: false,
});

export const DIGITAL_ASSET_STATE = DIGITAL_PRODUCT_INTERFACE({
  [PROPERTY.store_product_id]: null,
  [PROPERTY.product_id]: null,
  [PROPERTY.sp_cate_list_id]: CateListConstantS.DIGITAL_LIST_ID,
  [PROPERTY.sp_cate_item_id]: DIGITAL_PRODUCT_CATE_ITEM_ID.DIGITAL_ASSET,
  [PROPERTY.thumbnails]: [],
  [PROPERTY.is_public]: false,
  [PROPERTY.product_name_kr]: '',
  [PROPERTY.price]: null,
  [PROPERTY.license_type]: 'PRIVATE',
  [PROPERTY.signed_url_keys]: [],
  [PROPERTY.stores_products_token_gates]: [],
  [PROPERTY.product_story]: {},
  [PROPERTY.is_hyper_link]: undefined,
  [PROPERTY.preview_key]: undefined,
  [PROPERTY.qna_type]: QNA_TYPES.BOARD,
  [PROPERTY.qna_link]: '',
  [PROPERTY.agree_copyright]: false,
});
