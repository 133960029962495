export default {
  home: {
    home_1_0: 'Available for single-item orders!',
    home_1_1: 'Create group goods at reasonable prices',
    home_1_2: 'Create group goods \rat reasonable prices',
    home_2_1: 'Products that are frequently repurchased!',
    home_2_2: 'Read more',
    home_3_1: 'Real-time reviews from Marpplers',
    home_4_1: 'New arrivals just in!',
    home_4_2: 'Hot Custom Trends',
    home_5_1: 'High-quality brands carefully selected by Marpple',
    home_6_1: 'Popular customs these days, you can do it too!',
    home_7_1: '{{cate_list_name}} Read more',
  },

  keyword: {
    kwd_new: '{{month}}\nNew Arrivals',
    kwd_best: 'Marpple Best',
    kwd_1_1: 'Group Order',
    kwd_1_2: '1 Piece \nOnwards',
    kwd_1_3: 'Company Kit',
    kwd_1_4: 'Fan Goods',
    kwd_1_5: 'Promotion \nProduct',
    kwd_1_6: 'ESG Goods',
    kwd_1_7: 'Acryl Goods',
    kwd_1_8: '싱글 스티커',
    kwd_1_9: '떡메모지',
    kwd_1_10: 'Travel Goods',
    kwd_1_11: 'Phone Cases',
    kwd_1_12: 'Planner Decoration',
    kwd_1_13: 'Desk Decor',
  },
  url: {
    url_1: '/search/products?q=popular%20items%20for%20group%20orders',
    url_2: '/search/all?q=%EC%97%AC%ED%96%89',
    url_3: '/search/all?q=%EB%8D%B0%EC%8A%A4%ED%81%AC%ED%85%8C%EB%A6%AC%EC%96%B4',
    url_4: '/search/all?q=%EB%8D%B0%EC%8A%A4%ED%81%AC%ED%85%8C%EB%A6%AC%EC%96%B4',
    url_5: '/search/all?q=Recyclable%20Plastic%20Keychains',
  },

  navigation: {
    navigation_01: ' USD~',
    navigation_02: 'All Products',
    navigation_03: ' Category',
  },

  pro_editor: {
    alert: {
      title: {
        file_size: 'Please check the file size.',
        file_save: 'Please check the saving method.',
        template_use: 'Use only the template we provide.',
        layer: 'Please check the layers.',
        layer_stand: "Please check the 'stand' layer.",
        layer_ring: "Please check the 'ring' layer.",
        layer_cut: "Please check the 'cut' layer.",
        layer_cut_not_single: 'Please check the cutting line',
        layer_cut_not_path_type: 'Please check the cutting line',
        layer_cut_not_closed: 'Please check the cutting line',
        art_board: 'Please check the artboard.',
        download: 'An error occurred during the file download.',
        unknown: 'Unknown error occurred.<br>Please contact customer support.',
        dev: 'Error during file analysis.<br>Please contact customer support.',
      },
      text: {
        file_size: ' or smaller SVG file can be uploaded.',
        file_save: "When saving the file,<br>uncheck 'Responsive' in SVG Options.",
        template_use:
          'Download the template, design and upload it.<br>SVG files that don’t comply with the guide cannot be uploaded.',
        layer:
          'Adding or deleting layers, or changing layer names<br>arbitrarily will prevent the file from being uploaded.',
        layer_stand:
          'Adding or deleting the default rectangular path or adding<br>other elements will prevent the file from being uploaded.',
        layer_ring:
          'Adding, deleting, transforming the default ring path, or<br>adding other elements will prevent the file from being uploaded.',
        layer_cut:
          'The cutting line must be connected without interruptions as<br>1 continuous line, and if the cutting line is not created,<br> the file will not be uploaded.',
        layer_cut_not_single:
          'Please include only 1 cutting line, and if there are 0<br>or more than 2, the file will not be uploaded.',
        layer_cut_not_path_type:
          'Please change the cutting line to a compound path.<br>In Illustrator, right-click on the cutting line<br>→  Make Compound Path',
        layer_cut_not_closed: 'Please connect the cutting line into 1 unbroken line.',
        art_board:
          'The provided artboard size cannot be changed.<br>Ensure all designs fit within the artboard.',
      },
    },
  },

  review: {
    review_01: 'Real-time reviews from marpplers',
    review_02: 'View collected photo reviews',
    review_03: 'All Products',
    review_04: 'Read More',
    review_05: 'More Review ({{count}})',
    detail: {
      detail_01: 'Review details',
    },
    write: {
      write_01: 'Write a review',
      write_02: 'Review submission complete',
      write_03: 'Please write a product review',
      write_04: 'We appreciate your review on the product you customized at Marpple ',
      write_05: 'SNS Share',
      write_06:
        'Post a photo review on your SNS with the hashtags #마플 #마플커스텀 #내가만든커스텀. Additional points will be rewarded upon selection! No points will be given for posts without hashtags, incorrect links, or private accounts.',
      write_07: 'Please enter the link shared on your SNS.',
      write_08: 'Your review may be used as promotional content for Marpple. (Required)',
      write_09: 'How was the product? Please leave a rating.',
      write_10: 'Thank you for writing a review.',
      write_11: '(At least 5 characters required)',
      write_04_01: "We'll reward you with points for your great review!",
      write_06_01:
        'Post a photo review on your SNS with the hashtags #마플 #마플커스텀 #내가만든커스텀.  Additional points will be rewarded upon selection! No points will be given for posts without hashtags, incorrect links, or private accounts.',
      write_08_01: 'Your review may be used as promotional content for Marpple. (Required)',
      write_12: 'If you leave a review, the status will be changed to “Delivery complete”',
      write_13: 'No. {{no}}',
    },
    review_06: 'Hot reviews now',
    review_07: 'See all reviews',
    review_08: 'Write a review now and get points 🎉',
  },
  cell: {
    review: {
      read_more: 'Read more',
      category_more: 'Read more of {{name}}',
      size_info: 'Purchased {{size}}',
      detail_review: 'Detailed review',
      more_products: 'Also viewed by other customers',
      more_products_mo: 'Also viewed <br> by other customers',
      customizing: 'Customizing',
      view_more_products: `View more {{cate_list_name}}`,
      latest_review: 'Latest Reviews',
    },
    product: {
      prod_1_1: '평점',
      prod_1_2: 'Review',
      prod_1_3: 'Available for single-item orders!',
      prod_1_4: '{{count}}장 마다 추가로 {{price}}원씩 할인',
      prod_1_5: '{{n}}EA',
      prod_1_6: '{{n}}EA or more',
      prod_1_7: '{{n}}% Discount',
      prod_1_8: 'Price',
      prod_1_9: '{{n}}+ units available',
      prod_1_10: 'For inquiries',
    },
  },
  search: {
    search_01: 'Easily find over 2,000 custom products',
    search_02: 'Recommended search words',
    search_03: 'All customizations at Marpple',
    search_04:
      'There are <span style="color: #ff6b00">{{count}}</span> search results{{- br}}for <span style="color: #ff6b00">{{keyword}}</span>.',
    search_07: 'Recent Searches',
    search_09:
      'Please check if your search term is accurate.{{- br}}Try entering the product name instead of specific words.',
    search_10: 'There are no recent search histories.',
    search_11: 'Integrated',
    search_12: 'Product',
    search_13: 'Contents',
    search_14: 'Review',
    search_15: 'More Products',
    search_17: 'Real-time reviews from marpplers',
    search_16: 'Custom know-how',
    search_18: 'No products.',
    search_19: 'No content.',
    search_20: 'No reviews.',
  },
  common: {
    show_all: 'View all',
    remove_all: 'Clear all',
    currency: '${{price}}',
    quantity: '{{quantity}} Qty',
    view_more: 'Read more',
    delete: 'Delete',
    review_count: 'Review {{count}}',
    show_all_name: '{{name}}',
  },
  omp_layout: {
    left: {
      all_product: 'All Product',
      left_01: 'Make from 1EA',
      left_01_mo: 'Make it<br/>from 1EA',
      left_02: 'New / Best',
      left_03: 'All Products',
      left_04: 'Package/Kit Consult',
      left_04_mo: 'Package/Kit Consult',
      left_05: 'Reviews',
      left_06: 'Custom Know-How',
      left_07: 'Review',
      left_08: 'Promotional Products',
      left_08_01: 'Bulk Order',
      left_09: 'Portfolio',
      sub_01: 'Sign up',
      sub_02: 'Login',
      sub_03: '1:1 Inquiry',
      sub_04: 'Customer Service',
      sub_05: 'About',
      sub_06: 'My Page',
      sub_07: 'Logout',
      sub_08: '',
    },
    bottom_bar: {
      txt_01: 'Home',
      txt_02: 'Search',
      txt_03: 'Bulk Order',
      txt_04: 'Review',
      txt_05: 'Mypage',
    },
    mp_title: 'MARPPLE CUSTOM {{cate_list_name}}',
    title: 'MARPPLE',
    sub_menu_all: 'All Products',
    sub_menu_all_mo: 'All',
    temp_cate_list_name: 'Digital/Tech',
  },
  auth: {
    login: {
      login_01: 'Login',
      login_02: 'Email',
      login_03: 'Password',
      login_04: 'Find ID',
      login_05: 'Reset password',
      login_01_01: 'Facebook Login',
      result: 'Please wait a moment!',
      welcome: 'Nice to meet you!',
      welcome_message:
        'MCO account registration is complete.<br/>From unique custom goods to group products for your brand,<br/>create everything on Marpple!',
      welcome_message_sns:
        'MCO account connection is complete.<br/>From unique custom goods to group products for your brand,<br/>create everything on Marpple!',
      modal: {
        title: 'Login to continue',
        description: 'MCO members enjoy exclusive benefits!',
        signup: 'Sign up',
        signin: 'Login',
      },
      error: {
        title: 'An error occurred during login.',
        description: 'Please try again later<br/>or contact customer service.',
        contact: 'Customer Service 1566-5496',
      },
      result_description: 'Redirecting to the requested page.',
    },
    signup: {
      signup_01: 'Sign up',
      signup_02: 'Name',
      signup_03: 'Email',
      signup_04: 'Password (10~64 characters)',
      signup_05: 'Agree to the terms of service (Required)',
      signup_06: 'Agree to the collection and use of personal information (Required)',
      signup_07: 'I am over 14 years old. (Required)',
      signup_08: 'Consent to receive email marketing (Optional)',
      signup_09: 'View',
      signup_10: '',
      signup_01_01: 'Start with Facebook',
      signup_11: 'Agree with all',
    },
    reset_password: {
      reset_password_01: 'Password Reset',
      reset_password_02: 'Email',
      reset_password_03: 'Please enter your email address.',
      reset_password_04: 'Send password reset mail',
      reset_password_05: 'Send password change mail',
      reset_password_06:
        'An email has been sent to the address you entered with instructions on how to change your password. <br>If the guide email does not appear within a few minutes, please check your spam folder.',
      reset_password_07: '',
      reset_password_08: 'Password',
      reset_password_10: '10+ chars with letters, numbers, and symbols',
      reset_password_11: 'Confirm password',
      reset_password_12: 'Please re-enter your password.',
    },
    find_id: {
      find_id_02: 'Mobile Phone Authentication',
      find_id_03: 'Name',
      find_id_04: 'Please enter your name.',
      find_id_05: 'Contact',
      find_id_06: 'Please enter your contact information.',
      find_id_07: 'Request Authentication Number',
      find_id_08: 'Please enter the 6-digit authentication code.',
      find_id_01: 'Find ID',
      find_id_09: 'Resend Authentication Code',
      find_id_10: 'Find ID Results',
      find_id_11: 'Here is a list of IDs that match your information.',
      find_id_12: 'No Email',
      find_id_13: 'Facebook Registered Account',
    },
    email_verify: {
      email_verify_01:
        'We have sent you a verification email. Please check your email and click on the verification button to complete the verification.',
      email_verify_02: 'Did you not receive the verification email?',
      email_verify_03: 'Resend Verification Email',
      email_verify_04: 'Email Verification',
      email_verify_05: 'Please check your email.',
      email_verify_06: "If it doesn't appear within a few minutes, please check your spam folder.",
      email_verify_07: 'Welcome! <br>Your email verification is complete.',
      email_verify_08: 'Your email has not been verified.',
    },
    need_verify: {
      need_verify_01: 'Click the verify button to complete the verification.',
      need_verify_02: 'Email verification is required.',
      need_verify_03: 'Change Email',
    },
    // TODO @kjj 이메일 변경 영문 번역 누락되어 임시로 넣음
    // 재요청 후 수정 예정
    change_email: {
      change_email_01: 'Change Email',
      change_email_02: 'Your Email',
      change_email_03: 'Please enter your email address.',
      change_email_04: 'Please enter new email address.',
      change_email_05: 'Change Email',
      change_email_06: 'Email Changed',
    },
    error: {
      login_001_title: 'state와 session_id가 일치하지 않는 경우',
      login_001_message: 'state와 session_id가 일치하지 않는 경우',
      login_002_title: '인증서버에서 email, password를 사용한 로그인이 실패한 경우',
      login_002_message: '인증서버에서 email, password를 사용한 로그인이 실패한 경우',
      login_003_title: '로그인이 필요하지만 로그인을 하지 않은 경우',
      login_003_message: '로그인이 필요하지만 로그인을 하지 않은 경우',
      login_004_title: '로그인을 한 상태이지만 존재하지 않는 user 인 경우',
      login_004_message: '로그인을 한 상태이지만 존재하지 않는 user 인 경우',
      signup_001_title: 'Sign-up Failed',
      signup_001_message:
        'Unable to register due to an unexpected error.<br/>If the issue persists,<br/>contact <a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a>.',
    },
    cart: {
      confirm: {
        title: 'Item added to your cart',
        content: 'Would you like to view your cart?',
        ok: 'Go to cart',
        cancel: 'No',
      },
    },
  },
  error: {
    common: {
      error_01: 'An error has occurred.',
    },
    auth: {
      error_01:
        'Please enter more than 10 characters. / English, numbers, and special characters can be used.',
      error_03: 'You cannot excessively use the same character consecutively.',
      error_02: 'For security reasons, you cannot use consecutive characters.',
      error_04: 'Please set up a combination of English, numbers, and special characters.',
      error_05: 'please check the email format.',
      error_06: 'Please enter your name.',
      error_07: 'Please agree to the terms of service.',
      error_08: 'Please agree to the collection and use of personal information.',
      error_09: 'Only those aged 14 and over can register.',
      error_06_01: 'The name cannot include special characters.',
      error_10: 'The ID cannot be included in the password.',
      error_11: 'This is a deleted account.',
      error_12: 'This ID is already registered.',
      error_13: 'Sign up failed',
      error_14: 'Incorrect email or password.',
      error_15: 'Password Reset Unavailable',
      error_16: 'Unable to send password reset email. <br/>Please check your email address.',
      error_17: 'Failed to send password reset email',
      error_18: 'You cannot resend the password reset email within 1 minute. <br/>Please try again later.',
      error_19: 'No code has been generated.',
      error_20: 'Email sending error',
      error_21: 'The email was not sent. <br/>Please try again later.',
      error_22: 'Please enter your password.',
      error_23: 'The passwords do not match.',
      error_25: 'The password is empty.',
      error_24: 'Password confirmation is empty.',
      error_26: 'You cannot use a password <br/> that is the same as your current password.',
      error_15_01: 'The verification code is not valid.',
      error_15_02: 'No account matches the information you entered.',
      error_27: 'Password Reset Complete',
      error_27_01: 'Password changed',
      error_28: 'Unable to send verification code',
      error_28_01: 'Cannot send verification code <br/> Please check your contact details.',
      error_29: 'Please enter the 6-digit verification code.',
      error_30: 'This is not a valid phone number format.',
      error_31: 'Unable to find the ID.',
      error_32: 'Resending of verification code not possible',
      error_33: 'Transmission failed.',
      error_33_01: 'Please try again later.',
      error_34: 'Verification failure',
      error_34_01: 'The verification code does not match.',
      error_35: 'Facebook login loading is slow. Please try again later.',
      error_36: 'Please select an email.',
    },
    code: {
      code_401: 'Login is required.',
      code_400: 'Invalid request.',
    },
    review: {
      write_01: 'The image file available for uploading is JPEG, PNG, and GIF.',
      write_02: 'Upload less than {{max}} pages.',
      write_03: 'The file size is too large.<br />The file should be smaller than {{mb}}MB.',
      write_04: 'Enter the field with at least 5 letters.',
      write_05: 'Enter the field with less than 2,000 letters.',
      write_06: 'Please agree to the terms of using reviews.',
      write_07: 'The SNS address is wrong. Please check on that.',
      write_08: 'Purchase history cannot be verified.',
      write_09: 'The data is not valid.',
    },
    order: {
      order_01: 'Purchase history cannot be verified.',
    },
    wow: {
      purchase1: '',
    },
    general: {
      '제작 진행중인 상품이 있어 취소를 요청할 수 없습니다.오른쪽 하단 웹챗이나 고객센터(1566-9437)로 문의주세요.':
        '',
    },
  },
  product: {
    list: {
      list_1_1: 'Brand',
      list_2_1: 'Color',
      list_3_1: 'Size',
      list_4_1: 'Our Picks',
      list_5_1: 'Low to High',
      list_6_1: 'High to Low',
      list_7_1: 'Select Filters',
      list_8_1: 'Try customizing&nbsp;{{- checked_cate_list_name}}',
      list_9_1: 'All',
      list_10_1: 'Marpple{{name}} No.1',
      list_11_1: 'Sales 9,999+',
      list_11_2: '1~2 Tone Printing',
      list_12_1: 'From ${{price}}',
      list_13_1: '${{price}}',
      list_14_1: 'Show Result',
      list_15_1: 'Select a category',
      list_16_1: 'Filter',
      list_17_1: 'No products matching the conditions.',
      list_18_1: 'Please select the filter you want.',
      list_19_1: 'Show Results',
      list_20_1: 'Clear',
    },
    new_best: {
      new_best_1_1: 'Customize this product',
      new_best_2_1: 'Experience new custom',
      new_best_3_1: 'Can customize this product, too',
      new_best_4_1: 'Most customized these days',
      new_best_5_1: 'The best items for customization',
      new_best_6_1: 'New',
      new_best_7_1: 'Best',
      new_best_8_1: 'Group Best',
      new_best_8_2: 'Recommended Items for Group Customers',
      new_best_8_3: 'Top Picks for Group Customers',
    },
    custom: {
      start: 'Start Creating',
    },
  },
  my_page: {
    layout: {
      layout_01: 'My page',
    },
    order: {
      order_00: 'All',
      order_01: 'Purchase history',
      order_02: 'Payment confirmation',
      order_03: 'Your order has been received. It will proceed to the next step after payment confirmation.',
      order_04: 'Payment completed',
      order_05: 'Deposit has been confirmed.',
      order_06: 'Preparing for production',
      order_07: 'We are confirming your custom order and preparing for production.',
      order_08: 'In production',
      order_09: 'Your custom order is in production.',
      order_10: 'Preparing for shipment',
      order_11: 'We are preparing your product for shipment.',
      order_12: 'In transit',
      order_13: 'Your product has been dispatched and is on its way to you.',
      order_14: 'Delivered',
      order_15:
        'Your product has been delivered. You can apply for an exchange / return within 7 days including the delivery completion date.',
      order_16: 'Orders from the past month',
      order_17: 'Order date / Order number',
      order_18: 'Purchase product information',
      order_19: 'Amount',
      order_20: 'Order status',
      order_21: 'Purchase',
      order_22: 'There is no purchase history in the past month.',
      order_01_01: 'Cancel Order',
      order_01_02: 'Request for order cancellation',
      order_01_03: 'Order has been canceled.',
      order_23: 'You have not ordered any product.',
      order_23_01: 'There are no {{status}} products.',
      order_24: 'Confirming payment.',
      order_25: 'Preparing for production.',
      order_26: 'In production.',
      order_27: 'Preparing for shipment.',
      order_28: 'In transit.',
      order_29: 'Delivery completed.',
      order_30: 'Order number {{order_id}}',
      detail: {
        detail_01: 'Step {{step}}',
        detail_02: 'Change payment method',
        detail_03: 'Cancel order',
        detail_04: 'Pay additional charges',
        detail_05: 'Order product information',
        detail_07: 'Purchase product information',
        detail_06: 'Size',
        detail_08: 'Color',
        detail_09: 'Quantity',
        detail_10: 'Amount',
        detail_11: 'No.{{up_c_id}}',
        detail_12: 'Print: {{print_face}}',
        detail_13: 'Download Order as Excel',
        detail_14: 'Print Statement',
        detail_15: 'Production request details',
        detail_16: 'Shipping information',
        detail_17: 'Total payment amount',
        detail_18: 'Payment information',
        detail_19: 'No special requests.',
        detail_20: 'Total product price',
        detail_21: 'Total shipping fee',
        detail_21_01: 'additional fare for remote areas',
        detail_22: 'Coupon',
        detail_23: 'Points',
        detail_24: 'Additional amount',
        detail_25: 'Additional shipping fee',
        detail_26: 'Final payment amount',
        detail_27: 'Order cancellation amount',
        detail_28: 'Bundle shipping fee',
        detail_29: 'Order Quantity',
        detail_25_01: 'Additional shipping discount',
        detail_30: 'Payment being checked',
        detail_31: 'Payment method',
        detail_32: 'Approved time',
        detail_33: 'Payment amount',
        detail_34: 'Expected payment amount',
        detail_35: 'Evidence',
        detail_36: 'Paid Price',
        detail_37: 'Unpaid Amount',
        detail_38: 'Refunded points',
        detail_39: 'Refund Price',
        detail_24_01: 'Additional discount',
        detail_40: 'Parcel Receipt',
        detail_41: 'Hand to Hand Express Delivery',
        detail_42: 'Bundle delivery',
        detail_43: 'Pickup in store',
        detail_44: 'Cancel request is fail',
        detail_45: 'Cancel request is success.',
        detail_46:
          'You have pay an unpaid balance due to the order has been changed.<br/>Will you pay it now?',
        detail_12_01: 'Print',
        detail_47: 'Purchase History Details',
        detail_48: 'File',
        detail_49: 'Option',
        detail_50:
          'Cancel request is success.<br>It may actually take up to 1 to 2 weeks<br>for the refund to be deposited.',
        detail_51: 'Refund fee',
      },
      order_31: 'Repurchase',
      order_32: 'Write a review',
      order_29_01: 'Order cancelled',
      order_29_02: 'Order cancellation being checked',
      order_33: 'Order number',
      order_34: '',
      order_35: 'Order date {{order_date}}',
      order_36: 'File',
      order_37: 'Print',
      order_38: 'Color',
      order_39: 'Your order has been received. It will proceed to the next step after payment confirmation.',
      order_40: 'We are confirming your custom order and preparing for production.',
      order_41: 'Your custom order is in production.',
      order_42: 'We are preparing your product for shipment.',
      order_43: 'Your product has been dispatched and is on its way to you.',
      order_44:
        'Your product has been delivered. You can apply for an <a class="underline" href="/en/help_center?type=buying_shipping&hg_id=3">exchange / return</a> within 7 days including the delivery completion date.',
      order_45: 'Individual Cancellation',
      order_46:
        'The purchase is not possible because the design has been modified through customer service. Please use the original product, which can be repurchased.',
    },
    wish: {
      wish_01: 'Saved items',
      wish_02: 'Items for later',
      wish_03: 'My saved items',
      wish_04: "You don't have any items for later.",
      wish_05: 'Try saving items that suit your taste and enjoy your own custom experience.',
      wish_06: 'My saved items',
      wish_07: "You don't have any saved items.",
      wish_08: 'Try saving items that suit your taste and enjoy your own custom experience.',
      wish_09: 'How about these items?',
      wish_10: 'Add all to cart',
      wish_11: 'Add to cart',
    },
    point: {
      point_01: 'Points',
      point_02: 'You currently have points.',
      point_03: 'How to earn points',
      point_04: '1% of the purchase amount is credited after the purchase is confirmed.',
      point_05: 'Up to 300P is awarded for writing a review.',
      point_06: 'An additional 500P is awarded for sharing on SNS.',
      point_07: 'Points policy',
      point_08: 'There is no minimum amount to use points. They can be used from 1 won.',
      point_09: 'For more details, please check {{- FAQ}}.',
      point_10: 'Points earned and used',
      point_11: 'Date',
      point_12: 'Details of points earned and used',
      point_13: 'No record of points earned and used.',
    },
    my_review: {
      my_review_01: 'My reviews',
      my_review_02: 'Reviews available for writing',
      my_review_03: 'My written reviews',
      my_review_04: 'No reviews available to write.',
      my_review_05: 'No reviews have been written.',
    },
    my_info: {
      my_info_01: 'Personal information',
      my_info_02: 'Basic member information',
      my_info_03: 'Email',
      my_info_04: 'Change email',
      my_info_05: 'Password',
      my_info_06: 'Receive password change email',
      my_info_07: 'Points',
      my_info_08: 'Receive text updates',
      my_info_09: 'Email marketing consent (optional)',
      my_info_10: 'Yes',
      my_info_11: 'No, thank you',
      my_info_12: 'Additional member information',
      my_info_13: 'Nickname',
      my_info_14: 'Please enter a nickname.',
      my_info_15: 'Date of Birth',
      my_info_16: 'YYYYMMDD',
      my_info_17: 'Name',
      my_info_18: 'Mobile phone',
      my_info_19: 'Please enter only numbers without a hyphen.',
      my_info_20: 'Gender',
      my_info_21: 'Male',
      my_info_22: 'Female',
      my_info_23: 'Save',
      my_info_24: 'Delete account',
      my_info_25: 'Please enter your name.',
      my_info_26: 'No user info. Please log in.',
      my_info_27: 'Changed.',
      my_info_28: 'The account was not deleted. Please try again.',
      my_info_29: 'Are you sure you want to delete your account?',
      my_info_30: 'Save failed',
    },
    landing: {
      landing_01: '<span>{{name}},</span><span>Welcome 👋</span>',
      landing_02: 'Change personal information',
      landing_03: 'Purchase history',
      landing_04: '{{count}}',
      landing_05: 'Points',
      landing_06: '{{point}} P',
      landing_07: 'Reviews',
      landing_08: '{{count}} / <span style="color: #858585">{{all_count}}</span>',
      landing_09: 'Saved items',
      landing_10: '{{count}}',
      landing_11: 'You can customize this item too',
      landing_12: '1:1 Inquiry',
      landing_13: 'Personal Information',
      landing_14: 'Customer Service',
      landing_15: 'Logout',
      landing_16: 'My Shopping Info',
      landing_17: 'Customer Services',
      landing_18: '',
      landing_19: 'Cart',
      landing_20: '{{count}}',
      landing_21: 'Canceled',
    },
  },
  cart: {
    cart_01: 'Cart',
    cart_02: 'Fill in the order form',
    cart_03: 'Order completed',
    cart_04: 'Date / Product Number',
    cart_05: 'Product Information for Purchase',
    cart_06: 'Size / Quantity',
    cart_07: 'Amount',
    cart_08: 'Edit',
    cart_09: 'No. {{no}}',
    cart_10: 'None',
    cart_11: 'Add a different color',
    cart_12: 'Order Quantity',
    cart_13: 'Total product price',
    cart_14: 'Total shipping fee',
    cart_15: 'Estimated Payment Amount',
    cart_16: 'Fill in the order form',
    cart_17: 'Items without selected size and quantity exist.',
    option: {
      option_01: 'How many do you need?',
    },
    cart_18: 'You have no items in your cart.',
    cart_19: 'lease fill your empty shopping cart!',
    cart_20: 'Change option',
    cart_21: 'Purchase later',
    cart_22: 'Delete',
    cart_23: 'Sold out',
    cart_24: 'Discontinued',
    cart_25: 'The product is out of stock.',
    cart_26: 'The option or product you selected is out of stock.',
    cart_27: 'Minimum order({{moq_quantity}} unit) required.<br>Please adjust accordingly.',
    cart_28: 'File',
    cart_29: 'Check shipping on order page.',
    cart_30: 'Select quantity',
    cart_31: 'Previous',
    cart_32: 'Add',
    cart_33: 'Colors already added',
    cart_34: 'Colors to be added',
    cart_35: 'All colors of the product are in the Cart.',
    confirm_delete: {
      title: 'Do you want to delete this product?',
      content: 'Deleted products cannot be restored,<br>and designs will not be saved.',
      ok: 'Delete',
      cancel: 'Cancel',
    },
  },
  maker: {
    tooltip: {
      prev_editor_btn: 'Back to Editor',
      complete_btn: 'Complete',
      mo_title: 'Wait! Please check the design.',
      pc_title: 'If you want full-side printing,<br /><span>fill the image to the dotted line.</span>',
      mo_tooltip_text: 'Filled design to the dotted line?',
    },
    agree_text: 'Reviewed above, proceed with production.',
    warning: {
      image_size: 'The image size has been adjusted, please check.',
    },
    dosu: {
      confirm1_content:
        'This product allows up to {{dosu_count}}-color printing.<br>For this design, switch to our full-color products.',
      confirm1_title: 'Oops! Exceeded the printing limit.',
      confirm1_ok: 'Make full color product ',
      confirm1_cancel: 'Go back to editor ',
      confirm1_content_mobile:
        'This product allows up to {{dosu_count}}-color printing.<br>For this design, switch to our full-color products.',
    },
    image: {
      title: 'The colors extracted from my image',
    },
  },
  biz: {
    dosu: {
      warning_00: 'Automatically converted to printable colors.',
      warning_01: 'Auto-converted to default colors.',
      warning_02: 'Automatically converted to the color currently in use.',
      warning_03: 'Automatically converted to colors extracted from the image.',
    },
    opt: {
      title: {
        qty: 'Quantity',
        ref_file: 'Reference File',
        req_memo: 'Special Instructions',
        print_color: 'Printing Color',
        tippy_content: 'Discounts increase with order quantities!',
        error_message:
          'The printing color limit has been exceeded. Please edit within {{dosu_ctn}} colors per side.',
        more_dosu: '{{dosu_ctn}} colors (Exceeded printing limit)',
        embroidery_color: 'Embroidery color',
      },
      ref_file: {
        placeholder: 'Please attach any files to be referenced during product fabrication.',
        limit: 'File Types: ai and pdf files (Under 10MB)',
      },
      req_memo: {
        placeholder: 'Please enter any special instructions for production',
      },
      ea: 'ea',
      order: 'minimum order',
      order_unit: 'minimum order / order increments',
      group_discount: 'Bulk Order Discount Guide',
      dosu_guide:
        'The colors used in your design will be analyzed, and printable colors will be extracted for you.',
      file_error: 'Errors occurred during file download:',
      file_size_error: 'File size exceeds the limit.',
      file_ext_error: 'Invalid file extension',
      order_qty: 'Total Quantity',
      unit_price: 'Unit price',
    },
    biz_00: 'Get your corporate goods at Marpple!',
    biz_01: 'Easily create corporate goods with Marpple.',
    biz_02:
      '<strong>From design to sample production —</strong>\nEfficient, convenient, and reasonably priced.',
    biz_03: 'Consult for Corporate Goods',
    biz_04: 'Marpple offers diverse corporate goods tailored to various tastes and needs.',
    biz_05: "Custom goods encapsulating your brand or company's identity.",
    biz_06: 'Welcome kit for employees',
    biz_07: 'Goods for online/offline marketing and promotion',
    biz_08: "Experience Marpple's convenient and powerful custom service",
    biz_09: 'Sample production possible',
    biz_10: 'Marpple allows even a single sample production. Special components? No problem!',
    biz_11: 'Design draft confirmation',
    biz_12: 'Apply designs or images directly to products on the Marpple site and check them in real time.',
    biz_13: 'Small quantity production available.',
    biz_14:
      'We provide high quality production even for small quantities. All quantities, high or low, are okay!',
    biz_15: 'Convenient delivery',
    biz_16: 'From domestic and overseas delivery to individual designated delivery, everything is possible.',
    biz_17: 'Get your corporate goods with an easy and quick order method!',
    biz_18: 'Detailed quote consultation is conducted through a dedicated Marpple manager.',
    biz_19:
      'Leave production, inspection, packaging, and delivery to Marpple. Easy and convenient corporate goods are completed.',
    biz_20:
      'Marpple Partners with Major Firms, Startups, Schools, Broadcasters, and Entertainment & Gaming Companies.',
    biz_21: 'Struggling with Ideas for your Corporate Goods',
    biz_22:
      "We've curated goods for purposes like Welcome kits, Promotional items, and Brand merchandise. View our recommendations and easily start production!",
    biz_23: 'Get Recommendations',
    biz_24: 'Contact Us',
    biz_25: 'Here are frequently asked questions<br/>we have gathered',
    biz_26: 'Corporate Goods Consult',
    biz_27: 'Struggling with Ideas for your<br/>Corporate Goods',
    biz_28: 'Struggling with Ideas for your<br/>Corporate Goods',
    biz_29: 'Corporate Goods Consult',
    biz_30: ' Corporate Goods Consultation Request',
    faq: {
      faq_q_1: 'What is the difference between<br/>goods and a kit?',
      faq_a_1:
        'Goods typically denote individual items or separate products, crafted for promotional, fan-related, commemorative, or brand promotional purposes.\n' +
        "In contrast, a 'kit' refers to a set where multiple components are packaged together. Kits are meticulously planned, designed, and assembled for convenient use or to cater to specific purposes.",
      faq_q_2: 'Is it possible to change the standard kit configuration or add extra components?',
      faq_q_2_mo: 'Can custom goods not found<br/>on the Marpple site be made?',
      faq_a_2:
        "Yes! It is possible. After selecting the kit type, please inquire through 'Corporate goods Consultation.' A dedicated manager will be assigned to assist you based on your request during the consultation.",
      faq_q_3: 'Is there a minimum order quantity?',
      faq_a_3:
        'For kit production, orders of 50 or more are accepted. Minimum order quantities may vary based on product or package specifications. <br/>Ordering below the minimum quantity could lead to challenges such as mold fees, excessive printing charges, and other difficulties. <br/>Please consult with your dedicated manager for detailed discussions.',
      faq_q_4: 'Can I change the package?',
      faq_a_4:
        "It's possible. After finalizing the components, you can choose from various package types, designs, and sizes. <br/>Your dedicated manager will assist in detailed consultations regarding the package contents as well.",
      faq_q_5: 'Can I know about kit quatation?',
      faq_a_5:
        'Please confirm specific quotations through consultation with your dedicated manager. Quotation may vary based on factors such as order quantity and post-processing. <br/>Additional requests may include changes/additions to the box, custom designs, inserts, adding brand cards, co-packaging with our products, and sample production.',
      faq_q_6: 'How long does the kit production period take?',
      faq_a_6:
        'production time varies based on kit specifications and quantity. Typically, production for our recommended kit is completed within 2 weeks after confirmation. If there are changes in components or specifications, it may take an additional 1 to 2 weeks. <br/>For custom kits, the production time varies based on components and packing specifications.<br/>During the consultation, please specify your desired delivery schedule, Marpple will do our best to accommodate it.',
      faq_a_6_mo:
        'production time varies based on kit specifications and quantity. Typically, production for our recommended kit is completed within 2 weeks after confirmation. If there are changes in components or specifications, it may take an additional 1 to 2 weeks. <br/>For custom kits, the production time varies based on components and packing specifications.<br/>During the consultation, please specify your desired delivery schedule, Marpple will do our best to accommodate it.',
      faq_q_7: 'Is it possible to make sample kits?',
      faq_a_7:
        'If the components are products from the Marpple website, sample production is possible before bulk production. \n' +
        'It typically takes about 1 week from proof approval to sample receipt. \n' +
        'However, for certain custom-made goods, sample production might be challenging due to the manufacturing process. <br/>We will inform you in advance if sample production is not feasible for specific goods. \n' +
        'When it comes to packaging, producing physical samples might be difficult, but you can confirm the details through production samples or print samples.',
      faq_q_8: 'I would want to know about payment method.',
      faq_a_8:
        'Payments are typically processed through bank transfer (with tax invoice issuance) or credit card payment. \n' +
        "Production starts after payment confirmation. If you need alternative payment methods, inform to the Marpple manager. <br/>In exceptional cases, such as fixed expenditure dates according to the organization's policies, we can coordinate the payment date. <br/>This can be done under the issuance of a tax invoice, allowing for pre-payment or post-payment arrangements. Please note that in the case of bank transfers, if the payer's name differs from the order name, confirmation might be challenging. <br/>Therefore, it's essential to make the payment under the order name.",
    },
    kit: {
      kit_header_mo: 'We recommend corporate goods<br/>based on your budget',
      kit_header_pc: 'We recommend corporate goods<br/>based on your budget',
      kit_img_alt_1: 'Around $10 Set',
      kit_title_mo_1: 'Around $10<br/>Set',
      kit_title_pc_1: 'Around $10<br/>Set',
      kit_summary_1: 'Recommended as promotional goods!',
      kit_desc_mo_1:
        'Introducing versatile items suitable for various purposes and places, perfect for promotional use.',
      kit_desc_pc_1:
        'Introducing versatile items suitable for various purposes and places, perfect for promotional use.',
      kit_point: 'Chracteristic',
      kit_list_mo_1:
        '<li>Recommended as promotional material and promotional goods</li><li>Recommended as goods for offline events (conferences, seminars, etc.)!</li><li>Meets practicality, cost-effectiveness, and portability requirements.</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation)</li>',
      kit_list_pc_1:
        '<li>Recommended as promotional material and promotional goods</li><li>Recommended as goods for offline events (conferences, seminars, etc.)!</li><li>Meets practicality, cost-effectiveness, and portability requirements.</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation)</li>',
      kit_img_alt_2: 'Around $20 Set',
      kit_title_mo_2: 'Around $20<br/>Set',
      kit_title_pc_2: 'Around $20<br/>Set',
      kit_summary_2: 'ASAP! The fastest production option available.',
      kit_desc_mo_2: 'Recommended for those who wish to have a quick production turnaround',
      kit_desc_pc_2: 'Recommended for those who wish to have a quick production turnaround',
      kit_list_mo_2:
        '<li>Recommended as a welcome kit for new employees</li><li>Recommended for my event, club, and corporate event goods.</li><li>Appropriate combination of clothing and stationery</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation)</li>',
      kit_list_pc_2:
        '<li>Recommended as a welcome kit for new employees</li><li>Recommended for my event, club, and corporate event goods.</li><li>Appropriate combination of clothing and stationery</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation)</li>',
      kit_img_alt_3: 'Around $30 Set',
      kit_title_mo_3: 'Around $30<br/>Set',
      kit_title_pc_3: 'Around $30<br/>Set',
      kit_summary_3: 'Essential desk item set.',
      kit_desc_mo_3:
        'Boost your office efficiency with smart use of practical items commonly used in the office environment.',
      kit_desc_pc_3:
        'Boost your office efficiency with smart use of practical items commonly used in the office environment.',
      kit_list_mo_3:
        '<li>Recommended unique brand goods with a distinctive brand sensibility</li><li>Recommended for fan items and corporate PR purposes</li><li>A variety of products available at reasonable prices</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation).</li>',
      kit_list_pc_3:
        '<li>Recommended unique brand goods with a distinctive brand sensibility</li><li>Recommended for fan items and corporate PR purposes</li><li>A variety of products available at reasonable prices</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation).</li>',
      kit_img_alt_4: 'Around $60 Set',
      kit_title_mo_4: 'Around $60<br/>Set',
      kit_title_pc_4: 'Around $60<br/>Set',
      kit_summary_4: 'Gifts for your valued customers.',
      kit_desc_mo_4:
        'Effective in enhancing brand awareness and customer satisfaction with items that can be used consistently.',
      kit_desc_pc_4:
        'Effective in enhancing brand awareness and customer satisfaction with items that can be used consistently.',
      kit_list_mo_4:
        '<li>Recommended as gifts for VIP customers</li><li>Recommended unique merchandise infused with brand sensibility</li><li>A thoughtful set filled with practicality, durability, and luxury</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation).</li>',
      kit_list_pc_4:
        '<li>Recommended as gifts for VIP customers</li><li>Recommended unique merchandise infused with brand sensibility</li><li>A thoughtful set filled with practicality, durability, and luxury</li><li>The price is based on 100 pieces. (For quantities below 100, please inquire separately during consultation).</li>',
      kit_img_alt_5: 'Custom Set',
      kit_title_mo_5: 'Custom<br/>Set',
      kit_title_pc_5: 'Custom Set',
      kit_summary_5: 'Customize Goods and Packages Freely.',
      kit_desc_mo_5:
        'Increase office efficiency with stylish and practical items commonly used in the office.',
      kit_desc_pc_5:
        'Increase office efficiency with stylish and practical items commonly used in the office.',
      kit_list_mo_5:
        '<li>Both goods and packaging methods are customizable</li><li>Specify desired items and packaging when filling out the consultation form</li><li>For inquiries, please contact us at&nbsp;<a href="mailto:biz@marpple.com">biz@marpple.com</a>.</li>',
      kit_list_pc_5:
        '<li>Both goods and packaging methods are customizable</li><li>Specify desired items and packaging when filling out the consultation form</li><li>For inquiries, please contact us at&nbsp;<a href="mailto:biz@marpple.com">biz@marpple.com</a>.</li>',
    },
    kit_form: {
      kit_form_title_mo_1: 'Read before <br/>requesting<br/>consultation!',
      kit_form_title_pc_1: 'Read before <br/>requesting<br/>consultation!',
      kit_form_desc_mo_1:
        '<p>Hello, we are the Marpple B2B Business Team. Marpple offers high-quality custom orders for groups and corporate kits at reasonable prices. </p><p>Through the consultation form below, you can receive prompt and accurate goods recommendations and production consultations from our dedicated manager.  For any other inquiries, please contact us at <a href="mailto:biz@marpple.com">biz@marpple.com</a>.</p>',
      kit_form_desc_pc_1:
        '<p>Hello, we are the Marpple B2B Business Team. Marpple offers high-quality custom orders for groups and corporate kits at reasonable prices. </p><p>Through the consultation form below, you can receive prompt and accurate goods recommendations and production consultations from our dedicated manager.  For any other inquiries, please contact us at <a href="mailto:biz@marpple.com">biz@marpple.com</a>.</p>',
      kit_form_view_more: 'View more',
      kit_form_info_title: 'Application Procedure Guide',
      kit_form_info_title_1: '1. Consultation Reception',
      kit_form_info_desc_mo_1:
        "<p>Reply emails will be sent to the email address you provided during the application. <br/>(Please make sure to enter the correct email address.) <br/>When applying for consultation, please provide only 'non-confidential information' that does not fall under sensitive or confidential matters. <br/>The consultation details and related materials will be used only for the purpose of review and production.</p>",
      kit_form_info_desc_pc_1:
        "<ul><li>Reply emails will be sent to the email address you provided during the application.</li><li>(Please make sure to enter the correct email address.) </li><li>When applying for consultation, please provide only 'non-confidential information' that does not fall under sensitive or confidential matters. </li><li>The consultation details and related materials will be used only for the purpose of review and production.</li></ul>",
      kit_form_info_title_2: '2. Application Review',
      kit_form_info_desc_mo_2:
        '<p>Your consultation request will be processed within 3-5 business days. <br/>During the detailed review, the representative might require additional information and might reach out for further inquiries. <br/>(Please note that in some cases, it might take a bit longer due to the complexity of the matter. Thank you for your understanding.)</p>',
      kit_form_info_desc_pc_2:
        '<ul><li>Your consultation request will be processed within 3-5 business days. </li><li>During the detailed review, the representative might require additional information and might reach out for further inquiries. </li><li>(Please note that in some cases, it might take a bit longer due to the complexity of the matter. Thank you for your understanding.)</li></ul>',
      kit_form_info_title_3: '3. Consultation Ended',
      kit_form_info_desc_mo_3:
        "<p>If there is no response from the customer within 30 days after the representative's request for additional information or follow-up inquiries, the consultation request will be considered closed.</p>",
      kit_form_info_desc_pc_3:
        "<ul><li>If there is no response from the customer within 30 days after the representative's request for additional information or follow-up inquiries, the consultation request will be considered closed.</li></ul>",
      kit_form_title_2: 'Choose <br/>consultation type',
      kit_form_select_title_1: 'Promotional Products',
      kit_form_select_desc_1: 'I want to make individual goods items without a specific package.',
      kit_form_select_title_2: 'Pakage / Kit',
      kit_form_select_desc_2:
        'I want to create packaging items like boxes, tin cases, or kits <br/>that combine multiple components into one package.',
      kit_form_title_3: 'Please share <br/>consultation <br/>details.',
      kit_form_required: 'Required field',
      kit_form_label_1: 'Request',
      kit_form_placeholder_1: 'Feel free to create your title, such as [Company Name] Content.',
      kit_form_placeholder_mo_1: '<p>Feel free to create your title, such as [Company Name] Content.</p>',
      kit_form_label_2: 'Production Quantity',
      kit_form_placeholder_mo_2:
        '<p>For individual items, please provide the product names and<br/>quantities for each option (color, size) along with the product name. For kits, please provide quantities based on sets.</p>',
      kit_form_placeholder_pc_2:
        'For individual items, please provide the product names and quantities for each option (color, size) along with the product name. For kits, please provide quantities based on sets..',
      kit_form_label_3: 'Due Date',
      kit_form_placeholder_mo_3:
        '<p>Please select your desired delivery schedule.<br/>(Please note that production timelines vary for each product, but on average, it takes over 2 weeks from the order date to completion.)</p>',
      kit_form_placeholder_pc_3:
        'Please select your desired delivery schedule. (Please note that production timelines vary for each product, but on average, it takes over 2 weeks from the order date to completion.)',
      kit_form_placeholder_data_3:
        'Please select your desired delivery schedule.&#10;(Please note that production timelines vary for each product, but on average, it takes over 2 weeks from the order date to completion.)',
      kit_form_label_4: 'Goods Pickup Location',
      kit_form_placeholder_mo_4:
        '<p>Provide a rough address, and we can include the shipping cost in the estimate.</p>',
      kit_form_placeholder_pc_4:
        'Provide a rough address, and we can include the shipping cost in the estimate.',
      kit_form_label_5: 'Production Purpose',
      kit_form_placeholder_5:
        'Please specify the purpose or situation for production, such as promotion, giveaways, or sales.',
      kit_form_placeholder_mo_5:
        '<p>Please specify the purpose or situation for production, such as promotion, giveaways, or sales.</p>',
      kit_form_label_6: 'Budget',
      kit_form_placeholder_6:
        'Please specify your desired production unit price or any predetermined budget, if applicable.',
      kit_form_placeholder_mo_6:
        '<p>Please specify your desired production unit price or any predetermined budget, if applicable.</p>',
      kit_form_label_7: 'Detail',
      kit_form_placeholder_mo_7:
        'Provide extra details or unique product URL if not on Marpple site. Attach images/files below.',
      kit_form_placeholder_pc_7:
        '전Provide extra details or unique product URL if not on Marpple site. Attach images/files below.',

      kit_form_title_4: 'Please attach<br/>the reference<br/>file.',
      kit_form_label_8: 'Attachment',
      kit_form_desc_mo_2:
        'pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt. Please attach one file (up to 100MB in size).',
      kit_form_desc_pc_2:
        'Attachment format: pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt. Please attach one file (up to 100MB in size).',
      kit_form_title_5: 'Please<br/>introduce your<br/>company',
      kit_form_label_9: 'Company Type',
      kit_form_select_title_3: 'Corperation',
      kit_form_select_title_4: 'School /Public Institutions',
      kit_form_select_title_5: 'etc',
      kit_form_label_10: 'Company Name',
      kit_form_placeholder_10: 'Please enter the company or organization name.',
      kit_form_label_11: 'Contact Manager',
      kit_form_placeholder_11: 'Please enter the name of the contact person',
      kit_form_label_12: 'Contact Email',
      kit_form_placeholder_12: 'Please enter your email address to receive the response',
      kit_form_placeholder_mo_12: '<p>Please enter your email address to receive the response</p>',
      kit_form_label_13: 'Contact Number',
      kit_form_placeholder_13: 'Please enter your phone number to receive the response',
      kit_form_placeholder_mo_13: '<p>Please enter your phone number to receive the response</p>',
      kit_form_title_6: 'Personal<br />Information<br />Collection and<br />Use Policy',
      kit_form_label_14: 'Consent to the Collection and Use of Personal Information',
      kit_form_desc_14:
        'Marpple Corporation collects and uses personal information from companies and individuals seeking consultation for customer service purposes as follows:',
      kit_form_desc_14_2:
        '<li>Purpose of Personal Information Collection: Customer consultation, conveying inquiries</li><li>Items of Personal Information Collected: Name, phone number, email</li><li>Retention and Use Period of Personal Information: The collected information will be securely destroyed without exception after the purpose of collection and use has been achieved</li><li>If you do not agree to the collection and use of personal information, consultation services will not be provided</li>',
      kit_form_label_15: 'Apply',
      kit_form_label_16: 'Apply',
      kit_form_message_1: 'Do you want to delete it?',
      kit_form_message_2: 'Uploading...',
      kit_form_message_3: 'File upload failed.',
      kit_form_message_4: 'An error has occurred.',
      kit_form_validation_message_1: 'please check the email format.',
      kit_form_validation_message_2: 'please check the contact number format.',
      kit_form_validation_message_3: 'Choose consultation type.',
      kit_form_validation_message_4: 'Feel free to create your title, such as [Company Name] Content.',
      kit_form_validation_message_5: 'Please enter the production quantity.',
      kit_form_validation_message_6: 'Please select due date.',
      kit_form_validation_message_7: 'Please enter the production purpose.',
      kit_form_validation_message_8: 'Please enter the detail.',
      kit_form_validation_message_9: 'Please choose company type.',
      kit_form_validation_message_10: 'Please enter the company name',
      kit_form_validation_message_11: 'Please enter the contact email',
      kit_form_validation_message_12: 'Please enter the contact number',
      kit_form_validation_message_13: 'Please agree to the collection and use of personal information.',
      kit_form_message_5: 'The upload file size is limited to 100 MB.',
    },
    kit_form_result: {
      kit_form_result_title: 'Corporate goods Consultation Request Completed',
      kit_form_result_desc:
        'Your consultation request has been successfully received. <br/>We will review the details and contact you within 3-5 business days, <br/>via the email address and phone number provided.',
      kit_form_result_complete: 'Submit',
    },
    notice: {
      title: {
        price: 'Product Prices',
        consulting: 'Order Consultation',
        payment: 'Payment Methods',
        delivery: 'Delivery Information',
        req_consulting: 'Request Consultation',
      },
      price: 'All prices include <b>Value Added Tax (VAT)</b>',
      consulting:
        'For custom orders such as individually crafted products, packages, and printing options, please consult separately.',
      payment:
        'For custom orders, prepayment is preferred, and tax invoices can be issued with card payments available on the website.',
      delivery:
        'Additional charges may apply based on the delivery method, including individual delivery, warehousing, messenger service and international.',
      delivery2: 'For detailed consultaiton regarding shipping costs, pelase inquire separately.',
      req1: '1:1 Inquiry',
    },
  },
  group_biz_product: {
    gbp_01: 'Product Type',
    gbp_01_mo: 'Group Type',
    gbp_02: 'Product Price',
    gbp_02_mo: 'Product Price',
    gbp_03: 'Product Quantity',
    gbp_04: 'Above {{price}} ~',
    gbp_05: 'More Promotion/Business Goods',
    gbp_06: 'Corporate Goods Consult',
    gbp_07: 'Find products that fit your purpose and budget!',
    gbp_07_mo: 'Find products that fit your purpose and budget!',
    gbp_08: 'Make goods for our brand needs!',
    gbp_08_mo: 'Make goods for our brand needs!',
    gbp_09: 'These are products with many group orders.',
    gbp_09_mo: 'These are products with many group orders.',
    gbp_10: 'Introducing Marpple’s\nProminent Production Cases',
    gbp_11: 'Recommended categories for group orders',
    gbp_11_mo: 'Recommended categories for group orders',
    biz_keyword: {
      biz_kwd_best: 'Group Best',
      biz_kwd_1_1: 'Group\nT-Shirts',
      biz_kwd_1_2: 'Small batch\nprinting',
      biz_kwd_1_3: 'Promotional\ngoods',
      biz_kwd_1_4: 'Office supplies',
      biz_kwd_1_5: 'Pop-up store',
      biz_kwd_1_6: 'Welcome kits',
      biz_kwd_1_7: 'Gifts',
      biz_kwd_1_8: 'Exhibitions/\ntrade shows',
      biz_kwd_1_9: '소상공인',
    },
    faq: {
      faq_q_1: 'What is the difference between goods and a kit?',
      faq_a_1:
        "Goods typically denote individual items or separate products, crafted for promotional, fan-related, commemorative, or brand promotional purposes.<br/>In contrast, a 'kit' refers to a set where multiple components are packaged together. Kits are meticulously planned, designed, and assembled for convenient use or to cater to specific purposes.",
      faq_q_2: 'Can custom goods not found on the Marpple site be made?',
      faq_a_2:
        'Absolutely. If you provide the design and specifications of the goods you want, our dedicated manager will consult with you to confirm the feasibility of production.',
      faq_q_3: 'I would like to receive a quote for the goods',
      faq_a_3:
        'Since all goods at Marpple are made to order, the cost varies based on the type of goods, quantity, and specifications.<br/>For Marpple custom products, you can directly check the cost of making one item for the desired product on our website (www.marpple.com).<br/>For clothing items, the production cost depends on factors such as printing locations (front, back, sleeves), size, quantity, and printing method (screen printing, embroidery).<br/>Therefore, providing the design draft is essential for an accurate quote.<br/>For custom kits or other bespoke orders, please reach out to our dedicated manager for consultation and a detailed quote after discussing your requirements.',
      faq_q_4: 'I want to know more about the production process',
      faq_a_4: `Consultation Request: Please request through 'Corporate Goods Consultation' at the top of the page.
Production Consultation: A dedicated manager will be assigned to provide goods recommendations and production consultation based on your request.
Quotation: After the consultation, we will send you the initial quotation via email. Once the design and specifications are confirmed, we will send the final confirmed quotation.<ul><li>On the Marpple website, you can directly apply images to products for immediate proofing and confirmation.
After the design is completed, please provide the original design file (high-resolution PNG, AI, or PSD) along with the composite image you confirmed on the page.</li><li>For other custom products: please design according to the specific design guidelines provided and share the original design file (high-resolution PNG, AI, or PSD) following the guidelines.</li></ul><p>Production and Delivery: All products undergo meticulous inspection under the supervision of a dedicated manager to ensure high quality. They are securely packaged and delivered safely.</p>`,
      faq_q_5: 'Can I request a design as well?',
      faq_a_5:
        'We offer image optimization services, including resizing, background removal, and retouching, to ensure the production proof meets your specifications.',
      faq_q_6: 'Is it possible to create a sample?',
      faq_a_6:
        'At Marpple, we can provide pre-production samples. However, for some custom goods, creating samples might be challenging due to their unique production requirements.<br/>If sample production is not feasible for specific custom goods, we will inform you in advance.<br/>The sample cost is included in the initial production cost for the first sample. In case the main production order is canceled, a separate charge will apply for the sample.<br/>For more detailed information regarding samples, please consult with Marpple’s dedicated manager.',
      faq_q_7: 'How long does the production period take?',
      faq_a_7:
        'The production period can vary for each goods, and it takes approximately 7 business days (excluding weekends and holidays) after the final production approval.<br/>For the bulk orders, it may take more tahn 2 weeks based on business days.<br/>The duration may vary depending on whether sample production is required and the specifics of the production.<br/>Please inquire with Marpple manager, and we will provide you with a more detailed schedule.',
      faq_q_8: 'I would want to know about payment methods.',
      faq_a_8:
        "Payments are typically processed through bank transfer (with tax invoice issuance) or credit card payment.<br/>Production starts after payment confirmation. If you need alternative payment methods, inform to the Marpple manager.<br/>In exceptional cases, such as fixed expenditure dates according to the organization's policies, we can coordinate the payment date.<br/>This can be done under the issuance of a tax invoice, allowing for pre-payment or post-payment arrangements.<br/>Please note that in the case of bank transfers, if the payer's name differs from the order name, confirmation might be challenging.<br/>Therefore, it's essential to make the payment under the order name.",
      faq_q_9: 'How long does the delivery take?',
      faq_a_9:
        "Items are shipped via CJ Korea Express courier service. After dispatch, you can expect delivery within 1-2 business days.<br/>However, please note that delivery might take a bit longer in rural or remote areas, depending on the courier's workload in those regions.<br/>For changes in shipping methods such as international delivery, rural areas, air, express, or individual deliveries, please consult with Marpple’s dedicated manager.",
      faq_q_10: 'Is it possible to exchange or return custom-made products?',
      faq_a_10:
        "Marpple's custom-made goods are crafted to order and cannot be resold, making exchanges or returns due to change of mind unavailable.<br/>If your product arrives with issues, please notify us at biz@marpple.com within 7 days of receipt, providing proof of the defect.<br/>After verification, we'll arrange for product retrieval and offer after-sales service.<br/>Please note that exchanges or returns for items other than the ones ordered are not permitted.<br/>Reproduction and after-sales service are available only for the same product, size, and color, with Marpple covering the associated shipping costs.",
    },
  },
  portfolio: {
    pf_btn_01: 'More Portfolio',
    brand_01: 'NEXON',
    brand_01_c: 'Intern Welcome Kit',
    brand_02: 'LG',
    brand_02_c: 'Logo Group T-shirt',
    brand_03: 'SKT',
    brand_03_c: 'Notebook + Clip Pen featuring event slogans',
    brand_04: 'YouTube',
    brand_04_c: 'Offline event distribution goods',
    brand_05: 'kakao games',
    brand_05_c: 'G-STAR event merchandise',
    brand_06: 'kakao pay',
    brand_06_c: 'Blanket package for employee distribution',
    brand_07: 'Nongshim',
    brand_07_c: 'Goods utilizing SNS marketing',
    brand_08: 'SOOP',
    brand_08_c: 'Suede character cushion',
    brand_09: 'MARPPLE',
    brand_09_c: 'Welcome kit for corporate promotion',
    brand_10: 'NEXON',
    brand_10_c: 'Blue Archive pop-up store merchandise: 70 types',
    brand_11: 'UNESCO Korea Committee',
    brand_11_c: 'Official commemorative items using CI',
    brand_12: 'KESPA',
    brand_12_c: 'e-sports student welcome kit',
    brand_13: 'NEXON',
    brand_13_c: 'Mabinogi Internet cafe event neck pillow + blindfold set',
    brand_14: 'NEXON',
    brand_14_c: 'Children’s school supplies kit for employees’ children',
    brand_15: 'KRAFTON',
    brand_15_c: 'Event goods for exhibition hosting',
    brand_16: 'NEXON',
    brand_16_c: 'Mabinogi Internet Cafe event mouse pad',
    brand_17: 'NEXON',
    brand_17_c: 'Limited edition Closers character acrylic stand',
    brand_18: 'kakao games',
    brand_18_c: 'Odin new year calendar for user event prizes',
    brand_19: 'NEXON',
    brand_19_c: 'Jeju NEXON Computer Museum',
    brand_20: 'NEXON',
    brand_20_c: 'Elsword character merchandise',
    brand_21: 'NEXON',
    brand_21_c: 'Mabinogi 20th anniversary tarot cards',
  },
  about: {
    about_00: 'Is this your first time at Marpple?',
    about_01: 'Hello We are Marpple, a custom goods production specialized platform',
    about_02: `<strong>From memories and friendship, family and work, individuality and meaning, to names and birthdays</strong> create a product that's made just for you and is one of a kind in the world at Marpple. With 15 years of accumulated know-how in goods production and convenient online design solutions, we provide the best custom goods`,
    about_03: 'Start Creating',
    about_04: "Learn Marpple's custom method\nthat can be completed in 3 minutes.",
    about_05: 'we create the best custom goods for you',
    about_06: 'Upload the design (free design provided) / Create custom goods',
    about_07: 'Just order and pay!',
    about_08: "Take a look at Marpple's\nproduction process.",
    about_09: 'Try making custom products\nat Marpple!',
    about_10: 'A one-of-a-kind product in the world,\nYou can make it like this too! 💞',
    about_11: 'Customize with my image',
    about_12:
      'Try customizing by inserting a logo or photo you made yourself!\nCustomize your own goods that are unique in this world!',
    about_13: 'Start Creating',
    about_14: 'Customize with text',
    about_15:
      'You can select a font and insert the phrase you want!\nSchool or group name, precious family, as I want!',
    about_16: 'Customize with free decorating design',
    about_17:
      "Having trouble with design? With thousands\nQuickly and easily complete customization with Marpple's free design tool!",
    about_18: 'Customize in various ways',
    about_19:
      'Silkscreen, digital printing, embroidery, patch, fluorescent printing,\nTry to keep the individuality of my custom in various ways!',
    about_20: 'At Marpple, you can make everything\nfrom a single piece to group orders.',
    about_21: 'Group order',
    about_22:
      'Try making a custom product of good quality and high quality after checking the sample first, which can be ordered and produced from one in Marpple',
    about_23: "Worried about quality?\nExperience Marpple's premium quality",
    about_24: 'High Quality',
    about_25:
      'Marpple provides high-quality plain products and printing services that are directly produced in Korea. With various production methods and facilities such as digital printing and silk screen, we can offer a variety of customs, and we perfectly deliver various designs and details.',
    about_26:
      'Marpples is an online custom goods\nproduction platform that has been 🔍 verified\nwith 120,000 customer reviews',
    about_27: "Marpple Users' Reviews",
    about_28:
      'Marpple Partners with Major Firms, Startups, Schools, Broadcasters,\nand Entertainment & Gaming Companies.',
  },
  custom: {
    custom_01: "Today's Custom",
    custom_02: 'Popular Customization Today, You Can Do It Too!',
    custom_03: 'Custom Tips',
    custom_04: "Custom?\nNow it's easy!",
    custom_05: 'Group product guide',
    custom_06: 'Take business tips!',
    custom_07: 'All',
    custom_08: 'Please Provide the Entire Tab Header Text.',
  },
  event: {
    event_01: 'View All Events',
  },
  quality: {
    title: 'High Quality',
    qual_01: 'Trust Marpple with 100% satisfaction guaranteed.',
    qual_02:
      'Your experience with Marpple is highly valued. We continuously strive to produce top-quality products and maintain their quality.',
    qual_03: 'Over 2,000 personal products and waste-free production.',
    qual_04:
      'At Marpple, you can create unique products that are one-of-a-kind in the world. If you want to break away from the uniform designs offered by large corporations or mega brands and find something unique, Mapl is your answer.',
    qual_05:
      "Too many clothes produced in factories are discarded or wasted without being sold. Marpple's full print method first prints on the fabric and then proceeds to cutting and sewing, reducing waste and maintaining an environmentally friendly approach in production.",
    qual_06: 'State-of-the-art fabric printing technology',
    qual_07: 'Flatbed Print',
    qual_08:
      "A printing method that directly prints computer-generated images onto fabric using DTG method. It's optimized when realistic representations, complex designs, or expressions of various colors are needed.",
    qual_09: 'No limit to the number of colors.',
    qual_10: 'Realizing detailed images and colors.',
    qual_11: 'Cost-Free, Print-On-Demand Optimized.',
    qual_12: 'Silk screen',
    qual_13:
      "A printing method suitable for group T-shirts, it's ideal for bulk work of the same design and size. Capable of processing up to A2 size and working with up to 8 colors at once.",
    qual_14: 'Fast production speed',
    qual_15: 'Great for producing identical designs in bulk.',
    qual_16: 'Wash-Resistant and Long-Lasting Prints',
    qual_17: 'Flex',
    qual_18:
      'Flex printing is ideal for one or two color prints, simple logos or individual tasks, with the benefit of single quantity orders.',
    qual_19: 'high-end look, no fabric color showing through.',
    qual_20: 'No limitations on the printable area or coverage',
    qual_21: 'Astonishing wash durability',
    qual_22: 'Sublimation Print',
    qual_23:
      'Using high-temperature pressure, we transfer prints from paper to fabric. This method excels at reproducing realistic images on clothing, spandex, mugs, eco-bags, etc',
    qual_24: 'No limitations on the printable area or coverage',
    qual_25: 'No restrictions on the print material (fabric, mug, plastic materials, leather, etc.)',
    qual_26: 'No foreign feeling when touching the printed part',
    qual_27: 'Full Print',
    qual_28:
      'We print on fabric before cutting and sewing, ensuring high quality with our large-scale printers and roll presses. Ideal for custom designs on various items.',
    qual_29: 'No limitations on the printable area or coverage',
    qual_30: 'No foreign feeling when touching the printed part',
    qual_31: 'Suitable for large-format print with large pixels',
    qual_32: 'Embroidery',
    qual_33:
      'Provides high-quality embroidery on various clothing like hats, polo shirts, denim shirts, windbreakers, parkas, etc.',
    qual_34: 'Embroidery is possible even for just one order',
    qual_35: 'Method of directly attaching the design to clothing with thread gives a luxurious feel',
    qual_36: 'Advantages',
    qual_37: 'High-quality Brands Handpicked by Marpple',
    qual_38:
      'Marpple offers high-quality, durable products from select brands, testing diverse print methods for the best result.',
    qual_39:
      'Brands handpicked by Marpple offer products made from premium fabrics, with vivid dyed colors and sturdy stitching, providing durability and comfort.',
  },
  group_order: {
    go_01: 'Group Order',
    go_02: 'Experience the popularity of group tees proven by over 100,000 reviews.',
    go_03:
      'Experience a variety of group tees for businesses, gatherings, clubs, and schools at Marpple. Anyone can easily and quickly create at Marpple.',
    go_04: 'Instant Group Tee Creation',
    go_05: "Experience Marpple's convenient and powerful group order service.",
    go_06: 'Durable Quality',
    go_07:
      "Marpple's group tees are made with high-quality brand plain goods, ensuring excellent durability.",
    go_08: 'Easy and Fast Ordering Process',
    go_09: 'At Marpple, you can check and order from design to quotation in real-time.',
    go_10: 'Bigger Discounts for Bigger Orders',
    go_11: 'Discounts at Marpple start from 5 pieces. The discount benefits grow with your order quantity!',
    go_12: 'Amazing and Pleasing Marpple Group Order Service',
    go_13: 'Discounts at Marpple start from 5 pieces. The discount benefits grow with your order quantity!',
    go_14: 'Group T-Shirts',
    go_15: "We've collected custom group t-shirt products.",
    go_16: 'Brand Group T-Shirts',
    go_17: "We've collected brand t-shirt products.",
    go_18: 'Group Phone Cases',
    go_19: "We've collected all models up to the latest ones.",
    go_20: 'Group Uniforms',
    go_21: "We've collected various functional clothing for groups.",
    go_22: 'Group Eco Bags',
    go_23: "We've collected all eco bags.",
    go_24: 'Group Accessories',
    go_25: "We've collected a variety of group items.",
    go_26: 'Place a Group Order',
    go_27: 'Check out the group order discount price right now!',
    go_28: 'Phone Consultation 1566-9437',
    go_29: 'Consultation Available Hours: Weekdays 10:00-18:00',
  },
  omp_receipt: {
    txt1: 'Order Complete',
  },
  omp_title: {
    kit: 'MARPPLE Corporate goods',
    home: 'MARPPLE Create your own custom',
    new_best: 'MARPPLE New Product',
    search: 'MARPPLE All Products',
    reviews: 'MARPPLE Review',
    group: 'MARPPLE Group Orders',
    my_page: 'MARPPLE Mypage',
    order: 'MARPPLE Order',
    wish: 'MARPPLE Wish',
    point: 'MARPPLE Point',
    my_review: 'MARPPLE Review',
    my_review_list: 'MARPPLE Review',
    my_info: 'MARPPLE My info',
    high_quality: 'MARPPLE High quality',
    event: 'MARPPLE Event',
    login: 'MARPPLE Login',
    help: 'MARPPLE Help',
    know_how: 'MARPPLE Know_how',
    apparel: 'Marpple Custom Apparel',
    sports: 'Marpple Custom Apparel',
    electronics: 'Marpple Custom Apparel',
    accessories: 'Marpple Custom Apparel',
    home_deco: 'Marpple Custom Apparel',
    sticker: 'Marpple Custom Apparel',
    frames: 'Marpple Custom Apparel',
    stationery: 'Marpple Custom Apparel',
    kids_pet: 'Marpple Custom Apparel',
    new: 'MARPPLE New Product',
    best: 'MARPPLE Best Product',
    biz_best: 'MARPPLE Group Best Product',
    review: 'MARPPLE Review',
    about: 'MARPPLE First time at Marpple?',
    biz: 'MARPPLE Corporate goods',
    etc: 'MARPPLE Create your own custom',
    sign_up: 'MARPPLE Sign up',
    cart: 'MARPPLE Cart',
    order_detail: 'MARPPLE Order Detail',
    search_result: '{{keyword}} MARPPLE Search',
    group_biz: 'MARPPLE Promotion/Business',
    goods: 'MARPPLE Goods',
    Cushion: 'MARPPLE Cushion/Fabric',
    kids: 'MARPPLE Kids',
    pet: 'MARPPLE Pet',
    digital: 'MARPPLE Digital/Tech',
    receipt: 'Order Complete',
    checkout: 'Order Form',
  },
  omp_og: {
    title: {
      apparel: 'Apparel | Made by you, Made with Marpple',
      home: 'Made by you, Made with Marpple',
      sports: 'Sports | Made by you, Made with Marpple',
      electronics: 'Phone Acc | Made by you, Made with Marpple',
      accessories: 'Accessories | Made by you, Made with Marpple',
      home_deco: 'Living | Made by you, Made with Marpple',
      sticker: 'Sticker/Paper | Made by you, Made with Marpple',
      frames: 'Frames | Made by you, Made with Marpple',
      stationery: 'Stationery | Made by you, Made with Marpple',
      kids_pet: 'Kids & Pet | Made by you, Made with Marpple',
      new: 'New | Explore new products at Marpple',
      best: `Best | Explore best products at Marpple`,
      biz_best: `Best | Explore best products at Marpple`,
      review: 'Review',
      about: 'About',
      biz: 'Corporation Goods / Kit',
      etc: 'Made by you, Made with Marpple',
      group: 'Group orders made easy at Marpple!',
      group_biz: 'Promotional Merch',
      goods: 'Goods | Made by you, Made with Marpple',
      Cushion: 'Cushion/Fabric | Made by you, Made with Marpple',
      kids: 'Kids | Made by you, Made with Marpple',
      pet: 'Pet | Made by you, Made with Marpple',
      digital: 'Digital/Tech | Made by you, Made with Marpple',
    },
    description: {
      default:
        'Always Free Shipping All Around World! Create your own t-shirt design from scratch, Upload your own artwork or ask an expert for design help. Over 500 Styles Available. Free Expert Help & Review. Types: T-Shirts, Crewneck, Hoodies, Blanket, Polos, Hats, Tote Bags, Shoes. Make your own design or upload your own. Trusted by over 400000 customers! 99% Happy Custom',
      home: 'Free Shipping',
      apparel: 'Custom T-shirts, Free Shipping',
      sports: 'Custom Sports Items, Free Shipping',
      electronics: 'Custom Phone Acc, Free Shipping',
      accessories: 'Custom Accessories, Free Shipping',
      home_deco: 'Custom Living Items, Free Shipping',
      sticker: 'Custom Sticker, Free Shipping',
      frames: 'Custom Frames, Free Shipping',
      stationery: 'Custom Stationery, Free Shipping',
      kids_pet: 'Custom for your pet dog, at Marpple!',
      new: 'Made by you, Made with Marpple',
      best: `Made by you, Made with Marpple`,
      biz_best: `Made by you, Made with Marpple`,
      review: 'Made by you, Made with Marpple',
      about: 'Made by you, Made with Marpple',
      biz: 'Made by you, Made with Marpple',
      etc: 'Free Shipping',
      group: 'More you order, bigger the discount!',
      group_biz: 'Made by you, Made with Marpple',
      goods: 'Custom Goods Items, Free Shipping',
      Cushion: 'Custom Cusion/Fabric Items, Free Shipping',
      kids: 'Custom Kids Items, Free Shipping',
      pet: 'Custom Pet Items, Free Shipping',
      digital: 'Custom Digital/Tech Items, Free Shipping',
    },
  },

  omp_withdrawal: {
    title: 'Delete account',
    txt1: 'Are you really leaving the Marpple or MarppleShop? 😢',
    txt2: 'Before deleting your account, please be aware of the following.',
    ol_1: 'If you are a regular member, you can rejoin after deleting your account, but all information, including purchases, order history, and points, will be deleted.',
    ol_2: 'If you are a MarppleShop Creator, you can only delete your account when there are <strong>no ongoing transactions and remaining earnings are zero.</strong> Deletion is allowed only after all shipments, customer service, and settlements are completed. All account information, including your shop and sales history, will be permanently deleted and cannot be restored.',
    ol_3: `Even after deleting your account, purchased items will still be shipped, and your personal information will be protected according to Marpple and Marppleshop's policy and relevant laws for product returns and customer service.`,
    ol_4: 'For assistance, please contact our customer center at <a href="tel:1566-9437">1566-9437</a> or <a href="mailto:help@marpple.com">help@marpple.com</a> for assistance.',
    info_txt1: 'Ongoing Transactions',
    info_txt3: 'Remaining Earnings',
    info_txt5: 'Remaining Points',
    info_txt2: '{{count}}transactions',
    info_txt4: 'KRW {{amount}}',
    info_txt6: '{{point}}P',
    txt3: 'You can only delete your account when there are <strong>no ongoing transactions and remaining earnings are zero.<strong>',
    txt4: 'For more details, please check the MarppleShop Studio. For assistance, contact our customer center.',
    btn1: 'Delete account',
    txt5: 'Thank you for using MCO.',
    txt6: 'We will strive to welcome you back with even better service in the future.',
    confirm_txt1: 'Account deletion completed',
    confirm_txt2:
      'Thank you for using MCO.<br/>We will strive to provide better service and hope to see you again.',
    not_confirm_txt1: 'Account deletion could not be completed.',
    not_confirm_txt2:
      'You can only delete your account when there are no ongoing transactions and remaining earnings are zero. For more details, please check on MarppleShop Studio. For assistance, please contact us at <a href="tel:{{tel}}" >{{tel}}</a> or <a href="mailto:{{email}}">{{email}}</a>',
    confirm_title: 'Are you sure you want to delete your account?',
    confirm_txt3: 'All account information will be deleted and cannot be restored. Proceed?',
    cancel: 'Cancel',
  },
  translateUnit: {
    개: 'ea',
  },
  maker_upload_type: {
    no_file: '앗! 업로드된 파일이 없어요 😅',
    '만들기 툴은 곧 제공 예정이에요!': '',
    preview_text: '',
  },
  pdf: {
    uploader: {
      0: 'Design File Upload',
      1: 'Design Upload',
      2: 'Download the template<br>and create your design to make your own amazing product!',
      3: 'The template is available for use from Adobe Illustrator CS6.',
      4: 'Please use the template file to design for product quality and smooth processing.',
      5: 'The acceptable attachment file format for upload is PDF, supporting a maximum size of 100MB.',
      6: 'This product is individually crafted per order. Cancellations or changes in options are not available after payment has been made.',
      7: 'Kindly review the design file and options thoroughly. Order cancellations, refunds, or complimentary remakes for printing errors due to files not meeting the required standards are unavailable.',
      8: 'The supported attachment file format is PDF<br>with a maximum file size of 100MB or less.',
      9: 'The design file has been received!',
      10: 'File storage period',
      11: '',
      12: 'Template download.',
      13: 'Your uploaded file will be deleted. Would you like to close the editor?',
      14: 'month',
      15: 'If you have not used the latest template provided by Marpple,<br>there may be a delay in production depending on the need for resubmission of files.',
      16: 'Please make use of the newest Marpple’s template.',
      17: 'This file made by using a template.',
      18: 'Cancel',
      19: 'Complete',
      20: 'Please make sure to read this before uploading your file!',
      21: 'File Upload',
      invalid_template: 'Only files using the latest Marpple template can be submitted.',
      invalid_template2: '',
      invalid_template3: '',
    },
    expired: {
      1: '파일 보관 기간<br />만료',
      2: '파일 보관 기간 만료',
      3: '보관 기간<br/>만료',
      4: '파일 보관 기간이 만료되었습니다.',
      5: '파일 보관 기간이 만료된 상품이 있어요.',
      6: '파일 보관 기간이 만료되었어요.',
      7: '파일 보관 기간이 만료되었습니다.<br/>옵션을 다시 선택해 주시고,<br/>파일을 재업로드 해주세요.',
      8: '파일 보관 기간이 만료되었습니다.<br />옵션을 다시 선택해 주시고, 파일을 재업로드 해주세요.',
      9: '이전 주문 옵션',
      10: '확인',
      11: '파일 보관 기간이 만료된<br/>상품이 있어요.',
      12: '업로드하신 파일은 최종 업로드일로부터<br>6개월 간 보관됩니다.<br/>해당 상품의 커스텀 화면으로 이동 후,<br/>파일을 재업로드 해주세요.',
      13: '업로드하신 파일은 최종 업로드일로부터 6개월 간 보관됩니다.<br/>해당 상품의 커스텀 화면으로 이동 후, 파일을 재업로드 해주세요.',
      14: '이전 화면으로 돌아가기',
    },
    resubmit: {
      1: '재접수 완료',
      2: '재접수하기',
      3: '수정사항 체크',
      4: '도안 업로드',
      5: '재접수 필요',
      6: '재접수가 필요한 파일이 있습니다.',
    },
    agreements: {
      1: '파일 업로드형 주문 제작 상품 동의',
      2: '주문에 따라 개별적으로 생산되는 상품으로 결제 후 주문 취소 및 옵션 변경이 불가합니다.<br>디자인 파일과 옵션을 한 번 더 확인해 주세요. 작업 기준에 맞지 않는 파일을 접수하여 발생한 인쇄사고는 주문 취소, 환불, 무료 재제작이 불가능합니다.<br>업로드 하신 도안 파일은 최종 업로드일로부터 6개월 간 보관됩니다.',
      3: '주문에 따라 개별적으로 생산되는 상품으로 결제 후 주문 취소 및 옵션 변경이 불가합니다. 디자인 파일과 옵션을 한 번 더 확인해 주세요. 작업 기준에 맞지 않는 파일을 접수하여 발생한 인쇄사고는 주문 취소, 환불, 무료 재제작이 불가능합니다. 업로드 하신 도안 파일은 업로드일로부터 6개월 후 삭제됩니다.',
      4: '디자인을 한 번 더 확인해 주세요.',
      5: '파일 업로드형 상품 제작 시 주문취소 및 옵션변경이 불가하니<br />디자인을 한 번 더 확인해 주세요.<br />작업 기준에 맞지 않는 파일로 접수하여 발생한 인쇄사고 건은<br />주문취소, 환불, 무료 재제작이 불가능합니다.',
      6: '파일 업로드형 상품 제작 시 주문취소 및 옵션변경이 불가하니 디자인을 한 번 더 확인해 주세요.<br/>작업 기준에 맞지 않는 파일로 접수하여 발생한 인쇄사고 건은 주문취소, 환불, 무료 재제작이 불가능합니다.',
      7: '확인했습니다.',
    },
  },
  NewMaker: {
    Alert: {
      useLatestPdfVersion: {
        title: 'Please make use of the newest Marpple’s template.',
        content:
          'If you have not used the latest template provided by Marpple,<br>there may be a delay in production depending on the need for resubmission of files.',
        ok: 'Proceed with reception',
        cancel: 'Go back to editor',
        content_mobile:
          'If you have not used the latest template provided by Marpple,<br>there may be a delay in production depending on the need for resubmission of files.',
      },
    },
  },
  projection: {
    status: {
      '이미 제작이 시작되어 취소가 어렵습니다. 고객센터로 연락부탁드립니다.': '',
    },
  },
  material_info: {
    title: '재질 안내',
    name: {
      1: '금광데드롱',
      2: '은광데드롱',
      3: '금무지',
      4: '은무지',
      5: '홀로그램',
      6: '트레이싱지',
      7: '아트지',
      8: '모조지',
      9: '스노우지',
      10: '반누보화이트',
      11: '유포지',
      12: '투명데드롱',
      13: 'PVC',
      14: '크라프트지',
      15: '유광코팅',
      16: '레인보우코팅',
      17: '무광코팅',
      18: '스파클코팅',
      19: '리무버블아트지',
      20: '강접아트지',
      21: '강접아트지 유광코팅',
      22: '아르떼',
    },
    description: {
      1: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크 위로 필름의 금색이 올라와 노랗게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      2: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크위로 필름의 은색이 올라와 디자인 컬러가 다소 흐리게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      3: '반광이 있는 금색 종이로 손으로 찢을수 있으며, 금광 데드롱에 비해 반사광이 부드럽습니다. 인쇄 잉크위로 금색이 올라와 디자인 컬러가 다소 노랗게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      4: '반광이 있는 은색 종이로 손으로 찢을수 있으며, 은광 데드롱에 비해 반사광이 부드럽습니다. 인쇄 잉크위로 은색이 올라와 디자인 컬러가 다소 흐리게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      5: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크위로 필름의 은빛이 올라와 디자인 컬러가 /다소 연하게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      6: '기름종이로 많이 알려진 용지로 안개처럼 뒷면이 비치는 특수용지 입니다. 흠집이 나면 티가 잘 나고 후가공이 어려운 섬세한 용지로, 떡메모 가공시 일반 모조지보다 점착력이 약할 수 있습니다.',
      7: '균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있으며 색상표현이 뛰어납니다. 다양한 후가공을 우수하게 소화하여 스티커, 행택, 명함, 책 표지 등에 두루 사용됩니다.',
      8: '모조지는 매트한 질감이 특징입니다. 표면에 광택이 없으며 A4용지와 비슷한 느낌으로 펜, 연필 필기가 가능합니다. 잉크 흡수량이 많은 편으로 아트지에 인쇄할 때보다 색이 빠진 것처럼 느껴질 수 있습니다.',
      9: '아트지와 더불어 대중적으로 가장 많이 사용되는 용지입니다. 아트지 보다 광택이 없어 차분한 느낌을 주며 모조지 보다 표면이 매끈하고 색상표현이 뛰어납니다. 표지, 명함, 행택 등 다양한 용도로 사용됩니다.',
      10: '표면에 광택이 없는 부드러운 흰색펄프로 인쇄시 깔끔하고 고급스러운 느낌을 줍니다. 고급지 중 가장 인기있는 재질 중 하나입니다. ',
      11: '인쇄를 위한 특수 코팅이 되어있는 필름 스티커입니다. 색상 표현이 좋고 습기 있는 표면에도 잘 부착되어 냉장 식품 등의 라벨에 많이 사용됩니다. 탄성이 없는 편으로 굴곡면 부착 시 주름이 발생할 수 있습니다.',
      12: '물에 젖지 않고 잘 찢어지지 않는 필름 재질입니다. 투명한 소재 특성상 인쇄 영역에 부착면의 색상이 비칩니다. 백색 인쇄를 추가 하면 백색잉크를 깔고 컬러 인쇄하여 바탕색 비침을 막아 또렷한 색표현이 가능합니다.',
      13: '재질에 탄성이 있어 부착면이 열에 의한 팽창, 수축에 강해 스티커가 훼손되지 않습니다. 떼어낸 점착 면에 끈끈이가 잘 남지 않아 노트북 스티커, 차량스티커, 캐리어 스티커 등에 추천합니다.',
      14: '표백 공정을 거치지 않아 천연 펄프의 색상과 티끌이 보이는 친환경 종이입니다. 인쇄 시 잉크 위로 종이 색이 비칩니다. 자연스러운 비침을 원한다면 그대로, 또렷한 색상 표현을 원한다면 백색 인쇄를 추가하세요.',
      15: '많은 분들께 사랑받는 가장 기본적인 코팅입니다. 코팅이 없을 때보다 색이 선명하게 표현되고 빛을 받으면 반사광이 생깁니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      16: '금색 광택이 뛰어난 메탈 느낌의 재질로 내수성이 강해 물에 젖거나 잘 찢어지지 않습니다. 발색이 잘되어 바탕에 색상을 넣어 인쇄하면 화려한 느낌을 살릴 수 있으나 스크래치에 약해 주의가 필요합니다.',
      17: '코팅이 없을 때보다 색이 선명하게 표현되고 은은한 반사광이 생겨 고급스러운 느낌을 줍니다. 스크래치가 생기면 티가 잘 나는 편입니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      18: '코팅이 없을 때보다 색이 선명하게 표현되며 빛을 받는 각도에 따라 조각 홀로그램이 오색으로 빛나는 글리터 코팅입니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      19: '균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있으며 색상 표현이 뛰어납니다. 특수 스티커 가공을 하여 스티커가 찢어지지 않는 한 탈부착 재사용이 가능합니다.',
      20: '아트지 용지 뒷면에 강력 스티커 가공을 했습니다. 아트지는 균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있는 용지로 색상 표현이 뛰어납니다.',
      21: '강접아트지 스티커 표면 유광 필름을 코팅합니다. 코팅이 없을 때보다 색이 선명하게 표현되고 반사광이 생깁니다. 표면 방수는 되지만 옆면은 방수가 되지 않으니 방수 기능을 원하시면 방수 스티커를 구매해주세요.',
      22: '맑은 흰색의 비 코팅지로 색 재현성이 뛰어납니다. 평량 대비 두께감이 좋고 부드러운 표면감과 은은한 색상으로 여러 디자인에 두루 잘 어울리는 인쇄 전용 종이입니다.',
    },
    info: {
      1: {
        title: '상세 사양',
        content: {
          1: '은광 데드롱 스티커 50g',
          2: '필름 코팅 또는 UV 코팅',
          3: '금광 데드롱 스티커 50g',
          4: '은무지 50g',
          5: '홀로그램 데드롱 50g',
          6: '금무지 50g',
          7: '트레이싱지 90g',
          8: '스티커(80g), 행택(250g) 등',
          9: '스노우지 250g',
          10: '반누보 206g',
          11: '유포 필름 80g',
          12: '투명 데드롱 50g 스티커',
          13: 'PVC 120g',
          14: '표백 없이 제작한 친환경 용지',
          15: '필름 코팅',
          16: '리무버블 아트지 스티커 90g',
          17: '강접 아트지 스티커 80g',
          18: '강접 아트지 스티커 80g + 유광 필름 코팅',
          19: '아르떼 울트라 화이트 230g',
        },
      },
      2: {
        title: '특징',
        content: {
          1: '은광 금속 재질 느낌의 필름 스티커',
          2: '깨끗하고 선명한 느낌',
          3: '은색 무광 재질의 종이 스티커',
          4: '금색 금속 재질 느낌의 필름 스티커',
          5: '금색 무광 재질의 종이 스티커',
          6: '뒷면이 비치는 도톰한 반투명 종이',
          7: '자체 광택이 있는 종이',
          8: '빛반사가 없는 비코팅 용지',
          9: '은은한 반사광의 백색 용지',
          10: '색상 표현이 좋은 매트지',
          11: '물에 강하고 잘 찢어지지 않는 백색 필름',
          12: '물에 젖지 않는 투명 필름',
          13: '끈끈이가 잘 남지 않는 유연한 필름 스티커',
          14: '자연스러운 색상과 티끌',
          15: '화려한 무지개빛 반사광',
          16: '은은하고 부드러운 빛 반사',
          17: '화려한 글리터 반사광',
          18: '접착력이 약한 종이 스티커',
          19: '접착력이 강한 종이 스티커',
          20: '표면 방수가 되는 강한 접착력의 스티커',
          21: '약간의 종이결이 느껴지는 매트한 종이',
          22: '무지개색 반사광이 생기는 필름 스티커',
        },
      },
      3: {
        title: '표면 질감',
        content: {
          1: '매끈한 재질',
          2: '매끈한 질감',
          3: '부드러운 질감',
          4: '부드러운 재질',
          5: '다소 거친 질감',
        },
      },
      4: {
        title: '필기',
        content: {
          1: '불가능',
          2: '유성펜',
          3: '시간에 따라 번질 수 있습니다.',
          4: '네임펜 / 연필',
          5: '유성펜 / 볼펜 / 네임펜 / 연필 등',
          6: '스티커(80g), 봉투, 떡메모지(100g) 등',
          7: '유성펜 / 수성펜 / 연필',
          8: '유성펜 / 볼펜 / 연필',
          9: '유성펜 / 볼펜',
          10: '유성펜 / 연필 / 볼펜 등',
          11: '유성펜 / 연필',
          12: '연필',
        },
      },
    },
  },
  modify_info: {
    title: '수정사항 안내',
    1: {
      name: '폰트 윤곽선',
      description:
        '폰트(서체)를 사용하셨다면 반드시 아웃라인화(Create Outline) 해주세요. <br> 제작 기기에 해당 폰트가 설치돼 있지 않다면 굴림체, 바탕체 같은 기본 폰트로 출력됩니다.',
      content_1: "방법 1. 텍스트 오브젝트 우클릭 > '윤곽선 만들기'",
      content_2: '방법 2. 텍스트 오브젝트 클릭 > Ctrl + Shift + O 단축키 사용',
    },
    2: {
      name: 'PDF 저장 옵션',
      description:
        '반드시 아래 옵션으로 도안을 PDF로 저장해 주세요.<br>작업에 맞춘 옵션으로 저장해주셔야 원활한 제작 진행이 가능합니다.',
      content_1:
        '파일 > 다른 이름으로 저장 > 내 컴퓨터에 저장 (파일 포맷 PDF) <br> 1. Adobe PDF 사전 설정  [PDF/X-4:2008(Japan)] 을 선택 <br> 2. [페이지 축소판 포함], [상위 레이어 에서부터 Acrobat 레이어만들기] 체크 <br> 3. PDF 저장',
    },
    3: {
      name: '이미지 임베드',
      description:
        '이미지를 사용하셨다면 꼭 embed 해주세요.<br>디자인에 사용된 이미지를 클릭했을때 X자가 보인다면 이미지가 고객님의 컴퓨터에서만 보이는 상태입니다. ',
      content_1:
        '이미지를 클릭 후 상단 패널에서 [포함] 버튼을 눌러주세요. <br> 여러개의 이미지를 사용하셨다면 모두 동일한 방법으로 이미지를 (Embed)시켜주세요',
    },
    4: {
      name: '사이즈 다름',
      description:
        '업로드하신 도안이 주문하신 사이즈와 다릅니다.  <br> 템플릿 다운받기 버튼을 눌러, 구매 하신 사이즈에 맞는 도안으로 작업해주세요.',
    },
  },
  checkout: {
    'price_summary-1': '',
    price_summary: {
      1: 'Order Quantity',
      2: 'Total product price',
      3: 'Total shipping fee',
      4: 'Estimated Payment Amount',
    },
  },
  CustomDetail: {
    1: 'Recommandation',
  },
  additional_payment: {
    '결제방법 선택': 'Choose an payment method',
    결제하기: '',
    '추가 결제하기': 'Additional payment',
  },
};
