export const rejectImpUid = (str) => {
  if (typeof str !== 'string') return str;
  return str
    .replace(/\(imp_\d+\)/g, '. ')
    .replace(/\.\./g, '.')
    .replace(/F[0-9]*:/g, '')
    .replace(/[0-9]*,/g, '')
    .replace(/[0-9]*\|/g, '')
    .replace(/\s\s/g, ' ');
};
