import format from 'date-fns/format/index.js';
import { html } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { messages } from '../../Setting/S/messages_id.js';
import { makeSellerStudioTable } from '../../Setting/S/table.js';
import { getWithdrawLimitDate } from './util.js';

const formatDate = (s) => (s ? format(new Date(s), 'yyyy-MM-dd HH:mm') : '');

const makeWithdrawTableHtml = (withdraws, bank_account_type, holidays, is_tpl_possible) => {
  const withdraw_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">${T(messages.tooltip.t6)}</div>
  `;
  return makeSellerStudioTable({
    col_rates: [
      ...[
        { rate: 10, align: 'left' },
        { rate: is_tpl_possible ? 10 : 12, align: 'center' },
        { rate: is_tpl_possible ? 10 : 12, align: 'center' },
      ],
      ...(is_tpl_possible ? [{ rate: 10, align: 'center' }] : []),
      ...[
        { rate: is_tpl_possible ? 10 : 12, align: 'center' },
        { rate: is_tpl_possible ? 10 : 12, tool_tip: withdraw_amount_tooltip_content },
        { rate: 8, align: 'center' },
        { rate: 8, align: 'center' },
        { rate: 8, align: 'center' },
        { rate: is_tpl_possible ? 8 : 10, align: 'center' },
        { rate: 8, align: 'center' },
      ],
    ],
    headers: [
      ...[
        T(messages.table.th30),
        `${T(messages.menus.design_adjustments)} ${T(messages.table.th38)}`,
        `${T(messages.menus.seller_adjustments)} ${T(messages.table.th38)}`,
      ],
      ...(is_tpl_possible ? [`${T(messages.menus.tpl_adjustments)} ${T(messages.table.th38)}`] : []),
      ...[
        `${T(messages.menus.dtg_adjustments)} ${T(messages.table.th38)}`,
        T(messages.table.th31),
        T(messages.table.th32),
        T(messages.table.th33),
        T(messages.table.th34),
        `${T(messages.table.th35)}(${T(messages.table.th36)})`,
        T(messages.table.th37),
      ],
    ],
    bodys: withdraws.map(
      ({
        amount,
        pod_amount,
        pb_amount,
        tpl_amount,
        dtg_amount,
        reason,
        created_at,
        approved_at,
        canceled_at,
        real_amount,
      }) => {
        return [
          ...[formatDate(created_at), UtilS.commify(pod_amount), UtilS.commify(pb_amount)],
          ...(is_tpl_possible ? [UtilS.commify(tpl_amount)] : []),
          ...[
            UtilS.commify(dtg_amount),
            UtilS.commify(amount),
            approved_at ? UtilS.commify(real_amount) : '-',
            approved_at ? '-' : format(getWithdrawLimitDate(created_at, holidays), 'yyyy-MM-dd'),
            formatDate(approved_at) || '-',
            `${formatDate(canceled_at) || '-'} ${reason ? `(${reason})` : ''}`,
            approved_at
              ? T(messages.withdraws.finish)
              : canceled_at
              ? T(messages.withdraws.cancel)
              : T(messages.withdraws.pending),
          ],
        ];
      },
    ),
  });
};

export const makeWithdrawsSettingsBodyHtml = ({
  without_fee_amount,
  withdraws,
  bank_account_type,
  is_bank_account_filled,
  holidays,
  crew_withdraw_type,
  is_tpl_possible,
  // domain_name,
}) => {
  // const provide_amount = Math.floor(+withdraw_amount / 1.1);
  // const tax_amount = withdraw_amount - provide_amount;
  return html`
    <div class="header flex">
      <div class="left">
        <h2>${T(messages.menus.withdraws)}</h2>
        <p>${T(messages.withdraws.p1)}</p>
      </div>
      <div class="right">
        <div class="studio-withdraw">
          <div class="studio-withdraw__amount" data-show="${!!is_bank_account_filled}">
            ${T(messages.withdraws.p2)}<span
              class="studio-withdraw__available"
              data-without_fee_amount="${without_fee_amount}"
            >
              ${UtilS.commify(without_fee_amount)}원</span
            >
          </div>
          ${crew_withdraw_type === 'separate'
            ? `<button class="btn btn-blue request_withdraw">
            <img
              src="//s3.marpple.co/files/u_29089/2023/1/original/ed78b030f18cb8abfe21e1cb3831966c19b401ac1.webp"
              alt=""
            />
            ${T(messages.withdraws.btn1)}
          </button>`
            : ''}
        </div>
      </div>
    </div>
    <div
      class="bank_account"
      data-show="false"
      data-fx-json=${JSON.stringify({ bank_account_type, is_bank_account_filled })}
    ></div>
    <div class="body">
      <div id="withdraws" class="table-wrapper">
        ${makeWithdrawTableHtml(withdraws, bank_account_type, holidays, is_tpl_possible)}
      </div>
    </div>
  `;
};
