import {
  cond,
  curry,
  defaultTo,
  equals,
  filter,
  find,
  go,
  html,
  ifElse,
  not,
  pluck,
  reject,
  sel,
  selEq,
  strMap,
  take,
  zipWithIndexL,
} from 'fxjs/es';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { MShopStudioTokenGateStoresProductTmplS } from '../../../MShop/Studio/TokenGate/StoresProduct/S/Tmpl/module/MShopStudioTokenGateStoresProductTmplS.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { NewMakerWeS } from '../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import { creatorPriceOfProduct } from '../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { CREW_DOMAIN_NAME } from '../../Crew/S/constant.js';
import { MAXIMUM_PROFIT, PRODUCT_STATUS } from '../../Product/S/constant.js';
import { makeMinProfit } from '../../Product/S/fs.js';
import { makeProductStatusHtml } from '../../Product/S/tmpl.js';
import { makeProductStoryEditorHtml } from '../../ProductStoryEditor/S/tmpl.js';
import {
  getAvailableColors,
  getProductStatus,
  getProhibitedColors,
  hasColorOptions,
  makePriceListData,
  selProductStatus,
} from './fs.js';
import { messages } from './messages_id.js';
import { checkIf } from './tmpl.js';
import { makeColorText } from './util.js';

const makeProductThumbnailPaginationItemHtml = (thumbnail_image, product) => legacyHtml`
  <div class="thumbnail-pagination-item">
    ${MpShopProductTmplS.thumbnail(product, {
      width: 68,
      thumbnail_image,
    })}
  </div>
`;
export const makeProductThumbnailPaginationItemsHtml = (pagination_thumbnails, current_index = 0, product) =>
  strMap(
    ([i, thumb]) => legacyHtml`
      <li data-current="${i === current_index}">${makeProductThumbnailPaginationItemHtml(thumb, product)}</li>
    `,
    zipWithIndexL(pagination_thumbnails),
  );
const makeProductThumbnailEditLayerHtml = (index, size) => legacyHtml`
  <div class="product-thumbnail-editor" data-index="${index}">
    ${UtilS.htmlIf2(
      index > 0,
      () =>
        legacyHtml`
          <button data-name="btn_move_thumbnail" class="btn btn-yellow" data-direction="prev">이전</button>
        `,
    )}
    ${UtilS.htmlIf2(
      index < size - 1,
      () =>
        legacyHtml`
          <button data-name="btn_move_thumbnail" class="btn btn-yellow" data-direction="next">다음</button>
        `,
    )}
    <button data-name="btn_remove_thumbnail" class="btn btn-remove">삭제</button>
  </div>
`;
const isPBThumbnail = ({ bpf_id }) => typeof bpf_id === 'string' && bpf_id.startsWith('pb_thumb');
export const makeProductThumbnailItemsHtml = ({ product, thumbnails, current_index = 0, editable = true }) =>
  strMap(
    ([i, thumb]) => legacyHtml`
      <li data-current="${i === current_index}">
        ${UtilS.htmlIf2(isPBThumbnail(thumb), () =>
          makeProductThumbnailEditLayerHtml(i, go(thumbnails, reject(selEq('bpf_id', null)), sel('length'))),
        )}
        ${makeProductThumbnailItemHtml(product, thumb)}
        ${UtilS.htmlIf2(editable, () => makePrimaryThumbRadioInputHtml(i, sel('is_thumb', thumb)))}
      </li>
    `,
    zipWithIndexL(thumbnails),
  );
export const makeProductThumbnailSliderHtml = ({
  thumbnails = [],
  pagination_thumbnails = thumbnails,
  editable = true,
  product,
}) => {
  const thumb_idx = thumbnails.findIndex((a) => a.is_thumb);
  const main_idx = thumbnails.findIndex((a) => a.is_thumb) === -1 ? 0 : thumb_idx;
  return legacyHtml`
    <div class="thumbnail-slider">
      <div class="form-item">
        <ul class="thumbnail-pagination">
          ${UtilS.htmlIf2(pagination_thumbnails.length > 1, () =>
            makeProductThumbnailPaginationItemsHtml(pagination_thumbnails, main_idx, product),
          )}
        </ul>
      </div>
      <button data-direction="prev"><</button>
      <button data-direction="next">></button>
      <ul class="slider-items">
        ${makeProductThumbnailItemsHtml({ product, thumbnails, current_index: main_idx, editable })}
      </ul>
    </div>
  `;
};

export const makeAdditionalProfitColumnHtml = (additional_profit) => {
  return additional_profit === 0
    ? legacyHtml` <span>-</span> `
    : legacyHtml` <span class="mp_currency2">${UtilS.commify(additional_profit)}</span> `;
};

const makeCrewPriceTableHtml = (prices) => {
  return legacyHtml`
    <table>
      <thead>
        <tr>
          <th>${T('translation::판매 수량')}</th>
          <th>${T('translation::1개당 판매 수익')}</th>
          <th class="bonus-profit-service">${T('translation::1개당 추가 수익')}</th>
          <th>${T('translation::총 수익')}</th>
        </tr>
      </thead>
      <tbody>
        ${strMap(
          ({ quantity, profit, product_price }) => legacyHtml`
            <tr>
              <th>${quantity}</th>
              <td class="profit">
                <span class="mp_currency2">${UtilS.commify(profit)}</span>
              </td>
              <td class="profit bonus-profit-service">
                ${makeAdditionalProfitColumnHtml(prices[0].product_price - product_price)}
              </td>
              <td class="profit">
                <span class="mp_currency2">${UtilS.commify(PriceS.mult(profit, quantity))}</span>
              </td>
            </tr>
          `,
          prices,
        )}
      </tbody>
    </table>
  `;
};

const makeCrewSellerPriceTableHtml = curry(
  (seller_profit_share, prices) => legacyHtml`
    <table data-target="seller">
      <thead>
        <tr>
          <th>${T('translation::판매 수량')}</th>
          <th>${T('기본 제작 단가')}</th>
          <th>${T('1개당 셀러 수익')}</th>
          <th>${T('1개당 기업 수익')}</th>
        </tr>
      </thead>
      <tbody>
        ${strMap(({ quantity, profit, product_price }) => {
          const seller_profit = Number((profit * seller_profit_share).toFixed(4));
          const company_profit = Number((profit - seller_profit).toFixed(4));
          return legacyHtml`
            <tr>
              <td>${quantity}</td>
              <td class="profit">
                <span class="mp_currency2">${UtilS.commify(product_price)}</span>
              </td>
              <td class="profit">
                <span class="mp_currency2"
                  >${UtilS.commify(seller_profit.toFixed(seller_profit % 1 === 0 ? 0 : 2))}</span
                >
              </td>
              <td class="profit">
                <span class="mp_currency2"
                  >${UtilS.commify(company_profit.toFixed(company_profit % 1 === 0 ? 0 : 2))}</span
                >
              </td>
            </tr>
          `;
        }, prices)}
      </tbody>
    </table>
  `,
);

const makeCrewAdminPriceTableHtml = curry(
  (seller_profit_share, prices) =>
    legacyHtml`
      <table data-target="company">
        <thead>
          <tr>
            <th>${T('판매 수량')}</th>
            <th>${T('1개 당 기본 제작 단가')}</th>
            <th>${T('1개 당 기업 제작 단가')}</th>
            <th>${T('기업 할인 수익')}</th>
            <th>${T('기업 디자인 수익')}</th>
            <th>${T('셀러 디자인 수익')}</th>
          </tr>
        </thead>
        <tbody>
          ${strMap(({ quantity, product_price, profit, crew_margins }) => {
            const seller_profit = Number((profit * seller_profit_share).toFixed(4));
            const company_profit = Number((profit - seller_profit).toFixed(4));
            const total_seller_profit = seller_profit * quantity;
            const total_company_profit = company_profit * quantity;
            return legacyHtml`
              <tr>
                <td>${quantity}</td>
                <td class="profit">
                  <span class="mp_currency2">${UtilS.commify(product_price)}</span>
                </td>
                <td class="profit">
                  <span class="mp_currency2"> ${UtilS.commify(product_price - crew_margins)}</span>
                </td>
                <td class="profit">
                  <span class="mp_currency2">${UtilS.commify(crew_margins * quantity)}</span>
                </td>
                <td class="profit">
                  <span class="mp_currency2"
                    >${UtilS.commify(
                      total_company_profit.toFixed(total_company_profit % 1 === 0 ? 0 : 2),
                    )}</span
                  >
                </td>
                <td class="profit">
                  <span class="mp_currency2"
                    >${UtilS.commify(
                      total_seller_profit.toFixed(total_seller_profit % 1 === 0 ? 0 : 2),
                    )}</span
                  >
                </td>
              </tr>
            `;
          }, prices)}
        </tbody>
      </table>
    `,
);

export const makePriceTableHtml = ({
  price_list,
  title,
  crew_domain_name = CREW_DOMAIN_NAME.MARPPLE,
  is_crew_admin = false,
  seller_profit_share = 1,
}) => {
  price_list =
    typeof window !== 'undefined' && window.location.href.indexOf('plus.marpple.shop') != -1
      ? take(1, price_list)
      : price_list;

  const price_table_html = cond(
    [() => crew_domain_name === CREW_DOMAIN_NAME.MARPPLE, makeCrewPriceTableHtml],
    [() => is_crew_admin, makeCrewAdminPriceTableHtml(seller_profit_share)],
    [() => true, makeCrewSellerPriceTableHtml(seller_profit_share)],
  )(price_list);
  return legacyHtml`
    <div class="form-item price-list">
      ${title}
      <div class="table" lang="kr">${price_table_html}</div>
    </div>
  `;
};

export const makeProfitFormItemHtml = ({
  min,
  max = MAXIMUM_PROFIT,
  value,
  can_not_change_price,
  title,
  disabled = false,
  class_names = [],
}) => {
  return legacyHtml`
    <div class="form-item" lang="kr">
      ${title}
      <input
        ${UtilS.addAttrIf(disabled, 'disabled')}
        type="text"
        name="profit"
        class="${class_names.join(' ')}"
        placeholder="${T('가격을 설정해 주세요.')}"
        value="${UtilS.commify(value || min)}"
        min="${min}"
        max="${max}"
        ${can_not_change_price ? 'readonly="readonly"' : ''}
      />
    </div>
  `;
};

export const makeRetailPriceHtml = ({ value, title, klass = 'customer_price' }) => {
  return legacyHtml`
    <div class="form-item">
      ${title}
      <div class="${klass} mp_currency2">${UtilS.commify(value)}</div>
    </div>
  `;
};

const makeLimitedSaleFormItemHtml = ({
  mode = 'create',
  has_period_limit,
  has_quantity_limit,
  quantity,
  is_quantity_public,
  is_studio_worker_admin,
  max_purchase_per_user,
}) => {
  const DEFAULT_QUANTITY = 100;
  const MAX_QUANTITY = 10000;

  return html`
    <div class="form-item sales-period" data-item_name="limited_edition">
      <span class="title has-tooltip">
        ${T('한정 판매 기간 & 수량 설정')}
        <span class="tooltip-icon"></span>
        <div class="tooltip-container">
          <h4>${T('한정 판매 기간 & 수량 설정')}</h4>
          <p>${T(messages.tooltip.t5)}</p>
        </div>
      </span>
      <div class="form-item limited-labels">
        <div class="limited-label">
          <input
            id="check_limited_period_${mode}"
            type="checkbox"
            name="limited_sale_type"
            value="period"
            ${checkIf(has_period_limit)}
          />
          <label for="check_limited_period_${mode}"> ${T('한정 판매 기간')} </label>
        </div>
        <div class="limited-label">
          <input
            id="check_limited_quantity_${mode}"
            type="checkbox"
            name="limited_sale_type"
            value="quantity"
            ${checkIf(has_quantity_limit)}
          />
          <label for="check_limited_quantity_${mode}">${T('한정 판매 수량')}</label>
        </div>
        <div class="limited-label">
          <input
            id="check_max_purchase_per_user_${mode}"
            type="checkbox"
            name="limited_sale_type"
            value="max_per_user"
            ${checkIf(max_purchase_per_user)}
          />
          <label for="check_max_purchase_per_user_${mode}">${T('1인당 구매 가능 수량')}</label>
        </div>
      </div>
      <div
        class="form-item"
        data-limited_sale_type="period"
        ${UtilS.addAttrIf(!has_period_limit, 'style', 'display: none;')}
      >
        <span class="title"> ${T('한정 판매 기간')}</span>
        <div class="sales_period">
          <input name="sell_at" />
          <input name="sell_start_at" type="hidden" />
          <input name="sell_end_at" type="hidden" />
        </div>
      </div>
      <div
        data-limited_sale_type="quantity"
        ${UtilS.addAttrIf(!has_quantity_limit, 'style', 'display: none;')}
      >
        <div class="form-item">
          <span class="title"> ${T('한정 판매 수량')}</span>
          <input
            type="number"
            name="quantity"
            min="1"
            max="${MAX_QUANTITY}"
            step="1"
            value="${quantity || DEFAULT_QUANTITY}"
          />
        </div>
        <div
          class="form-item"
          data-item_name="is_quantity_public"
          ${UtilS.addAttrIf(!is_studio_worker_admin, 'style', 'display: none;')}
        >
          <span class="title"> ${T('한정 판매 수량 공개/비공개')}</span>
          <label>
            <input
              id="check_is_quantity_${mode}_public_true"
              type="radio"
              name="is_quantity_public"
              value="true"
              ${checkIf(is_quantity_public)}
            />
            <label for="check_is_quantity_${mode}_public_true">${T('수량 공개')}</label> </label
          ><label>
            <input
              id="check_is_quantity_${mode}_public_false"
              type="radio"
              name="is_quantity_public"
              value="false"
              ${checkIf(not(is_quantity_public))}
            />
            <label for="check_is_quantity_${mode}_public_false">${T('수량 비공개')}</label>
          </label>
        </div>
      </div>
      <div
        class="form-item"
        data-limited_sale_type="max_per_user"
        ${UtilS.addAttrIf(!max_purchase_per_user, 'style', 'display: none;')}
      >
        <span class="title"> ${T('1인당 구매 가능 수량')}</span>
        <input
          type="number"
          name="max_purchase_per_user"
          min="1"
          max="999999"
          step="1"
          value="${max_purchase_per_user || 1}"
        />
      </div>
    </div>
  `;
};

const makeColorListHtml = (input_name, color_options, checked_ids, disabled_colors) => legacyHtml`
  <ul class="color-option-list">
    ${strMap(
      (a) => legacyHtml`
        <li>
          <label class="color-option-item" data-checked="${checked_ids.includes(a.id)}">
            <span class="color-circle" style="background: ${
              a.color_code2
                ? `linear-gradient(135deg, ${
                    a.color_code == '#ffffff' || a.color_code == '#fefefe' ? '#f8f8f8' : a.color_code
                  } 50%, ${
                    a.color_code2 == '#ffffff' || a.color_code2 == '#fefefe' ? '#f8f8f8' : a.color_code2
                  } 50%)`
                : a.color_code
            };">
              <img
                src="//s3.marpple.co/files/u_1093672/2020/9/original/1fea8dac96a5cb54304e66567e295433219669402.png"
              />
            </span>
            <input
              type="checkbox"
              name="${input_name}"
              value="${a.id}"
              ${checkIf(find((id) => id === a.id, checked_ids))}
              ${UtilS.addAttrIf(disabled_colors.includes(a.id), 'disabled')}
            />
            <span class="color-name">${makeColorText(a)}</span>
          </label>
        </li>
      `,
      color_options,
    )}
  </ul>
`;

export const makeEnabledColorsFormItemHtml = ({
  base_product_colors,
  store_product_colors,
  primary_color_id,
  title = legacyHtml` ${T('판매 색상 선택')}&nbsp;<small>${T('다중 선택 가능')}</small> `,
  check_default = false,
  enable_check_all = true,
  is_primary_color_soldout,
}) => {
  const enabled_color_ids = pluck(['base_product_color_id'], filter(sel('enabled'), store_product_colors));

  const checked_ids = is_primary_color_soldout
    ? enabled_color_ids.length
      ? [primary_color_id, ...enabled_color_ids]
      : pluck(['id'], base_product_colors)
    : sel('length', store_product_colors)
    ? enabled_color_ids
    : check_default
    ? pluck(['id'], base_product_colors)
    : [primary_color_id];
  const checked_all = checked_ids.length === base_product_colors.length;
  return legacyHtml`
    <div class="form-item" data-item_name="colors">
      <div class='title-wrap'>
        <span class="title">${title}</span>
        ${UtilS.htmlIf2(
          enable_check_all && base_product_colors.length > 5,
          () => legacyHtml`
            <label class="check-all"
              ><input type="checkbox" ${UtilS.addAttrIf(checked_all, 'checked')} />${T('전체 선택')}</label
            >
          `,
        )}
      </div>
      ${makeColorListHtml('enabled_colors', base_product_colors, checked_ids, [primary_color_id])}
    </div>
  `;
};

const makeShippingDateVisibilityFormItemHtml = (shipping_date_hidden = false) => {
  return html`
    <div class="form-item" data-item_name="shipping_date_visibility">
      <span class="title">${T('translation::배송 출발일 노출')}</span>
      <label>
        <input
          id="shipping_date_visible"
          type="radio"
          name="shipping_date_hidden"
          value="false"
          ${checkIf(not(shipping_date_hidden))}
        />
        <label for="shipping_date_visible">${T('translation::공개')}</label>
      </label>
      <label>
        <input
          id="shipping_date_hidden"
          type="radio"
          name="shipping_date_hidden"
          value="true"
          ${checkIf(shipping_date_hidden)}
        />
        <label for="shipping_date_hidden">${T('translation::비공개')}</label>
      </label>
    </div>
  `;
};

const makeProhibitedColorsFormItemHtml = (base_product_colors, store_product_colors, primary_color_id) => {
  const checked_ids = sel('length', store_product_colors)
    ? pluck(['base_product_color_id'], filter(sel('prohibited'), store_product_colors))
    : [];
  return legacyHtml`
    <div class="form-item">
      <span class="title">${T('인쇄 불가능 색상')}</span>
      ${makeColorListHtml('prohibited_colors', base_product_colors, checked_ids, [primary_color_id])}
    </div>
  `;
};

const makeColorSelectorHtml = ({ colors, selected_color_id, input_name }) => {
  const selected_color = find((c) => c.id === selected_color_id, colors);

  return legacyHtml`
    <div class="color-selector">
      <div class="selected-item">
        <span class="color-circle" style="background-color: ${selected_color.color_code};"></span>
        <span class="color-name">${makeColorText(selected_color)}</span>
        <img
          class="icon-dropdown"
          src="//s3.marpple.co/files/u_1093672/2020/9/original/e71d8ec0fcd9087bd4c8790242b155b503785d734.png"
        />
      </div>
      <select name="${input_name}">
        ${strMap(
          (c) =>
            legacyHtml`
              <option
                value="${c.id}"
                ${UtilS.addAttrIf(c.id === selected_color_id, 'selected')}
                ${UtilS.addAttrIf(c[`is_public${_collabo}`] === false, 'disabled')}
              >
                ${makeColorText(c)}
              </option>
            `,
          colors,
        )}
      </select>
    </div>
  `;
};

export const makePrimaryColorFormItemHtml = (colors, selected_color_id) => legacyHtml`
  <div class="form-item">
    <span class="title">${T('대표 색상 선택')}</span>
    ${makeColorSelectorHtml({ colors, selected_color_id, input_name: 'primary_color' })}
  </div>
`;

export const makeEnabledColorListHtml = (enabled_colors) => legacyHtml`
  <div class="form-item">
    <span class="title">${T('선택한 색상 옵션')} </span>
    <ul class="color-list">
      ${strMap(
        (c) =>
          legacyHtml`
            <li>
              <label class="color-list-item">
                <span class="color-circle" style="background-color: ${c.color_code};"></span>
                <span class="color-name">${c.name}</span>
              </label>
            </li>
          `,
        enabled_colors,
      )}
    </ul>
  </div>
`;

export const makeProhibitedColorListHtml = (prohibited_colors) => legacyHtml`
  <div class="form-item">
    <span class="title"
      >${T('인쇄 불가능 색상')}
      <i class="color-notification"
        >(${T('디자인에 따라 특정 색상의 인쇄 품질이 떨어질 수 있어 판매가 제한됩니다')}.)</i
      ></span
    >

    <ul class="color-list">
      ${strMap(
        (c) =>
          legacyHtml`
            <li>
              <label class="color-list-item">
                <span class="color-circle" style="background-color: ${c.color_code};"></span>
                <span class="color-name">${c.name}</span>
              </label>
            </li>
          `,
        prohibited_colors,
      )}
    </ul>
  </div>
`;

// 트리플A 상품 때문에 임시적으로 공개여부 변경을 못하도록 막으려고
function needHiddenPublicForm(base_product) {
  return [3023, 3132, 3136].includes(base_product.id);
}

export const makeDefaultEditorHeaderHtml = (product_status_html = '<div></div>') => {
  return legacyHtml`
    <div class="buttons">
      ${product_status_html}
      <button class="btn btn-submit has-icon">
        <img
          src="//s3.marpple.co/files/u_1093672/2020/9/original/17dfcb4713cfa10c1f97391cd0a67d3af46f10521.png"
        />
        ${T('상품 생성')}
      </button>
    </div>
  `;
};

const makeProductEditorHtml = (
  product,
  {
    mode = 'create',
    need_confirm_action,
    has_permission_to_confirm,
    is_studio_worker_admin,
    editor_header,
    crew_domain_name,
    is_crew_admin,
    origin_store_product,
    fixed_bp_profit,
    store_url,
  },
) => {
  const {
    id,
    name,
    name_en,
    name_jp,
    profit,
    is_public,
    is_digital_product,
    _: { can_not_change_price, has_up, stores_product = {}, base_product, afreeca_product },
    product_faces2: { can_not_change_color },
    base_product_color_id,
  } = product;

  const {
    quantity,
    is_quantity_public,
    sell_start_at,
    sell_end_at,
    is_confirmed,
    is_confirm_req,
    seller_profit_share,
    shipping_date_hidden,
    max_purchase_per_user,
    _: { store_product_colors = [], digital_product = {}, stores_products_token_gates = [] } = {},
  } = stores_product;

  const {
    cate_list_id,
    cate_item_id,
    thumbnail_ratio,
    _: { base_product_colors },
    price_creator: base_product_price,
  } = base_product;
  const has_quantity_limit = quantity > 0;
  const has_period_limit = sell_start_at && sell_end_at;
  const thumbnails = defaultTo([], sel('value', product.thumbnails));

  const has_color_options =
    hasColorOptions(base_product, base_product_colors.length, can_not_change_color) &&
    not(NewMakerWeS.isWeBaseProduct(base_product.id)) &&
    not(is_digital_product);
  const prohibited_colors = getProhibitedColors(base_product_colors, store_product_colors);
  const available_colors = getAvailableColors(base_product_colors, store_product_colors);

  const primary_color = find(({ id }) => id === base_product_color_id, base_product_colors);
  const is_primary_color_soldout = not(primary_color.is_public) || not(primary_color.is_public_creator);

  const is_afreeca =
    afreeca_product?.id || crew_domain_name === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_DOMAIN;

  const profit_title = T('translation::디자인 가격');
  const profit_tooltip_content = is_afreeca
    ? T('translation::디자인 가격 부가세 포함 가격 아프리카')
    : T('translation::디자인 가격 부가세 포함 가격');
  const min_profit = makeMinProfit(product);
  const profit_sub_title = html`<small
    >${T('최소')}&nbsp;<span class="mp_currency2">${UtilS.commify(min_profit)}</span> ~
    ${T('최대')}&nbsp;<span class="mp_currency2">${UtilS.commify(MAXIMUM_PROFIT)}</span></small
  >`;
  const retail_price_title = T('소비자 가격');
  const retail_price_tooltip_content = T('디자인 가격이 더해져 자동 계산됩니다.', {
    title: is_digital_product ? T('판매 수수료') : T('기본 상품 가격'),
  });
  const price_list = makePriceListData(product);

  return html`
    <div
      class="product-editor"
      has_up="${has_up}"
      can_not_change_price="${can_not_change_price}"
      is_composite="${!!thumbnails[0].composite_template_id}"
    >
      <div class="setting-thumbnail">
        ${makeProductThumbnailSliderHtml({
          thumbnails,
          product: {
            ...product,
            thumbnail_ratio,
          },
        })}
      </div>
      <div class="form">
        ${editor_header}
        <input type="hidden" name="id" value="${id}" />
        <input type="hidden" name="sp_cate_list_id" value="${cate_list_id}" />
        <input type="hidden" name="sp_cate_item_id" value="${cate_item_id}" />
        ${UtilS.htmlIf2(
          need_confirm_action,
          () => html`
            <div class="form-item">
              <span class="title">${T('plus_admin::pa290')}</span>
              <p>
                <label class="align-center-flex"
                  ><input type="checkbox" name="is_confirm_req" ${checkIf(is_confirm_req)} />${T(
                    'mshop::ProductStatus::not_confirmed',
                  )}</label
                >
              </p>
              ${(function () {
                if (has_permission_to_confirm) {
                  return html`
                    <p>
                      <label for="check_product_confirm" class="align-center-flex"
                        ><input
                          id="check_product_confirm"
                          type="checkbox"
                          name="is_confirmed"
                          ${checkIf(is_confirmed)}
                        />${T('plus_admin::pa258_1')}</label
                      >
                    </p>
                  `;
                } else if (is_confirmed) {
                  return html`<p>
                    <label for="check_product_confirm" class="align-center-flex"
                      ><input type="checkbox" ${checkIf(is_confirmed)} />${T('plus_admin::pa258_1')}</label
                    >
                  </p>`;
                }
              })()}
            </div>
          `,
        )}
        ${makePublicFormItemHtml({ is_public, base_product, mode, is_afreeca })}
        ${UtilS.htmlIf2(not(is_digital_product) && is_studio_worker_admin, () =>
          makeShippingDateVisibilityFormItemHtml(shipping_date_hidden),
        )}
        ${makeNameFormItemHtml({ title: `${T('디자인 이름')}`, name, name_en, name_jp })}
        ${UtilS.htmlIf2(has_color_options, () => {
          return makeEnabledColorsFormItemHtml({
            base_product_colors,
            store_product_colors,
            primary_color_id: base_product_color_id,
            is_primary_color_soldout,
          });
        })}
        ${UtilS.htmlIf2(has_color_options, () => {
          const colors = is_primary_color_soldout
            ? available_colors.length > 0
              ? [primary_color, ...available_colors]
              : base_product_colors
            : store_product_colors.length > 0
            ? available_colors
            : [find(({ id }) => id == base_product_color_id, available_colors)];
          return makePrimaryColorFormItemHtml(colors, base_product_color_id);
        })}
        ${UtilS.htmlIf2(has_color_options && is_studio_worker_admin, () =>
          makeProhibitedColorsFormItemHtml(base_product_colors, store_product_colors, base_product_color_id),
        )}
        ${UtilS.htmlIf2(has_color_options && !is_studio_worker_admin && prohibited_colors.length, () =>
          makeProhibitedColorListHtml(prohibited_colors, base_product_colors),
        )}
        ${UtilS.htmlIf2(
          !fixed_bp_profit && is_digital_product,
          () =>
            html`
              ${makeProfitFormItemHtml({
                value: mode === 'create' ? min_profit : profit + base_product_price,
                min: min_profit,
                title: html`<span class="title"
                  >${T('디지털굿즈 판매 가격')}<small
                    >${T('최소')}&nbsp<span class="mp_currency2">${UtilS.commify(min_profit)}</span></small
                  ></span
                >`,
                can_not_change_price,
                class_names: ['digital-product-price'],
              })}
              <div class="form-item" data-item_name="digital_product_notice">
                <span class="title">판매 수수료 (정액제)</span>
                <div class="contents">
                  <p>디지털굿즈 판매 수수료는 1건당 400원이며 아래 비용을 포함하고 있습니다.</p>
                  <ul>
                    <li>클라우드 스토리지 비용</li>
                    <li>업로드/다운로드 트래픽 비용</li>
                    <li>구매 문자/알림톡 및 이메일 전송 비용</li>
                    <li>저작권 보호를 위한 구매 고객 휴대폰 인증 비용</li>
                  </ul>
                </div>
              </div>
            `,
          () => html`
            <div class="form-group" data-group_name="price">
              ${makeProfitFormItemHtml({
                value: profit,
                min: min_profit,
                title: makeTitleWithTooltipHtml({
                  title: profit_title,
                  sub_title: profit_sub_title,
                  tooltip_content: profit_tooltip_content,
                }),
                can_not_change_price,
              })}
              ${makeRetailPriceHtml({
                value: creatorPriceOfProduct(product),
                title: makeTitleWithTooltipHtml({
                  title: retail_price_title,
                  tooltip_content: retail_price_tooltip_content,
                }),
              })}
            </div>
          `,
        )}
        ${UtilS.htmlIf2(crew_domain_name !== CREW_DOMAIN_NAME.MARPPLE, () =>
          makeProfitShareFormItemHtml(
            sel('seller_profit_share', origin_store_product) || seller_profit_share,
            is_public || has_up,
          ),
        )}
        ${UtilS.htmlIf2(not(is_digital_product), () =>
          makePriceTableHtml({
            title: makeTitleWithTooltipHtml({
              title: T('판매 수량별 수익'),
              tooltip_content: T(messages.tooltip.t1),
            }),
            price_list,
            crew_domain_name,
            is_crew_admin,
            seller_profit_share: sel('seller_profit_share', origin_store_product) || seller_profit_share,
          }),
        )}
        ${UtilS.htmlIf2(!is_afreeca, () =>
          makeLimitedSaleFormItemHtml({
            has_quantity_limit,
            has_period_limit,
            quantity,
            mode,
            is_quantity_public,
            is_studio_worker_admin,
            max_purchase_per_user,
          }),
        )}
        ${UtilS.htmlIf2(is_digital_product, () =>
          makeDigitalProductTermsHtml(
            digital_product.agreed_to_terms,
            `${store_url}/settings/help?type=creator_seller_faq_kr&hg_id=306&hp_id=1156`,
          ),
        )}
        ${UtilS.htmlIf2(!is_afreeca, () =>
          MShopStudioTokenGateStoresProductTmplS.fn(stores_products_token_gates),
        )}
      </div>
    </div>
  `;
};

export const makeTitleWithTooltipHtml = ({
  title,
  sub_title = '',
  tooltip_title = title,
  tooltip_content,
}) => {
  return html`
    <span class="title has-tooltip">
      ${title}
      <span class="tooltip-icon bonus-profit-service"></span>
      <div class="tooltip-container">
        <h4>${tooltip_title}</h4>
        <p>${tooltip_content}</p>
      </div>
      ${sub_title}
    </span>
  `;
};

const makeProfitShareFormItemHtml = (seller_profit_share = 1, disabled) => legacyHtml`
  <div class="form-group" data-group_name="profit_share">
    <div class="form-item">
      <span class="title">${T('셀러 수익 비율')}</span>
      <input
        min="0"
        max="100"
        step="1"
        type="number"
        name="seller_profit_share"
        value="${(seller_profit_share * 100).toFixed(2)}"
        ${UtilS.addAttrIf(disabled, 'disabled')}
      />
    </div>
    <div class="form-item">
      <span class="title">${T('기업 수익 비율')}</span>
      <input
        type="text"
        name="company_profit_share"
        value="${(100 - seller_profit_share * 100).toFixed(2)} %"
        disabled
      />
    </div>
  </div>
`;

const makeNamePrice = (po, en, lang) => `
  <div>
    <span>${po['name' + en]}</span>
    ${po['price' + en] > 0 ? `<span>(+${UtilS.commify(po['price' + en])})</span>` : ''}
    <span>(${lang})</span>
  </div>`;
const makeNamePrices = (po) => {
  const arr = [];
  if (po.name) arr.push(makeNamePrice(po, '', 'KR'));
  if (po.name_en) arr.push(makeNamePrice(po, '_en', 'EN'));
  if (po.name_jp) arr.push(makeNamePrice(po, '_jp', 'JP'));
  return arr.join('');
};
export const makePBProductOptionHtml = (has_up) => (po) =>
  legacyHtml`
  <div class="product_option" _sel="./(o=>o.no==${po.no})" data-is_sold_out="${po.is_sold_out}">
    <div class="sorting">
      <img
        src="//s3.marpple.co/files/u_18050/2018/9/original/f_5864_1537269174009_Zx5Jk9e9PIP7z8f0qJb9eN.png"
        alt=""
      />
    </div>
    <div class="option-name">${makeNamePrices(po)}</div>
    <div class="is_sold_out">
      <label>
        <input type="checkbox" ${po.is_sold_out ? 'checked' : ''} />
        <span>품절</span>
      </label>
    </div>
    <div class="save_delete">
      ${has_up ? `` : `<button type="button" class="update btn btn-warning">${T('수정')}</button>`}
      <button type="button" class="delete btn btn-danger">${T('삭제')}</button>
    </div>
  </div>
`;
export const makePBProductOptionsHtml = ({ product_options = [], has_up }) => legacyHtml`
  <div class="form-item">
    <span class="title">${T('옵션 관리 (선택)')}</span>
    <div class="add-option">
      <div class="header">
        <button id="add_option_button" class="btn btn-ok" type="button">${T('옵션추가 +')}</button>
        <div class="add-option-inputs"></div>
      </div>
      <div class="body">
        <div class="product_options" _sel="product_options">
          ${strMap(makePBProductOptionHtml(has_up), product_options)}
        </div>
      </div>
    </div>
  </div>
`;

export const makeProductCreationModalHtml = ({
  fixed_bp_profit,
  product_color,
  need_confirm_action,
  has_permission_to_confirm,
  show_story_editor = true,
  is_studio_worker_admin,
  crew_domain_name,
  is_crew_admin,
  origin_store_product,
  is_mobile,
  store_url,
}) => {
  const editor_header = makeDefaultEditorHeaderHtml();
  return legacyHtml`
  <div class="product-creation">
    ${makeProductEditorHtml(product_color, {
      mode: 'create',
      fixed_bp_profit,
      need_confirm_action,
      has_permission_to_confirm,
      crew_domain_name,
      editor_header,
      is_crew_admin,
      origin_store_product,
      is_studio_worker_admin,
      is_mobile,
      store_url,
    })}
    ${show_story_editor && makeProductStoryEditorHtml(product_color._.product_story, crew_domain_name)}
  </div>
`;
};

export const makeProductEditingModalHtml = ({
  fixed_bp_profit,
  product_color,
  need_confirm_action,
  has_permission_to_confirm,
  has_permission_to_prohibit,
  has_permission_to_delete,
  is_studio_worker_admin,
  crew_domain_name,
  is_crew_admin,
  is_mobile,
  is_seller,
  store_url,
}) => {
  const { is_prohibited, is_solution } = product_color._.stores_product;
  const is_use_creator = product_color._.base_product['is_use_creator' + G._en];
  const product_status_html = go(
    product_color,
    selProductStatus,
    getProductStatus,
    ifElse(equals(PRODUCT_STATUS.CHECKING), makeProductStatusHtml, () => html`<div></div>`),
  );
  const afreeca_product = product_color._.afreeca_product;

  const is_afreeca =
    afreeca_product?.id || crew_domain_name === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_DOMAIN;

  const editor_header = html`
    <div class="buttons">
      ${product_status_html}
      <div class="btn-group-right">
        <button class="btn btn-reset">${T('초기화')}</button>
        <button class="btn btn-submit">${T('완료')}</button>
        <button class="btn-more">
          <img
            src="//s3.marpple.co/files/u_1093672/2020/9/original/8498917a4be98ac032b5d267e4e4de0f217c5ef21.png"
          />
        </button>
      </div>
      <div class="more-buttons">
        ${UtilS.htmlIf2(!is_afreeca, () => html`<button class="btn-modify">${T('디자인 수정')}</button>`)}
        <button class="btn-change-bp">${T('이 디자인으로 다른 상품 만들기')}</button>
        <button class="btn-clone">${T('상품 복제')}</button>
        ${UtilS.htmlIf2(
          is_seller,
          () =>
            html`<a href="${store_url}/products/${product_color.id}" target="_blank">
              ${T('샘플 주문하기')}
            </a>`,
        )}
        ${UtilS.htmlIf2(
          is_use_creator,
          () =>
            html`<a
              href="${afreeca_product?.af_mall_product_no
                ? `https://shopfreeca.afreecatv.com/products/${afreeca_product?.af_mall_product_no}`
                : `${store_url}/products/${product_color.id}`}"
              target="_blank"
            >
              ${T('미리 보기')}
            </a>`,
        )}
        ${UtilS.htmlIf2(
          has_permission_to_delete,
          () => html` <button class="btn-delete">${T('상품 삭제')}</button> `,
        )}
        ${UtilS.htmlIf2(
          has_permission_to_prohibit,
          () =>
            html`
              <button class="btn-prohibit">${is_prohibited ? T('판매 보류 취소') : T('판매 보류')}</button>
            `,
        )}
        ${UtilS.htmlIf2(
          !is_afreeca && has_permission_to_prohibit,
          () =>
            html`
              <button class="btn-is_solution">${is_solution ? T('신고 상품 취소') : T('신고 상품')}</button>
            `,
        )}
      </div>
    </div>
  `;

  return legacyHtml`
    <div class="product-editing">
      ${makeProductEditorHtml(product_color, {
        mode: 'edit',
        need_confirm_action,
        has_permission_to_confirm,
        is_studio_worker_admin,
        editor_header,
        crew_domain_name,
        is_crew_admin,
        fixed_bp_profit,
        is_mobile,
        store_url,
      })}
      ${makeProductStoryEditorHtml(product_color._.product_story, crew_domain_name)}
    </div>
  `;
};

const makePrimaryThumbRadioInputHtml = (index, is_thumb) => legacyHtml`
  <div class="form-item" data-item_name="primary_thumb">
    <label>
      <input
        id="thumb_id_${index}"
        type="radio"
        name="primary_thumb_idx"
        value="${index}"
        ${checkIf(is_thumb)}
      />
      <label for="thumb_id_${index}">${T('translation::기본 이미지로 지정')}</label>
    </label>
  </div>
`;

const makeProductThumbnailItemHtml = (product, thumbnail_image) => {
  return legacyHtml`
    ${
      thumbnail_image.template
        ? thumbnail_image.template(thumbnail_image.url)
        : legacyHtml`
          <div class="wrap-image">
            ${MpShopProductTmplS.thumbnail(product, {
              thumbnail_image,
              width: 520,
            })}
          </div>
        `
    }
  `;
};

const makePublicFormItemHtml = ({ is_public, base_product, mode, is_afreeca }) =>
  needHiddenPublicForm(base_product)
    ? html`
        <div class="form-item">
          일시적으로 단종된 상품입니다. 자세한 내용은 이메일 공지 내용을 참고해 주세요.
        </div>
      `
    : /* 아프리카인데 상품 생성 템플릿이면  */
    is_afreeca && mode === 'create'
    ? html`
        <div class="form-item" data-item_name="public" style="display: none;">
          <span class="title">${T('상품 공개 여부')}</span>
          <label class="align-center-flex">
            <input id="check_public" type="radio" name="is_public" value="true" checked />
            <label for="check_public">${T('공개')}</label>
          </label>
        </div>
      `
    : html`
        <div class="form-item" data-item_name="public">
          <span class="title">${T('상품 공개 여부')}</span>
          <label class="align-center-flex">
            <input id="check_public" type="radio" name="is_public" value="true" ${checkIf(is_public)} />
            <label for="check_public">${T('공개')}</label>
          </label>
          <label class="align-center-flex">
            <input id="check_not_public" type="radio" name="is_public" value="false" ${checkIf(!is_public)} />
            <label for="check_not_public">${T('비공개')}</label>
          </label>
        </div>
      `;
const makeNameFormItemHtml = ({ name, name_en, name_jp, title }) => legacyHtml`
  <div class="form-item names">
    <span class="title">${UtilS.escape(title)}&nbsp;<small>${T('다국어 지원 (선택 사항)')}</small></span>
    <input maxlength="50" type="text" name="name" placeholder="${T('한국어')}" value="${
  UtilS.escape(name) || ''
}" />
    <input maxlength="50" type="text" name="name_en" placeholder="${T('영어')}" value="${
  UtilS.escape(name_en) || ''
}" />
    <input maxlength="50" type="text" name="name_jp" placeholder="${T('일본어')}" value="${
  UtilS.escape(name_jp) || ''
}" />
  </div>
`;

const makeDigitalProductTermsHtml = (agreed_to_terms = false, link_url) => {
  const title = T('디자인 저작권 동의 (필수)');
  return html`
    <div class="form-item">
      <span class="title has-link"
        >${title}<a target="_blank" class="title-link" href="${link_url}">${T('자세히')}</a></span
      >
      <ul class="terms">
        <li>개인 소장 및 다운로드용 라이센스 판매입니다.</li>
        <li>
          이미지/디자인은 셀러 본인이 직접 촬영/창작한 작업물이며 저작권/소유권이 명확히 있는 콘텐츠의
          판매입니다.
        </li>
        <li>
          타인의 이미지 또는 타 사이트에서 가져온 이미지로 발생하는 모든 법적 분쟁에 대한 책임은 셀러 본인에게
          있습니다.
        </li>
        <li>
          저작권, 재산권, 초상권 위반 발견 시 해당 샵은 계정 운영 정지될 수 있으며 관련 분쟁 책임 및 피해 보상
          책임이 셀러 본인에게 있습니다.
        </li>
      </ul>
      <label>
        <input name="agreed_to_terms" type="checkbox" ${UtilS.addAttrIf(agreed_to_terms, 'checked')} />
        <span>라이센스, 저작권, 재산권, 초상권 관련 사항 모두 확인하였습니다.</span>
      </label>
    </div>
  `;
};
