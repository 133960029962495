import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { html, strMap, filter, go } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { ShippingConstantS } from '../../../../Shipping/S/Constant/module/ShippingConstantS.js';

export const makeCheckoutEnUserInfoSectionHtml = (user) => pug`
  .section.user_email.user_info ${
    user.email
      ? pug`
        .section_header
          .txt ${T('checkout::Email')}
          .btn ${T('checkout::Change Email')}
        .section_body
          .email_text ${UtilS.escape(user.email)}
          .msg ${T(
            "checkout::We'll send an order confirmation to this address. Please verify your current e-mail address.",
          )}
          .msg.red ${T('checkout::Input English')}
          `
      : pug`
        .section_header
          .txt ${T('checkout::Email')}
        .section_body
          input[placeholder="${T(
            'checkout::Enter your email address',
          )}" type="text" name="email" required].email
          .msg ${T("checkout::We'll send an order confirmation to this address.")}
          .msg.red ${T('checkout::Input English')}
          .checkbox_wrap[style="display: none;"]
            .checkbox.normal
            .msg Keep me up to date on news and exclusive offers
        ${
          user.type == 'TEMP'
            ? pug`
              .section_header
                .txt ${T('checkout::Password')}
              .section_body
                input[placeholder="${T(
                  'checkout::Enter your password',
                )}" type="password" name="password" required].password`
            : ''
        }
      `
  }`;

/**
 * @define 해외 배송 결제 페이지 내 "State(주)" 선택 옵션 박스 html 생성
 * @param {{code: string, name: string, country_id: number}[]} country_states
 * @param {string | undefined} selected_state
 * */
export const makeCountryStatesSelectOptionBoxHtml = ({ country_states, selected_state }) => {
  return html`
    <select name="state" class="state" value="" required>
      <option value="" disabled selected style="display:none">${T('checkout::State or Province')}</option>
      ${strMap(
        ({ code, name }) =>
          html`<option value="${code}" ${code === selected_state ? 'selected' : ''}>${name}</option>`,
        country_states,
      )}
    </select>
  `;
};

export const makeExpressSelectOptionsHtml = ({ is_jp, express, shipping }) => {
  return html`<select name="express" class="express ${shipping?.country_id ? '' : 'disabled'}">
    <option value="" disabled selected style="display: none;">
      ${is_jp ? T('checkout::Shipping Provider') : T('checkout::Please enter the shipping address first.')}
    </option>
    ${go(
      express,
      filter(({ country_id, country_code }) => {
        if (is_jp) return country_code === 'JP';
        return country_id === shipping.country_id;
      }),
      zipWithIndex,
      strMap(([idx, { shipping_company_id: sc_id, shipping_company_name: sc_name }]) => {
        const is_selected = (() => {
          const user_selected_sc_id = shipping?.shipping_company_id;
          if (user_selected_sc_id == null && idx === 0) {
            shipping.shipping_company_id = sc_id; // 첫번째 shipping_company 주입
            return 'selected';
          }
          return user_selected_sc_id === sc_id ? 'selected' : '';
        })();
        return html`<option value="${sc_id}" ${is_selected}>${sc_name}</option>`;
      }),
    )}
  </select>`;
};

export const makeExpressServiceSelectOptionHtml = ({ is_jp, shipping, express_services }) => {
  const available_express_services = express_services.filter(
    ({ shipping_company_id: sc_id, is_use }) => is_use && sc_id === shipping.shipping_company_id,
  );

  return html`
    <select name="express_service" class="express_service ${shipping?.country_id ? '' : 'disabled'}">
      <option value="" disabled selected style="display: none;">
        ${is_jp ? T('checkout::Shipping Provider') : T('checkout::Please enter the shipping address first.')}
      </option>
      ${(() => {
        if (UtilS.isEmpty(shipping.shipping_company_id)) {
          return '';
        }
        return UtilS.isEmpty(available_express_services)
          ? html`<option value="" selected>${ShippingConstantS.EXPRESS_SERVICE_DEFAULT_NAME}</option>`
          : go(
              available_express_services,
              zipWithIndex,
              strMap(([idx, { id, display_name }]) => {
                const is_selected = (() => {
                  const user_selected_scs_id = shipping?.shipping_company_service_id;

                  if (user_selected_scs_id == null && idx === 0) return 'selected';
                  return user_selected_scs_id === id ? 'selected' : '';
                })();
                return html`<option value="${id}" ${is_selected}>${display_name}</option>`;
              }),
            );
      })()}
    </select>
  `;
};
