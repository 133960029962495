import { flat, go, map, sumBy, once, sel } from 'fxjs/es';
import { CreatorCriteoF } from './module/CreatorCriteoF.js';

export const purchase = once((projection) => {
  if (!projection || !window.criteo_q || projection.lang != 'kr') return;
  window.criteo_q.push(
    ...CreatorCriteoF.eventSet({
      event: 'trackTransaction',
      id: projection.id,
      item: map(function (up) {
        const up_c_ss = go(
          up._.up_cs,
          map(function (up_c) {
            return up_c._.up_c_ss;
          }),
          flat,
        );

        const quantity = sumBy(function (up_c_s) {
          return up_c_s.quantity;
        }, up_c_ss);

        return {
          id: sel('_.stores_product.original_product_id', up),
          price: up._.product_color['price' + _en],
          quantity,
        };
      }, projection._.ups),
    }),
  );
});
