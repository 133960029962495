import { map, isArray, sel, curry2, flatMap } from 'fxjs/es';

// deepMap 과 같은 목적이지만, flat 을 해주는 것이 다름

export const deepFlatMap = curry2((f, selector = '', obj) => {
  const is_array = isArray(obj);

  if (!selector) return is_array ? map(f, obj) : f(obj);
  const [head_sel, ...rest] = selector.split('.');

  return is_array
    ? flatMap((item) => deepFlatMap((...v) => f(item, ...v), selector, item), obj)
    : deepFlatMap(f, rest.join('.'), sel(head_sel, obj));
});
