import { html } from 'fxjs/es';
export const pSellerProductInfoToolTip = () => {
  return html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <span>・ 선택한 배송 방법에 따라 크리에이터가 직접 배송해요.</span><br />
      <span>・ 상품준비중 상태인 경우 주문취소가 불가능해요.</span><br />
      <span>・ 모든 문의는 크리에이터가 직접 답변해 드려요.</span><br />
      <span>・ 교환/반품 시, 크리에이터와 메시지를 통해 소통해주세요.</span><br />
      <span>・ 반품 시 크리에이터가 설정한 반품 배송비가 발생합니다.</span><br />
    </div>
  `;
};
