import { ACTION, ACTION_TARGET, SERVICE } from '../../Authority/S/constant.js';
import { checkActions } from '../../Authority/S/util.js';
import { STUDIO_UPDATE_NOTE_URL } from './constant.js';
import { messages } from './messages_id.js';

export const LNB_INVITE_MENU = {
  title: messages.menus.invite_info,
  link: 'invite_info',
  page_name: 'invite_info',
  icon: 'lnr-book',
};

export const LNB_IS_NOT_AGREE_MENUS = [
  {
    title: messages.footer.terms,
    link: 'terms',
    page_name: 'terms',
    icon: 'lnr-book',
    dataShow() {
      return T.lang == 'kr';
    },
  },
  {
    title: messages.menus.account,
    link: 'account',
    page_name: 'account',
    icon: 'lnr-user',
    dataShow(user) {
      return user.is_seller;
    },
  },
  {
    title: messages.menus.faq,
    link: 'help',
    page_name: 'help',
    icon: 'lnr-question-circle',
  },
];

export const LNB_TOP_MENUS = [
  {
    // 굿즈 제작
    id: 'make_products',
    title: messages.menus.make_products,
    icon: 'lnr-shirt',
    collapses: [
      {
        title: messages.menus.products,
        link: 'products',
        page_name: 'products',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.orders,
        link: 'orders',
        page_name: 'orders',
        dataShow(user) {
          return checkActions({ actions: ACTION.READ_ORDERS, target: ACTION_TARGET.STORE }, { user });
        },
      },
    ],
  },
  {
    // 상품 판매
    id: 'seller_products',
    title: messages.menus.seller_product,
    icon: 'lnr-tag',
    dataShow() {
      return T.lang == 'kr';
    },
    collapses: [
      {
        title: messages.menus.real_pb_products,
        link: 'pb_products',
        page_name: 'pb-products',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.inventory_management,
        link: 'inventory_management',
        page_name: 'pb-inventory-management',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.pb_orders,
        link: 'pb_orders',
        page_name: 'pb-orders',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.pb_item_orders,
        link: 'pb_item_orders',
        page_name: 'pb-item-orders',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.pb_refund_orders,
        link: 'pb_refund_orders',
        page_name: 'pb-refund-orders',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
    ],
  },
  {
    // 디지털 상품 판매
    id: 'digital_product',
    title: messages.menus.digital_product,
    icon: 'digital-product-icon',
    dataShow() {
      return T.lang == 'kr';
    },
    collapses: [
      {
        title: messages.menus.make_digital_products,
        link: 'digital_products',
        page_name: 'digital-products',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.orders,
        link: 'digital_item_orders',
        page_name: 'digital-item-orders',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
    ],
  },
  {
    // 고객 문의 관리
    id: 'question_list',
    title: messages.menus.question_list,
    link: 'question_list',
    page_name: 'question-list',
    icon: 'lnr-bubble',
    dataShow() {
      return T.lang == 'kr';
    },
  },
  {
    // 샵 관리
    id: 'shop_management',
    title: messages.menus.shop_management,
    icon: 'lnr-store',
    collapses: [
      {
        title: messages.menus.styles,
        link: 'styles',
        page_name: 'styles',
      },
      {
        title: messages.menus.arrangement,
        link: 'arrangement',
        page_name: 'arrangement',
        dataShow(user) {
          return checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user });
        },
      },
      {
        title: messages.menus.shop_info,
        link: 'shop_info',
        page_name: 'shop_info',
      },
      {
        title: messages.menus.ecommerce_info,
        link: 'ecommerce_info',
        page_name: 'ecommerce-info',
        dataShow(user) {
          return (
            T.lang == 'kr' &&
            checkActions({ actions: ACTION.MAKE_PRODUCT, target: ACTION_TARGET.STORE }, { user })
          );
        },
      },
      {
        title: messages.menus.open_api,
        link: 'open_api',
        page_name: 'open_api',
        dataShow() {
          return T.lang == 'kr';
        },
      },
    ],
  },
  {
    // 정산 관리
    id: 'adjustments',
    title: messages.menus.adjustments,
    icon: 'lnr-database',
    collapses: [
      {
        title: messages.menus.adjustments,
        link: 'adjustments',
        page_name: 'adjustments',
        dataShow(user) {
          return checkActions(
            {
              actions: ACTION.READ_ADJUSTMENTS,
              target: ACTION_TARGET.STORE,
              service: SERVICE.WITHDRAW,
              pass_no_service: false,
            },
            { user },
          );
        },
      },
      {
        title: messages.menus.withdraws,
        link: 'withdraws',
        page_name: 'withdraws',
        dataShow(user) {
          return checkActions(
            {
              actions: ACTION.READ_WITHDRAWS,
              target: ACTION_TARGET.STORE,
              service: SERVICE.WITHDRAW,
              pass_no_service: false,
            },
            { user },
          );
        },
      },
      {
        title: messages.menus.bank_account,
        link: 'bank_account',
        page_name: 'bank_account',
        dataShow(user) {
          return checkActions(
            {
              actions: ACTION.READ_BANK_ACCOUNT,
              target: ACTION_TARGET.STORE,
              service: SERVICE.WITHDRAW,
              pass_no_service: false,
              user_exceptions: [1184090, 1372089],
            },
            { user },
          );
        },
      },
    ],
  },
  {
    // 통계
    id: 'analytics',
    title: messages.menus.analytics,
    link: 'analytics',
    page_name: 'analytics',
    icon: 'lnr-chart-bars',
    dataShow(user) {
      return checkActions({ actions: ACTION.READ_SALES_ANALYTICS, target: ACTION_TARGET.STORE }, { user });
    },
  },
  {
    // 내 샵 바로가기
    id: 'my_shop',
    title: messages.menus.my_shop,
    blank(domain_name) {
      return `/${T.lang}/${domain_name}`;
    },
    dataShow(user, is_app) {
      return !is_app;
    },
    icon: 'lnr-rocket',
  },
];

export const LNB_MID_MENUS = [
  {
    // 유튜브에서 판매
    id: 'youtube',
    title: messages.menus.youtube,
    link: 'youtube',
    page_name: 'youtube',
    icon: 'youtube-icon',
  },
  {
    // 크리에이터 가이드
    id: 'seller_guide',
    title: messages.menus.seller_guide,
    blank() {
      return `https://${T.lang}.marppleshop.help/`;
    },
    icon: 'seller-guide-icon',
    dataShow() {
      return T.lang != 'jp';
    },
  },
  {
    // 크리에이터 팁
    id: 'seller_tip',
    title: messages.menus.seller_tip,
    link: 'seller_tip',
    page_name: 'seller_tip',
    icon: 'lnr-smile',
    dataShow() {
      return T.lang == 'kr';
    },
  },
  {
    // FAQ
    id: 'faq',
    title: messages.menus.faq,
    link: 'help',
    page_name: 'help',
    icon: 'lnr-question-circle',
  },
  {
    // 신고 접수
    id: 'solution',
    title: messages.menus.solution,
    link: 'solution/list?status=ing',
    page_name: 'solution',
    icon: 'lnr-warning',
  },
  {
    // 계정정보
    id: 'account',
    title: messages.menus.account,
    link: 'account',
    page_name: 'account',
    icon: 'lnr-user',
    dataShow(user) {
      return user.is_seller;
    },
  },
];

export const LNB_BOTTOM_MENUS = [
  {
    // 초대
    id: 'invite',
    title: messages.menus.invite,
    link: 'invite',
    page_name: 'invite',
    dataShow(user) {
      return (
        user.is_seller &&
        checkActions(
          {
            actions: ACTION.INVITE_FRIEND,
            target: ACTION_TARGET.STORE,
          },
          { user },
        )
      );
    },
  },
  {
    // 업데이트 노트
    id: 'update_note',
    title: messages.menus.update_note,
    klass: 'lnb-update-note',
    dataShow() {
      return T.lang == 'kr';
    },
    blank() {
      return STUDIO_UPDATE_NOTE_URL;
    },
  },
  {
    // CCC
    id: 'creator_community_club',
    title: messages.menus.creator_community_club,
    dataShow() {
      return T.lang == 'kr';
    },
    blank() {
      return 'https://creatorscommunity.club/';
    },
  },
];
