import { once } from 'fxjs/es';
import { CreatorCriteoF } from './module/CreatorCriteoF.js';

export const addCart = once((items) => {
  if (!window.criteo_q) return;
  window.criteo_q.push(
    ...CreatorCriteoF.eventSet({
      event: 'viewBasket',
      item: items,
    }),
  );
});
