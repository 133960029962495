import { each, entries, go, html, minBy, pluck, strMap } from 'fxjs/es';
import { bizConsultingIcon, bizMailIcon } from '../../../OMP/Core/Atom/S/Tmpl/icon.js';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { getPriceCurrency } from '../../../Util/S/Function/currency.js';
import { OMPCoreUtilS } from '../../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { UtilObjS } from '../../../Util/Object/S/Function/module/UtilObjS.js';
import { $delegate, $find, $qs } from 'fxdom/es';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';

export const group_discount_guide_frame = () => ({
  frame_name: 'mp.group_discount_guide_frame',
  el_class: '__group_discount_guide',
  title: TT('biz::opt::group_discount'),
  page_name: 'mp.group_discount_guide_page',
  header_height: OMPCoreUtilF.isMobile() ? 28 : undefined,
  height: undefined,
  animation: OMPCoreUtilF.isMobile(),
  is_modal: !OMPCoreUtilF.isMobile(),
  always_remove: true,
  hide_frame_button_type: 'X',
  root_page: {
    page_name: 'mp.group_discount_guide_page',
    tabs: [
      {
        tab_name: 'mp.group_discount_guide_tab',
        template: (price_ranges) => {
          return groupDiscountGuideHtml(price_ranges);
        },
        appended: (tab_el) => {
          if (OMPCoreUtilF.isMobile()) {
            // MO 인 경우에는 동적으로 계산된 height 를 Contents 에 적용
            const table_style = tab_el.tab_opt?.table_style;
            if (table_style == null) return;

            const table_el = $find('.price_range_guide_window', tab_el);

            if (table_el) {
              if (UtilObjS.isNotEmpty(table_style)) {
                go(
                  table_style,
                  entries,
                  each(([k, v]) => {
                    table_el.style[k] = v;
                  }),
                );
              }
            }
          } else {
            // PC 인 경우에는 동적으로 계산된 width 를 Frame 에 적용
            const frame_width = tab_el.tab_opt?.frame_style?.width;
            if (frame_width == null) return;

            const guide_frame_el = $qs('.don_frame.__group_discount_guide');
            if (guide_frame_el == null) return;

            const inner_w = window.innerWidth;
            guide_frame_el.style.width = frame_width;
            guide_frame_el.style.left = `${(inner_w - parseInt(frame_width)) / 2}px`;

            const header_el = $qs('.don_frame.__group_discount_guide >.don_wrapper >.header');

            if (header_el == null) return;
            const body_width = tab_el.tab_opt?.frame_style?.body_width;
            if (body_width == null) return;
            header_el.style.width = body_width;
          }
        },
        showed: (tab_el) => {
          go(
            tab_el,
            $delegate('click', '.notice__title__consulting-circle', () => {
              // window.Twc?.Chat.open();
              window.ChannelIO('showMessenger');
              MuiF.closeFrame();
            }),
          );
        },
      },
    ],
  },
});

function groupDiscountGuideHtml(price_ranges) {
  return html`
    <div class="price_range_guide_window">
      <div class="body">${priceRangeHtml(price_ranges)}</div>
      <div class="notice">
        <table>
          <tbody>
            <tr class="notice__tr">
              <td class="notice__title">${TT('biz::notice::title::price')}</td>
              <td>${TT('biz::notice::price')}</td>
            </tr>
            <tr>
              <td class="notice__title">${TT('biz::notice::title::consulting')}</td>
              <td>${TT('biz::notice::consulting')}</td>
            </tr>
            <tr>
              <td class="notice__title">${TT('biz::notice::title::payment')}</td>
              <td>${TT('biz::notice::payment')}</td>
            </tr>
            <tr>
              <td class="notice__title__delivery">${TT('biz::notice::title::delivery')}</td>
              <td class="notice__title__delivery-content">${TT('biz::notice::delivery')}</td>
            </tr>
            <tr>
              <td></td>
              <td>${TT('biz::notice::delivery2')}</td>
            </tr>
            <tr>
              <td class="notice__title__req-consulting">${TT('biz::notice::title::req_consulting')}</td>
              <td class="notice__title__req-consulting__content">
                <div class="notice__title__consulting-circle">
                  ${bizConsultingIcon()}${TT('biz::notice::req1')}
                </div>
                <div class="notice__title__email-circle">
                  <a href="mailto:biz@marpple.com">${bizMailIcon()}biz@marpple.com</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `;
}

function priceRangeHtml(price_ranges) {
  const min_price_range = minBy((p) => p.price, price_ranges);
  if (OMPCoreUtilS.isMobile()) {
    return html`
      <table>
        <thead>
          <tr>
            <th>${TT('biz::opt::order_qty')} (${TT('biz::opt::ea')})</th>
            <th>${TT('biz::opt::unit_price')} (${getPriceCurrency()})</th>
          </tr>
        </thead>
        <tbody>
          ${strMap((r) => {
            const { price, qty } = r;
            const is_min = price === min_price_range.price;
            return html`<tr>
              ${toTdHtml.quantity({ is_min, qty })} ${toTdHtml.price({ is_min, price })}
            </tr>`;
          }, price_ranges)}
        </tbody>
      </table>
    `;
  }

  return html`
    <table>
      <tr>
        <td>${TT('biz::opt::order_qty')} (${TT('biz::opt::ea')})</td>
        ${strMap((qty) => {
          const is_min = qty === min_price_range.qty;
          return toTdHtml.quantity({ is_min, qty });
        }, pluck('qty', price_ranges))}
      </tr>
      <tr>
        <td>${TT('biz::opt::unit_price')} (${getPriceCurrency()})</td>
        ${strMap((price) => {
          const is_min = price === min_price_range.price;
          return toTdHtml.price({ is_min, price });
        }, pluck('price', price_ranges))}
      </tr>
    </table>
  `;
}

const toTdHtml = {
  quantity: ({ is_min, qty }) => html`<td class="quantity ${is_min ? 'min' : ''}">${qty}</td>`,
  price: ({ is_min, price }) => html`<td
    class="price ${is_min ? 'min' : ''}"
    style="${is_min ? 'position:relative;' : ''}"
  >
    ${is_min ? html`<div class="best_tag">BEST</div>` : ''} ${UtilS.commify(price)}
  </td>`,
};
