import { html, ifElse, strMap } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { MShopAppProductItemTmplS } from '../../../MShop/App/Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { messages } from '../../Setting/S/messages_id.js';

const medalImages = {
  1: '//s3.marpple.co/files/u_1504988/2021/3/original/6d48b7ffc243a06f09865d12dc6722365f42ed242.png',
  2: '//s3.marpple.co/files/u_1504988/2021/3/original/2de3ebbde8ca881e382cd52b3cf6154f8846e1e43.png',
  3: '//s3.marpple.co/files/u_1504988/2021/3/original/84132d88a62121e60022c56dd7bcaa4162b51a3d1.png',
};

const getMedalImage = (no) =>
  ifElse(
    () => medalImages[no],
    () => html`<img class="center-middle" src="${medalImages[no]}" />`,
    () => no,
  );

const makeSettingAnalyticsTrHtml =
  (no = 0) =>
  (p) => {
    const prod_name = p['name' + G._en];
    return html`
      <tr>
        <td class="center" style="width: 70px">${getMedalImage(++no)()}</td>
        <td class="left">
          <div class="product_info">
            <div class="img_wrap">${MpShopProductTmplS.thumbnail(p, { width: 34 })}</div>
            <span class="name"
              >${UtilS.escape(
                prod_name || MShopAppProductItemTmplS.makeBpName(p.bp_name, p.bp_option_title),
              )}</span
            >
          </div>
        </td>
        <td class="total-count">${UtilS.commify(p.total_count)}</td>
        <td class="is-public">
          <span data-is_public="${p.is_public}">
            ${T(p.is_public ? messages.common.public : messages.common.private)}
          </span>
        </td>
      </tr>
    `;
  };

export const makeSettingAnalyticsBodyHtml = ({ products }) =>
  legacyHtml`
    <div class="header">
      <h2>${T(messages.menus.analytics_by_prod)}</h2>
      <p>${T(messages.analytics.p1)}</p>
    </div>
    <div class="body">
      <div id="analytics" class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th class="center" style='width: 70px'>${T(messages.table.th11)}</th>
              <th class="center">${T(messages.table.th12)}</th>
              <th>${T(messages.table.th13)}</th>
              <th>${T(messages.table.th14)}</th>
            </tr>
          </thead>
          <tbody>
            ${strMap(makeSettingAnalyticsTrHtml(0), products)}
          </tbody>
        </table>
      </div>
    </div>
  `;
