import { $addClass, $closest, $is, $prependTo, $prev, $remove } from 'fxdom/es';
import { filter, go, map } from 'fxjs/es';

export const removeEmbedToolbarHandler = (e) => {
  const show$ = $closest('.embed_show', e.target);

  go([show$, $prev(show$)], filter($is('.embed, .embed_show')), map($remove));
  closeToolbar($closest('.custom-toolbar', e.target));
};

export const closeToolbar = ($custom_toolbar) => $custom_toolbar.parentNode && $remove($custom_toolbar);

export const closeToolbarHandler = ($custom_toolbar) => (e) => {
  const $embed_container = $closest('.embed_show', e.target);
  if ($embed_container) return;
  closeToolbar($custom_toolbar);
};

export const openToolbar = ($target, $toolbar) => {
  $prependTo($target, $toolbar);
  $addClass('medium-editor-toolbar-active', $toolbar);
};

export const openEmbedToolbarHandler = (is_marpple, toolbar$, editor) => (e) => {
  if (!editor.isActive) return;
  const embed_show$ = $closest('.embed_show', e.target);

  if (is_marpple !== $is('.marpple_embed_show', embed_show$)) return;
  openToolbar(embed_show$, toolbar$);
};
