import { $before, $el, $find, $findAll, $on, $prependTo, $prev, $remove } from 'fxdom/es';
import { each, go, html, pipe } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { CreatorEditorMarppleEmbedMuiF } from '../../../MarppleEmbed/F/Mui/module/CreatorEditorMarppleEmbedMuiF.js';
import { MARPPLE_EMBED_SELECTOR } from '../../../S/constant.js';
import { getCursorEl } from '../medium-editor.js';
import { initMarppleEmbedToolbar } from '../toolbar/medium-editor-marpple-embed-toolbar.js';
import { hideInsert } from './index.js';
import { createActionStack } from '../features/bug_tracker.js';

const makeFeatureBtnEl = () =>
  $el(`<div class="feature_btn marpple_embed_button">
          <i class="lnr lnr-gift"></i>
          <span class="feature-name">상품</span>
        </div>`);

export const initMarppleEmbedPlugin = (registerBtnToInsertBox, editor) => {
  initMarppleEmbedToolbar(editor);

  // register btn
  const btn_el = makeFeatureBtnEl();
  registerBtnToInsertBox(btn_el);

  $on('click', (e) => {
    hideInsert();
    openMarppleEmbedEditor(editor);
    // open frame
  })(btn_el);
};

export const openMarppleEmbedEditor = (editor, prev_embed_el$) => {
  const current$ = getCursorEl(editor);
  const is_create = !prev_embed_el$;
  const prev_embed$ = !is_create && $prev(prev_embed_el$);
  MuiF.openFrame(CreatorEditorMarppleEmbedMuiF.frame, (frame, page, [tab]) => {
    tab.makeData = () =>
      prev_embed_el$ && go(prev_embed_el$, $find(MARPPLE_EMBED_SELECTOR), (embed$) => embed$.innerHTML);

    frame.removed = (_, { el$: embed_container$, urls } = {}) => {
      if (!embed_container$ || !urls) return;
      if (is_create) {
        const embed$ = $el(`<div class="embed" contenteditable="false">${urls}</div>`);
        $before(embed$, current$);
        $before(embed_container$, current$);
        createActionStack('create marpple embed', { embed_container$ });
      } else {
        prev_embed$.innerHTML = urls;
        $before(embed_container$, prev_embed_el$);
        $remove(prev_embed_el$);
        createActionStack('update marpple embed', { embed_container$ });
      }
    };
  });
};

export const addEditMenuContainer = (container$) => {
  go(container$, $findAll('.app-product-item'), each(addEditMenu));
};

export const addEditMenu = (product$) => {
  const optionEl = $el(
    html`
      <button class="embed_option">
        <img
          src="//s3.marpple.co/files/u_1165777/2020/11/original/7d101e39c642cac9cf5e5d01435a402040d326d11.png"
          alt=""
        />
        <div class="options">
          <div class="option update-option">수정</div>
          <div class="option delete-option">삭제</div>
        </div>
      </button>
    `,
  );
  $prependTo(product$, optionEl);
  return product$;
};

export const removeEditMenus = pipe($findAll('.embed_option'), each($remove));
