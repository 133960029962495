import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const pTextOptionInfosHtml = (n_text_option_infos) => {
  return html`
    <div class="up-item-text-option-infos">
      <div class="up-item-text-option-infos__title">요청사항</div>
      <div class="up-item-text-option-infos__values">
        ${strMap(
          (n_text_option_info) => html`
            <div class="up-item-text-option-infos__value">${UtilS.escape(n_text_option_info.value)}</div>
          `,
          n_text_option_infos,
        )}
      </div>
    </div>
  `;
};
