import $dataStr from 'fxdom/es/dataStr.js';
import { compact, go, html, ippL, isArray, map, pluck, sel, strMap } from 'fxjs/es';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { tooltipIcon2El } from '../../../../../Util/S/Tmpl/tootip.js';
import { MShopStudioPbProductConstantS } from '../Constant/module/MShopStudioPbProductConstantS.js';
import { MShopStudioPbProductS } from '../Function/module/MShopStudioPbProductS.js';
import { MShopStudioPbProductTmplS } from './module/MShopStudioPbProductTmplS.js';

export const makeKrIcon = (margin_left = '', margin_right = '') => html`
  <img
    src="//s3.marpple.co/files/u_2308668/2023/4/original/00f27a52219caedbdf9fa580f9bf49cd140b0f001.png"
    alt="korea flag icon"
    class="pb-editor-form__flag-icon kr-icon ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} ${margin_left} ${margin_right}"
  />
`;
export const makeEnIcon = (margin_left = '', margin_right = '') => html`
  <img
    src="//s3.marpple.co/files/u_2308668/2023/4/original/ad591822bed406ceef5294ebba575da4d293ac7e1.png"
    alt="america flag icon"
    class="pb-editor-form__flag-icon ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} ${margin_left} ${margin_right}"
  />
`;
export const makeJpIcon = (margin_left = '', margin_right = '') => html`
  <img
    src="//s3.marpple.co/files/u_2308668/2023/4/original/e499abd9e12547aa0c2a5c53b1cfd71d362a1ae11.png"
    alt="japan flag icon"
    class="pb-editor-form__flag-icon ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} ${margin_left} ${margin_right}"
  />
`;
export const makeIsGlobalInputRadioHtml = (is_global) => {
  return html`
    <div class="pb-editor-form__control pb-editor-form__control-is-global">
      <div class="pb-editor-form__label-txt">
        해외 배송 가능 <span class="pb-editor-form__required">*</span>
      </div>
      <div class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--is_public">
        <label
          class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
        >
          <input type="radio" name="is_global" value="true" ${is_global ? 'checked' : ''} />
          ${T('해외 배송 가능')}
        </label>
        <label
          class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
        >
          <input type="radio" name="is_global" value="false" ${is_global ? '' : 'checked'} />
          ${T('해외 배송 불가')}
        </label>
      </div>
    </div>
  `;
};

export const makeGlobalProductNameInputHtml = ({ name = '', name_en = '', name_jp = '' }) => {
  return html`
    <div class="pb-editor-form__control pb-editor-form__name">
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">상품명 <span class="pb-editor-form__required">*</span></div>
        <div class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--name">
          ${makeKrIcon('mr-8')}
          <div class="pb-editor-form__body pb-editor-form__name-body">
            <input
              type="text"
              name="name"
              value="${UtilS.escape(name)}"
              class="pb-editor-form__body-input"
              placeholder="상품명을 입력해 주세요"
            />
            <div class="pb-editor-form__body-length pb-editor-form__body-name-length">
              <span class="pb-editor-form__body-length-txt"
                >${UtilS.escape(UtilS.checkStringLength(name || ''))}</span
              >/100
            </div>
          </div>
          ${makeEnIcon('ml-16', 'mr-8')}
          <div
            class="pb-editor-form__body pb-editor-form__name_en-body ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
          >
            <input
              type="text"
              name="name_en"
              value="${UtilS.escape(name_en)}"
              class="pb-editor-form__body-input"
              placeholder="영문 상품명을 입력해 주세요"
            />
          </div>
          ${makeJpIcon('ml-16', 'mr-8')}
          <div
            class="pb-editor-form__body pb-editor-form__name_jp-body ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
          >
            <input
              type="text"
              name="name_jp"
              value="${UtilS.escape(name_jp)}"
              class="pb-editor-form__body-input"
              placeholder="일문 상품명을 입력해 주세요"
            />
          </div>
        </div>
      </label>
      <p class="pb-editor-form__error-msg">
        ${MShopStudioPbProductS.pb_product_editor_error.restrict_character.text}
      </p>
    </div>
  `;
};

export const globalOptionDirectInput = ([idx, option]) => html`
  <div
    class="pb-editor-form__control pb-editor-form__direct-option ${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--option-direct"
    data-idx="${idx}"
    data-text_option_id="${option?.id}"
  >
    <label class="pb-editor-form__label" for="option_direct_input${idx}">
      <div class="pb-editor-form__label-txt">옵션 직접 입력형</div>
    </label>
    <div
      class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del pb-editor-form__body-area-op-direct"
    >
      ${makeKrIcon()}
      <div class="pb-editor-form__body pb-editor-form__body-op-direct">
        <input
          type="text"
          name="option_direct_input${idx}"
          id="option_direct_input${idx}"
          data-id="${option?.id}"
          value="${UtilS.escape(option?.name) || ''}"
          class="pb-editor-form__body-input pb-editor-form__body-input-op-direct pb-editor-form__body-input--text-length"
          placeholder="예) 이니셜 각인 요청사항을 적어주세요"
        />
        <div class="pb-editor-form__body-length pb-editor-form__body-op-direct-length">
          <span class="pb-editor-form__body-length-txt"
            >${UtilS.escape(UtilS.checkStringLength(option?.name || ''))}</span
          >/100
        </div>
      </div>
      <div class="pb-editor-form__body-area-btn-del">
        <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-direct-del">x</button>
      </div>
      <div class="pb-editor-form__body-area-btn-add">
        <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-op-direct-add">+</button>
      </div>
    </div>
    <div class="mt-12"></div>
    <div
      class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del pb-editor-form__body-area-op-direct ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
    >
      ${makeEnIcon()}
      <div class="pb-editor-form__body pb-editor-form__body-op-direct_en">
        <input
          type="text"
          name="option_direct_input${idx}"
          id="option_direct_input${idx}"
          data-id="${option?.id}"
          value="${UtilS.escape(option?.name_en) || ''}"
          class="pb-editor-form__body-input pb-editor-form__body-input-op-direct_en pb-editor-form__body-input--text-length"
          placeholder="예) 이니셜 각인 요청사항을 적어주세요"
        />
        <div class="pb-editor-form__body-length pb-editor-form__body-op-direct-length">
          <span class="pb-editor-form__body-length-txt-en"
            >${UtilS.escape(UtilS.checkStringLength(option?.name_en || ''))}</span
          >/100
        </div>
      </div>
    </div>
    <div class="mt-12"></div>
    <div
      class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del pb-editor-form__body-area-op-direct ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
    >
      ${makeJpIcon()}
      <div class="pb-editor-form__body pb-editor-form__body-op-direct_jp">
        <input
          type="text"
          name="option_direct_input${idx}"
          id="option_direct_input${idx}"
          data-id="${option?.id}"
          value="${UtilS.escape(option?.name_jp) || ''}"
          class="pb-editor-form__body-input pb-editor-form__body-input-op-direct_jp pb-editor-form__body-input--text-length"
          placeholder="예) 이니셜 각인 요청사항을 적어주세요"
        />
        <div class="pb-editor-form__body-length pb-editor-form__body-op-direct-length">
          <span class="pb-editor-form__body-length-txt-jp"
            >${UtilS.escape(UtilS.checkStringLength(option?.name_jp || ''))}</span
          >/100
        </div>
      </div>
    </div>
    <p class="pb-editor-form__error-msg">
      ${MShopStudioPbProductS.pb_product_editor_error.restrict_character.text}
    </p>
  </div>
`;

export const GlobalProductInfoTextarea = ({
  product_basic_info,
  product_basic_info_en,
  product_basic_info_jp,
}) => {
  return html`
    <div class="pb-editor-form__control">
      <label class="pb-editor-form__label ${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}">
        <div class="pb-editor-form__label-txt">
          상품정보제공고시 정보
          <span class="tooltip-icon2 tooltip-icon2--question">${tooltipIcon2El}</span>
          <div class="tooltip-container">
            <div class="editor-tooltip-box">
              ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('product_basic_info')}
            </div>
          </div>
        </div>
        <div class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--product-info">
          ${makeKrIcon()}
          <div class="pb-editor-form__body pb-editor-form__body--textarea">
            <textarea class="pb-editor-form__body-textarea" name="product_basic_info" cols="30" rows="10">
${UtilS.escape(product_basic_info) || ''}</textarea
            >
          </div>
        </div>
        <div class="mb-18"></div>
        <div
          class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--product-info ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
        >
          ${makeEnIcon()}
          <div class="pb-editor-form__body pb-editor-form__body--textarea">
            <textarea class="pb-editor-form__body-textarea" name="product_basic_info_en" cols="30" rows="10">
${UtilS.escape(product_basic_info_en) || ''}</textarea
            >
          </div>
        </div>
        <div class="mb-18"></div>
        <div
          class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--product-info ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
        >
          ${makeJpIcon()}
          <div class="pb-editor-form__body pb-editor-form__body--textarea">
            <textarea class="pb-editor-form__body-textarea" name="product_basic_info_jp" cols="30" rows="10">
${UtilS.escape(product_basic_info_jp) || ''}</textarea
            >
          </div>
        </div>
      </label>
      <p class="pb-editor-form__txt1">
        '전자상거래 등에서의 상품 등의 정보 제공에 관한 고시' 에 따라, 판매자가 상품 정보(원산지, 제조일, 제품
        인증 여부, A/S 책임자 등)를 사전에 제공토록 하는 통신판매업자 의무 규정입니다.
      </p>
    </div>
  `;
};

export const globalNoOptionTable = ({ sku, spo_item, spo_id, spo_item_id }) => {
  const { width, height, length, weight } = sku || {};
  const has_sku_size = width && height && length && weight;

  return `<div class="pb-editor-form__options-table-no-options">
                <table class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--option-table">
                    <thead>
                        <tr>
                        <th class="pb-editor-form__options-sku-nickname ${
                          UtilS.isEmpty(sku) ? '' : 'show-sku'
                        }">
                            Sku 코드
                            <span class="tooltip-icon2 tooltip-icon2--question">${tooltipIcon2El}</span>
                            <div class="tooltip-container">
                              <div class="editor-tooltip-box">${MShopStudioPbProductTmplS.pdTypeTooltipHTML(
                                'sku',
                              )}</div>
                            </div>
                        </th>
                        <th>상품</th>
                        <th class="pb-editor-form__options-size ${
                          MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME
                        } ${UtilS.isEmpty(sku) ? '' : 'show-sku'}">
                            상품 체적정보 <span class="pb-editor-form__required">*</span>
                        </th>
                       </tr>
                    </thead>
                    <tbody>
                    <tr 
                        data-id="${spo_item?.id}"
                        data-fx-json="${$dataStr({ sku, spo_id, spo_item_id })}"
                        class="${has_sku_size ? 'has-sku' : ''}">
                      <td class="pb-editor-form__options-sku-nickname ${
                        UtilS.isEmpty(sku) ? '' : 'show-sku'
                      }">
                        <input
                          type="text"
                          name="option_sku_nickname"
                          class="pb-editor-form__option-sku-nickname-input"
                          value="${sku?.nickname || sku?.id || ''}"
                          placeholder="상품 고유 코드"
                          ${sku?.id ? 'disabled' : ''}
                        />
                      </td>
                      <td>옵션 없음</td>
                      <td
                        class="pb-editor-form__options-size ${
                          MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME
                        } ${UtilS.isEmpty(sku) ? '' : 'show-sku'}""
                      >
                        <button type="button" name="option_size" class="pb-editor-form__option-btn-size">
                          정보 입력
                        </button>
                      </td>
                    </tr>
                    </tbody>
                </table>
            </div>`;
};
export const globalSpoItemsArea = ({ spo_options, spo_items, stock_type, skus = [] }) => {
  if (!spo_items || !spo_items.length) return '';

  if (!spo_options || !spo_options.length) {
    const [spo_item] = spo_items;
    const sku = spo_item?.sku;
    const spo_id = spo_item.spo_id;
    const spo_item_id = spo_item.id;
    return globalNoOptionTable({ sku, spo_item, spo_id, spo_item_id, skus });
  }

  return html`
    <div class="pb-editor-form__spo-items-wrapper">
      <div class="pb-editor-form__label-txt">
        옵션 목록
        <span class="pb-editor-form__label-txt-sub"
          >(총
          <span class="pb-editor-form__label-txt-sub2">${spo_items.length || '0'}</span>
          개)
        </span>
      </div>
      ${skus?.length
        ? ''
        : html`<div class="pb-editor-form__op-select-delete">
            <button type="button" class="pb-editor-form__btn-select-delete">선택 삭제</button>
          </div>`}
      <div class="pb-editor-form__options-table">${globalOptionsTable({ spo_items, stock_type, skus })}</div>
    </div>
  `;
};

export const globalOptionsTable = ({ spo_items, stock_type, skus = [] }) => {
  if (!spo_items || !spo_items.length) return '';

  return html`
    <table class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--option-table">
      <thead>
        <tr>
          <th class="pb-editor-form__options-check">
            <input
              type="checkbox"
              name="op_all"
              class="pb-editor-form__option-check-all"
              ${skus?.length ? 'disabled' : ''}
            />
          </th>
          <th class="pb-editor-form__options-sku-nickname ${skus?.length ? 'show-sku' : ''}">
            Sku 코드
            <span class="tooltip-icon2 tooltip-icon2--question">${tooltipIcon2El}</span>
            <div class="tooltip-container">
              <div class="editor-tooltip-box">${MShopStudioPbProductTmplS.pdTypeTooltipHTML('sku')}</div>
            </div>
          </th>
          ${strMap(
            (gnon) => html`
              <th class="pb-editor-form__options-name">
                <span class="group_name" data-group_name="${UtilS.escape(UtilS.escaper(gnon.group_name))}"
                  >${UtilS.escape(gnon.group_name)}</span
                >
                <span
                  class="group_name_en ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
                  data-group_name_en="${UtilS.escaper(UtilS.escape(gnon.group_name_en)) || ''}"
                >
                  ${gnon.group_name_en ? '/ ' + UtilS.escape(gnon.group_name_en) : ''}
                </span>
                <span
                  class="group_name_jp ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
                  data-group_name_jp="${UtilS.escaper(UtilS.escape(gnon.group_name_jp)) || ''}"
                >
                  ${gnon.group_name_jp ? '/ ' + UtilS.escape(gnon.group_name_jp) : ''}
                </span>
              </th>
            `,
            sel('0.group_names_option_names', spo_items),
          )}
          <th
            class="pb-editor-form__options-size ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} ${skus?.length
              ? 'show-sku'
              : ''}"
          >
            상품 체적정보 <span class="pb-editor-form__required">*</span>
          </th>
          <th class="pb-editor-form__options-price">옵션가</th>
          ${stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
            ? `
            <th class="pb-editor-form__options-stock-count">판매 재고수량</th>
            <th class="pb-editor-form__options-real-stock-count">총 재고수량</th>
          `
            : ''}
          ${skus?.length ? '' : '<th class="pb-editor-form__options-del">삭제</th>'}
        </tr>
      </thead>
      <tbody>
        ${strMap((spo_item) => {
          const sku = spo_item?.sku ?? {};
          const spo_id = spo_item.spo_id;
          const spo_item_id = spo_item.id;
          const { width, height, length, weight } = sku;
          const has_sku_size = width && height && length && weight;

          return html`
            <tr
              data-id="${spo_item.id}"
              data-spo_item_stock_id="${spo_item._?.spo_item_stock?.id || ''}"
              data-fx-json="${$dataStr({ sku, spo_id, spo_item_id })}"
              class="${has_sku_size ? 'has-sku' : ''}"
            >
              <td class="pb-editor-form__options-check">
                <input
                  type="checkbox"
                  name="op"
                  class="pb-editor-form__option-check"
                  ${sku?.id ? 'disabled' : ''}
                />
              </td>
              <td class="pb-editor-form__options-sku-nickname ${skus?.length ? 'show-sku' : ''}">
                <input
                  type="text"
                  name="option_sku_nickname"
                  class="pb-editor-form__option-sku-nickname-input"
                  value="${sku?.nickname || sku?.id || ''}"
                  placeholder="상품 고유 코드"
                  ${sku?.id ? 'disabled' : ''}
                />
              </td>
              ${strMap((gnon) => {
                return html`
                  <td class="pb-editor-form__options-name spo-names">
                    <span class="spo-item-text">${UtilS.escape(gnon.option_name)}</span>
                    <span class="spo-item-text ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
                      >${gnon.option_name_en ? '/ ' + UtilS.escape(gnon.option_name_en) : ''}</span
                    >
                    <span class="spo-item-text ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}"
                      >${gnon.option_name_jp ? '/ ' + UtilS.escape(gnon.option_name_jp) : ''}</span
                    >
                    <input
                      type="text"
                      name="spo_item"
                      class="pb-editor-form__option-name-input pb-editor-form__option-name-input-kr"
                      data-prev_value="${UtilS.escape(gnon.option_name)}"
                      value="${UtilS.escape(gnon.option_name)}"
                      style="display: none"
                    />
                    <input
                      type="text"
                      name="spo_item_en"
                      class="pb-editor-form__option-name-input pb-editor-form__option-name-input-en"
                      placeholder="예 : Large"
                      data-prev_value_en="${UtilS.escape(gnon.option_name_en)}"
                      value="${gnon.global_flag ? UtilS.escape(gnon.option_name_en) : ''}"
                      style="display: none"
                    />
                    <input
                      type="text"
                      name="spo_item_jp"
                      class="pb-editor-form__option-name-input pb-editor-form__option-name-input-jp"
                      placeholder="예 : ラージ"
                      data-prev_value_jp="${UtilS.escape(gnon.option_name_jp)}"
                      value="${gnon.global_flag ? UtilS.escape(gnon.option_name_jp) : ''}"
                      style="display: none"
                    />
                  </td>
                `;
              }, spo_item.group_names_option_names)}
              <td
                class="pb-editor-form__options-size ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} ${skus?.length
                  ? 'show-sku'
                  : ''}"
              >
                <button type="button" name="option_size" class="pb-editor-form__option-btn-size">
                  정보 입력
                </button>
              </td>
              <td class="pb-editor-form__options-price">
                <input
                  type="text"
                  name="option_price"
                  class="pb-editor-form__option-price-input"
                  value="${UtilS.commify(spo_item.price) || 0}"
                  ${sku?.id ? 'disabled' : ''}
                />
              </td>
              ${stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
                ? html`
                    <td class="pb-editor-form__options-quantity">
                      <input
                        min="0"
                        step="1"
                        type="number"
                        name="option_quantity"
                        class="pb-editor-form__option-quantity-input"
                        data-id="${spo_item._.spo_item_stock.id}"
                        value="${UtilS.escape(spo_item._.spo_item_stock.quantity) || 0}"
                        ${sku?.id ? 'disabled' : ''}
                      />
                    </td>
                    <td class="pb-editor-form__options-real-quantity">
                      <input
                        min="0"
                        step="1"
                        type="number"
                        name="option_real_quantity"
                        class="pb-editor-form__option-real-quantity-input"
                        data-id="${spo_item._.spo_item_stock.id}"
                        value="${UtilS.escape(spo_item._.spo_item_stock.real_quantity) || 0}"
                        ${sku?.id ? 'disabled' : ''}
                      />
                    </td>
                  `
                : ''}
              ${skus?.length
                ? ''
                : html`<td class="pb-editor-form__options-del">
                    <button type="button" class="pb-editor-form__option-btn-del">
                      <img
                        src="//s3.marpple.co/files/u_218933/2022/8/original/750ae6dba9f55f2f7064c33c489394713c8454e71.png"
                        alt="x"
                      />
                    </button>
                  </td>`}
            </tr>
          `;
        }, spo_items)}
      </tbody>
    </table>
  `;
};

export const globalOptionControls = (spo_options) => {
  const spo_options_data = map((spo_option) => {
    return {
      id: spo_option.id,
      option_group_name: spo_option.name || '',
      option_group_name_en: spo_option.name_en || '',
      option_group_name_jp: spo_option.name_jp || '',
      option_detail_info: go(spo_option._.spo_option_values, pluck('name'), (arr) => {
        if (!isArray(arr)) return '';
        return arr.join(', ');
      }),
      option_detail_info_en: go(spo_option._.spo_option_values, pluck('name_en'), (arr) => {
        if (!isArray(arr)) return '';
        return compact(arr).join(', ');
      }),
      option_detail_info_jp: go(spo_option._.spo_option_values, pluck('name_jp'), (arr) => {
        if (!isArray(arr)) return '';
        return compact(arr).join(', ');
      }),
    };
  }, spo_options);

  return go(
    spo_options_data.length
      ? spo_options_data
      : [
          {
            option_group_name: '',
            option_group_name_en: '',
            option_group_name_jp: '',
            option_detail_info: '',
            option_detail_info_en: '',
            option_detail_info_jp: '',
          },
        ],
    ippL,
    strMap(makeGlobalOptionControlHtml),
  );
};

export const makeGlobalOptionControlHtml = ([idx, option]) => {
  return html`
    <div
      class="pb-editor-form__control-area--cols2 pb-editor-form__option-control ${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}--option-control"
      data-idx="${idx}"
      data-id="${option?.id}"
    >
      <div class="pb-editor-form__control">
        <label class="pb-editor-form__label global-option-name">
          <div class="pb-editor-form__label-txt">옵션명</div>
          <div class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}__option-name">
            ${makeKrIcon()}
            <div class="pb-editor-form__body">
              <input
                type="text"
                name="option_group_name"
                class="pb-editor-form__body-input pb-editor-form__body-option-group-name"
                value="${UtilS.escape(option?.option_group_name)}"
                placeholder="예) 사이즈, 색상"
              />
            </div>
          </div>
          <div class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}__option-name mt-12">
            ${makeEnIcon()}
            <div class="pb-editor-form__body ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}">
              <input
                type="text"
                name="option_group_name_en"
                class="pb-editor-form__body-input pb-editor-form__body-option-group-name_en"
                value="${UtilS.escape(option?.option_group_name_en)}"
                placeholder="영문 옵션명을 입력해 주세요."
              />
            </div>
          </div>
          <div class="${MShopStudioPbProductConstantS.TPL_GLOBAL_WRAP_CLASSNAME}__option-name mt-12">
            ${makeJpIcon()}
            <div class="pb-editor-form__body ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME}">
              <input
                type="text"
                name="option_group_name_jp"
                class="pb-editor-form__body-input pb-editor-form__body-option-group-name_jp"
                value="${UtilS.escape(option?.option_group_name_jp)}"
                placeholder="일문 옵션명을 입력해 주세요."
              />
            </div>
          </div>
        </label>
        <p class="pb-editor-form__error-msg">
          ${MShopStudioPbProductS.pb_product_editor_error.required.text}
        </p>
      </div>

      <div class="pb-editor-form__control pb-editor-form__option-detail">
        <label class="pb-editor-form__label" for="option_detail_info${idx}">
          <div class="pb-editor-form__label-txt">옵션 상세 정보</div>
        </label>
        <div class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del">
          <div class="pb-editor-form__body">
            <input
              type="text"
              name="option_detail_info${idx}"
              id="option_detail_info${idx}"
              value="${UtilS.escape(option?.option_detail_info)}"
              class="pb-editor-form__body-input pb-editor-form__body-option-detail-info"
              placeholder="예) 블루, 레드, 핑크 (, 로 구분)"
            />
          </div>
          <div class="pb-editor-form__body-area-btn-del">
            <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-del--action">
              x
            </button>
          </div>
          <div class="pb-editor-form__body-area-btn-op-add">
            <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-option-add-tpl">
              +
            </button>
          </div>
        </div>
        <div
          class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} mt-12"
        >
          <div class="pb-editor-form__body">
            <input
              type="text"
              name="option_detail_info${idx}"
              id="option_detail_info${idx}"
              value="${UtilS.escape(option?.option_detail_info_en)}"
              class="pb-editor-form__body-input pb-editor-form__body-option-detail-info_en"
              placeholder="영문 옵션 상세 정보를 입력해 주세요."
            />
          </div>
          <div class="pb-editor-form__body-area-btn-del" style="display:none">
            <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-del--action">
              x
            </button>
          </div>
          <div class="pb-editor-form__body-area-btn-op-add" style="display:none">
            <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-option-add">+</button>
          </div>
        </div>
        <p class="pb-editor-form__error-msg pb-editor-form__error-msg--spo-en"></p>
        <div
          class="pb-editor-form__body-area pb-editor-form__body-area--op-add-del ${MShopStudioPbProductConstantS.TPL_GLOBAL_TOGGLE_CLASSNAME} mt-12"
        >
          <div class="pb-editor-form__body">
            <input
              type="text"
              name="option_detail_info${idx}"
              id="option_detail_info${idx}"
              value="${UtilS.escape(option?.option_detail_info_jp)}"
              class="pb-editor-form__body-input pb-editor-form__body-option-detail-info_jp"
              placeholder="일문 옵션 상세 정보를 입력해 주세요."
            />
          </div>
          <div class="pb-editor-form__body-area-btn-del" style="display:none">
            <button type="button" class="pb-editor-form__btn-op-del pb-editor-form__btn-op-del--action">
              x
            </button>
          </div>
          <div class="pb-editor-form__body-area-btn-op-add" style="display:none">
            <button type="button" class="pb-editor-form__btn-op-add pb-editor-form__btn-option-add">+</button>
          </div>
        </div>
        <p class="pb-editor-form__error-msg pb-editor-form__error-msg--spo-jp"></p>
        <p class="pb-editor-form__error-msg">
          ${MShopStudioPbProductS.pb_product_editor_error.restrict_character.text}
        </p>
      </div>
    </div>
  `;
};

export const makeSkuToggleHtml = ({ skus }) => {
  const has_sku = skus?.length;
  return html` <div class="pb-editor-form__control pb-editor-form__sku">
    <div class="pb-editor-form__label-txt flex-gap-2">
      Sku 코드 생성 <span class="pb-editor-form__required">*</span>
      <span class="pb-editor-form__toggle-container">
        <input
          type="checkbox"
          class="pb-editor-form__toggle-sku"
          ${has_sku ? 'disabled' : ''}
          ${has_sku ? 'checked' : ''}
        />
        <div class="slider round"></div>
      </span>
    </div>
    <ul>
      ${has_sku
        ? html`<li class="bold">- Sku 생성 후 상품 등록이 완료되어 카테고리와 옵션 수정이 불가능합니다.</li>`
        : html`<li>
              - Sku는 상품을 옵션 단위로 식별하고 관리하기 위해 사용되는 중복되지 않는 고유한 코드입니다.
            </li>
            <li>
              - 상품 등록을 위해 필수로 생성되어야 하는 코드 이지만, 직접 입력하지 않아도 마플샵에서 자동으로
              부여됩니다.
            </li>
            <li class="bold">
              <b>- 주의 : Sku 생성 후 상품을 등록하면 카테고리와 옵션 수정이 불가능합니다.</b>
            </li>`}
    </ul>
    <p class="pb-editor-form__error-msg">${MShopStudioPbProductS.pb_product_editor_error.required.text}</p>
  </div>`;
};

export const makeOptionDescription = ({ skus }) => {
  return html`<p class="pb-editor-form__txt1">
    ${skus?.length
      ? html`SKU가 발행된 상품은 옵션 수정이 불가능합니다.<br />옵션 변경이 필요할 경우, 상품을 다시 생성하여
          등록해 주시기 바랍니다.`
      : html`상품 선택 옵션을 생성합니다.`}
  </p>`;
};
