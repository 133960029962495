import { entries, filter, go, map, reject } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilS } from '../../../../Util/S/Function/module/MShopUtilS.js';
import { ERROR_INFO } from '../Constant/digitalProductError.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorS } from './module/MShopStudioDigitalProductCreatorS.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;
export const inputValidationResult = {
  [digital_product_prop.product_name_kr]: (name) => {
    if (!name?.length)
      return {
        key: digital_product_prop.product_name_kr,
        is_error: true,
        error: ERROR_INFO.required,
      };
    if (name.length < 3)
      return {
        key: digital_product_prop.product_name_kr,
        is_error: true,
        error: ERROR_INFO.min_text,
      };
    if (name.length > 100)
      return {
        key: digital_product_prop.product_name_kr,
        is_error: true,
        error: ERROR_INFO.text_length_100,
      };
    if (MShopUtilS.isNotAllowSpecialCharProductName(name))
      return {
        key: digital_product_prop.product_name_kr,
        is_error: true,
        error: ERROR_INFO.restrict_character2,
      };
    if (MShopUtilS.isProductNameBlackList(name)) {
      return {
        key: digital_product_prop.product_name_kr,
        is_error: true,
        error: ERROR_INFO.black_list_name,
      };
    }

    return { key: digital_product_prop.product_name_kr, is_error: false };
  },
  [digital_product_prop.price]: (price) => {
    if (!UtilS.is_number(price))
      return {
        key: digital_product_prop.price,
        is_error: true,
        error: ERROR_INFO.only_number,
      };
    if (parseInt(price) < 1000)
      return {
        key: digital_product_prop.price,
        is_error: true,
        error: ERROR_INFO.min_price1000,
      };
    if (parseInt(price) > 1000000)
      return {
        key: digital_product_prop.price,
        is_error: true,
        error: ERROR_INFO.max_price,
      };

    return { key: digital_product_prop.price, is_error: false };
  },
  [digital_product_prop.thumbnails]: (thumbnails) => {
    if (!thumbnails.length) {
      return {
        key: digital_product_prop.thumbnails,
        is_error: true,
        error: ERROR_INFO.thumbnail_picture,
      };
    }
    return { key: digital_product_prop.thumbnails, is_error: false };
  },
  [digital_product_prop.agree_copyright]: (is_agree) => {
    if (!is_agree) {
      return {
        key: digital_product_prop.agree_copyright,
        is_error: true,
        error: ERROR_INFO.is_agree_error,
      };
    }
    return { is_error: false };
  },
  [digital_product_prop.signed_url_keys]: (signed_url_keys) => {
    if (!signed_url_keys.length) {
      return {
        key: digital_product_prop.signed_url_keys,
        is_error: true,
        error: ERROR_INFO.digital_product_file,
      };
    }

    return { key: digital_product_prop.signed_url_keys, is_error: false };
  },
  [digital_product_prop.qna_link]: (qna_link) => {
    if (!qna_link.length) {
      return {
        key: digital_product_prop.qna_link,
        is_error: true,
        error: ERROR_INFO.required,
      };
    }
    if (!UtilS.isUrl(qna_link)) {
      return {
        key: digital_product_prop.qna_link,
        is_error: true,
        error: ERROR_INFO.qna_sns_link,
      };
    }
    return { key: digital_product_prop.qna_link, is_error: false };
  },
};

const rejectValidateException =
  ({ cate_name, qna_type }) =>
  (required_prop) => {
    if (required_prop === digital_product_prop.qna_link) {
      return qna_type === MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.BOARD;
    }
    /* TODO 카테고리 하드코딩 */
    if (
      required_prop === digital_product_prop.is_hyper_link &&
      cate_name !== MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE
    ) {
      return true;
    }

    return false;
  };

export const getDataErrors = (digital_product, thumbnail_photos) => {
  const required_props = go(
    MShopStudioDigitalProductCreatorConstantS.REQUIRED_PROPERTIES,
    reject(rejectValidateException(digital_product)),
  );

  const validation_fn_obj = MShopStudioDigitalProductCreatorS.inputValidationResult;

  digital_product[digital_product_prop.thumbnails] = thumbnail_photos;

  return go(
    digital_product,
    entries,
    filter(([k]) => {
      return required_props.includes(k);
    }),
    map(([k, v]) => {
      const validationFn = validation_fn_obj[k];
      return validationFn(v);
    }),
    filter((err) => {
      return err.is_error;
    }),
  );
};
