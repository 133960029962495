import { ifElse, go, pipe } from 'fxjs/es';
import getDay from 'date-fns/getDay/index.js';
import addDays from 'date-fns/fp/addDays/index.js';
import setSeconds from 'date-fns/fp/setSeconds/index.js';
import setMinutes from 'date-fns/fp/setMinutes/index.js';
import setHours from 'date-fns/fp/setHours/index.js';

const set6am = pipe(setHours(6), setMinutes(0), setSeconds(0));

const getOrderCancellableTime = (_ordered_at) => {
  const ordered_at = new Date(_ordered_at);
  const day = getDay(ordered_at);

  return go(
    ordered_at,
    ifElse(() => day == 0, addDays(1), addDays(8 - day)),
    set6am,
  );
};

export const isCancellableOrder = (ordered_at) => {
  return new Date() < getOrderCancellableTime(ordered_at);
};
