import { legacyHtml } from '../../../../../Util/S/Function/util.js';

export const makeAddUrlHtml = () => legacyHtml`
  <div class="layout">
    <div class="content">
      <p class="title">상품 추가</p>
      <p class="description">
        마플샵 상품 상세 페이지 URL을 입력해 상품을 추가해 보세요. <br />
        각 상품 URL을 엔터로 구분하면 여러 상품을 쉽게 추가할 수 있습니다.<br />
        PB 상품의 경우 마플 E-Commerce(ME) 상품인지 확인 후 등록시켜주세요.
      </p>
      <textarea
        class="textarea"
        name=""
        placeholder="https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호"
      >
      </textarea>
    </div>

    <div class="footer">
      <button class="btn btn-cancel-frame">취소하기</button>
      <button class="btn btn-submit">저장하기</button>
    </div>
  </div>
`;
