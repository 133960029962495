export const MARPPLE = {
  id: 'MARPPLE',
};
export const SELLER = {
  id: 'SELLER',
};
export const DIGITAL = {
  id: 'DIGITAL',
};
export const types = [MARPPLE, SELLER, DIGITAL];
