import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { PRODUCT_STORY_MAX_WIDTH } from '../../ProductStoryEditor/S/width.js';

const makeYoutubeEmbedUrl = (url = '') => {
  url = UtilS.escape(url);
  const embed_url = 'https://www.youtube.com/embed/';
  const embed_regexp = /embed\/+/;
  const match_regexp = /watch\?v=([^&]+)/;
  const short_regexp = /shorts\/([^&|?]+)/;

  if (embed_regexp.test(url)) {
    return url;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp);
    return embed_url + youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp);
    return embed_url + youtube_id;
  }
  return (
    embed_url +
    url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '')
  );
};

export const makeYoutubeEmbedHtml = (youtube_embed) =>
  youtube_embed
    ? legacyHtml`
        <div class="youtube-wrap">
          <iframe
            src="${makeYoutubeEmbedUrl(youtube_embed)}"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      `
    : '';

export const makeFacebookEmbedHtml = (fb_embed) =>
  fb_embed
    ? legacyHtml` <div class="fb-post" data-href="${UtilS.escape(fb_embed)}" data-width="500"></div> `
    : '';
export const makeProductStoryImageHtml = (func) => (product_story_image) =>
  legacyHtml`
  <div
    class="product-story-image"
    data-id="${product_story_image.id || 0}"
    data-file-id="${product_story_image.file_id}"
  >
    <img src="${
      (product_story_image.url.includes('data:image')
        ? product_story_image.url
        : UtilImageS.getResizedUrl({
            url: product_story_image.url,
            width: PRODUCT_STORY_MAX_WIDTH,
            format: 'webp',
            quality: 80,
          })) || ''
    }" alt="" />
    ${func ? func(product_story_image) : ''}
  </div>
`;
