import { $el } from 'fxdom/es';
import { html, go, strMap, zipWithIndexL } from 'fxjs/es';

const addClassIf = (v, k) => (v ? k : '');

export const createCustomToolbar = ({ klass, actions }) => {
  const len = actions.length;
  return $el(html`
    <div
      contenteditable="false"
      class="${klass} custom-toolbar medium-editor-toolbar medium-toolbar-arrow-under"
    >
      <ul class="medium-editor-toolbar-actions">
        ${go(
          actions,
          zipWithIndexL,
          strMap(
            ([idx, action]) => html`
              <li>
                <button
                  class="medium-editor-action ${action.name}
                  ${addClassIf(idx == 0, 'medium-editor-button-first')}"
                  ${addClassIf(idx == len - 1, 'medium-editor-button-last')}"
                >
                  <i class="${action.icon}"></i>
                </button>
              </li>
            `,
          ),
        )}
      </ul>
    </div>
  `);
};
