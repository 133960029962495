import { CreatorEditorMarppleEmbedMuiS } from '../../S/Mui/module/CreatorEditorMarppleEmbedMuiS.js';
import { initSortableArrange } from '../../../../../Util/F/arrangement.js';
import { $findAll, $appendTo, $before, $closest, $delegate, $find, $remove, $removeClass } from 'fxdom/es';
import { go, pipe } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { addEditMenuContainer } from '../../../F/medium-editor/custom-insert-plugin/marpple.js';
import { CreatorEditorMarppleEmbedF } from './../Function/module/CreatorEditorMarppleEmbedF.js';
import { MARPPLE_EMBED_SELECTOR } from '../../../S/constant.js';

export const tab = {
  ...CreatorEditorMarppleEmbedMuiS.tab,

  appended(tab_el$) {
    const content_container$ = $find('.content', tab_el$);
    const marpple_embed_container_body$ = $find('.marpple-embed-container .body', tab_el$);

    const embed_container$ = $find(MARPPLE_EMBED_SELECTOR, marpple_embed_container_body$);

    const product_els = $findAll('.app-product-item', tab_el$);
    if (product_els.length == 1) $removeClass('app-product-item--today--one', product_els[0]);

    initSortableArrange(embed_container$, { draggable: '.app-product-item' });
    addEditMenuContainer(embed_container$);

    go(
      tab_el$,
      // block product link move
      $delegate('click', '.app-product-item', CreatorEditorMarppleEmbedF.preventProductLinkHandler),
      // 최초 생성
      $delegate('click', '.content label', async () => {
        $removeClass('empty', content_container$);
        await CreatorEditorMarppleEmbedF.addEmbedProducts($appendTo(embed_container$));
      }),
      // 추가
      $delegate('click', '.marpple-embed-container > .header .btn-submit', () =>
        CreatorEditorMarppleEmbedF.addEmbedProducts($appendTo(embed_container$)),
      ),

      // footer
      $delegate(
        'click',
        '.footer .btn-submit',
        CreatorEditorMarppleEmbedF.saveMarppleEmbed(marpple_embed_container_body$),
      ),
      $delegate('click', '.footer .btn-cancel-frame', () => MuiF.removeFrame()),

      // option
      $delegate('click', '.embed_option', CreatorEditorMarppleEmbedF.openOptionHandler),
      $delegate('click', '.embed_option .update-option', async (e) => {
        const product$ = $closest('.app-product-item', e.target);
        await CreatorEditorMarppleEmbedF.addEmbedProducts(
          pipe(
            (el) => $before(el, product$),
            () => $remove(product$),
          ),
        );
      }),
      $delegate('click', '.embed_option .delete-option', (e) =>
        $remove($closest('.app-product-item', e.target)),
      ),
      $delegate('focusout', '.embed_option', (e) =>
        $removeClass('opened', $closest('.embed_option', e.target)),
      ),
    );
  },
};
