import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { frame } from './frame.js';

/*
 * 서버사이드 렌더링 후 프론트에서 이니셜라이즈 하는 함수입니다.
 * */
export const start = () =>
  MuiF.startFrame(frame, (frame, page, tabs) => {
    /* 서버사이드 렌더링 후 동적으로 해줘야 하는 부분 */
  });
