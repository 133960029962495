import { curry2, isArray, map, sel } from 'fxjs/es';
import { produce } from 'immer';

// 깊이 있는 중첩 map 을 돌아야 할 경우
// 아래와 같은 코드를 짜지 않기 위함

/*
map((tp) =>
  map(
    (pdf) =>
      map(
        (prj) =>
          map(
            (up) => ({
              ...up,
              projection_id: prj.id,
              up_id: up.id,
              pdf_id: pdf.id,
              type: 'qr',
              is_we_combined: pdf.is_we_combined,
            }),
            prj._.ups,
          ),
        pdf._.projections,
      ),

    tp._.pdfs,
  ),
),
 */

export const deepMap = curry2((f, selector = '', obj) => {
  const is_array = isArray(obj);

  if (!selector) return is_array ? map(f, obj) : f(obj);
  const [head_sel, ...rest] = selector.split('.');

  return is_array
    ? map((item) => deepMap((...v) => f(item, ...v), selector, item), obj)
    : produce(obj, (draft) => {
        draft[head_sel] = deepMap(f, rest.join('.'), sel(head_sel, obj));
      });
});
