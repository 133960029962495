import { go, html, strMap, zipWithIndexL } from 'fxjs/es';
import { makeTitleWithTooltipHtml } from '../../../../../../Creator/Setting/S/product.tmpl.js';
import { MShopStudioTokenGateStoresProductConstantS } from '../Constant/module/MShopStudioTokenGateStoresProductConstantS.js';

const simplifyAddress = ({ address, crop = 3 }) => address.slice(0, crop) + '...' + address.slice(-crop);
const channelAddressLinkEnum = {
  EthereumMainnet: 'https://etherscan.io/address/',
  Goerli: 'https://goerli.etherscan.io/address/',
  PolygonMainnet: 'https://polygonscan.com/address/',
  Mumbai: 'https://mumbai.polygonscan.com/address/',
};

export const item = ({
  amount,
  _: {
    ether_contract: { name, chain, address, symbol, erc_interface },
  },
}) => html`
  <div class="setting-token-gate-item">
    <div class="setting-token-gate-item__chain">${chain.replace('Mainnet', '')}</div>
    <div class="setting-token-gate-item__address">
      <a href="${channelAddressLinkEnum[chain]}${address}" target="_blank"
        >${simplifyAddress({ address, crop: 5 })}</a
      >
    </div>
    <div class="setting-token-gate-item__name">${name}</div>
    <div class="setting-token-gate-item__erc-interface">${erc_interface}</div>
    <div class="setting-token-gate-item__symbol">${amount}${symbol}</div>
    <button type="button" class="setting-token-gate-item__remove">x</button>
  </div>
`;

export const edit = (stores_products_token_gates) => html`
  <div class="setting-token-gate-edit">
    <select class="setting-token-gate-edit__chain">
      <option value="">Select Chain</option>
      <option value="EthereumMainnet">Ethereum</option>
      <option value="PolygonMainnet">Polygon</option>
    </select>
    <input
      class="setting-token-gate-edit__address"
      type="text"
      placeholder="NFT Contract Address (ERC721)"
      disabled
    />
    <input
      style="display: none;"
      class="setting-token-gate-edit__amount"
      type="text"
      placeholder="Quantity"
      disabled
      value="1"
    />
    <button type="button" class="setting-token-gate-edit__add-btn" disabled></button>
  </div>
  <div
    class="setting-token-gate-wrapper ${!stores_products_token_gates.length
      ? 'setting-token-gate-wrapper--empty'
      : 'setting-token-gate-wrapper--list'}"
  >
    <div class="setting-token-gate-empty">
      <div class="setting-token-gate-empty__icon">!</div>
      ${T('tokenGate::tg014')}
    </div>
    <div class="setting-token-gate-list">
      <div
        class="setting-token-gate-condition${stores_products_token_gates.length > 1
          ? ' setting-token-gate-condition--show'
          : ''}"
      >
        <label class="setting-token-gate-condition__and">
          <input type="radio" name="setting-token-gate-condition__radio" value="AND" checked />${T(
            'tokenGate::tg015',
          )}
        </label>
        <label class="setting-token-gate-condition__or">
          <input
            type="radio"
            name="setting-token-gate-condition__radio"
            value="OR"
            ${stores_products_token_gates[0]?.condition == 'OR' ? 'checked' : ''}
          />${T('tokenGate::tg016')}
        </label>
      </div>
      ${go(
        stores_products_token_gates,
        zipWithIndexL,
        strMap(
          ([
            idx,
            {
              amount,
              _: {
                ether_contract: { name, chain, address, symbol, erc_interface },
              },
            },
          ]) => html`
            <div class="setting-token-gate-item" data-no="${idx}">
              <div class="setting-token-gate-item__chain">
                <div class="setting-token-gate-item__chain-icon">
                  ${MShopStudioTokenGateStoresProductConstantS.CONTRACT_CHAIN_ICON[chain]}
                </div>
                ${chain.replace('Mainnet', '')}
              </div>
              <div class="setting-token-gate-item__address">
                <a href="${channelAddressLinkEnum[chain]}${address}" target="_blank"
                  >${simplifyAddress({ address, crop: 5 })}</a
                >
              </div>
              <div class="setting-token-gate-item__name">${name || 'Unknown'}</div>
              <div class="setting-token-gate-item__erc-interface">
                <div class="setting-token-gate-item__erc-interface-box">${erc_interface}</div>
              </div>
              <div class="setting-token-gate-item__symbol">${amount} ${symbol || 'Unknown'}</div>
              <div class="setting-token-gate-item__last">
                <button type="button" class="setting-token-gate-item__remove"></button>
              </div>
            </div>
          `,
        ),
      )}
    </div>
  </div>
`;

export const fn = (stores_products_token_gates) => html` <div class="form-item">
  ${makeTitleWithTooltipHtml({
    title: T('tokenGate::tg017'),
    tooltip_content: T('tokenGate::tg018'),
  })}
  <div class="setting-token-gate">${edit(stores_products_token_gates)}</div>
</div>`;
