import { curry, equals2, every, filter, find, go, sel, selEq, some } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const getServices = (user) => sel('_.crew._.services', user);
export const getActions = (user) => sel('_.actions', user);

export const hasService = curry((service, crew_services) => some((s) => s.name == service, crew_services));

export const checkActions = curry(
  (
    {
      service: required_service,
      actions = [],
      target,
      pass_no_service = true,
      is_all = true,
      user_exceptions,
    },
    { user, user_actions: _user_actions, crew_services },
  ) => {
    const user_actions = filter(selEq('target', target), _user_actions || getActions(user));

    if (user_exceptions && user_exceptions.some(equals2(user.id))) return true;

    if (!required_service && !actions.length) return true;

    // 제한된 서비스인데 해당 서비스가 없을 경우
    const has_required_service = !required_service
      ? true
      : some((s) => s.name == required_service, crew_services || getServices(user));

    if (!has_required_service) return pass_no_service;

    if (!actions.length) return true;

    // action 체크
    return go(
      actions,
      UtilS.wrapArr,
      (is_all ? every : some)((a) => find(selEq('name', a), user_actions)),
    );
  },
);
