import { $find, $findAll } from 'fxdom/es';
import { go, map, sum } from 'fxjs/es';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
//
// const gray_info_quantity_with_stock$ = $el(
//   `<div class="product-gray-info product-gray-info-quantity-stock">${T(
//     '한정 판매 재고수량은 재고수량보다 같거나 적게 입력해 주세요.',
//   )}</div>`,
// );
//
// const gray_info_max_purchase_with_stock$ = $el(
//   `<div class="product-gray-info product-gray-info-max-purchase-stock">${T(
//     '1인당 구매 가능 수량은 재고수량보다 같거나 적게 입력해 주세요.',
//   )}</div>`,
// );
//
// const gray_info_max_purchase_with_quantity$ = $el(
//   `<div class="product-gray-info product-gray-info-max-purchase-quantity">${T(
//     '1인당 구매 가능 수량은 한정 판매 수량보다 같거나 적게 입력해 주세요.',
//   )}</div>`,
// );
//
// const afterGrayInfo$ = (info$, input$) => {
//   $after(info$, $closest('.pb-editor-form__body', input$));
// };

const getStockQuantity = (tab_el$) => {
  const stock_type = $find('[name="stock_type"]:checked', tab_el$).value;

  if (SpoSpoConstantS.pSpoStockType.OPTION_STOCK == stock_type) {
    return (
      go(
        $findAll('.pb-editor-form__option-quantity-input', tab_el$),
        map((input$) => Number(input$.value)),
        sum,
      ) || 0
    );
  } else if (SpoSpoConstantS.pSpoStockType.TOTAL_STOCK == stock_type) {
    return Number($find('[name="total_stock"]', tab_el$).value);
  }
  return null;
};

export const checkQuantity = async ({ delegateTarget: tab_el$ }) => {
  /*
   * 한정판매수량은 무조건 재고보다 작거나같아야함
   * 1인당 판매가능은 한정판매수량보다 무조건 작거나 같아야함
   * */
  // if ($find('.product-gray-info-quantity-stock', tab_el$)) {
  //   $remove(gray_info_quantity_with_stock$);
  // }
  //
  // if ($find('.product-gray-info-max-purchase-stock', tab_el$)) {
  //   $remove(gray_info_max_purchase_with_stock$);
  // }

  const product_type_id = $find('[name="product_type"]', tab_el$).value;
  const { is_limited_possible } = box.sel(`product_types->(#${product_type_id})`);

  if (is_limited_possible) {
    const stock_quantity = getStockQuantity(tab_el$);
    const quantity$ = $find('[name="quantity"]', tab_el$);
    const max_purchase$ = $find('[name="max_purchase_per_user"]', tab_el$);
    const order_count = Number($find('[data-order_count]', tab_el$)?.dataset?.order_count || '0');
    const quantity = Number(quantity$.value);
    const max_purchase_per_user = Number(max_purchase$.value);
    if (stock_quantity !== null) {
      if ($find('[name="check_quantity"]:checked', tab_el$) && stock_quantity < quantity - order_count) {
        // quantity$.value = stock_quantity;
        // afterGrayInfo$(gray_info_quantity_with_stock$, quantity$);
        await MShopShareFramePopUpF.alert({
          title: '수량 변경',
          body: `한정 판매 수량${order_count > 0 ? '과 판매된 수량의 차이' : ''}(${UtilS.commify(
            quantity - order_count,
          )}) 보다<br />재고수량(${UtilS.commify(stock_quantity)}) 이 크거나 같아야합니다.`,
        });
        quantity$.focus();
        return true;
      }

      if (
        $find('[name="check_max_purchase_per_user"]:checked', tab_el$) &&
        stock_quantity < max_purchase_per_user
      ) {
        // max_purchase$.value = stock_quantity;
        // afterGrayInfo$(gray_info_max_purchase_with_stock$, max_purchase$);
        await MShopShareFramePopUpF.alert({
          title: '수량 변경',
          body: T('1인당 구매 가능 수량은 재고수량보다 같거나 적게 입력해 주세요.'),
        });
        max_purchase$.focus();
        return true;
      }
    }

    if ($find('[name="check_quantity"]:checked', tab_el$)) {
      if (quantity < max_purchase_per_user) {
        // max_purchase$.value = quantity;
        // return afterGrayInfo$(gray_info_max_purchase_with_quantity$, max_purchase$);
        await MShopShareFramePopUpF.alert({
          title: '수량 변경',
          body: T('1인당 구매 가능 수량은 한정 판매 수량보다 같거나 적게 입력해 주세요.'),
        });
        max_purchase$.focus();
        return true;
      }
    }
  }
};
