import { html } from 'fxjs/es';
import { CreatorSettingPrivacyTmplS } from './module/CreatorSettingPrivacyTmplS.js';

export const kr_studio_privacy_20230118 = ({ store_name }) => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h1>[마플샵] 판매자 개인정보처리방침 변경 안내</h1>
      </div>
      <div class="body">
        <p>
          안녕하세요. 마플코퍼레이션입니다. 마플샵 (이하 "몰")의 개인정보처리방침의 일부 내용이 변경되어
          2023년 1월 25일자로 적용될 예정입 니다. 이에 판매자 개인정보방침 개정에 관한 내용을 사전에
          알려드리오니 회원 여러분께서는 새로운 약관을 확인하시고 참고하여 주시기를 바랍니다.
        </p>
        <p><b>변경고지일 : 2023년 1월 18일 변경 내용</b></p>
        <p>변경 내용</p>
        <table class="terms_preview">
          <tr>
            <th style="width: 30%;">변경 전</th>
            <th>변경 후</th>
          </tr>
          <tr>
            <td>
              <h3>4. 개인정보 공유 및 제공</h3>
              <p>
                회사는 이용자들의 개인정보를 『3. 개인정보의 수집 및 이용목적』에 고지한 범위 내에서 사용하며,
                이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에
                공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
              </p>
              <div class="privacy2_list">
                <ul>
                  <li>이용자들이 사전 제공에 동의한 경우</li>
                  <li>
                    법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </li>
                </ul>
              </div>
              <p>
                개인정보를 제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명,
                개인정보 제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에
                동의한 사항, 고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한
                사항에 대해 개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를
                거치게 되며, 고객이 동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.
              </p>
            </td>
            <td>
              <h3>4. 개인정보 공유 및 제공</h3>
              <p>
                회사는 원활한 서비스를 제공하기 위해 제휴를 맺은 회사들에게 서비스를 위탁하고 있으며,
                판매자회원의 정보는 안전하고 원활한 결제 및 배송 서비스를 위해 제공될 수 있습니다. 개인정보를
                제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명, 개인정보
                제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에 동의한 사항,
                고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한 사항에 대해
                개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며,
                고객이 동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.
              </p>
              <table>
                <thead>
                  <tr>
                    <th>제공받는자</th>
                    <th>개인정보 이용목적</th>
                    <th>개인정보의 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>(주)KG이니시스</td>
                    <td>전담 PG사의 요청에 의함 이용자의 송금정보를 이용하여 적극적인 정산을 하기위함</td>
                    <td>이름, 전화번호, 생년월일, 주소, 계좌번호, 카드정보</td>
                    <td>수집,이용 동의일로부터 거래종료 후 5년까지</td>
                  </tr>
                </tbody>
              </table>
              <div class="privacy2_list">
                <ul>
                  <li>이용자들이 사전 제공에 동의한 경우</li>
                  <li>
                    법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </li>
                </ul>
              </div>
              <p>
                개인정보를 제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명,
                개인정보 제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에
                동의한 사항, 고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한
                사항에 대해 개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를
                거치게 되며, 고객이 동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.
              </p>
            </td>
          </tr>
        </table>
      </div>
      <hr style="margin-top: 50px;" />
    </div>

    ${CreatorSettingPrivacyTmplS.kr_studio_privacy_20230125({ store_name })}
  `;
};
