export const N_OPTION_INFO_STATUS = {
  NORMAL: 'NORMAL',
  NO_STOCK: 'NO_STOCK',
  OPTION_CHANGED: 'OPTION_CHANGED',
  NOT_ENOUGH_STOCK: 'NOT_ENOUGH_STOCK',
  LIMIT_EXPIRED: 'LIMIT_EXPIRED',
  DISCONTINUED: 'DISCONTINUED',
};

export const WARNING_MESSAGE = {
  OPTION_CHANGED: T(
    'mshop_cart_and_checkout::상품의 옵션이 변경되었습니다. 확인후 장바구니에 다시 넣어주세요.',
  ),
  NO_STOCK: T('mshop_cart_and_checkout::선택한 옵션 또는 상품이 품절되었습니다.'),
  NOT_ENOUGH_STOCK: T(
    'mshop_cart_and_checkout::선택한 옵션 또는 상품의 재고가 부족하여 주문이 불가합니다. 수량을 변경해 주세요.',
  ),
  DISCONTINUED: T('mshop_cart_and_checkout::선택한 옵션 또는 상품이 품절되었습니다.'),
};
