import { html } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const kr_studio_20230125 = ({ store_name }) => {
  return html`
    <div class="body terms_body">
      <div class="title">
        <h2>셀러스튜디오 이용약관</h2>
      </div>
      <div class="body text_body">
        <div class="paragraph">
          <h3 id="service1">제1조(목적)</h3>
          <p>
            본 약관은 주식회사 마플코퍼레이션(이하 ‘회사’)가 운영하는 ‘마플샵’(이하 ‘몰’)과 셀러스튜디오(이하
            ‘판매자센터’)에서 전자상거래 관련 서비스 및 기타 서비스(이하 ’서비스’라 함)를 이용하기 위하여
            ‘마플샵’에 ‘셀러’(이하 ‘판매자회원’) 자격으로 가입한 자와 회사 간의 권리, 의무 및 제반 절차를
            확정하고 이를 이행함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
          </p>
          <p>
            ※ PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 현 약관을 준용합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service2">제2조(정의)</h3>
          <p>본 "약관"에서 사용하는 용어의 정의는 다음과 같습니다.</p>
          <ol>
            <li>
              ①. 판매자회원: 만 14세 이상의 개인(개인 사업자 포함) 또는 법인으로서 현 약관에 동의하고 ‘회사’가
              제공하는 ‘몰’, ‘판매자센터’, ‘서비스’를 사용하며, ‘회사’에서 제공하는 서비스를 통해 자신의
              ‘아이디어, 콘텐츠, 디자인’을 제작, 게재 및 홍보하고, 그 ‘아이디어, 콘텐츠, 디자인’을 이용하여
              ‘회사’가 ‘상품’을 제작 및 판매할 수 있도록 허락하는 이용자를 의미합니다.
            </li>
            <li>
              ②. 판매자회원의 ‘아이디어, 콘텐츠, 디자인’: 이미지, 영상, 그림, 음성, 음향, 음원, 폰트, 문자,
              문서, 부호, 성명, 예명, 초상 등으로 표현된 ‘판매자회원’의 저작물 또는 정보로서 그 이용에 있어서
              사용자의 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다. ‘판매자회원’은
              ‘아이디어, 콘텐츠, 디자인’ 및 이를 이용하여 ‘회사’가 제작할 ‘상품’을 게재하여 자신만의 ‘샵’을
              꾸미고, 자신의 ‘아이디어, 콘텐츠, 디자인’을 홍보할 수 있습니다.
            </li>
            <li>
              ③. 샵: ‘판매자회원’이 자신의 ‘아이디어, 콘텐츠, 디자인’을 ‘구매자회원’에게 소개 및 홍보할 수
              있는 회사가 운영하는 ‘몰’ 내 존재하는 공간 또는 전시장을 의미합니다.
            </li>
            <li>
              ④. 판매자센터: ‘회사’가 ‘판매자회원’이 자신의 ‘상품’을 디자인하여 국내 및 국외에서
              ‘구매자회원’에게 홍보할 수 있도록 돕고 통계 확인, 정산 등의 다양한 ‘서비스’를 지원하는 플랫폼을
              의미합니다.
            </li>
            <li>
              ⑤. 마플샵 배송 상품: ‘회사’가 ‘판매자회원’으로부터 ‘아이디어, 콘텐츠, 디자인’의 이용을 허락받아
              제작한 의류, 핸드폰케이스, 악세사리 등으로, ‘회사’는 ‘구매자회원’과 ‘상품’에 대한 매매계약을
              직접 체결함으로써 이를 ‘몰’을 통해 판매합니다.
            </li>
            <li>
              ⑥. 셀러 배송 상품: ‘판매자회원'이 직접 ‘회사'에 직접 등록해서 판매하고자하는 상품, ‘마플샵 배송
              상품'과는 다르게 판매자회원이 마플샵에 주문 생산 방식을 이용하지 않고 자체적으로 공급하여 직접
              판매하는 상품을 의미합니다.
            </li>
            <li>⑦. 구매자회원: ‘몰’에서 ‘상품’을 구매하는 이용자를 의미합니다.</li>
            <li>
              ⑧. 서비스: 회사가 ‘몰’을 통해 ‘판매자회원’이 자신의 ‘아이디어, 컨텐츠, 디자인’ 및 ‘상품’을
              제작∙홍보할 수 있도록 지원하는 것과 주문 확인, 상품 제작, 상품 배송 등 ‘구매자회원’의 구매행위와
              관련된 과정들을 지원하는 것을 총칭합니다.
            </li>
            <li>
              ⑨. 판매 대금: ‘구매자회원’이 ‘상품’을 구매한 대가로 지불하는 금액을 말합니다. ‘판매 대금’은
              ‘회사’가 책정한 ‘기본가격’에 ‘판매자회원’이 책정한 ‘디자인가격’을 더하여 산정됩니다.
            </li>
            <li>
              ⑩. 기본가격: ‘상품’ 제작·제조 비용, 금융결제수수료, ‘몰’의 개발, 유지, 관리 등 상품 공급과
              관련된 제반 서비스 비용 등에 대한 금액을 총칭한 것을 의미합니다.
            </li>
            <li>
              ⑪. 디자인가격: ‘판매 대금’ 중 ‘판매자회원’이 자신의 디자인에 대하여 책정한 금액을 의미합니다.
            </li>
            <li>
              ⑫. 정산 대금: 판매자회원이 판매한 상품들의 대금 정산을 의미합니다.
              <ol>
                <li>
                  a. 마플샵 배송 상품 판매대금 : ‘판매 대금’ 중 ‘판매자회원’이 책정한 자신의
                  디자인가격으로부터, 전자 결제 수수료 등 공제가 필요한 부분에 대한 금액을 차감한 후
                  ‘회사’로부터 지급 받아야하는 금액을 의미합니다.
                </li>
                <li>
                  b. 셀러 배송 상품 판매대금 : ‘판매 대금' 중 ‘판매자회원"이 직접 공급해서 판매하는 상품의
                  가격 과 직접 설정한 배송비를 더한 금액에, 전자 결제 수수료와 마플샵 수수료(플랫폼 사용료)를
                  차감한 후 ‘회사'로부터 지급 받아야하는 금액을 의미합니다.
                </li>
              </ol>
            </li>
            <li>
              ⑬. 신고센터제도: 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한
              법률상 이익 침해에 대한 불만을 제기하기 위한 몰 내 게시판을 의미합니다.
            </li>
            <li>
              ⑬. 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에
              의합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">제 3 조 (약관의 게시 및 개정)</h3>
          <ol>
            <li>
              ①. 회사는 이 약관을 판매자회원이 쉽게 알 수 있도록 몰 초기화면 또는 연결화면을 통하여
              게시합니다.
            </li>
            <li>
              ②. 회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우
              개정내용과 적용일자를 명시하여 몰을 통해 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다.
              다만, 변경 내용이 판매자회원에게 불리하거나 판매자회원의 권리 또는 의무에 관한 중요 사항의
              변경은 개정약관의 적용일자 30일 전부터 적용일자까지 공지하고, 전자우편 등을 통하여
              판매자회원에게 개별 통지합니다(판매자회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운
              때에는 몰 초기화면을 통하여 공지함으로써 개별 통지한 것으로 간주합니다.)
            </li>
            <li>
              ③. 판매자회원이 개정약관에 동의하지 않는 경우에는 개정약관의 적용일 이전에 거부의사를 표시하고
              이 약관에 의한 이용계약을 해지할 수 있습니다.
            </li>
            <li>
              ④. 회사가 본 조 제 2항에 따라 개정약관을 공지 또는 통지하면서 판매자회원에게 적용일전까지
              의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
              판매자회원이 명시적으로 거부의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
            </li>
            <li>
              ⑤. 본 약관에 동의하는 것은 정기적으로 회사가 운영하는 웹사이트를 방문하여 약관의 변경사항을
              확인하는 것에 동의함을 의미하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에
              대하여 회사는 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service4">제4조 (약관의 효력)</h3>
          <ol>
            <li>
              ①. 회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 개별 운영 정책 등(이하 ‘운영정책‘이라
              함)을 제정하여 운영할 수 있으며, 해당 내용을 몰을 통하여 게시합니다. 운영정책은 이 약관과 더불어
              서비스 이용계약(이하 ‘이용계약‘이라 함)의 일부를 구성합니다.
            </li>
            <li>
              ②. 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며,
              판매자회원이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과
              상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.
            </li>
            <li>
              ③. 본 약관에 의해 판매자회원으로 가입하고자 하는 자는 구매회원 이용약관의 내용을 숙지하고
              구매회원과 회사간의 권리∙의무관계에 대해 동의함을 확인합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service5">제5조 (이용계약의 성립)</h3>
          <ol>
            <li>
              ①. 이용약관은 서비스를 이용하고자 하는 자(이하 ‘이용신청자‘라 함)가 본 약관에 동의하고 회사가
              정한 절차에 따라 판매자회원 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써
              성립합니다. 회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 전자우편 또는 기타
              방법으로 할 수 있습니다.
            </li>
            <li>
              ②. 이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당
              서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
            </li>
            <li>
              ③. 판매자회원 가입은 만 14세 이상의 개인(개인 사업자 포함) 또는 법인사업자가 할 수 있으며, 만
              19세 미만의 판매자회원은 가입시 법정대리인의 동의서 및 회사가 요구하는 입증자료(가족관계증명서,
              법정대리인 인감증명서 등)를 제출하여야 합니다.
            </li>
            <li>
              ④. 회사는 본 조 제1항에 따라 판매자 회원가입을 신청한 자 중에서 아래 각 호에 해당되는 경우에는
              승낙을 거부할 수 있습니다. 이용신청자가 아래 각호의 행위를 하였을 경우, 회사는 이에 대한 어떠한
              책임도 부담하지 않으며 이용 신청자의 아래 각호 행위로 인해 회사에 손해가 발생하거나 제3자와 법적
              분쟁이 발생할 시 이용 신청자는 자신의 비용(변호사 비용, 소송 비용 등을 포함하나 이에 한정하지
              않음)과 책임으로 회사의 손해를 배상하여야 합니다. 다만, 회사의 고의 또는 중대한 과실로 인한 경우
              본 항이 적용되지 않습니다.
              <ol>
                <li>
                  1. 가입을 신청한 자가 이 약관 및 회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한
                  적이 있는 경우
                </li>
                <li>
                  2. 제출 정보(본인확인절차 포함)의 내용에 허위, 기재 누락, 오기, 제3자 정보도용이 있는 경우
                </li>
                <li>
                  3. 실명이 아니거나 타인의 정보 등(예시: 주민등록번호, 사업자번호, I-PIN 등)을 이용한 경우
                </li>
                <li>
                  4. 회사로부터 자격 정지 조치 등을 받은 판매자회원이 그 조치기간 중에 이용계약을 임의
                  해지하고 재이용신청을 하는 경우
                </li>
                <li>
                  5. 이용신청자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를
                  제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우
                </li>
                <li>
                  6. 이용신청자와 서비스 정산 금액을 지급받는 자의 정보가 다를 경우(단, 제5조 제3항에 따라
                  법정대리인이 정산을 받는 경우는 예외로 한다)
                </li>
                <li>
                  7. 부정한 용도 또는 회사의 서비스 제공 목적에 반하여 영리를 추구할 목적으로 서비스를
                  이용하고자 하는 경우
                </li>
                <li>
                  8. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여
                  필요하다고 인정하는 경우
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service6">제6조 (판매자회원의 책임 및 관리 의무)</h3>
          <ol>
            <li>
              ①. 판매자회원은 본 약관에 동의하여 이용계약을 체결하고 자신의 샵에 아이디어, 컨텐츠, 디자인을
              게재함으로써, 회사가 해당 아이디어, 컨텐츠, 디자인을 이용하여 상품을 제작∙판매하는 것을
              허락합니다.
            </li>
            <li>
              ②. 판매자회원은 자신의 “셀러 배송 상품”에 한하여 ‘회사'는 구매자에게 직접 상품을 판매하고 배송할
              수 있는 ‘마켓플레이스'를 제공합니다. 해당 물품이 판매되는 경우 발생하는 판매대금을 ‘회사'가
              제시하는 계약 조건에 따라 정산대금을 받게 됨을 허락합니다.
            </li>
            <li>
              ③. 판매자회원은 “셀러 배송 상품"에 한하여 상품에 대한 공급, 배송, 그리고 고객과의 상품 문의를
              직접 진행해야합니다.
            </li>
            <li>
              ④. 판매자회원은 “셀러 배송 상품"에 한하여 구매자회원의 결제일로부터 3 영업일 이내에 상품의
              발송을 완료하여야 하고(단, 판매자회원이 별도 배송조건을 설정한 상품의 경우 그에 따름), “셀러
              스튜디오"에 송장번호 등의 발송 관련 정보를 입력하여 발송이 완료되었음을 증명하여야 합니다.
            </li>
            <li>
              ⑤. 판매자회원이 전항의 기한 내에 발송하지 못할 경우에는 반드시 구매자 회원에서 발송지연사유 및
              발송예정일을 입력해야하며, 방송지연 안내 처리를 하지 않거나, 발송지연 안내 처리시 입력한
              발송예정일을 초과하는 경우 회사는 페널티 부여 등으로 불이익을 줄 수 있습니다.
            </li>
            <li>
              ⑥. 구매자회원의 귀책사유 없이 배송지 오류 등으로 인하여 구매자회원이 “셀러 배송 상품"을
              정상적으로 수령하지 못한 경우 또는 상품 배송과정에서 하자가 발생한 경우, 판매자회원은 그에 관한
              모든 책임을 부담하여야합니다.
            </li>
            <li>
              ⑦. 판매자회원은 구매자회원이 주문한 “셀러 배송 상품”을 공급하기 곤란하다는 것을 알았을 때, 즉시
              해당 사실을 구매자회원에게 통보하고, 구매자회원의 동의를 얻은 후 취소를 해야하며, 회사가
              구매자회원의 상품 대금 결제일로부터 3일 이내에 상품대금 환불 및 환불에 필요한 조치를 취할 수
              있도록 해야 합니다.
            </li>
            <li>
              ⑧. 판매자회원은 “셀러 배송 상품”의 하자나 오배송 등 구매자회원의 귀책사유없는 반품 또는 교환의
              경우 구매자회원에게 해당 배송비를 부담시키면 안됩니다.
            </li>
            <li>
              ⑨. 구매자회원은 ‘셀러 배송 상품"인 경우, 상품 배송완료일로부터 7일 이내에 반품요청을 할 수
              있으며 판매자회원은 구매자회원이 이 기간 내 반품이나 교환을 요청하는 경우 구매자회원의 요청에
              따라 반품 또는 교환을 해 주어야 합니다. 단, 구매자회원의 귀책사유로 상품이 훼손된 경우, 사용이나
              일부 소비로 인해 상품의 가치가 현저히 감소한 경우, 복제 가능한 상품의 포장을 훼손한 경우,
              구매자회원의 주문에 따라 개별적으로 생산되는 상품 등으로서 상품 판매시 반품이나 교환의 제한을
              명시적으로 고지하고 구매자회원의 동의를 받은 경우 및 기타 법령에 의하여 반품이 제한되어 있는
              경우는 예외로 합니다. 또한 상품이 표시 또는 광고 내용과 다를 경우에는 상품수령 후 90일 이내 또는
              그 사실을 알게 된 날 또는 알 수 있었던 날로부터 30일 이내에 구매자회원이 반품 또는 교환을
              요청하는 경우, 판매자회원은 반품 또는 교환을 해주어야 합니다.
            </li>
            <li>
              ⑩. 구매자회원이 “셀러 배송 상품" 수령 후 교환이나 환불을 요청하는 경우에는 판매자회원은 관련
              법률에 의거하여 반품을 받은 후 교환이나 환불 또는 필요한 조치를 하며, 추가로 발생하는 비용은
              교환이나 환불의 책임이 있는 측에서 부담합니다.
            </li>
            <li>
              ⑪. 판매자회원은 “셀러 배송 상품”의 주문일로부터 취소 및 반품 처리시 정확한 사유를 입력하여야
              하며, 이와 관련된 클레임 발생시 판매자회원에게 페널티 부여 등의 불이익이 발생될 수 있습니다.
            </li>
            <li>
              ⑫. 판매자회원은 “셀러 배송 상품”의 주문일로부터 90일 이내 해당 주문건의 원활한 배송 및
              취소/반품/교환 처리가 종료될 수 있도록 필요한 조치를 취해야 합니다. 만약, 주문일로부터 90일 이내
              주문이 정상적으로 종료되지 못한 경우 회사는 거래관계의 확정을 위하여 판매회원의 의사에
              명시적으로 반하지 않는 한 해당 주문을 임의로 구매확정 내지 주문 취소 및 환불 처리할 수 있습니다.
            </li>
            <li>
              ⑬. 회사의 제 12항의 처리로 인해 판매자회원에게 정산된 주문건에 대해 구매자회원이 민원을 제기할
              경우 판매자회원은 신의성실의 원칙에 따라 해결하여야 합니다.
            </li>
            <li>
              ⑭. 판매자회원은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 판매자회원 명의의 통장계좌
              등의 변경이 있을 경우, 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한
              손해에 대하여 책임을 지지 않습니다.
            </li>
            <li>
              ⑮. 판매자회원은 관련 법령, 약관, 운영정책, 이용안내 및 회사가 공지하거나 통지한 사항 등을
              준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
            </li>
            <li>
              ⑯. 판매자회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며, 이를 위반한 경우 회사는 서비스
              이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에
              대해서는 운영정책에서 정합니다. 다만, 회사가 이용계약해지의 조치를 취하는 경우 회사는
              이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
              판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수 있습니다.
              <ol>
                <li>
                  1. 일반 사항
                  <ol>
                    <li>1. 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                    <li>2. 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 해당하는 행위</li>
                    <li>3. 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우</li>
                    <li>
                      4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에
                      반하여 광고성 정보를 지속적으로 전송하는 행위
                    </li>
                    <li>5. 통신판매 이외의 목적으로 서비스를 이용하는 행위</li>
                    <li>6. 회사의 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위</li>
                    <li>
                      7. 이 약관에 따른 권리와 의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나
                      이를 담보제공하는 행위
                    </li>
                    <li>8. 회사의 서비스를 이용하지 않고 상품을 판매하는 직거래를 하거나 유도하는 행위</li>
                  </ol>
                </li>
                <li>
                  2. 마플샵 배송 상품 상품등록 및 판매 관련 금지행위
                  <ol>
                    <li>
                      1. 가족 또는 지인 등 타인의 계정을 이용하여 판매자 본인의 상품을 구매하는 등의 부정 거래
                      행위
                    </li>
                    <li>
                      2. 회사의 결제대금보호서비스를 통하지 않는 직거래 유도행위 및 구매자회원의 직거래 요청을
                      수락하는 행위
                    </li>
                    <li>
                      3. 타인의 콘텐츠, 디자인, 상표, 로고 등을 허락없이 사용하는 등 타인의 지식재산권을
                      침해하는 행위
                    </li>
                    <li>4. 상품 등록 시 상품과 관련없는 상표명을 사용하거나 중복해서 사용하는 행위</li>
                    <li>
                      5. 상품 등록 시 유해하거나 타인에게 불쾌감을 줄 수 있는 부적절한 콘텐츠, 디자인을
                      사용하는 행위
                    </li>
                    <li>
                      6. 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을
                      무단으로 사용하는 행위
                    </li>
                    <li>
                      7. 공적인물의 사진이나 성명 등을 해당 공적인물의 허락없이 사용하여 타인의 초상권, 성명권
                      등을 침해하는 행위
                    </li>
                    <li>8. 관련 법령에 위반되는 아이디어, 디자인, 컨텐츠가 담긴 상품을 판매하는 행위</li>
                    <li>9. 상품 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위</li>
                    <li>10. 기타 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위</li>
                  </ol>
                </li>
                <li>
                  3. 셀러 배송 상품 상품등록 및 판매 관련 금지 행위
                  <ol>
                    <li>1. 상품 등록시 “상품 정보 고지"를 제대로 기입하지 않는 경우</li>
                    <li>2. 상품 등록시 “KC인증 및 기타 상품 인증 정보"를 제대로 기입하지 않는 경우</li>
                    <li>3. 수선, 복원 또는 변형(리폼, 커스텀)을 한 상품을 등록하는 경우</li>
                    <li>4. 제품의 기능을 상실하거나 훼손된 상품을 등록하는 경우</li>
                    <li>5. 오염, 얼룩, 또는 훼손이 심한 상품을 등록하는 경우</li>
                    <li>
                      6. 가품(레플리카, 이미테이션, 미러급, 커스텀급, SA급, A급, 특정 브랜드 style 또는 st 등
                      그 명칭을 불문하고 해당 브랜드의 정품이 아닌 것을 포함하고, 해당 브랜드의 상표권 또는
                      저작권을 침해하거나 침해할 소지가 있다고 판단되는 제품)을 진품 판매로 위장하는 경우
                    </li>
                    <li>
                      7. 장물, 불법적으로 취득한 상품, 관련 법령에 따라 거래가 금지되거나 법령에 저촉되는
                      상품을 등록하는 행위
                    </li>
                    <li>
                      8. 위험 또는 기타의 이유로 상품의 판매에 문제가 있다고 회사가 판단하는 상품을 등록하는
                      행위
                    </li>
                    <li>9. 그외 회사가 정한 기준에 따라 판매가 불가한 일부 브랜드의 제품을 등록하는 행위</li>
                    <li>
                      10. 판매자회원이 판매 불가 상품을 판매한 경우, 그러한 사실이 판매 시점 이후에 밝혀진
                      경우라 하더라도 회사는 해당 판매 상품을 포함한 판매자회원의 상품 전체를 일괄 반송 할 수
                      있으며 이 경우 배송비는 판매자회원이 부담합니다.
                    </li>
                    <li>
                      11. 판매자회원 본인의 상품 구매 또는 실제 상품의 이동 없이 판매점수 및 광고 효과를
                      높이기 위한 구매 또는 부당한 할인 획득을 위해 제3자의 명의 또는 계정을 이용하여 구매하는
                      행위
                    </li>
                    <li>
                      12. 물품판매 또는 용역제공을 가장하여 자금을 융통하는 것과 같이 『여신전문금융업법』 등
                      법령에 따라 금지된 방법으로 비정상적인 결제를 하는 행위
                    </li>
                    <li>
                      13. 그외 국가에서 규정한 위법 상품(링크)을 온라인으로 판매하는 행위를 모두 규제합니다.
                    </li>
                  </ol>
                </li>
                <li>
                  4. 회사가 규정한 불법 이미지를 업로드 하는 경우
                  <ol>
                    <li>
                      1. 제 3자의 저작권, 기타 초상권, 재산권 등을 침해하는 내용일 경우 판매자회원이 제3자의
                      콘텐츠를 도용하거나 무단으로 판매 및 거래하는 내용일 경우
                    </li>
                    <li>2. 음란물, 명예 훼손 또는 불법적이거나 비도덕적인 콘텐츠가 포함된 내용인 경우</li>
                    <li>
                      3. 범죄, 폭력, 아동 학대, 성적 표현 등 미풍양속에 반하는 콘텐츠가 포함된 내용일 경우
                    </li>
                    <li>4. 반사회적 내용, 사회적 물의를 일으킬 소지가 있는 콘텐츠가 포함된 내용인 경우</li>
                    <li>5. 사람, 동물 등을 비하하거나 차별하는 콘텐츠가 포함된 내용일 경우</li>
                    <li>6. 불법적이거나 폭력적인 행동을 묘사하는 내용인 경우</li>
                    <li>
                      7. 동물 학대, 자살, 자해, 약물 남용 등을 조장하거나 묘사하는 콘텐츠가 포함된 내용인 경우
                    </li>
                    <li>
                      8. 특정 국적이나, 종교, 문화, 집단에 대한 공격으로 해석되거나 불쾌감을 유발할 콘텐츠가
                      포함된 내용일 경우
                    </li>
                    <li>9. 사기성 광고 또는 불공정 경쟁을 일으키는 내용인 경우</li>
                    <li>10. 특정 정치 집단을 선호하거나 비하하는 콘텐츠를 업로드하는 경우</li>
                    <li>11. 특정 상표, 디자인 마크, 사업자명, 로고 일부가 포함된 내용인 경우</li>
                  </ol>
                  위와 같은 콘텐츠의 업로드로 인하여 문제가 발생한 경우, 모든 관련 책임은 콘텐츠를 업로드한
                  판매자회원 본인에게 있으며, 회사는 판매자회원의 해당 콘텐츠와 관련하여 어떠한 보증도 하지
                  않으며 책임을 부담하지 않습니다.
                </li>
              </ol>
            </li>
            <li>
              ⑰. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별 약관의 변경, 판매자회원 공지사항 등의 확인
              의무를 게을리해 발생한 판매자회원의 피해에 대해서는 회사는 책임을 지지 않습니다.
            </li>
            <li>
              ⑱. 회사에서 제공하는 마플 서비스에 가입된 판매자회원의 경우, 현 약관을 별도로 확인하고 동의한
              자에 한해서만 마플 서비스 계정으로 몰 이용이 가능합니다.
            </li>
            <li>
              ⑲. 판매자회원은 회사에 대하여 외부(회사의 서비스와 유사한 온라인 및 오프라인 플랫폼 모두를
              포함하나 이에 한정하지 않음)에 제공하는 디자인가격 또는 주요 조건보다 불리하지 않다는 사실을
              진술, 보증합니다. 판매자회원이 외부에 아이디어, 컨텐츠, 디자인등을 판매 또는 이용을 허락하면서
              회사에게 부여된 주요조건보다 더 유리한 조건으로 거래한 경우, 판매자회원은 이를 회사에 통보하여야
              하며, 회사는 외부에게 부여한 주요조건으로 판매자회원의 아이디어, 컨텐츠, 디자인을 제공받을
              권리를 가짐과 아울러 기존 거래 중 불리한 조건으로 거래한 부분에 대하여 정산할 권리를 가질 수
              있습니다.
            </li>
            <li>
              ⑳. 상품 및 인쇄방식의 특성상 판매자회원의 아이디어, 컨텐츠, 디자인에 대하여 수정이 필요한 경우,
              회사는 판매자회원에게 수정을 요청할 수 있습니다. 판매자회원은 회사의 요청에 따라 수정된 디자인을
              회사에 전달하고, 수정된 디자인을 자신의 샵에 게재하여, 구매자회원이 수정된 디자인을 알 수 있도록
              해야 합니다.
            </li>
            <li>
              ㉑. 판매자회원의 로그인 정보(계정 및 비밀번호)에 대한 관리 책임은 판매자회원에게 있으며, 회사는
              판매자회원의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에
              책임지지 않습니다.
            </li>
            <li>
              ㉒. 회사가 몰 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청하는 경우 판매자회원은 이에
              성실히 응해야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service7">제7조 (회사의 권리와 의무)</h3>
          <ol>
            <li>
              ①. 회사는 판매자회원으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 합리적이라고 판단되는
              경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 판매자회원에게 그 사유와
              처리절차를 안내합니다.
            </li>
            <li>
              ②. 회사는 판매자회원이 몰에 등록한 상품정보, 회사와의 인터뷰 내용 등 회사를 통하거나 회사와
              협업하여 생성된 콘텐츠 등을 몰 내의 콘텐츠 및 몰과 관련된 서비스의 마케팅 목적을 위해 몰의 공식
              SNS 계정 포스팅 등 몰의 콘텐츠로서 사용할 수 있습니다. 회사가 필요하다고 판단한 경우 회사는 본
              항에 언급된 콘텐츠를 수정/편집하여 활용할 수 있습니다. 인터뷰의 경우, 셀러가 되고자 하는 이유,
              목표, 홍보 방안, 회사가 제공하는 서비스에 대한 평가 등에 대해 인터뷰를 진행하고 콘텐츠 활용에
              도움을 주신 판매자회원에게 그에 대한 소정의 원고료를 지불합니다.
            </li>
            <li>
              ③. 회사는 판매자회원의 서비스이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이
              관련 법률에 따른 절차를 통해 요청하는 경우 판매자회원의 동의없이 해당 기관에 제공할 수 있습니다.
            </li>
            <li>
              ④. 회사는 판매자회원이 몰에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 회사의
              저작권, 상표권, 윤리 필수 지침 가이드를 준수하지 않았거나 방송통신심의위원회 등 관련기관으로부터
              요청이 있는 경우 또는 판매자회원이 몰을 이 약관 이외의 목적으로 사용한다고 판단되는 경우
              판매자회원에 대하여 특정 서비스의 이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을
              중지시키는 등의 조치를 취할 수 있습니다.
            </li>
            <li>
              ⑤. 회사는 안정적인 서비스 제공을 위해 거래액, 상품의 가격 등을 기준으로 구매자고객에게 제공될
              상품(판매중 및 판매대기 상품을 모두 포함)의 디자인가격에 최대 한도를 정할 수 있습니다.
            </li>
            <li>
              ⑥. 회사는 판매자회원의 회원가입, 정산, 대금지급 등을 위해 필요한 자료를 판매자회원에게 요청할 수
              있으며, 그 정보를 외부로부터 보호하고 해당 목적 외 다른 목적으로 사용하지 않습니다.
            </li>
            <li>
              ⑦. 회사는 판매자회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 적절한 모니터링을
              실시할 수 있고, 판매자회원은 회사의 모니터링 업무에 대하여 협조하여야 하며, 회사가 요청하는 필요
              최소한의 증빙자료를 제출할 의무가 있습니다.
            </li>
            <li>
              ⑧. 회사는 본 약관의 위반 행위를 확인되면 해당 판매자회원에게 부가적으로 제공한 혜택의 일부 또는
              전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 할 수
              있습니다.
            </li>
            <li>
              ⑨. 판매자회원이 본 약관에 따른 의무를 위반하여 회사가 손해를 입은 경우, 판매자회원은 손해배상의
              책임을 부담합니다. 다만, 해당 판매자회원이 자신의 고의 또는 과실 없음을 증명한 때에는 그러하지
              않습니다.
            </li>
            <li>
              ⑩ 회사는 재화의 생산 중단 혹은 기타 사정에 의한 재화의 공급이 어려운 경우 고지 없이 재화의
              제공을 중단할 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
            </li>
            <li>
              ⑪ 판매자 회원은 상품 판매등록 이후에도 상품에 대한 정보 수정이 가능합니다. 다만 주문 발생 후
              해당 상품에 대한 수정 및 삭제는 불가 하며 회사는 이를 회사의 서비스 이용과 관련한 내부자료로
              간주하여 상품에 대한 정보를 보관합니다.
            </li>
            <li>
              ⑫ 해당 몰은 판매자회원이 자신의 상품을 판매하는 “마켓플레이스" 서비스의 제공자이며, 회사는
              통신판매중개자로 거래 당사자가 아니므로, 판매자회원이 등록한 상품정보 및 거래 등에 대해 일체
              책임을 지지 않습니다. 단, 회사가 판매자로 등록 판매한 상품의 경우는 판매자로서 책임을 부담하고
              있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service8">제8조 (개인정보의 보호)</h3>
          <ol>
            <li>
              ①. 판매자회원은 『전자상거래 등에서의 소비자보호에 관한 법률』에 따른 통신판매사업자 신고자에
              해당하지 않으며, 구매자회원의 개인정보는 판매자회원에게 제공되지 않습니다.
            </li>
            <li>
              ②. 판매자회원은 이용신청, 정산 시 허위의 정보를 제공하여서는 아니되며, 회원의 정보를 허위로
              수정하여 회사 또는 제3자에게 손해를 입힌 경우 발생한 모든 손해를 배상하여야 합니다.
            </li>
            <li>
              ③. 회사는 서비스 이용계약을 위하여 회원이 제공한 정보 외에도 수집 및 이용목적 등을 밝히고,
              판매자 회원의 동의를 받아 회원으로부터 필요한 정보를 수집, 이용할 수 있습니다. 회원이 동 정보의
              수집 및 이용에 관한 동의를 거절하는 경우, 회사는 제한되는 서비스 내용을 구체적으로 명시하여야
              하며, 필수수집항목이 아닌 개인정보에 대한 동의 거절을 이유로 서비스 제공을 제한하거나 거절하지
              않습니다. 회원은 본 항에 따라 동의하였더라도 언제든지 그 동의를 철회할 수 있습니다.
            </li>
            <li>
              ④. 회사는 서비스의 제공과 관련하여 알게 된 판매자회원의 개인정보를 이 약관에서 정한 목적 이외의
              용도로 사용하거나 본인의 승낙 없이 제 3자에 누설, 배포하지 않고, 이를 보호하기 위하여 노력하여야
              하며 회원의 개인정보 처리에 관한 사항은 『개인정보 보호법』 및 『정보통신망 이용촉진 및 정보보호
              등에 관한 법률』 등 개인정보의 보호에 관한 관계 법령 및 회사의 개인정보 처리방침에 따릅니다.
            </li>
            <li>
              ⑤. 회사는 관련 법령에 따른 적법한 절차 및 방법에 의거하여 수사기관 등의 요청이 있을 경우, 그
              범위 내의 판매자회원에 관한 개인정보를 제공할 수 있습니다.
            </li>
            <li>
              ⑥. 판매자 회원이 본 조를 위반하거나 회사에 대한 통지를 지연하여 회사 또는 제3자에게 발생한 모든
              손해에 대한 민·형사상의 책임은 판매자회원에게 있으며, 이로 인하여 발생한 모든 불이익에 대하여
              회사에 중대한 귀책사유가 없는 한 회사에게 어떠한 책임도 물을 수 없습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service9">제9조 (서비스의 제공 및 이용료)</h3>
          <ol>
            <li>
              ①. 회사는 판매자회원이 자신의 아이디어, 콘텐츠, 디자인 등을 이용하여 자신만의 상품을 디자인할 수
              있도록 제반 서비스를 제공하며, 상품 제작, 주문 확인, 상품 배송, 고객 응대 등의 전 과정을 진행 및
              관리합니다.
            </li>
            <li>
              ②. 회사는 판매자회원이 자신이 직접 제공하고자 하는 상품을 업로드, 및 판매를 중개하는
              ‘마켓플레이스' 서비스를 제공하고 있습니다.
            </li>
            <li>
              ③. 회사에서 지원하는 주문 확인, 상품 제작, 상품 배송, 고객 응대 등의 서비스에 대한 서비스
              이용료는 판매 대금 중 기본가격에 포함됩니다.
            </li>
            <li>
              ④. 회사는 카드수수료 등 공제가 필요한 금액을 판매자회원에게 지급해야 하는 정산 대금에서의 공제
              등으로 징수할 수 있으며, 회사와 판매자회원간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료
              및 징수 방법을 다르게 정할 수 있습니다.
            </li>
            <li>
              ⑤. 회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 서면(전자우편
              포함) 또는 몰을 통하여 통지하고 협의해야 합니다.
            </li>
            <li>
              ⑥. 본 조 제5항에 의하여 서비스수수료의 변경이 통지된 경우, 이에 동의하지 않는 판매자회원은 본
              계약을 해지할 수 있습니다.
            </li>
            <li>
              ⑦. 회사는 판매자회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항,
              서비스 화면, 전자우편 등의 방법으로 판매자회원에게 제공할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service10">제10조 (서비스 이용의 제한 및 중단)</h3>
          <ol>
            <li>
              ①. 회사는 다음 각 호에 해당하는 경우 특정 서비스의 일부 또는 전부를 일시적 또는 영구적으로 수정,
              중단하거나 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을
              하지 않습니다.
              <ol>
                <li>1. 기술적 장애, 천재지변, 국가비상사태 등의 사유로 인하여 서비스제공이 불가능한 경우</li>
                <li>
                  2. 서비스 이용 설비의 보수 등 공사로 인한 부득이한 경우이거나 기술 진보에 따른 차세대
                  서비스로의 전환 필요성이 있는 경우
                </li>
                <li>3. 『전기통신사업법』에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
                <li>
                  4. 원활한 서비스 제공의 곤란 또는 수익성 악화되거나 서비스 제공과 관련한 회사 정책의 변경 등
                  상당한 이유가 있는 경우
                </li>
                <li>
                  5. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스
                  이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
                </li>
                <li>6. 기타 불가항력적 사유가 있는 경우</li>
              </ol>
            </li>
            <li>
              ②. 회사는 판매자회원의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 서비스 제공을
              제한, 중지, 또는 이용계약을 해지할 수 있습니다.
              <ol>
                <li>1. 제5조 제4항의 승낙거부사유가 있음이 확인된 경우</li>
                <li>2. 이 약관에서 규정하는 회원의 의무사항을 위반하는 행위를 하는 경우</li>
                <li>
                  3. 수사기관으로부터 수사목적의 요청이 있거나, 방송통신심의위원회, 서울시 전자상거래센터 등의
                  기관으로부터 심의결정 또는 서비스 제한 요청이 있는 경우
                </li>
                <li>4. 판매자회원의 서비스 이용이 전체 서비스 시스템 과부하의 원인이 되는 경우</li>
                <li>
                  5. 기타 본 약관 또는 회사의 다른 서비스 약관 및 정책 등을 위반하여 회사 또는 다른 회원에게
                  손해가 발생하거나 발생할 우려가 있다고 판단되는 경우
                </li>
              </ol>
            </li>
            <li>
              ③. 회사는 서비스의 원활한 운영을 위하여 판매자회원이 등록한 상품이 6개월 이상 판매 이력이 없거나
              또는 판매자회원의 정보관리 이력이 없는 경우 몰에 공지 또는 개별 통지한 후 판매자회원이 등록한
              상품정보를 삭제할 수 있습니다.
            </li>
            <li>
              ④. 회사가 판매자회원 자격을 정지하거나 판매/구매서비스의 이용 제한, 기타 필요한 조치를 취할 경우
              판매자회원에게 유선 또는 메일(e-mail), 기타의 방법을 통하여 통보합니다 다만, 회원의 연락이
              두절되거나 긴급하다 판단되는 경우 선 조치 후 통보할 수 있습니다. 이에 대하여 판매자 회원은
              회사의 조치에 대해 이의를 제기할 수 있으며 회사는 회원의 이의제기가 타당하다고 판단되는 경우
              조치를 해제할 수 있습니다.
            </li>
            <li>
              ⑤. 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수
              있습니다.
            </li>
            <li>
              ⑥ 판매자 회원이 회사의 이용규정을 위반하거나 이로 인한 클레임이 발생하였을 경우 회사는
              운영정책에 따른 조치(회사 내부 회원등급의 하락, 벌점부여, 포인트 회수, 서비스의 이용 제한 등)를
              할 수 있으며, 판매자 회원은 회사에서 제공하는 서비스의 이용이 제한될 수 있습니다
            </li>
            <li>
              ⑦ 판매자 회원은 서비스 이용 중에 다음 각호의 행위를 할 수 없습니다.
              <ol>
                <li>
                  1. 거래·결제 부정행위
                  <ol>
                    <li>1-1. 회사가 제공하는 혜택(쿠폰,이벤트, 포인트 등)을 부당하게 이용하는 행위.</li>
                    <li>1-2. 결제 수단의 명의도용.</li>
                    <li>1-3. 그 밖의 각호의 행위에 준하는 일체의 거래 및 결제 부정행위.</li>
                    <li>
                      1-4. 판매자회원이 매출증대를 위해 본인의 ID 또는 타인의 ID를 사용하여 거짓 정보를 직접
                      조작하는 행위.
                    </li>
                    <li>
                      1-5. 회사에서 제공하는 결제 서비스를 이용하지 않고 구매자에게 직거래를 유도하는 행위.
                    </li>
                    <li>1-6. 발송하지 않은 상품에 대한 운송장 번호를 미리 입력하는 행위.</li>
                  </ol>
                </li>
                <li>
                  2. 서비스 이용 부적합 행위
                  <ol>
                    <li>2-1. 회사가 정하지 않은 방식으로 시스템에 접속 및 이벤트 참여하는 행위.</li>
                    <li>
                      2-2. 회원정보를 거짓으로 등록하거나 회사의 정당한 연락을 회피하는 행위 및 불성실,
                      부정확한 답변을 하는 행위.
                    </li>
                    <li>
                      2-3. 게시물의 내용과 무관한 키워드 반복적으로 작성하는 경우 혹은 특정 키워드 반복적으로
                      작성하는 경우.
                    </li>
                    <li>2-4. 그 밖의 각호의 행위에 준하는 일체의 서비스이용 부적합 행위.</li>
                  </ol>
                </li>
                <li>
                  3. 매매 부적합 서비스 등록 행위
                  <ol>
                    <li>3-1. 회사의 시스템에 매매부적합 서비스를 판매 등록하는 행위.</li>
                    <li>3-2. 기타 법령과 공공질서 및 미풍양속에 어긋나는 서비스 거래 행위.</li>
                    <li>3-3. 그 밖의 각호의 행위에 준하는 매매부적합 서비스의 문의·거래 행위.</li>
                  </ol>
                </li>
                <li>
                  4. 비 매너 행위
                  <ol>
                    <li>4-1. 회원에게 협박·욕설·비방·모욕하는 행위.</li>
                    <li>
                      4-2. 회사 및 임직원, 회사에 의하여 고용된 자에게 협박·욕설·비방·모욕을 줄 수 있는 행위.
                    </li>
                    <li>4-3. 회사의 서비스 제공 및 이용에 대하여 일방적인 요구를 지속하는 행위.</li>
                    <li>4-4. 대한민국 법령 또는 공서양속에 위배되는 행위.</li>
                    <li>4-5. 그 밖의 각호의 행위에 준하는 일체의 비매너 및 업무방해 행위.</li>
                  </ol>
                </li>
                <li>
                  5. 권리침해
                  <ol>
                    <li>5-1. 타인의 정보를 통해 서비스를 이용하는 행위.</li>
                    <li>
                      5-2. 회사의 정보 및 데이터를 동의 없이 회원 또는 제3자가 상업적으로 이용하는 행위.
                    </li>
                    <li>5-3. 거래의 제반 행위를 통해 저작권·초상권 등의 제 3자의 권리를 침해하는 경우.</li>
                    <li>
                      5-4. 회사의 개인정보처리방침 또는 관련 법령을 위배하여 다른 회원의 개인정보를 침해하는
                      행위.
                    </li>
                    <li>5-5. 타인 및 회사의 명예 또는 신용, 정당한 이익을 훼손하는 행위.</li>
                    <li>5-6. 그 밖의 각호의 행위에 준하는 일체의 권리침해 행위.</li>
                  </ol>
                </li>
                <li>6. 회사가 규정한 저작권, 상표권, 윤리 필수 지침 가이드를 따르지 않는 행위</li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service11">제11조 (지식재산권보호 및 권리침해신고 등)</h3>
          <ol>
            <li>
              ①. 회사는 서비스를 이용함에 있어 저작권자의 저작권 보호를 위한 정책을 수립하여 운영하며
              판매자회원은 회사의 저작권 정책을 준수하여야 합니다.
            </li>
            <li>
              ②. 판매자회원은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허락을
              취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권,
              성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.
            </li>
            <li>
              ③. 회사는 판매자회원이 서비스에 등록∙사용한 아이디어, 디자인, 컨텐츠 또는 상품정보 및 제반
              정보에 대하여 제3자로부터 권리침해의 주장이 있을 경우, 회사는 내부 운영정책에 따른 조치(특정
              서비스의 이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을 중지시키는 등의 조치)를
              취할 수 있습니다.
            </li>
            <li>
              ④. 전항과 관련하여 회사는 판매자회원에게 소명자료를 요청할 수 있으며, 판매자회원은 이에 적극
              협조하여야 합니다. 단, 판매자회원이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌
              경우 회사는 상품 삭제 및 등록 제한 상태를 유지할 수 있습니다.
            </li>
            <li>
              ⑤. 판매자회원이 몰에 등록한 아이디어, 컨텐츠, 디자인, 상품 등에 대한 저작권은 판매자회원에게
              귀속됩니다. 이와 관련하여 회사가 판매자회원의 저작권 등 제3자의 권리침해 행위로 민사, 형사 또는
              행정상 분쟁이 발생하는 경우, 판매자회원은 회사를 면책시키기 위하여 적극적으로 할 수 있는 모든
              조치를 취하여야 하고, 만약 회사가 이로 인하여 민사, 형사 또는 행정상 책임을 부담하게 되는 경우,
              회사는 판매자회원에게 그로 인한 모든 손해의 배상을 청구하고 관련 비용을 구상할 수 있습니다.
            </li>
            <li>⑥. 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.</li>
            <li>
              ⑦. 판매자회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전 서면 승낙 없이 복제, 송신,
              출판, 배포, 방송 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하는 경우 그에 대한 책임은
              판매자회원 본인에게 있습니다.
            </li>
            <li>
              ⑧. 판매자회원은 유무상을 불문하고 회사가 제공하는 저작물(회사가 본 서비스를 통하여 제공하는
              이미지, 영상, 로고, 템플릿, 슬로건 등을 포함하나 이에 한정하지 않음)을 제3자에게 배포하거나 그
              소유권을 이전하여 줄 수 없으며 이를 재판매하거나 공유하는 행위 역시 모두 금지됩니다. 다만
              판매자회원이 회사로부터 사전에 서면 허가를 받은 경우, 해당 판매자회원은 제3자에게 회사가 제공한
              저작물을 배포할 수 있습니다.
            </li>
            <li>
              ⑨. 판매자회원의 아이디어, 컨텐츠, 디자인을 회사가 상품으로 제작하는 과정에서 회사가 디자인을
              수정, 개선 또는 변형하거나 그 외 창작에 기여를 하였다고 인정될만한 행위를 하여 새로운 저작물이
              창작된 경우, 해당 저작물에 대한 지식재산권, 상표권 등 기타 권리는 판매자회원과 회사의 각
              기여도에 따라 귀속 주체가 달라질 수 있고, 회사와 판매자회원은 이와 관련한 구체적인 조건을 별도의
              약정을 통하여 정할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service12">제12조 (신고센터제도)</h3>
          <ol>
            <li>
              ①. 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한 법률상 이익 침해를
              근거로 회사에 대하여 불만을 제기할 경우, 회사는 이를 원활하게 해결하기 위하여 신고센터제도를
              운영합니다.
            </li>
            <li>
              ②. 구매자회원 또는 제3자는 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인하여 침해된 법률상
              이익을 구체적으로 작성하여 신고센터제도에 불만을 접수할 수 있으며, 해당 불만은 접수 즉시
              판매자회원에게 공유됩니다. 판매자회원은 구매자회원 또는 제3자의 불만을 공유받은 날로부터 2
              영업일 이내에 이에 대하여 소명하여야 합니다.
            </li>
            <li>
              ③. 회사는 불만이 접수된 날로부터 [●] 영업일 이내에 해당 불만의 구체성, 합리성, 판매자회원의 소명
              내용 등을 종합적으로 판단하여 해당 아이디어, 컨텐츠, 디자인 등이 사용된 상품의 판매를 중단하거나
              이를 샵으로부터 삭제하는 등의 조치를 취할 수 있습니다.
            </li>
            <li>
              ④. 판매자회원은 전항의 회사의 조치가 있은 날로부터 14일 이내에 회사에 대하여 이의를 제기할 수
              있습니다. 판매자회원이 14일 동안 이의를 제기하지 않는 경우 회사는 전항에 따른 조치를 확정하며,
              해당 판매자회원은 이로 인하여 발생하는 손해에 대하여 회사에게 그 어떠한 책임도 물을 수 없습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service13">제13조 (정산)</h3>
          <ol>
            <li>
              ①. 정산은 월 1회 실시를 원칙으로 하며, 해당월1일부터 10일까지의 신청건에 한하여 당월 지급합니다.
            </li>
            <li>②. 판매자회원은 몰에서 제공하는 페이지에서 판매 현황을 확인할 수 있습니다.</li>
            <li>
              ③. 회사는 판매자회원에게 당월 정산 대금에 대한 정산 자료를 익월 영업일 기준 5일 이내로 몰의 정산
              관련 페이지를 통해 제공합니다.
            </li>
            <li>④. 본 조 제3항의 당월 정산 대금은 당월에 출고된 주문을 기준으로 계산합니다.</li>
            <li>
              ⑤. 마플샵 배송 상품이 구매확정되는 경우, 전체 판매 대금 중 상품의 기본가격을 제외한 판매자회원이
              산정한 디자인가격에 대한 금액에서 카드 수수료, 사업자가 없는 회원에 한하여 사업소득세에 대한
              원천징수액 등 공제가 필요한 금액을 제한 나머지 금액을 판매자회원에게 지급합니다.
            </li>
            <li>
              ⑥. 셀러 배송 상품이 구매확정되는 경우, 해당 상품의 판매가에 셀러가 설정한 배송비를 더한 가격에서
              마플샵 이용 수수료, 전자 결제 수수료를 제외한 금액을 판매자회원에게 지급합니다.
            </li>
            <li>
              ⑦. 세금계산서 발행이 가능한 사업자를 보유한 판매자회원은 회사에게 회사로부터 지급받을 금액에
              대한 세금계산서를 익월 10일까지 발행합니다.
            </li>
            <li>
              ⑧. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이 본
              약관 7조 6항에 따라 회사에 제공한 결제 계좌로 출금신청이 마감된 10일 이후 7 영업일 이내에 그
              금액을 송금합니다. 단, 판매자회원이 지정한 입금계좌의 예금주는 판매자회원과 동일인이어야
              하며(단, 제5조 제3항에 따라 법정대리인이 정산을 받는 경우 예외로 합니다), 해당 일이 회사의
              영업일이 아닌 경우 이후 최초 영업일에 송금합니다.
            </li>
            <li>
              ⑨. 회사는 판매자회원이 정산받을 금액이 [1]만원 미만일 경우, 정산을 유예하고 다음 정산 금액에
              합산하여 정산할 수 있습니다.
            </li>
            <li>⑩. 판매자회원이 병역 근무 중인 경우, 정산이 제한될 수 있습니다.</li>
            <li>
              ⑪. 판매자회원 가입 후 처음 정산요청 가능일자로부터 [60]개월내 출금요청을 하지 아니하였을 경우,
              출금신청 가능한 수익은 자동으로 소멸합니다.
            </li>
            <li>
              ⑫. 정산요청 관련 서류 누락, 계좌번호 오기입 등 판매자 회원의 귀책사유로 정산대금의 지급이 실패한
              경우, 그 대금에 대한 당월 지급은 불가하며, 판매자회원은 익월 재신청해야합니다.
            </li>
            <li>
              ⑬. 판매자회원은 원활한 정산 처리를 위해 회사가 별도의 공지사항 혹은 이용안내로 고지한 방법을
              통해 계좌정보 등 정산금 지급에 필요한 정보 기재 후 일정한 방법을 통해 계좌정보등록을 하여야
              합니다. 해당 절차를 모두 완료한 경우 판매자회원과 회사는 정산계약을 체결한 것으로 간주합니다.
            </li>
            <li>
              ⑭. 판매자회원이 미성년자인 경우 법정대리인(부모 등)의 동의를 받아 정산계약을 체결할 수 있으며,
              회사가 지정 및 안내하는 방법에 따라 이용 동의를 하여야 합니다.
            </li>
            <li>
              ⑮. 구매자회원으로부터 주문취소/환불 건이 발생하는 경우, 발생 시기에 따라 아래와 같이 반영합니다.
              <ol>
                <li>1. 당월 정산 금액 마감 전 발생하는 경우, 당월 정산에 반영하여 정산합니다.</li>
                <li>
                  2. 당월 정산 금액 마감 후 발생하는 경우, 당월 정산에는 반영하지 아니하며 익월 정산에
                  반영하여 정산합니다.
                </li>
              </ol>
            </li>
            <li>
              ⑯. 셀러 배송 상품의 정산후, 구매자회원으로부터 주문취소/환불에 대한 요청이 들어왔을 경우에는
              판매자회원이 직접 구매자회원과 대화를 통해 진행해야하며 판매자회원의 귀책사유(오배송, 상품의
              하자, 손상 및 기타 사유)로 인한 경우에는 배송비를 포함한 상품가격의 주문취소/환불은 판매자회원이
              책임을 집니다.
            </li>
            <li>
              ⑰. 셀러 배송 상품의 정산후, 환불시 발생할 수 있는 추가 배송비의 부담의 주체는 판매자회원과
              구매자회원간 소통을 통하여 정해야하며, 기존 환불금에 포함되어 있는 배송비와는 별도로
              계산해야합니다. (초기배송비 및 반품배송비, 제주 및 도서산간비용 모두 별도 처리가 필요)
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service14">제14조 (정산의 보류)</h3>
          <ol>
            <li>
              ①. 회사는 판매자회원의 귀책사유로 인해 발생한 비용을 정산 대금 정산 시 상계한 뒤 지급할 수
              있습니다.
            </li>
            <li>
              ②. 판매자회원의 서비스 중지 또는 이용계약 해지 후 당월 판매자회원의 판매대금의 일정 비율에
              해당하는 금액을 일정기간 동안 예치하여 동 기간 동안 구매한 구매자회원으로부터 오류 수정, 환불
              등의 청약 철회 요구가 발생하는 경우 관련 비용의 지급에 사용할 수 있습니다.
            </li>
            <li>
              ③. 판매자회원의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을
              경우, 회사는 판매자회원과 채권자 간의 합의 또는 채무액의 변제 등으로 동 결정이 해제될 때까지
              판매대금의 ‘정산’을 중지하거나 채권자에게 변제 또는 공탁할 수 있습니다.
            </li>
            <li>
              ④. 본 조에서 정한 사항 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는
              판매자회원에게 이를 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보할 수
              있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service15">제15조 (결제대금보호서비스)</h3>
          <ol>
            <li>
              ①. 회사는 몰을 통해 서비스를 제공하는 과정에서 대금의 수령, 보관 및 송금업무로 이루어지는
              결제대금보호 서비스(이하 구매안전서비스)를 제공합니다.
            </li>
            <li>
              ②. 회사가 제공하는 구매안전 서비스는 몰과 서비스를 통한 모든 거래에 적용됩니다. 판매자회원이
              구매안전 서비스를 통하지 않는 직거래를 유도하는 경우, 회사는 해당 거래를 취소하거나, 샵 운영을
              중지시키는 등 기타 필요한 조치를 취할 수 있습니다.
            </li>
            <li>
              ③. 회사가 제공하는 구매안전 서비스를 이용하지 않은 거래 및 물품 또는 구매안전 서비스가 종결된
              이후의 거래와 관련하여 발생한 모든 사항에 대하여는 회사는 그 어떠한 책임도 부담하지 않습니다.
            </li>
            <li>
              ④. 판매자회원은 회사가 제공하는 서비스를 이용함에 있어서 구매안전 서비스의 이용과 그 규칙에
              동의하여야 합니다.
            </li>
            <li>
              ⑤. 판매자회원과 구매자회원이 회사의 서비스를 이용함에 있어 안전하고 편리하게 금전 거래를 할 수
              있도록 결제대금예치 서비스(에스크로) 및 지 대행지급서비스를 제공합니다.
            </li>
            <li>
              ⑥. 해당 결제대금예치서비스(에스크로)와 지급 대행 서비스는 금융위원회에 등록된 전자금융업 허가 및
              등록을 한 결제대행사를 통해 판매자회원을 대행하여 구매자회원이 판매자회원에게 지급하여야 할
              자금의 내역을 전자적인 방법으로 지급인에게 고지, 수수, 정산합니다.
            </li>
            <li>
              ⑦. 몰에서 결제한 모든 거래는 (주)KG이니시스의 결제대금예치 서비스 및 지급대행서비스가
              적용됩니다.
            </li>
            <li>
              ⑧. 분쟁 및 그 밖의 사유 발생시 회사는 합리적인 판단에 따라 구매자회원과 판매자회원에게 이용 제한
              등의 필요한 조치를 취할 수 있으며, 진행 중인 거래의 서비스 대금 지급이나 서비스 대금 환불을
              보류할 수 있습니다.
            </li>
            <li>
              ⑨. 회사는 판매자회원과 구매자회원의 거래의 안정성과 편의성 향상을 목적으로 해당 서비스를
              제공하고 있으며 회사의 고의 또는 중대한 과실이 없는 한 해당 결제 서비스를 이용하는 당사자 간의
              거래에 어떠한 책임을 지지 않습니다.
            </li>
            <li>
              ⑩. 회사는 자사의 결제 서비스를 이용하는 판매자회원 혹은 구매하자회원의 어느 일방을 대리,
              대행하거나 그 이행을 보조하는 위치에 있지 않습니다. 회사는 거래 당사자 간 자금의 흐름에 직접
              관여하거나 개입하지 않으며, 회사와 계약을 체결한 결제대급예치업자가 구매자회원이 지불한 금액을
              판매자회원에게 전달합니다.
            </li>
            <li>
              ⑪. 해당 결제서비스를 이용하는데 있어 잘못된 정보를 입력하여 잘못된 대금지급이 이루어졌을 경우,
              회사는 이에 책임일 지지 않습니다.
            </li>
            <li>
              ⑫. 회원, 결제대행업체, 금융기관 등과의 사이에서 발생한 분쟁은 당사자 간의 해결을 원칙으로 하며,
              회사는 이와 관련한 어떠한 책임도 지지 않으며, 해당 사안의 결제 대행업체 또는 금융기관의 약관이
              우선됩니다.
            </li>
            <li>
              ⑬. 결제대금예치서비스 및 지급대행서비스에 대해서는 (주)KG이니시스의
              <a href="https://www.inicis.com/terms" target="_blank">전자금융거래약관</a>이 적용됩니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service16">제16조 (양도금지)</h3>
          <ol>
            <li>
              ①. 판매자회원은 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게
              양도하거나 담보의 목적으로 제공할 수 없습니다.
            </li>
            <li>
              ②. 회사는 판매자회원이 본 조를 위반하는 경우 서비스 제공을 거부할 권한을 가지며, 기존
              판매자회원에 대하여 이용계약을 해지할 수 있습니다. 다만, 회사가 이용계약해지의 조치를 취하는
              경우 회사는 이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
              판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service17">제17조 (비밀유지)</h3>
          <ol>
            <li>
              ①. 판매자회원은 구매자회원의 정보 등 몰의 이용과 관련해 취득한 일체의 정보를 회사의 서면 동의
              없이 외부에 유출하거나 이 약관 이 외의 목적으로 사용할 수 없습니다.
            </li>
            <li>②. 전항의 의무는 본 이용계약의 종료 후에도 존속합니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service18">제18조(준거법 및 관할법원)</h3>
          <ol>
            <li>
              이 약관의 해석 및 분쟁의 해결은 대한민국의 법령에 따르며, 소송이 제기되는 경우에는 민사소송법에
              따라 관할 법원을 정합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>&lt;부칙&gt;</h3>
          <p>
            본 약관은 2023년 1월 25일부터 시행됩니다.<br />
            2020년 11월 6일부터 시행해 온 약관은 본 약관으로 대체됩니다
          </p>
        </div>
        <div class="paragraph btn_prev">
          ${store_name
            ? html`<a href="/${T.lang}/${UtilS.escape(store_name)}/settings/terms/20201106"
                >- 이전 셀러스튜디오 이용약관 보기</a
              >`
            : html`<a href="/${T.lang}/@/studio/terms/20201106">- 이전 셀러스튜디오 이용약관 보기</a>`}
        </div>
      </div>
      <div class="option">
        <button class="done">동의</button>
      </div>
    </div>
  `;
};
