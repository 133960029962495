export const ERROR_INFO = {
  required: {
    type: 'text',
    text: '필수 입력 사항입니다.',
  },
  min_text: {
    type: 'text',
    text: '3자 이상 입력해 주세요.',
  },
  text_length_100: {
    type: 'text',
    text: '100자 이하로 입력해 주세요.',
  },
  text_length_300: {
    type: 'text',
    text: '300자 이하로 입력해 주세요.',
  },
  text_length_30: {
    type: 'text',
    text: '30자 이하로 입력해 주세요.',
  },
  restrict_character: {
    type: 'text',
    text: '특수문자를 제외하고 입력해 주세요.',
  },
  restrict_character2: {
    type: 'text',
    text: '특수문자는 * - · ( ) [ ] / & + , ~ . : @ ! 만 입력 가능합니다.',
  },
  black_list_name: {
    type: 'text',
    text: '사용 불가능한 단어가 포함되어 있습니다.',
  },
  only_number: {
    type: 'text',
    text: '숫자를 입력해 주세요.',
  },
  min_price1000: {
    type: 'text',
    text: '최소 1000원 ~ 최대 1,000,000원 이하로 입력해 주세요.',
  },
  max_price: {
    type: 'text',
    text: '최소 1000원 ~ 최대 1,000,000원 이하로 입력해 주세요.',
  },
  is_agree_error: {
    type: null,
    text: '약관 동의후 등록해 주세요.',
  },
  thumbnail_picture: {
    type: 'alert',
    info: {
      title: '상품 이미지 미등록',
      body: '상품 이미지는 1개 이상 필수입니다.<br>상품 이미지를 업로드해 주세요.',
    },
  },
  thumbnail_picture_max: {
    type: 'alert',
    info: {
      title: '이미지 개수 제한',
      body: '상품 이미지는 최대 6개까지 등록할 수 있습니다.<br>6개 이하로 업로드해 주세요.',
    },
  },
  thumbnail_over_size: {
    type: 'alert',
    info: {
      title: '이미지 용량 초과',
      body: '파일 용량이 초과되었습니다.<br>5MB 이하로 업로드해 주세요.',
    },
  },
  digital_product_file: {
    type: 'text',
    text: '판매할 파일을 1개 이상 필수로 업로드해 주세요.',
  },
  qna_sns_link: {
    type: 'text',
    text: 'URL형식에 맞게 입력해 주세요',
  },
};
