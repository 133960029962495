import {
  $after,
  $appendTo,
  $attr,
  $closest,
  $delegate,
  $el,
  $find,
  $is,
  $on,
  $remove,
  $setAttr,
  $wrap,
} from 'fxdom/es';
import { createActionStack } from '../features/bug_tracker.js';

const getUrl = (url = '') => (url.slice(0, 2) == '//' ? url.slice(2) : url);
const editUrl = (url = '') => (/^https:\/\/|http:\/\//.test(url) ? url : '//' + url);

const link_default_class = 'editor_link';
const link_selector = `.${link_default_class}`;

export const showLinkPrompt = ($target, link_class, { onLink, onRemove } = {}) => {
  const is_link = isLinkEl($target);
  const url = is_link ? getUrl($attr('href', $target.parentNode) || '') : '';

  $.body_fixed(true);
  const $message_box = $el(`
      <div class="don_dialog_msg link_prompt_box" contenteditable="false">
        <div class="content">
          <div class="body">
            <div class="msg">
              <input class="url input_common" type="text" value="${url}" placeholder="ex) https://marpple.shop">
            </div>
          </div>
          <div class="footer">
            <div class="buttons">
              <button class="btn btn-cancel cancel"> 취소 </button>
              <button class="btn btn-ok ok"> 확인 </button>
            </div>
          </div>
        </div>
      </div>
    `);

  const removeLink = () => {
    const $a = $target.parentNode;
    $after($target, $a);
    $remove($a);
    onRemove && onRemove($target);
    createActionStack('remove link');
  };

  const createLink = (url) => {
    const $a = $el(`<a href="${url}" target="_blank" class="${link_default_class} ${link_class}"></a>`);
    $wrap($a, $target);
    onLink && onLink($target);
    createActionStack('create link');
  };

  const changeLink = (url) => {
    const $a = $target.parentNode;
    $setAttr({ href: url }, $a);
    createActionStack('change link');
  };

  const saveUrl = () => {
    $remove($message_box);
    const url = editUrl($find('input.url', $message_box).value);
    $.body_fixed(false);
    if (!url || url == '//') return is_link ? removeLink() : '';
    is_link ? changeLink(url) : createLink(url);
  };

  $delegate('click', '.cancel', (e) => {
    $remove($message_box);
    $.body_fixed(false);
  })($message_box);

  $delegate('click', '.ok', (e) => saveUrl())($message_box);

  $on('keydown', (e) => (e.keyCode == 13 ? saveUrl() : ''))($find('input.url', $message_box));

  $appendTo($closest('.editor', $target), $message_box);
  $find('.body input', $message_box).focus();
};

export const isLinkEl = (el) => ($is(link_selector, el.parentNode) ? el.parentNode : false);
