import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { MShopStudioPcInfoTmplS } from '../../../MShop/Studio/PcInfo/S/Tmpl/module/MShopStudioPcInfoTmplS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { messages } from '../../Setting/S/messages_id.js';
import { makeStoreLayoutHtml } from '../../StoreLayout/S/tmpl.js';

export const makeSortableProductItemsHtml = (store_products) =>
  strMap(
    (sp) => `<div class="product_item" data-place_idx="${
      UtilS.is_number(sp.place_idx) ? sp.place_idx : ''
    }" data-sp_id="${sp.sp_id}">
        <i class="d-block lnr lnr-checkmark-circle selected_mark"></i>
        ${MpShopProductTmplS.item('arrangement')(sp)}
      </div>`,
    store_products,
  );

export const makeArrangementSettingsBodyHtml = ({
  store,
  user,
  pagination_state,
  store_products,
  has_banner = !!store.banner_img,
  is_mobile,
}) =>
  is_mobile
    ? html`<div class="no_mobile">
        ${MShopStudioPcInfoTmplS.unsupportedMobile(T(messages.arrangement.only_pc))}
      </div>`
    : html`
        <div class="header flex">
          <div class="left">
            <h2>${T(messages.menus.arrangement)}</h2>
            ${T.lang == 'kr'
              ? legacyHtml`
                  <p className="description">
                    ${T(messages.arrangement.desc)}
                  </p>
                `
              : ''}
          </div>
          <div class="right">
            <button class="btn btn-save">${T(messages.arrangement.save)}</button>
          </div>
        </div>
        <div class="arrangement_container body" data-fx-json="${$dataStr(pagination_state)}">
          <div class="arrangement_body">
            <div class="store_container">
              <div class="store_preview">
                ${makeStoreLayoutHtml({
                  locals: { store, login_user: user, has_banner, draw_header: false },
                  body: ``,
                })}
                <div class="store-products">${makeSortableProductItemsHtml(store_products)}</div>
              </div>
            </div>
            <div class="clipboard_container" data-open="true">
              <div class="clipboard">
                <div class="header">
                  <p class="title">${T('클립보드')}</p>
                </div>
                <div class="clipboard-guide">
                  <img
                    src="//s3.marpple.co/files/u_1504988/2021/3/original/68b53ce913b4672ecb154e38460e3a13f4db13d21.png"
                    alt="드래그 아이콘"
                  />
                  <p>${T(messages.arrangement.desc2)}</p>
                </div>
              </div>
              <div class="drop_area"></div>
            </div>
            <button class="open_btn" data-open="true" type="button">
              <i class="d-block lnr lnr-chevron-left will-open"></i>
              <i class="d-block lnr lnr-cross will-close"></i>
            </button>
          </div>
        </div>
        <div class="infinite_scroll_target"></div>
      `;
