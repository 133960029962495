import { compact, entries, flatMap, go, groupBy, map, omit, sel } from 'fxjs/es';
import { DeliveryTypeS } from '../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';
import { OrderShippingOrderS } from '../../../ShippingOrder/S/Function/module/OrderShippingOrderS.js';

export const convertUpsToStoreOrders = (
  user_products,
  { is_far, selected_country_shipping_cost, _en = G._en, lang = T.lang } = {
    is_far: false,
    _en: G._en,
    lang: T.lang,
  },
) => {
  let i = 0;
  let separate_shipping_i = 0;
  return go(
    user_products,
    flatMap((up) => up._.up_cs),
    groupBy((up_c) => up_c.store_id),
    entries,
    map(([, up_cs]) => {
      const store = sel('0._.product_color._.stores_product._.store')(up_cs);
      return {
        store_id: store.id,
        _: {
          store,
          shipping_orders: go(
            up_cs,
            groupBy((up_c) => {
              const stores_delivery_company =
                up_c._.product_color._.stores_product._.original_stores_product._
                  .stores_delivery_companies[0];
              if (stores_delivery_company.is_separate_shipping) {
                return `${stores_delivery_company.id}_${separate_shipping_i++}`;
              } else {
                return stores_delivery_company.id;
              }
            }),
            entries,
            map(([, up_cs]) => {
              const original_stores_products = up_cs.map((up_c) => {
                return up_c._.product_color._.stores_product._.original_stores_product;
              });
              const is_free_shipping_product =
                original_stores_products.find(({ is_free_shipping_product }) => {
                  return is_free_shipping_product;
                }) || false;
              const stores_delivery_company = original_stores_products[0]._.stores_delivery_companies[0];
              const is_mp = DeliveryTypeS.isMarpple(stores_delivery_company.delivery_type_id);
              const is_oversea = lang !== 'kr';

              if (is_oversea && selected_country_shipping_cost?.cost != null && is_mp) {
                OrderShippingOrderS.setStoresDeliveryCompanies({
                  shipping_cost: selected_country_shipping_cost,
                  stores_delivery_companies: original_stores_products[0]._.stores_delivery_companies,
                });
              }
              const { shipping_price, additional_shipping_price } =
                OrderShippingOrderS.makePayableShippingPrice({
                  user_products: go(
                    up_cs,
                    flatMap((up_c) => up_c._.up_c_ss),
                    map(omit('_')),
                  ),
                  stores_delivery_company,
                  is_free_shipping_product,
                  is_far,
                  /* 마플샵 배송은 한번만 계산하기 위하여 (스토어로 먼저 나뉘어짐) */
                  mps_i: is_mp ? i++ : null,
                  _en,
                });
              return {
                delivery_type_id: stores_delivery_company.delivery_type_id,
                shipping_price,
                additional_shipping_price,
                stores_delivery_company_id: stores_delivery_company.id,
                delivery_company_id: stores_delivery_company.delivery_company_id,
                free_shipping_min_price: stores_delivery_company.free_shipping_min_price,
                original_shipping_price: stores_delivery_company.shipping_price,
                original_additional_shipping_price: stores_delivery_company.additional_shipping_price,
                original_take_back_price: stores_delivery_company.take_back_price,
                is_mp,
                lang,
                data: JSON.stringify(stores_delivery_company),
                _: {
                  stores_delivery_company,
                  item_order_up_cs: go(
                    up_cs,
                    map((up_c) => {
                      return {
                        ...up_c,
                        up_c_id: up_c.id,
                        is_free_shipping_product,
                        delivery_company_id: stores_delivery_company.delivery_company_id,
                      };
                    }),
                  ),
                },
              };
            }),
            compact,
          ),
        },
      };
    }),
  );
};
