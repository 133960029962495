!(function () {
  $.frame.defn_frame({
    frame_name: 'mp.sell_your_design',
    page_name: 'mp.sell_your_design',
    header_height: G.is_pc_size() ? 0 : 60,
  });

  $.frame.defn_page({
    page_name: 'mp.sell_your_design',
    tabs: [
      {
        tab_name: 'mp.sell_your_design',
        showed: function () {
          const win_wid = $.width(window);
          if (G.is_pc_size())
            new Swiper('.swiper-container', {
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              loop: true,
              spaceBetween: 16,
              slidesPerView: 'auto',
              centeredSlides: true,
              initialSlide: 2,
            });
          else
            new Swiper('.swiper-container', {
              // navigation: {
              //   nextEl: '.swiper-button-next',
              //   prevEl: '.swiper-button-prev',
              // },
              pagination: {
                el: '.swiper-pagination',
                clickable: true,
              },
              loop: true,
              autoplay: {
                delay: 2000,
                disableOnInteraction: false,
              },
              spaceBetween: 16,
              width: win_wid > 600 ? 600 : win_wid,
              height: win_wid > 600 ? 600 : win_wid,
            });
          $.remove_class($('.not_ready'), 'not_ready');
        },
      },
    ],
  });
})();
