import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MShopAppUpItemConstantS } from '../Constant/module/MShopAppUpItemConstantS.js';

export const makeOptionInfoStatus = ({
  _is_not_stock,
  goods_type_id,
  no_spo_item,
  spo_item_stock_quantity,
  quantity,
  remaining_limited_quantity,
  text_option_changed,
  discontinued = false,
}) => {
  if (discontinued) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.DISCONTINUED;
  if (_is_not_stock) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NO_STOCK;
  if (remaining_limited_quantity === 0) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NO_STOCK;
  if (remaining_limited_quantity && quantity > remaining_limited_quantity)
    return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NOT_ENOUGH_STOCK;
  if (GoodsTypeS.isMps(goods_type_id)) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL;
  if (spo_item_stock_quantity === 0) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NO_STOCK;
  if (spo_item_stock_quantity - quantity < 0)
    return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NOT_ENOUGH_STOCK;
  if (no_spo_item) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.OPTION_CHANGED;
  if (text_option_changed) return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.OPTION_CHANGED;

  return MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL;
};
