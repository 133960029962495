import { html, strMap } from 'fxjs/es';
import { PriceS } from '../../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

export const cchStyleOptionList = ({ n_option_infos, n_text_option_infos }) => {
  return html`
    <div
      class="cch-style-option-list"
      data-options_length="${n_option_infos.length}"
      data-text_options_length="${n_text_option_infos.length}"
    >
      <div class="cch-style-option-list__item-wrapper">
        ${strMap((n_option_info) => {
          const option_name = UtilS.escape(n_option_info.name) || T('mshop_cart_and_checkout::수량');
          return html`
            <div
              class="cch-style-option-list__item"
              data-status="${n_option_info.status}"
              data-up_c_s_id="${UtilS.escape(n_option_info.up_c_s_id)}"
            >
              <div class="cch-style-option-list__item__info-1">
                <span class="cch-style-option-list__item__info-1__not-normal-warning"
                  >${T('mshop_cart_and_checkout::구매불가')}</span
                >
                <span class="cch-style-option-list__item__info-1__option-name"> ${option_name}</span
                ><span style="padding:0 2px;">/</span
                ><span class="cch-style-option-list__item__info-1__quantity">
                  ${UtilS.escape(n_option_info.quantity)}${T('mshop_cart_and_checkout::개')}
                </span>
              </div>
              <div class="cch-style-option-list__item__info-2">
                <div class="cch-style-option-list__item__info-2__price mp_currency">
                  ${PriceS.pricify(n_option_info.discounted_price * n_option_info.quantity)}
                </div>
              </div>
            </div>
          `;
        }, n_option_infos)}
      </div>
      <div class="cch-style-option-list__text-item">
        <div class="cch-style-option-list__text-item__title">요청사항</div>
        ${strMap(
          (n_text_option_info) => html`
            <div class="cch-style-option-list__text-item__value">
              ${UtilS.escape(n_text_option_info.value)}
            </div>
          `,
          n_text_option_infos,
        )}
      </div>
    </div>
  `;
};
