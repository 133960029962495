import {
  $addClass,
  $attr,
  $closest,
  $data,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $next,
  $prev,
  $removeClass,
  $setAttr,
  $setHTML,
  $trigger,
} from 'fxdom/es';
import { filterL, find, findWhere, go, html, last, map, mapL, reject, takeAllC } from 'fxjs/es';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorTmplS } from '../../S/Tmpl/module/MShopStudioDigitalProductCreatorTmplS.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';

const error_info = MShopStudioDigitalProductCreatorConstantS.ERROR_INFO;
const initFile = (e) => () =>
  setTimeout(function () {
    e.currentTarget.type = '';
    e.currentTarget.type = 'file';
    e.currentTarget.disabled = false;
  }, 0);

const makeUniqId = (function () {
  const id_state = { id: 0 };
  return () => ++id_state.id;
})();

const makeImageToArrayInBox = (parent_el) => {
  box.set(
    'digital_product_thumbnail_files',
    map(
      ($el) => findWhere({ file_id: $attr('data-file-id', $el) }, box.sel('digital_product_thumbnail_files')),
      $findAll('.digital-product-thumbnail-editor__item--on[data-is_file="true"]', parent_el),
    ),
  );
};

const toggleDigitalProductThumbnail = (is_show_upload, parent_el) => {
  $setAttr(
    { 'is-show-upload': is_show_upload },
    $find('.digital-product-thumbnail-editor__view-area', parent_el),
  );
};
const clickThumbListEmptyItem = (e) => {
  toggleDigitalProductThumbnail(true, e.delegateTarget);
  const selected_photo_el = $find('.digital-product-thumbnail-editor__item--selected', e.delegateTarget);
  if (selected_photo_el)
    $removeClass(
      'digital-product-thumbnail-editor__item--selected',
      $find('.digital-product-thumbnail-editor__item--selected', e.delegateTarget),
    );
};

export const clickImageUpload = (e) => {
  $trigger('click', $find('.digital-product-thumbnail-editor__input-file', e.delegateTarget));
};

const getEditorContainerEl = (el) => {
  return $closest('.digital-product-creator', el);
};

const setThumbFile = async (e, container_el, files) => {
  const is_input = !!e;
  const digital_product_photos_el = $find('.digital-product-thumbnail-editor__photos', container_el);
  const total_len =
    $findAll('.digital-product-thumbnail-editor__photo', digital_product_photos_el).length + files.length;
  const fail_images = [];
  if (is_input && total_len > 6)
    return go(MShopShareFramePopUpF.alert(error_info.thumbnail_picture_max.info), initFile(e));

  if (is_input && find((file) => file.size > 5242880, files)) {
    return go(MShopShareFramePopUpF.alert(error_info.thumbnail_over_size.info), initFile(e));
  }

  if (is_input) {
    e.currentTarget.disabled = true;
  }

  return go(
    files,
    filterL((file) => file.type.match('image')),
    mapL((file) => {
      return go(
        new Promise((rs, rj) => {
          const reader = new FileReader();
          reader.onload = () => {
            const min_size = 740;
            const max_size = 5000;
            const image = new Image();
            image.onload = () => {
              if (image.width < min_size) {
                fail_images.push({ file, type: 'min_width' });
                return rj(file);
              }
              if (image.width > max_size) {
                fail_images.push({ file, type: 'max_width' });
                return rj(file);
              }
              if (image.height < min_size) {
                fail_images.push({ file, type: 'min_height' });
                return rj(file);
              }
              if (image.height > max_size) {
                fail_images.push({ file, type: 'max_height' });
                return rj(file);
              }
              rs(image);
            };
            image.src = reader.result || '';
          };
          reader.readAsDataURL(file);
        }),
        async () => {
          const url = await $.get_data_url(500)(file);
          const file_id = makeUniqId();
          file.file_id = file_id;
          const idx =
            box.sel('digital_product_thumbnail_photos').length > 0
              ? box.sel('digital_product_thumbnail_photos').length
              : 0;

          return box.push('digital_product_thumbnail_photos', { file_id, url, idx });
        },
        () => box.push('digital_product_thumbnail_files', file),
      ).catch((file_original_name) => {
        console.error(file_original_name);
      });
    }),
    takeAllC,
    () => {
      if (is_input) initFile(e);
      $setHTML(
        MShopStudioDigitalProductCreatorTmplS.makeThumbnailRegister(
          box.sel('digital_product_thumbnail_photos'),
        ),
        $find('.digital-product-creator__thumbnails-area', container_el),
      );

      if (fail_images.length) {
        $.alert(T('digital-product_product::이미지 가로 세로 사이즈를 최소 740px 최대 5000px로 올려주세요.'));
      }
    },
    () => {
      makeImageToArrayInBox(container_el);
      MShopStudioDigitalProductCreatorF.checkAllInput(container_el);
    },
  );
};

const changeThumbFile = async (e) => {
  if ($.valid_files(e.currentTarget))
    return $.alert(
      html`
        <div class="ecommerce-info__alert">
          <h3>파일 첨부 불가능</h3>
          <p style="text-align: center;">
            첨부가 불가능한 파일입니다.<br />
            (첨부 가능 파일 : jpg, png)
          </p>
        </div>
      `,
    );

  const files = e.currentTarget.files;
  const container_el = getEditorContainerEl(e.currentTarget);

  return await setThumbFile(e, container_el, files);
};
const clickThumbListItem = (e) => {
  const item_el = $closest('.digital-product-thumbnail-editor__item', e.currentTarget);
  const idx = $attr('data-idx', item_el);
  const prev_photo_el = $find(`.digital-product-thumbnail-editor__photo--show`, e.delegateTarget);
  const photo_el = $find(`.digital-product-thumbnail-editor__photo[data-idx="${idx}"]`, e.delegateTarget);
  const selected_photo_el = $find('.digital-product-thumbnail-editor__item--selected', e.delegateTarget);

  toggleDigitalProductThumbnail(false, e.delegateTarget);

  if (selected_photo_el) $removeClass('digital-product-thumbnail-editor__item--selected', selected_photo_el);
  $addClass('digital-product-thumbnail-editor__item--selected', item_el);

  if (prev_photo_el) $removeClass('digital-product-thumbnail-editor__photo--show', prev_photo_el);
  $addClass('digital-product-thumbnail-editor__photo--show', photo_el);
};

const clickSetFirstThumb = (e) => {
  const idx = $attr('data-idx', $closest('.digital-product-thumbnail-editor__photo', e.currentTarget));
  const firstPhoto = box.sel('digital_product_thumbnail_photos')[idx];
  go(
    box.sel('digital_product_thumbnail_photos'),
    reject((photo) => photo.url == firstPhoto.url),
    (photos) => {
      box.set('digital_product_thumbnail_photos', [firstPhoto, ...photos]);
      return box.sel('digital_product_thumbnail_photos');
    },
    (photos) => {
      $setHTML(
        MShopStudioDigitalProductCreatorTmplS.makeThumbnailRegister(photos),
        $find('.digital-product-creator__thumbnails-area', e.delegateTarget),
      );
    },
    () => makeImageToArrayInBox(e.delegateTarget),
  );
};

const clickPrevThumbnail = (e) => {
  const selected_item_el = $find('.digital-product-thumbnail-editor__item--selected', e.delegateTarget);
  const prev_el = $prev(selected_item_el);

  if (prev_el && $hasClass('digital-product-thumbnail-editor__item--on', prev_el)) {
    $trigger('click', $find('.digital-product-thumbnail-editor__item-img', prev_el));
  } else {
    const last_el = go($findAll('.digital-product-thumbnail-editor__item-img', e.delegateTarget), last);
    $trigger('click', last_el);
  }
};

const clickDeleteThumbnail = (e) => {
  const delete_photo_el = $find(
    '.digital-product-thumbnail-editor__item--selected .digital-product-thumbnail-editor__item-thumb-del',
    e.delegateTarget,
  );
  $trigger('click', delete_photo_el);
};

const clickNextThumbnail = (e) => {
  const selected_item_el = $find('.digital-product-thumbnail-editor__item--selected', e.delegateTarget);
  const next_el = $next(selected_item_el);

  if (next_el && $hasClass('digital-product-thumbnail-editor__item--on', next_el)) {
    $trigger('click', $find('.digital-product-thumbnail-editor__item-img', next_el));
  } else {
    $trigger(
      'click',
      $find(
        '.digital-product-thumbnail-editor__item-img',
        $find('.digital-product-thumbnail-editor__item--on', e.delegateTarget),
      ),
    );
  }
};

export const clickDeleteThumbListItem = (e) => {
  const item_el = $closest('.digital-product-thumbnail-editor__item', e.currentTarget);
  const file_id = $attr('data-file-id', item_el);
  // const container_el = getEditorContainerEl(e.currentTarget);
  if (file_id) {
    box.remove2(`digital_product_thumbnail_files->(file=>file.file_id=='${file_id}')`);
    box.remove2(`digital_product_thumbnail_photos->(photo=>photo.file_id=='${file_id}')`);
  } else {
    const { url } = $data(item_el);
    box.remove2(`digital_product_thumbnail_photos->(photo=>photo.url=='${url}')`);
  }

  $setHTML(
    MShopStudioDigitalProductCreatorTmplS.makeThumbnailRegister(box.sel('digital_product_thumbnail_photos')),
    $find('.digital-product-creator__thumbnails-area', e.delegateTarget),
  );

  // checkAllEvents(container_el);
};

export const thumbnailEvent = (container_el) => {
  return go(
    container_el,
    $delegate('click', '.digital-product-thumbnail-editor__item[data-id=""]', clickThumbListEmptyItem),
    $delegate('click', '.digital-product-thumbnail-editor__upload-btn', clickImageUpload),
    $.on3('change', '.digital-product-thumbnail-editor__input-file', changeThumbFile),
    $delegate('click', '.digital-product-thumbnail-editor__item-img', clickThumbListItem),
    $delegate(
      'click',
      '.digital-product-thumbnail-editor__photo-set-first input[name="digital-product_thumbnail_first"]',
      clickSetFirstThumb,
    ),
    $delegate('click', '.digital-product-thumbnail-editor__item-thumb-del', clickDeleteThumbListItem),
    $delegate('click', '.digital-product-thumbnail-editor__photo-delete', clickDeleteThumbnail),
    $delegate('click', '.digital-product-thumbnail-editor__arrow-prev', clickPrevThumbnail),
    $delegate('click', '.digital-product-thumbnail-editor__arrow-next', clickNextThumbnail),
  );
};
