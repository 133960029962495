import { html } from 'fxjs/es';

const thread_close_text = `<span class="highlight">신고 스레드가 종료</span>&nbsp되었습니다.`;
const confirm_product_text = `해당 상품이&nbsp<span class="highlight">판매 재개</span>&nbsp되었습니다.`;
const confirm_store_text = `내부 규정 위반 사실이 없음을 확인하여&nbsp<span class="highlight">해당 크리에이터샵은 정상 운영</span>&nbsp됩니다.`;
const close_product_text = `해당 상품이&nbsp<span class="highlight">판매 중지</span>&nbsp되었습니다.`;
const close_store_text = `내부 규정 위반 사유로&nbsp<span class="highlight">크리에이터샵 운영 중지</span>&nbsp되었습니다.`;

const answer_seller_text = `크리에이터가 답변을 완료했습니다.`;

const makeIngStatus = ({ IS_INTERNAL_REGULATION, dday_last_request_at }) =>
  IS_INTERNAL_REGULATION
    ? html`
        <div class="status-message">
          <span class="highlight">${dday_last_request_at}일 이내</span>에 크리에이터로 부터 답변이 없으면,
          <span class="highlight">크리에이터샵 운영 중지</span> 됩니다.
        </div>
      `
    : html`
        <div class="status-message">
          해당 상품은 <span class="highlight">판매 중지 처리</span>되었습니다.
          <span class="highlight">${dday_last_request_at}일 이내</span>에 크리에이터가 답변을 등록하면 판매가
          재개됩니다.
        </div>
      `;

const makeIngStatusForStudio = ({ IS_INTERNAL_REGULATION, dday_last_request_at }) => html`
  <div>${makeIngStatus({ IS_INTERNAL_REGULATION, dday_last_request_at })}</div>
  <div class="add_solution_thread">
    <div class="buttons">
      <a id="solution_thread">답변하기</a>
    </div>
  </div>
`;

const makeIng2Status = () =>
  html`<div class="status-message">
    ${answer_seller_text} 해당 크리에이터의 이의신청 내용을 검토중 입니다.
  </div>`;

const makeIng2StatusForCrew = () => html`
  <div class="reason">
    <textarea
      name="reason"
      placeholder="(필수 아님) 정상 운영 또는 운영 중지의 사유를 적어주세요."
    ></textarea>
    <div class="buttons">
      <a data-status="confirm">정상 운영</a>
      <a data-status="close">운영 중지</a>
    </div>
  </div>
`;

const makeConfirmStatus =
  (is_crew) =>
  ({ IS_INTERNAL_REGULATION, is_close, dday_last_answer_at }) =>
    IS_INTERNAL_REGULATION
      ? html` <div class="status-message">${thread_close_text} ${confirm_store_text}</div> `
      : is_close
      ? html` <div class="status-message">${thread_close_text} ${confirm_product_text}</div> `
      : html`
          <div class="status-message">
            ${answer_seller_text} ${confirm_product_text} 재요청 답변 기간이
            <span class="highlight">${dday_last_answer_at}일</span> 남았습니다.
          </div>
          ${is_crew
            ? ''
            : html`
                <div class="add_solution_thread">
                  <div class="buttons">
                    <a id="solution_thread">답변 재요청</a>
                    <a id="solution_close">스레드 종료</a>
                  </div>
                </div>
              `}
        `;

const makeConfirmStatusForStudio = ({ IS_INTERNAL_REGULATION, is_close, dday_last_answer_at }) =>
  html`
    <div>
      ${makeConfirmStatus(true)({
        IS_INTERNAL_REGULATION,
        is_close,
        dday_last_answer_at,
      })}
    </div>
  `;

const makeCloseStatus = ({ IS_INTERNAL_REGULATION }) => html`
  <div class="status-message">
    ${thread_close_text} ${IS_INTERNAL_REGULATION ? close_store_text : close_product_text}
  </div>
`;

export const status = {
  ing: {
    crew: makeIngStatus,
    studio: makeIngStatusForStudio,
    client: makeIngStatus,
  },
  ing2: {
    crew: makeIng2StatusForCrew,
    studio: makeIng2Status,
    client: makeIng2Status,
  },
  close: {
    crew: makeCloseStatus,
    studio: makeCloseStatus,
    client: makeCloseStatus,
  },
  confirm: {
    crew: makeConfirmStatus(true),
    studio: makeConfirmStatusForStudio,
    client: makeConfirmStatus(),
  },
};
