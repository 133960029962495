import { find, html, strMap } from 'fxjs/es';

export const makeCustomDropdown = (selected, options) => html`
  <div class="feature custom-dropdown dropdown-transparent" tabindex="0">
    <div class="dropdown-selected">${find((o) => o.value == selected, options)?.name || options[0].name}</div>
    <div class="dropdown-options">
      ${strMap(
        (option) => html`<div class="dropdown-option" data-value="${option.value}">${option.name}</div>`,
        options,
      )}
    </div>
  </div>
`;
