import { makeTrimCanvas } from '../../../../../modules/Maker/F/canvas_trim.js';
import { makeCartModalPreviewHtml } from '../../../../../modules/MPay/Cart/S/tmpl.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { makeProductListSizesHtml } from '../../../../../modules/Product/List/S/tmpl.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { setAndUploadThumbnailOgImage } from '../../../../../modules/Composite/Thumbnail/F/fs.js';
import { find, sel, go } from 'fxjs/es';

!(function () {
  function price_tmpl_kr(product_color, new_price) {
    new_price = new_price || product_color.price;
    return _p.go(
      _p.defaults(
        {
          new_price: new_price,
          profit: PriceS.sub(new_price, product_color.price),
        },
        product_color,
      ),
      _p.t(
        'trans_pc',
        '\
        .kr.price_wrapper[lang="kr"]\
          label {{T("Set Your Price")}}\
          .input_mp_currency\
            input[value="{{trans_pc["new_price"]}}" key="new_price" step="1000" type="number" min="{{trans_pc["price"]}}"]\
          .show_profit\
            .minimum_price\
              label {{T("Minimum Price")}}\
              .price.mp_currency_kr {{trans_pc["price"]}}\
            .profit\
              label {{T("Profit")}}\
              .price.mp_currency_kr {{trans_pc["profit"]}}\
      ',
      ),
    );
  }

  function price_tmpl_en(product_color, new_price) {
    new_price = new_price || product_color.price_en;
    return _p.go(
      _p.defaults(
        {
          new_price_en: new_price,
          profit: PriceS.sub(new_price, product_color.price_en),
        },
        product_color,
      ),
      _p.t(
        'trans_pc',
        '\
        .en.price_wrapper[lang="en"]\
          label {{T("Set Your Price")}}\
          .input_mp_currency\
            input[value="{{trans_pc["new_price_en"]}}" key="new_price_en" step="1" type="number" min="{{trans_pc["price_en"]}}"]\
          .show_profit\
            .minimum_price\
              label {{T("Minimum Price")}}\
              .price.mp_currency_en {{trans_pc["price_en"]}}\
            .profit\
              label {{T("Profit")}}\
              .price.mp_currency_en {{trans_pc["profit"]}}\
      ',
      ),
    );
  }

  $.frame.defn_frame({
    frame_name: 'mp.make_product_color_price',
    page_name: 'mp.make_product_color_price_page',
    // header_height: 60,
    always_remove: true,
    hide_frame_button_type: 'X',
    is_modal: G.is_pc_size(),
    closed: function (el, product_color) {
      if (product_color) {
        _p.defer(function () {
          $.frame.close(product_color);
        });
      }
    },
  });

  $.frame.defn_page({
    page_name: 'mp.make_product_color_price_page',
    title: T('Sell Your Products'),
    tabs: [
      {
        tab_name: 'mp.make_product_color_price_tab',
        template: (product_color) => legacyHtml`
          <div class="product_color_item">
            ${makeCartModalPreviewHtml(product_color, 'maker->product_color')}
            <div class="left">
              <div class="pc_info">
                <div class="title_and_faces">
                  <div class="title">
                    ${product_color._.base_product['name' + _collabo + _en]}
                    [${product_color._.base_product_color['name' + _en]}]
                  </div>
                  <div class="faces">
                    <div class="sub_title">
                      ${T('Print')} :
                      <span
                        >${
                          _p.go(
                            product_color.product_faces2.value,
                            _p.filter('designs.length'),
                            _map((pf) => pf['face_name' + G._en] || pf.face_name_en),
                            _p.join(' / '),
                          ) || T('No Print')
                        }</span
                      >
                    </div>
                  </div>
                </div>
                <div class="sizes">
                  <div class="sub_title">
                    ${T('Size')} :
                    <span
                      >${_p.go(
                        product_color._.base_product._.base_product_sizes,
                        makeProductListSizesHtml,
                      )}</span
                    >
                  </div>
                </div>
              </div>
              <div class="title">
                <div class="kr">
                  <label
                    >${T('Korean')} - ${T('Title')}
                    <input
                      placeholder="Product Title"
                      type="text"
                      key="name"
                      value="${
                        (_collabo
                          ? product_color.name
                          : product_color.name || product_color._.base_product['name' + _collabo]) || ''
                      }"
                    />
                  </label>
                </div>
                <div class="en">
                  <label
                    >${T('English')} - ${T('Title')}
                    <input
                      placeholder="Product Title"
                      type="text"
                      key="name_en"
                      value="${
                        (_collabo
                          ? product_color.name_en
                          : product_color.name_en || product_color._.base_product.name_en) || ''
                      }"
                    />
                  </label>
                </div>
                <div class="jp">
                  <label
                    >${T('Japanese')} - ${T('Title')}
                    <input
                      placeholder="Product Title"
                      type="text"
                      key="name_jp"
                      value="${
                        (_collabo
                          ? product_color.name_jp
                          : product_color.name_jp || product_color._.base_product.name_jp) || ''
                      }"
                    />
                  </label>
                </div>
              </div>
              <div class="set_product_price" style="display:none;">
                ${_p.go(product_color, price_tmpl_kr)} ${_p.go(product_color, price_tmpl_en)}
              </div>
              <div class="option">
                <button class="submit" type="button">${T('Submit')}</button>
              </div>
            </div>
          </div>
        `,
        showed: function (don_tab) {
          G.mp.cart.swiper_init();
          $.find1(don_tab, '.title input').focus();
          $.don_loader_end();
        },
        appended: __(
          _p.tap(function (don_tab) {
            _go(don_tab, $.find('.product_face_list'), G.mp.maker.draw_product_face6);
            // G.mp.maker.draw_product_face2(don_tab, box.sel('maker->product_color'));
          }),
          $.on('click', '.product_face_list.bottom .preview_thumb', function (e) {
            const swiper = $1('.product_face_container.swiper-container').swiper;
            const idx = $.attr(e.currentTarget, '_idx');
            swiper.slideTo(idx);
          }),
          // $.on('click', '.set_product_price input', set_product_price),
          $.on('change', '.input_mp_currency input', __(_p.v('currentTarget'), set_product_price)),
          $.on2('click', '.submit', async function (e) {
            $.don_loader_start();
            const pc = box.sel('maker->product_color');
            _p.go(
              e.currentTarget,
              $.closest('.product_color_item'),
              $.find('.title input'),
              _p.each(function (input_el) {
                pc[$.attr(input_el, 'key')] = $.val(input_el);
              }),
            );
            const product_color = box().maker.product_color;

            return _p.go(
              undefined,
              async () => {
                if (product_color.thumbnails?.value?.length) {
                  await setAndUploadThumbnailOgImage(product_color, true);
                  return product_color.thumbnails.value[0];
                } else {
                  return go(
                    $('.product_face_container .product_face_list.top .product_face'),
                    _p.map(function (el) {
                      return {
                        base_product_face_id: $.attr(el, 'bpf_id'),
                        face_name: $.attr(el, 'face_name'),
                        face_name_en: $.attr(el, 'face_name_en'),
                        canvas: $.find1(el, '.canvas_600 canvas'),
                        is_thumb: $.has_class(el, 'swiper-slide-active'),
                      };
                    }),
                    _p.map(function (bpf) {
                      return _p.go(
                        bpf.canvas,
                        makeTrimCanvas,
                        function (canvas2) {
                          return $.uploadFileToUrl(canvas2.toDataURL(), pc.name);
                        },
                        function (file) {
                          _p.extend(bpf, _p.pick(file, ['width', 'height', 'url']));
                          delete bpf.canvas;
                          return bpf;
                        },
                      );
                    }),
                    find(sel('is_thumb')),
                  );
                }
              },
              function (file) {
                return {
                  pc: _p.omit(pc, ['_']),
                  thumbnail: { url: file.url },
                };
              },
              $.frame.close,
            );
          }),
        ),
      },
    ],
  });

  function set_product_price(input_el) {
    const new_price = $.val(input_el);
    const minimum_price = $.attr(input_el, 'min');
    let profit = PriceS.sub(new_price, minimum_price);
    if (profit < 0) {
      profit = 0;
      $.val(input_el, minimum_price);
    }
    _p.go(
      input_el,
      $.closest('.price_wrapper'),
      $.find('.show_profit'),
      _each(__($.find1('.profit .price'), $.text(profit))),
    );
  }
})();
