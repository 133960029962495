import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const event = (tab_el) => {
  new Swiper('.omp-review-detail__image-slider', {
    freeMode: OMPCoreUtilF.isMobile(),
    slidesPerView: 'auto',
    watchOverflow: false,
    spaceBetween: 30, // 슬라이드 사이 여백
    threshold: 15,
    navigation: {
      nextEl: '.omp-review-detail__image-slider-nav.omp-swiper-next',
      prevEl: '.omp-review-detail__image-slider-nav.omp-swiper-prev',
    },
  });
};
