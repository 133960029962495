import { CreatorEditorMarppleEmbedUrlMuiS } from '../../S/Mui/module/CreatorEditorMarppleEmbedUrlMuiS.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { $delegate, $find } from 'fxdom/es';
import { filter, go, identity, map } from 'fxjs/es';
import { CreatorEditorMarppleEmbedS } from '../../../S/Function/module/CreatorEditorMarppleEmbedS.js';

export const tab = {
  ...CreatorEditorMarppleEmbedUrlMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값 또는 tab 엘리먼트에 데이터를 저장할 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    const textarea$ = $find('.textarea', tab_el$);

    go(
      tab_el$,
      $delegate('click', '.btn-cancel-frame', (e) => {
        MuiF.removeFrame();
      }),
      $delegate('click', '.btn-submit', (e) => {
        go(
          textarea$.value.split('\n'),
          map(CreatorEditorMarppleEmbedS.formatToMarppleProductUrl),
          filter(identity),
          MuiF.removeFrame,
        );
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
};
