import MediumEditor from '@marpple/dinosaurs/front/medium-editor.js';
import { $el, $is, $on, $qs } from 'fxdom/es';
import { each } from 'fxjs/es';
import { getAllSelectedRootEls, saveSelectionRange } from '../../../../Feed/F/util.js';
import { changeTagName } from '../../util.js';
import { createActionStack } from '../features/bug_tracker.js';

export const getMediumHeader = (size) =>
  MediumEditor.Extension.extend({
    name: `custom-h${size}`,
    init: function () {
      const button = $el(`
      <button class="medium-editor-action" title="CustomH${size}">
        <i class="fa fa-header"><sup>${size}</sup></i>
      </button>
    `);

      const $editable = $qs('.editable');

      const tag = `h${size}`;

      this.button = button;

      $on('click', () => {
        const restoreSelectionRange = saveSelectionRange($editable);
        const $selected = getAllSelectedRootEls();
        const is_tag = $is(tag, $selected[0]);
        each(changeTagName(is_tag ? 'p' : tag), $selected);

        restoreSelectionRange($editable);

        this.base.checkContentChanged();

        createActionStack(`header ${size}`);
      })(button);

      this.subscribe('showToolbar', () => {});
    },
    getButton: function () {
      return this.button;
    },
  });
