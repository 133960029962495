export const CONTRACT_CHAIN = {
  EthereumMainnet: 'EthereumMainnet',
  Goerli: 'Goerli',
  PolygonMainnet: 'PolygonMainnet',
  Mumbai: 'Mumbai',
};

export const CONTRACT_ERC_INTERFACE = {
  ERC20: 'ERC20',
  ERC721: 'ERC721',
  ERC777: 'ERC777',
  ERC1155: 'ERC1155',
};
const etherSvg = `<svg width="14" height="14" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-[0.625rem] "><g clip-path="url(#clip0_1496_15081)"><path d="M11.9986 1L11.8457 1.5012V16.0448L11.9986 16.192L18.9965 12.2015L11.9986 1Z" fill="#343434"></path><path d="M11.9979 1L5 12.2015L11.9979 16.1921V9.13304V1Z" fill="#8C8C8C"></path><path d="M11.9982 17.4703L11.9121 17.5716V22.7523L11.9982 22.9951L19.0003 13.4818L11.9982 17.4703Z" fill="#3C3C3B"></path><path d="M11.9979 22.995V17.4702L5 13.4817L11.9979 22.995Z" fill="#8C8C8C"></path><path d="M11.998 16.192L18.9959 12.2016L11.998 9.13307V16.192Z" fill="#141414"></path><path d="M5 12.2016L11.9978 16.192V9.13307L5 12.2016Z" fill="#393939"></path></g><defs><clipPath id="clip0_1496_15081"><rect width="14.0003" height="22" fill="white" transform="translate(5 1)"></rect></clipPath></defs></svg>`;
const polygonSvg = `<svg style="color:rgb(130, 71, 229);" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-[0.625rem] text-polygon-purple"><path d="M17.0922 8.49896C16.7232 8.27063 16.2435 8.27063 15.8377 8.49896L12.9594 10.2495L11.0037 11.3911L8.12546 13.1417C7.75647 13.37 7.27675 13.37 6.87085 13.1417L4.58303 11.7717C4.21402 11.5434 3.95572 11.1248 3.95572 10.6681V7.96619C3.95572 7.50953 4.17712 7.09093 4.58303 6.8626L6.83395 5.53068C7.20295 5.30235 7.68263 5.30235 8.08857 5.53068L10.3395 6.8626C10.7085 7.09093 10.9668 7.50953 10.9668 7.96619V9.71671L12.9225 8.53701V6.78649C12.9225 6.32983 12.7011 5.91123 12.2952 5.6829L8.12546 3.17128C7.75647 2.94295 7.27675 2.94295 6.87085 3.17128L2.62731 5.6829C2.2214 5.91123 2 6.32983 2 6.78649V11.8478C2 12.3044 2.2214 12.723 2.62731 12.9514L6.87085 15.463C7.23985 15.6914 7.71958 15.6914 8.12546 15.463L11.0037 13.7505L12.9594 12.5708L15.8377 10.8584C16.2066 10.63 16.6864 10.63 17.0922 10.8584L19.3432 12.1903C19.7122 12.4186 19.9705 12.8372 19.9705 13.2939V15.9958C19.9705 16.4524 19.7491 16.871 19.3432 17.0993L17.0922 18.4694C16.7232 18.6977 16.2435 18.6977 15.8377 18.4694L13.5867 17.1374C13.2177 16.9091 12.9594 16.4905 12.9594 16.0338V14.2833L11.0037 15.463V17.2135C11.0037 17.6702 11.2251 18.0888 11.631 18.3171L15.8745 20.8288C16.2435 21.0571 16.7232 21.0571 17.1291 20.8288L21.3727 18.3171C21.7417 18.0888 22 17.6702 22 17.2135V12.1522C22 11.6956 21.7786 11.277 21.3727 11.0487L17.0922 8.49896Z"></path></svg>`;
export const CONTRACT_CHAIN_ICON = {
  EthereumMainnet: etherSvg,
  Goerli: etherSvg,
  PolygonMainnet: polygonSvg,
  Mumbai: polygonSvg,
};
