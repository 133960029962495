export const TYPE_TO_TITLE = {
  copyright: T('report::저작권 침해'),
  'fake-product': T('report::위조상품 판매'),
  'internal-regulation': T('report::마플샵 내부 규정 위반'),
};
export const USER_TYPE_TO_TITLE = {
  person: T('report::개인'),
  group: T('report::단체'),
};
export const STATUS_TITLE = {
  ing: T('report::답변 대기'),
  confirm: T('report::판매 재개'),
  close: T('report::판매 중지'),
  ing2: T('report::내부 규정 위반 답변완료'),
};

export const SOLUTION_STATUS_TITLE = {
  ing: T('report::답변 대기'),
  confirm: T('report::샵 정상 운영'),
  close: T('report::샵 운영 중지'),
  ing2: T('report::답변완료'),
};

export const STATUS_TITLE2 = {
  ing: T('report::답변 대기'),
  confirm: T('report::판매 재개/샵 정상 운영'),
  close: T('report::판매 중지/샵 운영 중지'),
  ing2: T('report::내부 규정 위반 답변완료'),
};
