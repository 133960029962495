import { go, range, reduce, takeAll, zipWithIndexL } from 'fxjs/es';

// export const transposeL = (xs) => {
//   const l = xs[0].length;
//
//   return go(
//     rangeL(l),
//     mapL((i) => map((x) => x[i], xs)),
//   );
// };
//
// export const transpose = pipe(transposeL, takeAll);

// 위 아래 성능 차이가 좀 있음
//
export const transpose = (matrix) => {
  const head = matrix[0];
  if (!head) return [];
  const inner_length = head.length;
  return go(matrix, zipWithIndexL, takeAll, (index_rows) =>
    reduce(
      (acc, [row_idx, row]) => {
        row.forEach((val, col_idx) => (acc[col_idx][row_idx] = val));
        return acc;
      },
      range(inner_length).map(() => []),
      index_rows,
    ),
  );
};
