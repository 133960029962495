import { each, go } from 'fxjs/es';

export const setStoresDeliveryCompanies = ({ shipping_cost, stores_delivery_companies }) => {
  go(
    stores_delivery_companies,
    each((stores_delivery_company) => {
      stores_delivery_company.shipping_price = shipping_cost.cost;
    }),
  );
};
