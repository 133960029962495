import { html } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { makeFooterHtml } from '../../Footer/S/tmpl.js';
import { setSnsStoreData } from '../../Store/S/fs.js';

import { MENU_CART, MENU_ORDER, MENU_WISH } from './constant.js';

const drawHeader = ({ domain_name, store, logo_img, menu_selected, counts, has_banner, name }) => html`
  <div class="store-header ${has_banner ? 'has_banner' : ''}">
    <div class="store-header-body">
      <h1 class="store-header-logo" data-has-logo="${!!logo_img}">
        <a href="/${T.lang}/${UtilS.escape(domain_name)}">
          ${logo_img
            ? html` <img class="don_lazy" alt="${UtilS.escape(name)} logo image" data-src="${logo_img}" /> `
            : html` <span class="store-name">${UtilS.escape(name)}</span> `}
        </a>
      </h1>
      <ul class="store-header-right_gnb right_gnb">
        ${makeStoreHeadSnsIconHtml(UtilS.escape(store.sns_url))}
        <li class="me not_sell_family ${menu_selected[MENU_ORDER]}">
          <a href="/${T.lang}/${UtilS.escape(domain_name)}/orders">
            <img
              src="//s3.marpple.co/files/u_1187077/2020/1/original/1418584832678626298d9c092188c549d1971adbe756234.svg"
              alt=""
            />
          </a>
        </li>
        <li
          class="wish not_sell_family ${menu_selected[MENU_WISH]}"
          ${counts.wish ? `data-count="${counts.wish}"` : ''}
        >
          <a href="/${T.lang}/${UtilS.escape(domain_name)}/wish">
            <img
              src="//s3.marpple.co/files/u_1187077/2020/1/original/1418583190490ac64cfa9b16666c7c634da629c766a5e76.svg"
            />
          </a>
        </li>
        <li
          class="cart not_sell_family ${menu_selected[MENU_CART]}"
          ${counts.cart ? `data-count="${counts.cart}"` : ''}
        >
          <a href="/${T.lang}/${UtilS.escape(domain_name)}/cart">
            <img
              class="icon_img"
              src="//s3.marpple.co/files/u_1187077/2020/1/original/1418485dfbd10bd115fdc1442a6d32d5ee37b5b91c72f5c.svg"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
`;

export const makeStoreLayoutHtml = async ({
  locals: {
    store,
    draw_header = true,
    login_user = {},
    menu_selected = {},
    has_banner,
    counts = { wish: 0, cart: 0 },
  },
  body,
}) => {
  const { domain_name, logo_img, name } = store;
  const header_data = { domain_name, store, logo_img, menu_selected, counts, has_banner, name };
  return legacyHtml`
    <div class="store">
      ${draw_header ? drawHeader(header_data) : ''}
      <div class="store-body">${body}</div>
      ${makeFooterHtml(store, login_user)}
    </div>
  `;
};

const makeStoreHeadSnsIconHtml = (url) => {
  const { type, head_ico_url, link } = setSnsStoreData(url);
  if (!head_ico_url || !head_ico_url.length) return '';

  return legacyHtml`
    <li class="sns">
      <a target="_blank" href="${link}" class="external-url"><img src="${head_ico_url}" alt="${type}" /></a>
    </li>
  `;
};
