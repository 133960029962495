import { html } from 'fxjs/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NewMakerConditionAgreeTextF } from '../../../ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';

export const fn = ({ tooltip }) => {
  /* 모바일 완료 누르고 나서 뜨는 툴팁 팝업 */
  return html`<div class="condition_tooltip_wrapper">
    <div class="condition_tooltip_header">
      <div class="condition_tooltip_header_title">${TT('maker::tooltip::mo_title')}</div>
    </div>
    <div class="condition_tooltip_body">
      <img src="${tooltip}" alt="툴팁" />
    </div>
    ${NewMakerConditionAgreeTextF.fn(null, true)}
    <div class="condition_tooltip_footer">
      <button class="condition_tooltip_btn editor">${TT('maker::tooltip::prev_editor_btn')}</button>
      <button class="condition_tooltip_btn complete disabled">${TT('maker::tooltip::complete_btn')}</button>
    </div>
  </div>`;
};

export const mo_tooltip = () => {
  if (!UtilF.isLegacyMobile()) return html``;

  const _default = html`
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49922 1.19995C4.74368 1.19995 1.69922 4.24441 1.69922 7.99995C1.69922 11.7555 4.74368 14.8 8.49922 14.8C12.2548 14.8 15.2992 11.7555 15.2992 7.99995C15.2992 4.24441 12.2548 1.19995 8.49922 1.19995Z"
        fill="white"
      />
      <path
        d="M9.04146 4.57617L8.95708 9.4043H8.02896L7.94458 4.57617H9.04146ZM7.83208 10.7637C7.8274 10.398 8.13208 10.098 8.49771 10.098C8.85865 10.098 9.15865 10.398 9.16333 10.7637C9.15865 11.1293 8.85865 11.4246 8.49771 11.4293C8.13208 11.4246 7.8274 11.1293 7.83208 10.7637Z"
        fill="#FF6B00"
      />
    </svg>
    <div class="condition_tooltip_text">${TT('maker::tooltip::mo_tooltip_text')}</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path d="M4 10.5L8.5 6L4 1.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  `;

  return html`<div class="condition_tooltip" style="display: none;">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      class="arrow"
    >
      <path d="M5.5 0L10.6962 8.25H0.303848L5.5 0Z" fill="#FF6B00" />
    </svg>
    ${G.mp.maker.condition_tooltip_mo_tmpl || _default}
  </div>`;
};
