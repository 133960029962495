// import { go, some } from 'fxjs';

export const shinhan_issue = {
  prjs: [
    // { projection_id: 1769700, user_id: 2787046 },
    // { projection_id: 1769857, user_id: 2787202 },
    // { projection_id: 1770050, user_id: 2787379 },
    // { projection_id: 1770101, user_id: 2787406 },
    // { projection_id: 1770194, user_id: 2787475 },
    // { projection_id: 1770294, user_id: 2787566 },
    // { projection_id: 1770429, user_id: 2787697 },
    // { projection_id: 1770579, user_id: 2787831 },
    // { projection_id: 1770704, user_id: 2787953 },
    // { projection_id: 1770773, user_id: 2787997 },
    // { projection_id: 1771030, user_id: 2788299 },
    // { projection_id: 1771270, user_id: 2788553 },
    // { projection_id: 1771331, user_id: 2788603 },
    // { projection_id: 1771696, user_id: 2788940 },
    // { projection_id: 1771760, user_id: 2789016 },
    // { projection_id: 1771811, user_id: 2789057 },
    // { projection_id: 1771898, user_id: 2789101 },
    // { projection_id: 1771986, user_id: 2789222 },
    // { projection_id: 1772009, user_id: 2789215 },
    // { projection_id: 1772057, user_id: 2789297 },
    // { projection_id: 1772243, user_id: 2739684 },
    // { projection_id: 1772433, user_id: 2789632 },
    // { projection_id: 1772475, user_id: 2789677 },
    // { projection_id: 1772549, user_id: 2789762 },
    // { projection_id: 1772559, user_id: 2789771 },
    // { projection_id: 1772629, user_id: 2789849 },
    // // { projection_id: 1772660, user_id: 2789871 },
    // { projection_id: 1772667, user_id: 2789853 },
    // { projection_id: 1772668, user_id: 2789886 },
    // { projection_id: 1772731, user_id: 2789947 },
    // { projection_id: 1772851, user_id: 2790061 },
    // { projection_id: 1773057, user_id: 2790266 },
  ],
  coupon: 'SHINHAN___0707',
};

// const all_prj_ids = [
//   1769700, 1769796, 1769806, 1769843, 1769857, 1770050, 1770101, 1770194, 1770294, 1770308, 1770343, 1770405,
//   1770429, 1770502, 1770523, 1770562, 1770579, 1770704, 1770728, 1770773, 1770792, 1770812, 1770844, 1771030,
//   1771047, 1771054, 1771105, 1771119, 1771128, 1771201, 1771217, 1771270, 1771325, 1771331, 1771351, 1771364,
//   1771401, 1771531, 1771696, 1771760, 1771773, 1771800, 1771811, 1771818, 1771898, 1771925, 1771986, 1772005,
//   1772009, 1772057, 1772184, 1772201, 1772227, 1772243, 1772433, 1772475, 1772508, 1772522, 1772549, 1772550,
//   1772559, 1772560, 1772625, 1772629, 1772644, 1772647, 1772660, 1772667, 1772668, 1772715, 1772731, 1772761,
//   1772851, 1772864, 1772903, 1773057, 1773128,
// ];
//
// const done_prj_ids = [
//   1771818, 1771925, 1773128, 1770792, 1772761, 1772522, 1772644, 1771105, 1770405, 1772864, 1771217, 1770523,
//   1772903, 1770812, 1769806, 1772508, 1772625, 1771800, 1771351, 1771773, 1772550, 1771054, 1772184, 1770562,
//   1771119, 1772560, 1771531, 1770343, 1770844, 1770502, 1771201, 1769843, 1771364, 1771047, 1769796, 1772227,
//   1772005, 1771325, 1771401, 1770728, 1772201, 1772715, 1770308, 1772647, 1771128, 1772660,
// ];

// const user_ids = [2789886, 2790061, 2787379, 2739684, 2789849, 2787997, 2789762, 2789853];
//
// const projection_ids = [1772243, 1770050, 1770773, 1772549, 1772629, 1772667, 1772668, 1772851];
//
// go(
//   shinhan_issue.prjs,
//   reject(({user_id})=>user_ids.includes(user_id)),
//   pluck('projection_id'),
//   console.log
// )
