/*
@pb_up_material =
  product_id,
  price,
  quantity,
  option_price,
  spo_item_id,
  spo_text_option_values,
 */
export const pbUpMaterial = (pb_up_material) => {
  if (!pb_up_material.product_id) throw new Error('no pb_up_material.product_id');
  if (Number.isNaN(parseFloat(pb_up_material.profit))) throw new Error('nan pb_up_material.profit');
  if (!pb_up_material.quantity) throw new Error('no or 0 pb_up_material.quantity');
  if (!pb_up_material.spo_item_id) throw new Error('no pb_up_material.spo_item_id');
};
