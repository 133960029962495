import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';

export const makePriceForCoupon = ({
  pod_products_price,
  shippings_price,
  mps_products_price,
  promotion_code,
}) => {
  if (promotion_code?.store_id) {
    /* 마플 배송 (pod, 입고) 상품 +  마플 배송 배송비 */
    return PriceS.add(mps_products_price, shippings_price);
  }
  /* 마플 배송 (pod) 상품 + pod 상품이 있는 경우에만 마플 배송 배송비 */
  return PriceS.add(pod_products_price, pod_products_price > 0 ? shippings_price : 0);
};
