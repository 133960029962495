export const CREW_BANK_FRAME = `@FRAME/Creator/BankAccount/crew_bank_account`;
export const CREW_BANK_PAGE = `@PAGE/Creator/BankAccount/crew_bank_account`;
export const CREW_BANK_TAB = `@TAB/Creator/BankAccount/crew_bank_account`;

export const BANK_TYPES = {
  KID: 'kid',
  FOREIGN: 'foreign',
  PERSON: 'person',
  COMPANY: 'company',
};

export const BANK = {
  FIELDS: {
    common: ['number', 'owner', 'bank', 'adjustment_agree'],
    [BANK_TYPES.KID]: [
      'parent_name',
      'parent_agree_url',
      'parent_birth',
      'phone',
      'registration_code',
      'term_agree',
    ],
    [BANK_TYPES.PERSON]: ['user_name', 'registration_code', 'term_agree', 'phone'],
    [BANK_TYPES.FOREIGN]: ['paper_url', 'user_name', 'nationality'],
    [BANK_TYPES.COMPANY]: [
      'company_name',
      'company_president',
      'business_license_number',
      'company_email',
      'company_address',
      'company_status',
      'company_type',
      'business_license_url',
    ],
  },
};

export const BANK_ACCOUNT_FIELD = {
  company_email: () => T('bank_account::ba64'),
  number: ({ type }) => (BANK_TYPES.FOREIGN == type ? T('bank_account::ba82') : T('bank_account::ba13')),
  business_license_number: () => T('bank_account::ba63'),
  registration_code: () => T('bank_account::ba30'),
};

export const BANK_TYPE_KR = {
  [BANK_TYPES.PERSON]: T('bank_account::ba5'),
  [BANK_TYPES.KID]: T('plus_admin::pa139'),
  [BANK_TYPES.COMPANY]: T('bank_account::ba6'),
  [BANK_TYPES.FOREIGN]: T('bank_account::ba7'),
};

export const PARENT_AGREE_DOC_URL =
  'https://s3.ap-northeast-2.amazonaws.com/s3.marpple.co/marpple_shop/home/%5B%E1%84%86%E1%85%A1%E1%84%91%E1%85%B3%E1%86%AF%E1%84%89%E1%85%A3%E1%86%B8%5D%E1%84%86%E1%85%B5%E1%84%89%E1%85%A5%E1%86%BC%E1%84%82%E1%85%A7%E1%86%AB%E1%84%8C%E1%85%A1%2B%E1%84%86%E1%85%A1%E1%84%91%E1%85%B3%E1%86%AF%E1%84%89%E1%85%A3%E1%86%B8%2B%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%84%8B%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B5%E1%84%87%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A5%2B%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A1%E1%86%AB%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%2B%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4%E1%84%89%E1%85%A5.pdf';
