import { each, find, go, head, sel } from 'fxjs/es';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { MShopStudioPbProductConstantS } from '../Constant/module/MShopStudioPbProductConstantS.js';

export const getProps = ({ stores_product, product_types, stores_delivery_companies }) => {
  const product = sel('_.product', stores_product);
  const tpl_fee = sel('_.tpl_fee', stores_product);
  const skus = sel('_.skus', stores_product);
  const hs_code = sel('_.hs_code', stores_product);
  const has_up = sel('_.has_up', product);
  const spo = sel('_.spo', product);
  const spo_text_options = sel('_.spo._.spo_text_options.length', product)
    ? sel('_.spo._.spo_text_options', product)
    : [{}];
  const product_type_id =
    sel('product_type_id', stores_product) ||
    go(
      product_types,
      find((product_type) => product_type.id == MShopStudioPbProductConstantS.PB_PRODUCT_TYPES.always),
      sel('id'),
    );
  const stores_delivery_company_id = sel(
    '_.stores_delivery_companies_product.stores_delivery_company_id',
    product,
  );
  const selected_stores_delivery_company =
    stores_delivery_companies.find(({ id }) => stores_delivery_company_id == id) ||
    head(stores_delivery_companies);

  const spo_options = sel('_.spo._.spo_options', product);
  const spo_items = sel('_.spo._.spo_items', product);
  const spo_producing_period = sel('_.spo._.spo_producing_period', product);
  const show_spo_producing_period = go(void 0, () => {
    const product_type = find((pt) => {
      return pt.id == product_type_id;
    }, product_types);

    return product_type?.show_spo_producing_period;
  });
  const product_story = sel('_.product_story', product);
  const total_spo_item_stock = go(void 0, () => {
    if (spo?.stock_type != SpoSpoConstantS.pSpoStockType.TOTAL_STOCK) return '';
    return sel('_.spo_item_stock', spo);
  });

  go(
    spo_items,
    each((item) => {
      item.sku = item?._?.sku;
    }),
  );

  return {
    total_spo_item_stock,
    product_story,
    product,
    tpl_fee,
    skus,
    hs_code,
    has_up,
    spo_text_options,
    selected_stores_delivery_company,
    spo_options,
    spo_items,
    spo_producing_period,
    show_spo_producing_period,
    spo,
    product_type_id,
  };
};
