import {
  CREATOR_RECEIPT_FRAME,
  CREATOR_RECEIPT_PAGE,
  CREATOR_RECEIPT_TAB,
} from '../../../../../modules/Creator/Receipt/S/constant.js';
import { makeEnterMarppleShop2Event } from '../../../../../modules/Marpple/PopUp/F/enter_marpple_shop2.js';
import {
  MPAY_RECEIPT_FRAME,
  MPAY_RECEIPT_PAGE,
  MPAY_RECEIPT_TAB,
} from '../../../../../modules/MPay/Receipt/S/constant.js';

const [RECEIPT_FRAME, RECEIPT_PAGE, RECEIPT_TAB] =
  collabo_type == 'creator'
    ? [CREATOR_RECEIPT_FRAME, CREATOR_RECEIPT_PAGE, CREATOR_RECEIPT_TAB]
    : [MPAY_RECEIPT_FRAME, MPAY_RECEIPT_PAGE, MPAY_RECEIPT_TAB];

$.frame.defn_frame({
  frame_name: RECEIPT_FRAME,
  page_name: RECEIPT_PAGE,
  appended: function () {
    // 사용안하는 adScriptReceiptCreator 함수 삭제
  },
});

$.frame.defn_page({
  page_name: RECEIPT_PAGE,
  tabs: [
    {
      tab_name: RECEIPT_TAB,
      appended: makeEnterMarppleShop2Event,
    },
  ],
});
