import { isValid } from 'date-fns';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { go } from 'fxjs/es';

!(function () {
  $.frame.defn_frame({
    frame_name: 'mp.my_info_frame',
    el_class: 'mp_my_info_frame',
    page_name: 'mp.my_info_page',
  });

  $.frame.defn_page({
    page_name: 'mp.my_info_page',
    tabs: [
      {
        title: T('Personal Information Modify'),
        tab_name: 'mp.my_info_tab',
        appended: __(
          $.on2('click', '.btn_modify', function (e) {
            const cont = $.find1(e.delegateTarget, '.my_info_modify');
            const name_el = $.find1(cont, 'input[name="name"]');
            const nick_name_el = $.find1(cont, 'input[name="nick_name"]');
            const mobile_el = $.find1(cont, 'input[name="mobile"]');
            const birthday_el = $.find1(cont, 'input[name="birthday"]');
            const checked_gender_el = $.find1(cont, 'input[name="gender"]:checked');
            const checked_sms_el = $.find1(cont, 'input[name="agree_sms"]:checked');
            const checked_email_el = $.find1(cont, 'input[name="agree_email"]:checked');

            if (UtilS.is_empty($.val(name_el))) {
              return _p.go(T('Name is empty'), $.alert, function () {
                name_el.focus();
              });
            }

            if (UtilS.is_special_str($.val(name_el))) {
              return _p.go(T('my_info::Only Numbers and Text'), $.alert, function () {
                name_el.focus();
              });
            }

            if (nick_name_el && UtilS.is_special_str($.val(nick_name_el))) {
              return _p.go(T('my_info::Only Numbers and Text'), $.alert, function () {
                nick_name_el.focus();
              });
            }

            // birthday 8자 아님
            if ($.val(birthday_el).length > 0 && $.val(birthday_el).length != 8) {
              return _p.go(T('my_info::ex) 19940203'), $.alert, function () {
                birthday_el.focus();
              });
            }

            // birthday 숫자 외의 문자 포함
            if ($.val(birthday_el).length > 0 && !UtilS.is_number($.val(birthday_el))) {
              return _p.go(T('my_info::Only Numbers'), $.alert, function () {
                birthday_el.focus();
              });
            }

            // birthday 유효하지 않은 날짜
            if (
              $.val(birthday_el).length > 0 &&
              !isValid(UtilS.parseStringifyDate_yyyyMMdd($.val(birthday_el)))
            ) {
              return _p.go(T('my_info::check_birthday'), $.alert, function () {
                birthday_el.focus();
              });
            }

            if (T.lang == 'kr' && $.val(mobile_el).length > 0 && !UtilS.is_number($.val(mobile_el))) {
              return _p.go(T('my_info::Only Numbers'), $.alert, function () {
                mobile_el.focus();
              });
            }

            return go(
              $.post(
                '/@api/mp/my_info',
                _p.extend(
                  {
                    user_id: box.sel('is_user->id'),
                    name: $.val(name_el),
                    birthday: $.val(birthday_el) || '',
                    gender: checked_gender_el && $.val(checked_gender_el),
                    agree_sms: $.val(checked_sms_el) == 'true',
                    agree_email: $.val(checked_email_el) == 'true',
                  },
                  T.lang == 'kr' &&
                    mobile_el && {
                      mobile: $.val(mobile_el) || '',
                    },
                  nick_name_el && {
                    nick_name: $.val(nick_name_el) || '',
                  },
                ),
              ),
              function (data) {
                if (!data.result) return $.alert(T('my_info::It has not changed. please try again.'));
                // $.alert(T('my_info::Changed'));

                return go(
                  T('my_info::Changed'),
                  $.alert,
                  () => {
                    if (T.lang != 'kr') return;
                    const agree_sms = $.find1(e.delegateTarget, '.control[agree_sms]');
                    if ($.attr(agree_sms, 'agree_sms') == data.user.agree_sms) return;
                    return go(
                      `${moment().format('YYYY-MM-DD')}에 ${
                        G.collabo_type != 'line' ? '마플' : '라인프렌즈 크리에이터'
                      } 문자 소식 받기를 ${data.user.agree_sms ? '동의' : '거부'}하셨습니다.`,
                      $.alert,
                      () => {
                        $.attr(agree_sms, 'agree_sms', data.user.agree_sms);
                      },
                    );
                  },
                  () => {
                    if (T.lang != 'kr') return;
                    const agree_email = $.find1(e.delegateTarget, '.control[agree_email]');
                    if ($.attr(agree_email, 'agree_email') == data.user.agree_email) return;
                    return go(
                      `${moment().format('YYYY-MM-DD')}에 ${
                        G.collabo_type != 'line' ? '마플' : '라인프렌즈 크리에이터'
                      } 이메일 소식 받기를 ${data.user.agree_email ? '동의' : '거부'}하셨습니다.`,
                      $.alert,
                      () => {
                        $.attr(agree_email, 'agree_email', data.user.agree_email);
                      },
                    );
                  },
                  () => {
                    box.set('is_user->name', data.user.name);
                  },
                );
              },
            );
          }),
          $.on2('click', '.btn_withdrawal', function () {
            return _p.go($.get('/@api/user/is_user'), function (data) {
              if (!data.result)
                return $.alert(T('my_info::There is no user information. Please log in again after login.'));

              $.frame.open(
                {
                  frame_name: 'mp.user_withdrawal_frame',
                  page_name: 'mp.user_withdrawal_page',
                },
                {
                  page_name: 'mp.user_withdrawal_page',
                  tabs: [
                    {
                      tab_name: 'mp.user_withdrawal_tab',
                      data_func: function () {
                        return data.user;
                      },
                    },
                  ],
                },
              );
            });
          }),
          $.on2('click', '.btn_change_email', function (e) {
            return _p.go($.get('/@api/get/user'), function (data) {
              if (!data.result)
                return $.alert(T('my_info::There is no user information. Please log in again after login.'));

              $.frame.open(
                {
                  frame_name: 'mp.checkout.change_email',
                  closed: function (X, data) {
                    if (!data || !data.result) return;
                    window.location.reload();
                  },
                },
                {
                  tabs: [
                    {
                      page_name: 'mp.checkout.change_email',
                      data_func: function () {
                        return data.user;
                      },
                    },
                  ],
                },
              );
            });
          }),
          $.on2('click', '.btn_change_pw', function (e) {
            const input_email = box.sel('is_user->email');

            if (!UtilS.is_valid_email(input_email))
              return $.alert(T('my_info::This email address is not available.'));

            const lo = G.$location();
            return _p.go(
              $.post('/@api/mp/reset_password', {
                email: G.to_lower(input_email),
                url: lo.query.url,
              }),
              function (data) {
                if (!data.result) return $.alert(T('Reset password failed'));
                return _p.go(T('Check your email for a link'), $.alert, function () {
                  return $.frame.close();
                });
              },
            );
          }),
        ),
      },
    ],
  });
})();
