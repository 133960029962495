import { html } from 'fxjs/es';
import { getVolumetricWeightBySku } from '../../../../../Shipping/S/Function/shipping.js';
import { tooltipIcon2El } from '../../../../../Util/S/Tmpl/tootip.js';

const formatNumber = (num) => {
  num = parseFloat(num);
  if (num % 1 === 0) {
    return num.toString(); // 정수인 경우 소수점 이하 표시하지 않음
  } else {
    let formatted = num.toString(); // 문자열로 변환
    formatted = formatted.replace(/(\.[1-9]*)0+$/, '$1'); // 소수점 이하가 0이 아닌 경우만 남기고 0 제거
    return formatted;
  }
};
export const sizeHtml = ({ option_names, sku }) => {
  const { width, height, length, weight } = sku;
  const volumetric_weight = width && height && length ? getVolumetricWeightBySku({ sku }) : 0;
  const disabled_save = !(width && height && length && weight);

  return html` <div class="pb-product-size">
    <div class="pb-product-size__title">
      <h4>상품 체적정보 입력</h4>
      <span class="tooltip-icon2 tooltip-icon2--question">${tooltipIcon2El}</span>
      <div class="tooltip-container">
        <div class="editor-tooltip-box">
          <div>
            상품 최종 패키지의 <span class="bold">가로 x 세로 x 높이 / 6000 = 부피 무게 계산</span><br />
            배송비 측정은 부피 무게와 실제 중량 중 더 높은 값으로 결정됩니다.
          </div>
        </div>
      </div>
      <div class="pb-product-size__options">
        ${option_names.length > 0 ? `옵션 ${option_names.join(' / ')}` : ''}
      </div>
    </div>

    <div class="pb-product-size__wrapper">
      <form class="pb-product-size__form">
        <div class="pb-product-size__inputs">
          <div class="pb-product-size__form_control">
            <label for="sku_size_width">가로</label>
            <input
              type="number"
              min="0"
              id="sku_size_width"
              placeholder="cm"
              value="${formatNumber(width)}"
            />
          </div>
          <div class="pb-product-size__form_control">
            <label for="sku_size_length">세로</label>
            <input
              type="number"
              min="0"
              id="sku_size_length"
              placeholder="cm"
              value="${formatNumber(length)}"
            />
          </div>
          <div class="pb-product-size__form_control">
            <label for="sku_size_height">높이</label>
            <input
              type="number"
              min="0"
              id="sku_size_height"
              placeholder="cm"
              value="${formatNumber(height)}"
            />
          </div>
          <div class="pb-product-size__form_control">
            <label for="sku_size_weight">중량</label>
            <input
              type="number"
              min="0"
              id="sku_size_weight"
              placeholder="g"
              value="${formatNumber(weight)}"
            />
          </div>
        </div>

        <div class="pb-product-size__footer">
          <div class="pb-product-size__volume">
            <label>부피 무게 계산</label>
            <span class="pb-product-size__volume-text">${volumetric_weight || 0} g</span>
          </div>
          <div class="pb-product-size__buttons">
            <button type="button" class="cancel">취소</button>
            <button type="button" class="save primary" ${disabled_save ? 'disabled' : ''}>저장</button>
          </div>
        </div>
      </form>
    </div>
  </div>`;
};
