import { $addClass, $find, $attr, $closest, $el, $findAll, $removeClass, $toggleClass } from 'fxdom/es';
import { go, map } from 'fxjs/es';
import { getEmbedHtml } from '../../../S/util.js';
import { addEditMenu, removeEditMenus } from '../../../F/medium-editor/custom-insert-plugin/marpple.js';
import { makeFragment } from '../../../../../Util/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { CreatorEditorMarppleEmbedUrlMuiF } from '../../Url/F/Mui/module/CreatorEditorMarppleEmbedUrlMuiF.js';

export const preventProductLinkHandler = (e) => {
  e.originalEvent.preventDefault();
};

export const addEmbedProducts = async (addFn) => {
  MuiF.openFrame(CreatorEditorMarppleEmbedUrlMuiF.frame, (frame) => {
    frame.removed = async (_, urls) => {
      if (!urls || !urls.length) return;
      await go(
        getEmbedHtml(urls.join(','), 'marpple', { only_content: true }),
        $el,
        $findAll('.app-product-item'),
        map($removeClass('app-product-item--today--one')),
        map(addEditMenu),
        makeFragment,
        addFn,
      );
      G.don_lazy();
    };
  });
};

export const saveMarppleEmbed = (marpple_embed_container_body$) => () => {
  const urls = go(
    $findAll('.app-product-item__top-info > a', marpple_embed_container_body$),
    map($attr('href')),
    map((href) => `https://marpple.shop${href}`),
  );

  const count = urls.length;
  removeEditMenus(marpple_embed_container_body$);

  if (count == 1)
    $addClass('app-product-item--today--one', $find('.app-product-item', marpple_embed_container_body$));
  MuiF.removeFrame({ el$: marpple_embed_container_body$.children[0], urls });
};

export const openOptionHandler = (e) => {
  const embed_option$ = $closest('.embed_option', e.target);
  $toggleClass('opened', embed_option$);
  embed_option$.focus();
};
