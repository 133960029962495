export const MODULE = {
  KOREA_PORT_ONE: 'KOREA_PORT_ONE',
  GLOBAL_PORT_ONE: 'GLOBAL_PORT_ONE',
};

export const NAME = {
  payment_request: 'payment_request',
  cancel_request: 'cancel_request',
  cancel_failed: 'cancel_failed',
  api_result: 'api_result',
  /* api 요청 결과 */
  process_result: 'process_result',
  /* 처리 로직 결과 */
};

export const SERVER = {
  webhook: 'webhook',
  service: 'service',
  scheduler: 'scheduler',
};
