import querystring from 'querystring';
import { CreatorCriteoF } from './module/CreatorCriteoF.js';

export const applySeller = () => {
  if (!window.criteo_q || T.lang != 'kr') return;

  const query = querystring.parse(location.search.slice(1));
  const { c_id } = query;

  window.criteo_q.push(
    ...CreatorCriteoF.eventSet({
      event: 'trackTransaction',
      id: c_id,
      item: [{ id: '1', price: 1, quantity: 1 }],
    }),
  );
};
