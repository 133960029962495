import { convertUpsToStoreOrders } from './convertUpsToStoreOrders.js';
import { makeTotalPriceInfo } from './makeTotalPriceInfo.js';

export const makeTotalPriceInfoWithUps = (
  { ups, _en = G._en },
  { mp_plus_price_info, is_far = false, selected_country_shipping_cost } = { is_far: false },
) => {
  const store_orders = convertUpsToStoreOrders(ups, { is_far, selected_country_shipping_cost });
  return makeTotalPriceInfo({ store_orders, mp_plus_price_info });
};
