import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';

/**
 *
 * @param orderer_name {HTMLElement} 주문자 이름
 * @param min_length {number} name 최소 이름 길이
 * @param max_length {number} name 최대 이름 길이
 * @return {boolean} name 길이가 length 보다 짧으면 true, 짧으면 false
 */
export const isOrdererNameLengthInvalid = ({ orderer_name, min_length, max_length }) => {
  if (orderer_name) {
    const name = UtilStringS.removeSpaces(orderer_name);
    if (name.length < min_length || name.length >= max_length) {
      return true;
    }
  }
  return false;
};
