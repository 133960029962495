import MediumEditor from '@marpple/dinosaurs/front/medium-editor.js';
import { $addClass, $el, $on, $removeClass } from 'fxdom/es';
import { curry, each } from 'fxjs/es';
import { getAllSelectedRootEls } from '../../../../Feed/F/util.js';
import { createActionStack } from '../features/bug_tracker.js';

export const addAlignClass = curry((cl, node) => {
  if (node.nodeType == 3) node = node.parentNode;
  $removeClass('align-left', $removeClass('align-center', node));
  $addClass(cl, node);
});

export const getMediumAlignCenter = () =>
  MediumEditor.Extension.extend({
    name: 'align-center',
    init: function () {
      const button = $el(`
      <button class="medium-editor-action" title="AlignCenter">
        <i class="fa fa-align-center"></i>
      </button>
    `);

      this.button = button;

      $on('click', (e) => {
        each(addAlignClass('align-center'), getAllSelectedRootEls());

        this.base.checkContentChanged();
        createActionStack('align center', e);
      })(button);
    },
    getButton: function () {
      return this.button;
    },
  });

export const getMediumAlignLeft = () =>
  MediumEditor.Extension.extend({
    name: 'align-left',
    init: function () {
      const button = $el(`
      <button class="medium-editor-action" title="AlignLeft">
        <i class="fa fa-align-left"></i>
      </button>
    `);

      this.button = button;

      $on('click', (e) => {
        each(addAlignClass('align-left'), getAllSelectedRootEls());
        this.base.checkContentChanged();
        createActionStack('align left', e);
      })(button);
    },
    getButton: function () {
      return this.button;
    },
  });

export const getMediumAlignRight = () =>
  MediumEditor.Extension.extend({
    name: 'align-right',
    init: function () {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = '<i class="fa fa-align-right"></i>';
      this.button.title = 'AlignCenter';
    },
    getButton: function () {
      return this.button;
    },
  });
