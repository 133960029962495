import { find, go, values } from 'fxjs/es';

export const error = {
  oxj001: {
    value: 'oxj001',
    text: '선택된 수량 만큼의 상품 재고가 현재 없습니다.',
  },
  oxj002: {
    value: 'oxj002',
    text: '해당 옵션이 수정됐습니다. 다시 옵션을 선택해주세요.',
  },
  oxj003: {
    value: 'oxj003',
    text: '본인이 담은 상품이 아닙니다.',
  },
  oxj004: {
    value: 'oxj004',
    text: '선택한 수량만큼 재고가 없습니다.',
  },
  oxj005: {
    value: 'oxj005',
    text: '해당 상품의 재고가 소진되었습니다.',
  },
  oxb001: {
    value: 'oxb001',
    text: '스토어 주인이 아닙니다.',
  },
  oxj006: {
    value: 'oxj006',
    text: '취소 요청할 권한이 없습니다.',
  },
  oxj007: {
    value: 'oxj007',
    text: '모든 옵션을 선택해주세요.',
  },
  oxj008: {
    value: 'oxj008',
    text: '문제가 발생했습니다.',
  },
  oxj009: {
    value: 'oxj009',
    text: '현 옵션 제품은 품절 입니다.',
  },
  refund001: {
    value: 'refund010',
    text: '환불 대상의 수량이 환불하려는 수량보다 적게 있습니다.',
  },
  refund002: {
    value: 'refund002',
    text: '환불 요청 할 수량이 없습니다.',
  },
  cancel001: {
    value: 'cancel001',
    text: '취소 요청 할 수량이 없습니다.',
  },
  cancel002: {
    value: 'cancel002',
    text: '취소하면 무료 배송비 조건에 해당되지 않아 배송비가 발생합니다.<br>그래도 취소 하시겠습니까?',
  },
  cancel003: {
    value: 'cancel003',
    text: '해당 제품을 취소하려면 전체 취소만 가능합니다.<br>전체 취소를 하시겠습니까?',
  },
  cancel004: {
    value: 'cancel004',
    text: '취소하면 무료 배송비 조건에 해당되지 않아 배송비가 발생하여 환불 금액이 0원 입니다.<br>그래도 취소 하시겠습니까?',
  },
  cancel005: {
    value: 'cancel005',
    text: '가상계좌는 전체취소만 가능합니다.<br>전체 취소 하시겠습니까?',
  },
  createCustomerOrder001: {
    value: 'createCustomerOrder001',
    text: '수정된 상품이 있습니다.<br>해당 상품의 옵션 확인 후 다시 주문해주세요.',
  },
  createCustomerOrder002: {
    value: 'createCustomerOrder002',
    text: '가격이 변경된 상품이 있습니다.<br>새로고침 됩니다.',
  },
  createCustomerOrder003: {
    value: 'createCustomerOrder003',
    text: '수량 정보가 잘못된 상품이 있습니다.<br>새로고침 됩니다.',
  },
};
export const findError = (err_value) =>
  go(
    error,
    values,
    find((err) => err.value === err_value),
  );
