import { html } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { basicCanvasThumbnail } from './basicCanvasThumbnail.js';
import { PriceS } from '../../../../../../Price/S/Function/module/PriceS.js';

export function cchCartStyleUpItemMain(up_item_main_html_info) {
  const { to_url, post_message, thumbnail_url, product_face, name, second_name, discounted_price } =
    up_item_main_html_info;
  return html`
    <div class="cch-cart-style-up-item-main">
      <div class="cch-cart-style-up-item-main__thumbnail">
        <a href="${to_url}" data-post-message="${post_message}">
          ${thumbnail_url
            ? html` <img src="${UtilS.escape(thumbnail_url)}" alt="" /> `
            : basicCanvasThumbnail(product_face)}
        </a>
      </div>
      <div class="cch-cart-style-up-item-main__info">
        <div class="cch-cart-style-up-item-main__info__name">
          <a href="${to_url}" data-post-message="${post_message}"> ${UtilS.escape(name)}</a>
        </div>
        <div class="cch-cart-style-up-item-main__info__second-name">
          <a href="${to_url}" data-post-message="${post_message}">${UtilS.escape(second_name)}</a>
        </div>
        <div class="cch-cart-style-up-item-main__info__price mp_currency">
          <a href="${to_url}" data-post-message="${post_message}">${PriceS.pricify(discounted_price)}</a>
        </div>
      </div>
    </div>
  `;
}
