import { html } from 'fxjs/es';

export const fn = ({
  src,
  up_id,
  store_id,
  dtg_info = { is_new_dtg: false, is_need_update_for_dtg: true },
}) => html`
  <div class="maker_guide_body">
    <div class="img">
      <img src="${src || ''}" alt="" />
    </div>
    <div class="btns">
      <button
        class="btn_down_agree"
        id="${up_id}"
        store_id="${store_id}"
        is_new_dtg="${dtg_info?.is_new_dtg}"
        is_need_update_for_dtg="${dtg_info?.is_need_update_for_dtg}"
      >
        위 내용을 확인하였으며 동의합니다.
      </button>
    </div>
  </div>
`;
