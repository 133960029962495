import { go, html, strMap, filter } from 'fxjs/es';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilS } from '../../../../Util/S/Function/module/MShopUtilS.js';
import { ShippingCostS } from '../../../../../Shipping/Cost/S/Function/module/ShippingCostS.js';

export const pUpItemListPriceHtml = (marpple_shop_total_price_info) => {
  let {
    quantity,
    products_price,
    shippings_price,
    total_price,
    mps_shippings_price,
    mps_additional_shippings_price,
    seller_shippings_price,
    seller_additional_shippings_price,
    coupon_price,
    point_price,
  } = marpple_shop_total_price_info;

  const { delivery_fee } = ShippingCostS.parseShippingCost(shippings_price);
  shippings_price = delivery_fee;

  const is_oversea = T.lang !== 'kr';
  const is_hide_shipping_cost = is_oversea && (shippings_price == null || shippings_price === 0);

  return html`
    <div class="up-item-list-price-info">
      <div class="up-item-list-price-info__1 pc">${T('mshop_cart_and_checkout::주문정보')}</div>
      <div class="up-item-list-price-info__2">
        <div class="up-item-list-price-info__2__item">
          <div class="up-item-list-price-info__2__label">${T('mshop_cart_and_checkout::총 수량')}</div>
          <div class="up-item-list-price-info__2__content">
            ${quantity} ${T('mshop_cart_and_checkout::개')}
          </div>
        </div>
        ${go(
          [
            { name: T('mshop_cart_and_checkout::총 상품금액'), price: products_price },
            {
              name: T('mshop_cart_and_checkout::총 배송비'),
              price: is_hide_shipping_cost ? -1 : shippings_price,
            },
          ],
          strMap(
            ({ name, price }) => html`
              <div class="up-item-list-price-info__2__item">
                <div class="up-item-list-price-info__2__label">${name}</div>
                <div class="up-item-list-price-info__2__content">
                  ${price === -1
                    ? '-'
                    : price === 0
                    ? T('mshop_cart_and_checkout::무료배송')
                    : MShopUtilS.moneySign(PriceS.pricify(price))}
                </div>
              </div>
            `,
          ),
        )}
        ${go(
          [
            { name: T('mshop_cart_and_checkout::마플샵배송'), price: mps_shippings_price || 0 },
            {
              name: T('mshop_cart_and_checkout::마플샵배송 도서산간'),
              price: mps_additional_shippings_price || 0,
            },
            { name: T('mshop_cart_and_checkout::셀러배송'), price: seller_shippings_price || 0 },
            {
              name: T('mshop_cart_and_checkout::셀러배송 도서산간'),
              price: seller_additional_shippings_price || 0,
            },
          ],
          filter(({ price }) => price > 0),
          strMap(
            ({ name, price }) => html`
              <div class="up-item-list-price-info__2__item-shipping">
                <div class="up-item-list-price-info__2__item-shipping__label">
                  <img
                    src="//s3.marpple.co/files/u_193535/2023/6/original/92423a40058342e68a7356673d5fc3fbb82f5eb21.png"
                    alt=""
                  />
                  <span>${UtilS.escape(name)}</span>
                </div>
                <div class="up-item-list-price-info__2__item-shipping__content">
                  ${MShopUtilS.moneySign(PriceS.pricify(price))}
                </div>
              </div>
            `,
          ),
        )}
        ${go(
          [
            { name: T('mshop_cart_and_checkout::쿠폰 할인'), price: coupon_price || 0 },
            { name: T('mshop_cart_and_checkout::포인트 할인'), price: point_price || 0 },
          ],
          filter(({ price }) => price < 0),
          strMap(
            ({ name, price }) => html`
              <div class="up-item-list-price-info__2__item">
                <div class="up-item-list-price-info__2__label">${UtilS.escape(name)}</div>
                <div class="up-item-list-price-info__2__content">${PriceS.pricify(price)}</div>
              </div>
            `,
          ),
        )}
      </div>
      <div class="up-item-list-price-info__3">
        <div class="up-item-list-price-info__3__label">${T('mshop_cart_and_checkout::총 주문 금액')}</div>
        <div class="up-item-list-price-info__3__content">
          ${MShopUtilS.moneySign(PriceS.pricify(total_price))}
          ${is_hide_shipping_cost
            ? html`<span class="delivery_fee_adder">${T('mshop_cart_and_checkout::배송비추가')}</span>`
            : ''}
        </div>
      </div>
      <div class="up-item-list-price-info__3 delivery-fee-info">
        <div class="up-item-list-price-info__3__label gray light">${TT('cart::cart_29')}</div>
      </div>
    </div>
  `;
};
