import axios from 'axios';
import { $find } from 'fxdom/es';
import { each, go, html, object } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreCellF } from '../../../Core/Cell/F/Function/module/OMPCoreCellF.js';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPReviewConstantS } from '../../S/Constant/module/OMPReviewConstantS.js';

export const reviewInfi = (tab_el) => {
  const reviews = $find('.omp-review__contents', tab_el);

  if (reviews) {
    return MuiF.tabInfinite(reviews, {
      limit: OMPReviewConstantS.REVIEW_LIST_LIMIT,
      template: (review) => {
        const reviews_wrapper = $find('.omp-review__contents-wrapper', tab_el);
        const type = reviews_wrapper.dataset.type;
        return html`
          <div class="omp-review__content-wrapper infi_item">
            ${((review) => {
              if (OMPCoreUtilF.isMobile() && type === 'is_all') {
                return OMPCoreCellTmplS.verticalReviewTmpl(review);
              }
              if (OMPCoreUtilF.isMobile() && type === 'is_photo') {
                return OMPCoreCellTmplS.verticalReviewMoTmpl(review);
              }
              if (type === 'is_all') {
                return OMPCoreCellTmplS.horizontalReviewTmpl(review);
              }
              if (type === 'is_photo') {
                return OMPCoreCellTmplS.verticalReviewTmpl(review);
              }
            })(review)}
          </div>
        `;
      },
      appended: (reviews$) => {
        go(reviews$, each(OMPCoreCellF.reviewAppendReadMore));
      },
      data_func: (offset) => {
        const reviews_wrapper = $find('.omp-review__contents-wrapper', tab_el);
        const type = reviews_wrapper.dataset.type;
        const query = new URLSearchParams(location.search);
        const query_obj = object(query.entries());
        return go(
          axios.get(`/${TT.lang}/@api/review/list`, {
            params: { ...box.sel('review_list->query'), ...query_obj, is_all: type === 'is_all', offset },
          }),
          ({ data: reviews }) => {
            return reviews;
          },
        );
      },
    });
  }
};
