import { html } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
export const cchStoreLogo = ({ post_message, redirect_url, store_name }) => {
  return html`
    <a class="cch-store-logo" data-post-message="${post_message}" href="${redirect_url}">
      <img
        src="//s3.marpple.co/files/u_1504988/2024/2/original/a38de5aa23247666e079045acec2bf81e0c457eb1.svg"
        alt=""
      />
      <span>${UtilS.escape(store_name)}</span></a
    >
  `;
};
