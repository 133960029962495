import format from 'date-fns/format/index.js';
import { strMap } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { messages } from '../../../Setting/S/messages_id.js';
import { addEmptyFeed } from '../util.js';
import { makeFeedCardHtml } from './tmpl.js';

export const makeFeedListSettingBodyHtml = ({ feeds, store_name }) => legacyHtml`
  <div class="header">
    <h2>${T(messages.menus.seller_tip)}</h2>
  </div>
  <div class="page_body">
    <div class="feed_container">
      ${strMap(
        (feed) =>
          makeFeedCardHtml(feed, {
            link: `/${T.lang}/${UtilS.escape(store_name)}/settings/seller_tip/${feed.id}`,
          }),
        feeds,
      )}
      ${addEmptyFeed(3, feeds)}
    </div>
  </div>
`;

export const makeFeedDetailSettingBodyHtml = ({ feed }) => legacyHtml`
  <div class="header">
    <h2>${T(messages.menus.seller_tip)}</h2>
  </div>
  <div class="container">
    <div class="tip_header">
      <p class="title">${UtilS.escape(feed.title)}</p>
      <p class="date">${format(feed.public_at, 'MMM dd, yyyy')}</p>
    </div>
    <div class="feed_content editor">${feed.content}</div>
  </div>
`;
