import {
  $qs,
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $on,
  $replaceWith,
  $remove,
  $setCss,
  $removeAttr,
  $setAttr,
  $attr,
} from 'fxdom/es';
import { juxt, go } from 'fxjs/es';
import { NewMakerComponentsImageDropBoxF } from './module/NewMakerComponentsImageDropBoxF.js';

export const events = (selector_wrap, target_element, selectFunc) => {
  go(
    selector_wrap,
    $delegate('click', '.paper_option', async (event) => {
      const { currentTarget, delegateTarget } = event;
      event.originalEvent.stopImmediatePropagation();
      const selected_bp_option_id = currentTarget.dataset.id;
      const [options, paper_selector_head] = go(
        currentTarget,
        $closest('.maker-image-selector'),
        juxt($data, $find('.maker-image-selector__head')),
      );

      $replaceWith(
        $el(NewMakerComponentsImageDropBoxF.imageSelectorHead({ selected_bp_option_id, options })),
        paper_selector_head,
      );
      $remove(delegateTarget);
      $.body_fixed(false);
      return selectFunc(selected_bp_option_id);
    }),
    $delegate('click', '.maker-image-selector__head', async (event) => {
      const { delegateTarget } = event;
      event.originalEvent.stopImmediatePropagation();
      $remove(delegateTarget);
      $.body_fixed(false);
    }),
  );

  $on('click', ({ currentTarget }) => {
    $remove(currentTarget);
    $.body_fixed(false);
  })(selector_wrap);

  const id = go(target_element, $closest('.bp_option_group'), $attr('data-id'));

  $on('resize', () => {
    const wrapper = $qs('.bp_option_groups');
    const { left, width } = wrapper.getBoundingClientRect();
    const image_selector = $qs('.maker-image-selector');
    const column_length = image_selector?.dataset?.column_length;
    if (!column_length) return;
    const target = $qs(`.bp_option_group[data-id="${id}"] select`).getBoundingClientRect();
    go(
      image_selector,
      $setCss({
        left,
        width,
        top: target.top,
      }),
    );

    if (width <= '260') {
      go(image_selector, $find('.maker-image-selector__options'), $removeAttr('style'));
    } else {
      go(
        image_selector,
        $find('.maker-image-selector__options'),
        $setAttr({ style: `grid-template-columns : repeat(${column_length}, 1fr)` }),
      );
    }
  })(window);
};
