import { go, html, strMap } from 'fxjs/es';
import { MShopAppUpItemTmplS } from '../../../UpItem/S/Tmpl/module/MShopAppUpItemTmplS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { MShopAppComponentsItemTmplS } from '../../../Components/Item/S/Tmpl/module/MShopAppComponentsItemTmplS.js';
import { tooltipIcon2El } from '../../../../../Util/S/Tmpl/tootip.js';
import { MShopUtilS } from '../../../../Util/S/Function/module/MShopUtilS.js';

export const deliveryTypeGroupedItem = (delivery_type_grouped_item) => {
  const { delivery_type_id, delivery_type_grouped_pre_shipping_orders } = delivery_type_grouped_item;
  const is_oversea = T.lang !== 'kr';
  return html`
    <div class="delivery-type-grouped-item" data-delivery_type_id="${delivery_type_id}">
      <div class="delivery-type-grouped-item__1">
        ${MShopAppComponentsItemTmplS.cchDeliveryTypeLogo(delivery_type_id)}
      </div>
      <div class="delivery-type-grouped-item__2">
        ${go(
          delivery_type_grouped_pre_shipping_orders,
          strMap(
            (delivery_type_grouped_pre_shipping_order) => html`
              <div class="delivery-type-grouped-item__2__item">
                <div
                  class="pre-shipping-order-item"
                  data-delivery_type_id="${delivery_type_grouped_pre_shipping_order.delivery_type_id}"
                  data-has_condition="${!!delivery_type_grouped_pre_shipping_order.free_shipping_min_price}"
                  data-free_shipping="${delivery_type_grouped_pre_shipping_order.is_free_shipping}"
                  data-is_separate_shipping="${delivery_type_grouped_pre_shipping_order.is_separate_shipping}"
                  data-has_additional_shipping_price="${delivery_type_grouped_pre_shipping_order.additional_shipping_price >
                  0}"
                >
                  <div class="pre-shipping-order-item__1">
                    ${go(
                      delivery_type_grouped_pre_shipping_order.up_item_cart_style_ups,
                      strMap((up_item_cart_style_up) => {
                        return html`
                          <div class="pre-shipping-order-item__1__item">
                            ${MShopAppUpItemTmplS.cartStyleUpItem(up_item_cart_style_up)}
                          </div>
                        `;
                      }),
                    )}
                  </div>
                  ${is_oversea
                    ? ``
                    : html`
                        <div class="pre-shipping-order-item__2">
                          <div class="pre-shipping-order-item__price-set pc">
                            <div class="pre-shipping-order-item__price-set__label">
                              <span>
                                <span>${T('mshop_cart_and_checkout::배송비')}</span>
                                <span class="tooltip-icon2">${tooltipIcon2El}</span>
                                <div class="tooltip-container">
                                  <h4 class="tooltip-title">배송비 조건</h4>
                                  <p style="margin-bottom:3px;">
                                    상품의 배송비 조건에 따라 배송비가 부과됩니다.
                                  </p>
                                  <p style="margin-bottom:3px;">
                                    같은 크리에이터의 상품도 출고지에 따라 배송비 조건이 각각 적용될 수
                                    있습니다.
                                  </p>
                                </div>
                              </span>
                              <div class="pre-shipping-order-item__price-set__free-shipping">
                                ${T('mshop_cart_and_checkout::무료배송')}
                              </div>
                              <div class="pre-shipping-order-item__price-set__price mp_currency">
                                ${PriceS.pricify(delivery_type_grouped_pre_shipping_order.shipping_price)}
                              </div>
                              <div class="pre-shipping-order-item__price-set__condition">
                                <span class="mp_currency"
                                  >${PriceS.pricify(
                                    delivery_type_grouped_pre_shipping_order.free_shipping_min_price,
                                  )}</span
                                >${T('mshop_cart_and_checkout::이상')}<br />${T(
                                  'mshop_cart_and_checkout::구매시 무료 배송',
                                )}
                              </div>
                            </div>

                            <div class="pre-shipping-order-item__download-period pc">
                              ${T('mshop_cart_and_checkout::결제 완료 후')}<br />
                              ${T('mshop_cart_and_checkout::7일간 다운로드')}<br />
                              ${T('mshop_cart_and_checkout::가능')}
                            </div>
                            <div class="pre-shipping-order-item__download-period mobile">
                              ${T('mshop_cart_and_checkout::결제 완료 후 7일간 다운로드 가능')}
                            </div>
                          </div>
                          <div class="pre-shipping-order-item__price-set mobile">
                            <div class="pre-shipping-order-item__download-period mobile">
                              ${T('mshop_cart_and_checkout::결제 완료 후 7일간 다운로드 가능')}
                            </div>
                            <div class="pre-shipping-order-item__price-set__basic">
                              <div class="pre-shipping-order-item__price-set__label">
                                <div class="pre-shipping-order-item__price-set__shipping-label">
                                  ${T('mshop_cart_and_checkout::배송비')}
                                  <span class="tooltip-icon2">${tooltipIcon2El}</span>
                                  <div class="tooltip-container">
                                    <h4 class="tooltip-title">배송비 조건</h4>
                                    <p style="margin-bottom:3px;">
                                      상품의 배송비 조건에 따라 배송비가 부과됩니다.
                                    </p>
                                    <p style="margin-bottom:3px;">
                                      같은 크리에이터의 상품도 출고지에 따라 배송비 조건이 각각 적용될 수
                                      있습니다.
                                    </p>
                                  </div>
                                </div>
                                <div class="pre-shipping-order-item__price-set__condition">
                                  <span>|</span>
                                  <span>
                                    ${MShopUtilS.moneySign(
                                      PriceS.pricify(
                                        delivery_type_grouped_pre_shipping_order.free_shipping_min_price,
                                      ),
                                    )}
                                    ${T('mshop_cart_and_checkout::이상')}
                                    ${T('mshop_cart_and_checkout::구매시 무료 배송')}</span
                                  >
                                </div>
                              </div>
                              <div class="pre-shipping-order-item__price-set__content">
                                <div class="pre-shipping-order-item__price-set__free-shipping">
                                  ${T('mshop_cart_and_checkout::무료배송')}
                                </div>
                                <div class="pre-shipping-order-item__price-set__price">
                                  ${MShopUtilS.moneySign(
                                    PriceS.pricify(delivery_type_grouped_pre_shipping_order.shipping_price),
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="pre-shipping-order-item__price-set__additional">
                              <div class="pre-shipping-order-item__price-set__label">
                                <div class="pre-shipping-order-item__price-set__shipping-label">
                                  ${T('mshop_cart_and_checkout::도서산간비')}
                                </div>
                              </div>
                              <div class="pre-shipping-order-item__price-set__content">
                                <div class="pre-shipping-order-item__price-set__price">
                                  ${MShopUtilS.moneySign(
                                    PriceS.pricify(
                                      delivery_type_grouped_pre_shipping_order.additional_shipping_price,
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      `}
                </div>
              </div>
            `,
          ),
        )}
      </div>
    </div>
  `;
};
