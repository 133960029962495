export const tooltipIcon2El = `
  <span class="tooltip-icon2--question-default">
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle stroke="#4E4E4E" cx="8" cy="8" r="7.5" />
        <text
          font-family="AppleSDGothicNeo-SemiBold, Apple SD Gothic Neo"
          font-size="11"
          font-weight="500"
          letter-spacing="-.5"
          fill="#4E4E4E"
        >
          <tspan x="6" y="12">?</tspan>
        </text>
      </g>
    </svg>
  </span>
  <span class="tooltip-icon2--question-hover">
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle stroke="#3C89F9" cx="8" cy="8" r="7.5" />
        <text
          font-family="AppleSDGothicNeo-SemiBold, Apple SD Gothic Neo"
          font-size="11"
          font-weight="500"
          letter-spacing="-.5"
          fill="#3C89F9"
        >
          <tspan style="user-select:none" x="6" y="12">?</tspan>
        </text>
      </g>
    </svg>
  </span>
`;
