import { $data, $find } from 'fxdom/es';
import { drawProductFace } from '../../../../../Maker/F/draw_product_faces.js';

export const drawThumbnail = (product_face_el) => {
  if (!product_face_el.dataset.fxJson) return;
  const product_face = $data(product_face_el);
  const canvas = $find('canvas')(product_face_el);
  product_face_el.dataset.product_face = null;
  drawProductFace(canvas, product_face, false, false, false, false);
};
