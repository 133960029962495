import { flatMapL, go, mapL, pick, sortBy } from 'fxjs/es';

export const flatAndPickUpCSs = (ups) =>
  go(
    ups,
    flatMapL((up) => up._.up_cs),
    flatMapL((up_c) => up_c._.up_c_ss),
    mapL(
      pick([
        'id',
        'quantity',
        'base_product_id',
        'base_product_color_id',
        'base_product_size_id',
        'spo_item_id',
        'discounted_price',
      ]),
    ),
    sortBy(({ id }) => id),
  );
