import { $attr, $before, $el, $find, $findAll, $on, $qsa, $remove, $removeAttr, $setAttr } from 'fxdom/es';
import { each, filter, find, go, map, pipe, sel, zipWithIndexL } from 'fxjs/es';
import Sortable from 'sortablejs';
import { MShopShareFramePopUpF } from '../../../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { legacyHtml } from '../../../../../Util/S/Function/util.js';
import { dataURLtoFile } from '../../../../Feed/F/util.js';
import { getCursorEl } from '../medium-editor.js';
import { createImageToolbar } from '../toolbar/medium-editor-image-toolbar.js';
import { createActionStack } from '../features/bug_tracker.js';

const addWildcardProtocol = (str) => {
  if (str.slice(0, 2) == '//') return str;
  if (str.slice(0, 4) == 'http') return str;
  return '//' + str;
};

const makeFeatureBtnEl = () =>
  $el(`<label>
    <div class="feature_btn">
      <input
        multiple
        name="insert-image"
        type="file"
        accept="image/jpeg,image/png,image/pjpeg,image/gif"
      />
      <i class="lnr lnr-picture"></i>
      <span class="feature-name">사진</span>
    </div>
  </label>`);

const toDataUrl = (file) =>
  new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file); // convert to base64 string
  });

export const applyResizable = ($img_container) => {
  const $resize_wrapper = $find('.resize_wrapper', $img_container);
  const container_rect = $img_container.getBoundingClientRect();

  jQuery($resize_wrapper).resizable({
    autoHide: true,
    handles: 'e, w',
    resize: (event, ui) => {
      ui.position.left = 0;
      ui.position.right = 0;
      const redering_width = ui.element[0].getBoundingClientRect().width;
      const percentWidth = ((redering_width / container_rect.width) * 100).toFixed(2) + '%';
      ui.element[0].style.width = percentWidth;
      ui.size.height = 'auto';
      $setAttr({ 'data-width': percentWidth }, ui.originalElement[0]);
    },
  });
  return $img_container;
};

export const destroyImageResizable = pipe(
  $findAll('.img_container .resize_wrapper .ui-resizable-handle'),
  each($remove),
);

export const removeDraggableAttr = pipe(
  $findAll('.img_container .resize_wrapper a, .img_container .resize_wrapper img'),
  each($removeAttr('draggable')),
);

export const replaceImgWidthRelative = ($editor) => {
  const $img_container = $find('.img_container', $editor);
  if (!$img_container) return $editor;

  go(
    $findAll('.img_container .resize_wrapper', $editor),
    each(($wrapper) => {
      const width_percent = $attr('data-width', $wrapper) || '100%';
      $wrapper.style.width = width_percent;
      $wrapper.style.height = 'auto';

      const $size_info = $el(`<div class="image_size_info">width: ${width_percent}; height: auto;</div>`);

      $before($size_info, $wrapper);

      // image src replace
      const img$ = $find('img', $wrapper);
      if (img$.src.includes('data:image')) return;

      img$.src = UtilImageS.getResizedUrl({ url: img$.src, width: 1276, format: 'webp' });
    }),
  );
};

export const uploadEditorImgs = pipe(
  ($tab) => $findAll('.img_container img.image', $tab),
  filter(($img) => $img.src.includes('data:image')),
  async ($imgs) => {
    if (!$imgs.length) return;

    const files = map(($img) => $img.file || dataURLtoFile($attr('src', $img), 'hi.png'), $imgs);
    const { urls: img_urls, msg } = await $.upload_exif({ files }, { url: '/@api/editor/images' });

    if (msg) throw new Error('이미지 사이즈가 10MB를 초과합니다.');

    each(([idx, $img]) => {
      $img.src = addWildcardProtocol(
        UtilImageS.getResizedUrl({ url: img_urls[idx], width: 1276, format: 'jpg' }),
      );
    }, zipWithIndexL($imgs));
  },
);

export const applyImageSortable = ($editable) => {
  go(
    $findAll('.img_container', $editable),
    filter(($container) => $findAll('.image', $container).length > 1),
    each(
      ($container) =>
        ($container.sortable = Sortable.create($find('.resize_wrapper', $container), {
          dragClass: 'on_drag',
          selectedClass: 'selected',
          easing: 'cubic-bezier(0.0, 0.0, 0.58, 1.0)',
          draggable: '> .image',
        })),
    ),
  );
};

export const initImagePlugin = async (registerBtnToInsertBox, option = {}, editor) => {
  // insert image
  const feature_btn_el = makeFeatureBtnEl();
  registerBtnToInsertBox(feature_btn_el);

  const resizable_script = go(
    $qsa('script'),
    find((script) => script.src && script.src.includes('jquery-ui')),
  );

  await new Promise((res) => {
    if (window.jQuery().resizable) res();
    resizable_script.onload = () => res();
  });

  createImageToolbar(editor);
  const $editable = editor.elements[0];

  applyImageSortable($editable);

  // init saved image html
  go($findAll('.img_container', $editable), map($setAttr({ contenteditable: false })), map(applyResizable));

  const image_input_el = $find('input', feature_btn_el);
  const onImageInputChange = async (e) => {
    if (sel('target.files.length', e) == 0) return;
    const insert$ = e.target;

    try {
      if (option.validate) {
        await option.validate(e.target);
      }

      const $current = getCursorEl(editor);

      await go(
        insert$.files,
        async (files) => {
          const $img_container = $el(legacyHtml`
          <div class="img_container" contenteditable="false">
            <div class="resize_wrapper"></div>
          </div>
        `);

          const $resize_wrapper = $find('.resize_wrapper', $img_container);

          await each(async (file) => {
            const $img = $el(`
                <img class="image" src="${(await toDataUrl(file)) || ''}" alt="">
          `);
            $img.file = file;
            $resize_wrapper.appendChild($img);
          }, files);

          $img_container.sortable = Sortable.create($resize_wrapper, {
            dragClass: 'on_drag',
            selectedClass: 'selected',
            easing: 'cubic-bezier(0.0, 0.0, 0.58, 1.0)',
            draggable: '> .image',
          });

          createActionStack('image added', { $img_container });

          return $img_container;
        },
        ($img_container) => $before($img_container, $current),
        applyResizable,
      );

      insert$.value = '';
    } catch (err) {
      insert$.value = '';

      if (err?.title) {
        return MShopShareFramePopUpF.alert(err);
      }
      $.alert(err);
    }
  };

  $on('change', onImageInputChange)(image_input_el);
};
