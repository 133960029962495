import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { delay, go, tap } from 'fxjs/es';
import { UserJwtF } from '../../../../../User/Jwt/F/Function/module/UserJwtF.js';
import { resetUserCounts } from '../../../../../MPay/Cart/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { PBPbUpS } from '../../../../../PB/PbUp/S/Function/module/PBPbUpS.js';
import axios from 'axios';

export const addPbUp = async (pb_up_material, is_cart) => {
  PBPbUpS.pbUpMaterial(pb_up_material);

  const scroll_top_promise = !MShopUtilF.isMobile() ? MShopUtilF.moveScrollToTop(200) : undefined;
  const { data: up } = await axios.post(`/${T.lang}/@api/pb_ups/add`, { pb_up_material });
  await go(
    up,
    async (up) => {
      if (up._?.user) await go(UserJwtF.loginMessage(up._.user), delay(600));
      return up;
    },
    tap(() => {
      if (is_cart) {
        const data = { ...box.sel('user_counts') };
        data.cart += Number(pb_up_material.quantity);
        resetUserCounts({ data });
      }
    }),
    async () => {
      if (MShopUtilF.isMobile()) {
        await MuiF.closeFrame();
      }
      if (is_cart) {
        await scroll_top_promise;
        MShopUtilF.popToastMsg(T('Added to cart'), 'info');
      }
    },
  );
  return up;
};
