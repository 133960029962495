import { curry2, pipe, takeAll, toIter } from 'fxjs/es';

export const compareArrL = curry2(function* (f, xs1, xs2) {
  const xs2_iter = toIter(xs2);
  for (const x1 of xs1) {
    yield f(x1, xs2_iter.next().value);
  }
});

export const subArrL = compareArrL((a, b) => a - b);

export const subArr = pipe(subArrL, takeAll);

export const mapAccL = curry2(function* (f, init, iter) {
  let acc = init;
  const iterator = iter[Symbol.iterator]();
  let a, a1;
  while (((a = iterator.next()), !a.done)) {
    [a1, acc] = f(a.value, acc);
    yield [a1, acc];
  }
});

export const mapAcc = curry2((f, init, iter) => takeAll(mapAccL(f, init, iter)));

export const between = curry2((min, max, n) => n >= min && n < max);
