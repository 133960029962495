import { head, go, map, filter, identity, sel, find } from 'fxjs/es';
import { CreatorEditorButtonS } from '../../S/Function/module/CreatorEditorButtonS.js';
import { $addClass, $find, $is, $removeClass } from 'fxdom/es';

export const changeButtonColor = (button_el, color_code) => {
  $removeClass(findButtonColorClass(button_el), button_el);
  $addClass(makeButtonColorClass(color_code), button_el);
  return button_el;
};

export const findButtonColorClass = (button_el) =>
  find((klass) => /^editor-button__content--(\w+)$/.test(klass), button_el.classList);

export const makeButtonColorClass = (color_code) =>
  `editor-button__content--${CreatorEditorButtonS.colorValueToName(color_code)}`;

export const parseButtonColor = (button_el) =>
  go(
    $is('.editor-button__content', button_el) ? button_el : $find('.editor-button__content', button_el),
    sel('classList'),
    map((klass) => /^editor-button__content--(\w+)$/.exec(klass)),
    filter(identity),
    head,
    sel('1'),
  );
