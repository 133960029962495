import { initTooltips2 } from '../../../../../Util/F/tooltip.js';
import { each, go } from 'fxjs/es';
import { $findAll, $qs } from 'fxdom/es';
import { MShopAppUpItemF } from '../../../UpItem/F/Function/module/MShopAppUpItemF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import StickySidebar from 'sticky-sidebar';

export const afterRender = (el) => {
  initTooltips2({
    container_el: el,
    custom: {
      placement: 'bottom',
      animation: 'shift-toward',
      maxWidth: MShopUtilF.isMobile() ? 450 : 310,
      offset: undefined,
    },
  });
  go(el, $findAll('.canvas-basic-item'), each(MShopAppUpItemF.drawThumbnail));
  if (!MShopUtilF.isMobile() && $qs('.cart-up-item-list-price')) {
    new StickySidebar('.cart-up-item-list-price', {
      containerSelector: '.cart-up-item-list-price__sticky-sidebar',
      bottomSpacing: 10,
      topSpacing: 50,
    });
  }
};
