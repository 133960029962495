import { sel } from 'fxjs/es';
import { isNeedPreview } from '../../../../../Maker/S/categorize.js';
/*
 * {
 * product_color:{product_faces2.can_not_change_color}
 * base_product:{name}
 * base_product_color:{name}
 * is_color_show: base_product_colors.length > 1
 * }
 *
 * */
export const makeMpUpSecondName = ({
  product_color,
  base_product,
  base_product_color,
  is_color_show,
  selected_option_group,
}) => {
  const can_not_change_color = Boolean(sel('product_faces2.can_not_change_color', product_color));
  const color_name = base_product_color['name' + G._en];
  const option_title = selected_option_group?.title;
  const bp_name = base_product['name_creator' + G._en];
  return `${bp_name}${
    !is_color_show || can_not_change_color || isNeedPreview(base_product) ? '' : ` [${color_name}]`
  }${option_title ? ` - ${option_title}` : ''}`;
};
