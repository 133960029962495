import { html } from 'fxjs/es';

export const wrapper = (type, makeHtml, is_mobile) => html`
  <div class="request-of-solution">
    ${is_mobile
      ? ''
      : html`
          <div class="request-of-solution-menu">
            <div class="help_tab">
              <ul>
                <li selected="${type == 'home'}">
                  <a href="/${T.lang}/@/solution">${T('report::서비스 안내')}</a>
                </li>
                <li selected="${type == 'add'}">
                  <a href="/${T.lang}/@/solution/add">${T('report::신고 접수하기')}</a>
                </li>
                <li selected="${type == 'list'}">
                  <a href="/${T.lang}/@/solution/list">${T('report::조회하기')}</a>
                </li>
              </ul>
            </div>
          </div>
        `}
    <div class="request-of-solution-body">${makeHtml}</div>
  </div>
`;

export const fn = (is_mobile) =>
  wrapper(
    'home',
    html`
      <div class="request-of-solution-home">
        ${is_mobile ? html`<h1>${T('report::신고 접수는 PC에서만 가능합니다.')}</h1>` : ''}
        <h2>${T('report::신고 접수 서비스 안내')}</h2>
        <div class="article">
          <p>${T('report::마플샵에서 판매 중인 상품이 제3자 저작물의')}</p>
          <p>${T('report::해당 상품이 실제로 제3자의 권리를 침해하였는지 여부는')}</p>
          <p>${T('report::신고 접수 서비스는 고객님을 보호하기 위한 임시적인 방법')}</p>
          <p>${T('report::신속하고 정확한 신고접수 서비스 운영을 통해')}</p>
        </div>
        <div class="article">
          <h2>${T('report::관련기관 홈페이지')}</h2>
          <ul class="org-list">
            <li>
              <a href="http://www.police.go.kr/www/security/cyber.jsp" target="_blank"
                ><img
                  class="police-img"
                  src="//image1.marpple.co/files/u_29089/2020/7/original/3109939873ae3490a3242ff2cffbaaa53747808f2938942.png${is_mobile
                    ? ''
                    : '?w=168'}"
                  alt="${T('report::경찰청 사이버안전국')}"
              /></a>
            </li>
            <li>
              <a href="https://www.copyright.or.kr/main.do" target="_blank"
                ><img
                  class="copyright-img"
                  src="//image1.marpple.co/files/u_29089/2020/7/original/311001163084eeadbc86ea0c82f81207a11f50e62089a1a.png${is_mobile
                    ? ''
                    : '?h=34'}"
                  alt="${T('report::한국저작권위원회')}"
              /></a>
            </li>
          </ul>
          <p class="a2">${T('report::이미지를 클릭 하면, 해당 홈페이지로 이동합니다.')}</p>
        </div>
        <div class="article">
          <h2>${T('report::신고 접수 서비스를 접수할 수 있는 대상')}</h2>
          <p>${T('report::① 저작권침해')}</p>
          <p>${T('report::② 위조상품 판매')}</p>
          <p>${T('report::③ 마플샵 내부 규정 위반')}</p>
        </div>
        <div class="article">
          <h2>${T('report::신고 접수 시 처리 과정')}</h2>
          <p>${T('report::저작권 침해 / 위조 상품 판매 (상표권·디자인권 침해)')}</p>
          <div class="process-img">
            <img
              src="${is_mobile
                ? '//s3.marpple.co/files/u_29089/2020/7/original/3147228edc17096c56b8bf25f17a0a7c1657f3785aa5842.jpg'
                : '//s3.marpple.co/files/u_29089/2020/7/original/31472428b514bdb5ba878fe7536d9fab930d56017477275.jpg'}"
              alt=""
            />
          </div>
        </div>
        <div class="article">
          <h2>${T('report::저작권 침해 신고 접수 시 유의사항')}</h2>
          <p>${T('report::저작권침해 신고 접수 시 해당 상품을 판매 중인')}</p>
          <p>${T('report::셀러가 저작권자이거나 정당한 권리자임을 증빙')}</p>
          <p>${T('report::신고 접수 스레드는 요청자-셀러 간의 합의를 위한 공간')}</p>
          <p>${T('report::저작권법 제 103조 제 3항 및 동법 시행령')}</p>
          <p>${T('report::재판매된 상품에 대해서는 반복하여 신고 접수를 할 수 없으며')}</p>
        </div>
        <div class="article">
          <h2>${T('report::위조상품판매 신고 접수 시 유의사항')}</h2>
          <p>${T('report::위조상품에 대한 조치 요청은 상표법 제107조')}</p>
          <p>${T('report::상표법 제108조')}</p>
          <p>${T('report::위조상품 판매로 인한 조치 시 해당 상품을')}</p>
          <p>${T('report::셀러는 위조 상품이 아니라 정당한 상표 등의')}</p>
          <p>${T('report::신고 접수 스레드는 요청자-셀러 간의 합의를 위한 공간')}</p>
        </div>
        <div class="article">
          <h2>${T('report::마플샵 내부 규정 위반')}</h2>
          <div class="process-img">
            <img
              src="${is_mobile
                ? '//s3.marpple.co/files/u_29089/2020/7/original/31472306fcfb256df35825f72f5191a397f2c33d30438c8.jpg'
                : '//s3.marpple.co/files/u_29089/2020/7/original/3147243fffbf1075d2a32906c126d09988b760ba9bb3654.jpg'}"
              alt=""
            />
          </div>
        </div>
        <div class="article">
          <h2>${T('report::마플샵 내부 규정 위반 신고 접수 시 유의사항')}</h2>
          <p>${T('report::마플샵 내부 규정 위반 신고 접수 시 해당 상품을 판매 중인')}</p>
          <p>${T('report::셀러가 내부 규정 위반 사실이 없음을 증명할 수 있는 자료를 첨부한')}</p>
          <p>${T('report::14일 이내 답변 등록이 되지 않은 경우와 심사 후 규정 위반')}</p>
        </div>
        ${is_mobile
          ? ''
          : html`
              <div class="buttons">
                <a href="/${T.lang}/@/solution/list"><span>${T('report::조회하기')}</span></a>
                <a href="/${T.lang}/@/solution/add"><span>${T('report::신고 접수하기')}</span></a>
              </div>
            `}
      </div>
    `,
    is_mobile,
  );
