import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPbProductTipMuiF } from '../Mui/module/MShopStudioPbProductTipMuiF.js';

export const openFrame = () => {
  MuiF.openFrame(MShopStudioPbProductTipMuiF.frame, async (frame, page, [tab]) => {
    frame.header_height = 0;
    frame.hide_frame_button_position = 'RIGHT';
    frame.hide_frame_button_type = 'X';
    frame.animation = false;
    frame.is_modal = !MShopUtilF.isMobile();
  });
};
