import { each, go } from 'fxjs/es';
import { $addClass, $findAll, $remove, $removeClass } from 'fxdom/es';

export const calcDistance = ($container, $target) => {
  const target_rect = $target.getBoundingClientRect();
  const container_rect = $container.getBoundingClientRect();
  return {
    left: target_rect.x - container_rect.x + target_rect.width / 2,
    top: target_rect.y - container_rect.y,
  };
};

export const removeToolbarHtml = ($tab) => {
  go($findAll('.medium-editor-toolbar', $tab), each($remove));
};

export const highlight_action = (on, action$) =>
  (on ? $addClass : $removeClass)('medium-editor-button-active', action$);
