import { $addClass, $find, $qs, $setText } from 'fxdom/es';
import { go } from 'fxjs/es';

export const alert = ({ title, body, ok }) => {
  const prom = $.alert(`${title ? `<div class="title">${title}</div>` : ``}
      ${body ? `<div class="popup-body">${body}</div>` : ``}`);
  const el = $qs('.don_dialog_msg');
  go(el, $addClass('mshop-pop-up-style-1'));
  if (ok) {
    go(el, $find('.ok'), $setText(ok));
  }
  return prom;
};
