export const getExtensionOfFilename = (file_name) => {
  const file_len = file_name.length;
  const lastDot = file_name.lastIndexOf('.');
  const file_ext = file_name.substring(lastDot, file_len).toLowerCase();
  return file_ext;
};

export const byteConverter = (byte, decimals, only) => {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  if (byte == 0) return '0 Byte';
  if (only === 'MB') return (byte / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';
  const idx = Math.floor(Math.log(byte) / Math.log(K_UNIT));
  const result = parseFloat((byte / Math.pow(K_UNIT, idx)).toFixed(decimals)) + ' ' + SIZES[idx];
  return result;
};

export const convertToBytes = (size) => {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };

  const sizeParts = size.match(/(\d+)([A-Z]+)/);
  const value = Number(sizeParts[1]);
  const unit = sizeParts[2];

  return value * units[unit];
};
