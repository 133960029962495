import { html } from 'fxjs/es';
import { messages } from '../../../Creator/Setting/S/messages_id.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { GoodsTypeConstantS } from '../Constant/module/GoodsTypeConstantS.js';
import { GoodsTypeS } from '../Function/module/GoodsTypeS.js';

export const selectGoodsTypeHref = ({ has_permission_tpl, goods_type_id, href }) => {
  if (has_permission_tpl) {
    return html`
      <div class="select-goods-type-href">
        <div class="select-goods-type-href__items">
          <div
            data-goods_type_id="${GoodsTypeConstantS.PBG.id}"
            class="select-goods-type-href__item${GoodsTypeS.isPbg(goods_type_id)
              ? ' select-goods-type-href__item--selected'
              : ''}"
          >
            <a href="${href}">셀러 상품</a>
          </div>
          <div
            data-goods_type_id="${GoodsTypeConstantS.TPL.id}"
            class="select-goods-type-href__item${GoodsTypeS.isTpl(goods_type_id)
              ? ' select-goods-type-href__item--selected'
              : ''}"
          >
            <a href="${href}/tpl">입고 상품</a>
          </div>
        </div>
      </div>
    `;
  }
};

export const adjustmentGoodsTypeHref = ({ has_permission_tpl, goods_type_id, domain_name }) => {
  return html` <div class="select-goods-type-href">
    <div class="select-goods-type-href__items">
      <div
        data-goods_type_id="${GoodsTypeConstantS.MPS.id}"
        class="select-goods-type-href__item${GoodsTypeS.isMps(goods_type_id)
          ? ' select-goods-type-href__item--selected'
          : ''}"
      >
        <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/adjustments"
          >${T(messages.menus.design_adjustments)}</a
        >
      </div>
      <div
        data-goods_type_id="${GoodsTypeConstantS.PBG.id}"
        class="select-goods-type-href__item${GoodsTypeS.isPbg(goods_type_id)
          ? ' select-goods-type-href__item--selected'
          : ''}"
      >
        <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/pb_adjustments"
          >${T(messages.menus.seller_adjustments)}</a
        >
      </div>
      ${has_permission_tpl
        ? html`<div
            data-goods_type_id="${GoodsTypeConstantS.TPL.id}"
            class="select-goods-type-href__item${GoodsTypeS.isTpl(goods_type_id)
              ? ' select-goods-type-href__item--selected'
              : ''}"
          >
            <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/tpl_adjustments"
              >${T(messages.menus.tpl_adjustments)}</a
            >
          </div>`
        : ''}
      <div
        data-goods_type_id="${GoodsTypeConstantS.DTG.id}"
        class="select-goods-type-href__item${GoodsTypeS.isDtg(goods_type_id)
          ? ' select-goods-type-href__item--selected'
          : ''}"
      >
        <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/dtg_adjustments"
          >${T(messages.menus.dtg_adjustments)}</a
        >
      </div>
    </div>
  </div>`;
};
