import $dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { GoodsTypeConstantS } from '../../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
/*
 * TODO stores_delivery_companies 에 delivery_type_id 로 구분해볼수있을듯 ??
 */
const SHIPPING_PLACE = {
  [GoodsTypeConstantS.MPS.id]: T('order::마플샵배송'),
  [GoodsTypeConstantS.PBG.id]: T('order::셀러배송'),
  [GoodsTypeConstantS.TPL.id]: T('order::마플샵배송'),
  [GoodsTypeConstantS.DTG.id]: T('order::디지털상품'),
};

export const pThumbProductInfo = (n_thumb_product_info, n_to_detail_url) => {
  const { store_name, product_name, price, goods_type_id, _en } = n_thumb_product_info;
  return html`
    <a
      href="${n_to_detail_url}"
      data-post-message="${$dataStr({
        navigate: {
          sel: 'TabNavigation.HomeStack.UtilScreen',
          params: {
            type: 'sub',
            title: T('order::Order details'),
            uri: UtilS.escape(n_to_detail_url),
          },
        },
      })}"
    >
      <div class="up-item-product-info">
        <div class="up-item-product-info__shipping-place">${SHIPPING_PLACE[goods_type_id]}</div>
        <div class="up-item-product-info__store-name">${store_name}</div>
        <div class="up-item-product-info__name">${product_name}</div>
        <div class="up-item-product-info__price mp_currency">${PriceS.pricify_by(price, _en)}</div>
      </div>
    </a>
  `;
};
